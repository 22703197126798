import { Model } from '@nozbe/watermelondb'
import { field, date, readonly } from '@nozbe/watermelondb/decorators'

export default class ProductInventory extends Model {
    
    static table = 'products_inventory'

    @field('company_id') companyId
    @field('cost') cost
    @field('outlet_id') outletId
    @field('price') price
    @field('product_id') productId
    @field('qty') qty
    @field('variant_id') variantId
    
    @field('remote_id') remoteId
    @readonly @date('created_at') createdAt
    @readonly @date('updated_at') updatedAt
}