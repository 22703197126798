import React, { useState } from 'react';
import { TextInputField, FormField, Button, Spinner, BackButton, Switch } from 'evergreen-ui';
import { InputNumber, Select } from 'antd';

import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import { CurrencyWrapper } from '../../services/Currency';

const GET_OUTLET = gql`
    query($id: Int!) {

        outlet: outlets_by_pk(id: $id) {
            id
            name
            phone
            commission
            wholesale
            warehouse
            discount_limit
            supervisor_percent
            salesperson_percent
            company_id
            raw_materials
        }
    }
`;

const SAVE_MUTATION = gql`

    mutation add(
        $id: Int!,
        $name: String!,
        $phone: String!,
        $company_id: Int,
        $commission: Boolean,
        $wholesale: Boolean,
        $warehouse: Boolean,
        $raw_materials: Boolean,
        $discount_limit: float8,
        $supervisor_percent: float8,
        $salesperson_percent: float8
    ) {
        update_outlets(where: {id: {_eq: $id}}, _set: {
            name: $name,
            phone: $phone,
            commission: $commission,
            wholesale: $wholesale,
            warehouse: $warehouse,
            raw_materials: $raw_materials,
            discount_limit: $discount_limit,
            supervisor_percent: $supervisor_percent,
            salesperson_percent: $salesperson_percent
        }) {
            affected_rows
        }
    }  

`;

const ADD_MUTATION = gql`

    mutation add(
        $id: Int,
        $name: String!,
        $phone: String!,
        $company_id: Int,
        $commission: Boolean,
        $wholesale: Boolean,
        $raw_materials: Boolean,
        $discount_limit: float8,
        $supervisor_percent: float8,
        $salesperson_percent: float8
    ){
        insert_outlets(objects: {
            name: $name,
            phone: $phone,
            company_id: $company_id,
            commission: $commission,
            raw_materials: $raw_materials,
            wholesale: $wholesale,
            discount_limit: $discount_limit,
            supervisor_percent: $supervisor_percent,
            salesperson_percent: $salesperson_percent
        }) {
            affected_rows
        }
    }
  

`;

const OutletWrapper = ({ outletId, outlet, history, companyId }) => {

    const [name, setName] = useState(outlet.name || '');
    const [phone, setPhone] = useState(outlet.phone || '');
    const [commission, setCommission] = useState(outlet.commission);
    const [wholesale, setWholesale] = useState(outlet.wholesale);
    const [warehouse, setWarehouse] = useState(outlet.warehouse);
    const [supervisorPercentage, setSupervisorPercentage] = useState(outlet.supervisor_percent || 0.00);
    const [salespersonPercentage, setSalesPersonPercentage] = useState(outlet.salesperson_percent || 0.00);
    const [discountLimit, setDiscountLimit] = useState(outlet.discount_limit || 0.00);
    const [rawMaterials, setRawMaterials] = useState(outlet.raw_materials);

    return (
        <div className="p-8 rounded-lg shadow-lg">
            <div className="">
                <p className="text-black text-lg font-bold">{outletId ? 'Edit' : 'Create'} Outlet</p>
                <div className="mt-4">
                    <TextInputField
                        label="Name"
                        defaultValue={name}
                        onChange={(e) => {

                            setName(e.target.value);

                        }}
                        placeholder="Name"
                    />
                    <TextInputField
                        label="Phone Number"
                        defaultValue={phone}
                        onChange={(e) => {

                            setPhone(e.target.value);

                        }}
                        placeholder="Phone Number"
                    />
                    <div className="mb-2">
                        <p className="📦color_425A70 📦fnt-fam_b77syt 📦fnt-sze_14px 📦f-wght_500 📦ln-ht_20px 📦ltr-spc_-0-05px 📦dspl_block 📦mb_4px 📦box-szg_border-box">
                            Discount Limit
                        </p>
                        <CurrencyWrapper
                            component={({ symbol }) => (
                                <InputNumber
                                    value={discountLimit}
                                    min={0.00}
                                    onClick={(e) => {

                                        e.target.select();

                                    }}
                                    onChange={(discount) => {

                                        setDiscountLimit(discount);

                                    }}
                                    style={{ width: '100%' }}
                                    parser={value => value.replace(/[^0-9.]/g, '')}
                                    formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                // size="small"
                                />
                            )}
                        />
                    </div>
                    <p className="📦color_425A70 📦fnt-fam_b77syt 📦fnt-sze_14px 📦f-wght_500 📦ln-ht_20px 📦ltr-spc_-0-05px 📦dspl_block 📦mb_4px 📦box-szg_border-box">Enable Warehouse</p>
                    <Switch marginBottom={16}
                        checked={warehouse}
                        onChange={(e) => setWarehouse(e.target.checked)}
                    />
                    <p className="📦color_425A70 📦fnt-fam_b77syt 📦fnt-sze_14px 📦f-wght_500 📦ln-ht_20px 📦ltr-spc_-0-05px 📦dspl_block 📦mb_4px 📦box-szg_border-box">Enable Commission</p>
                    <Switch marginBottom={16}
                        checked={commission}
                        onChange={(e) => setCommission(e.target.checked)}
                    />

                    <p className="📦color_425A70 📦fnt-fam_b77syt 📦fnt-sze_14px 📦f-wght_500 📦ln-ht_20px 📦ltr-spc_-0-05px 📦dspl_block 📦mb_4px 📦box-szg_border-box">Enable Wholesale</p>
                    <Switch marginBottom={16}
                        checked={wholesale}
                        onChange={(e) => setWholesale(e.target.checked)}
                    />
                    <p className="📦color_425A70 📦fnt-fam_b77syt 📦fnt-sze_14px 📦f-wght_500 📦ln-ht_20px 📦ltr-spc_-0-05px 📦dspl_block 📦mb_4px 📦box-szg_border-box">Enable Raw Materials</p>
                    <Switch marginBottom={16}
                        checked={rawMaterials}
                        onChange={(e) => setRawMaterials(e.target.checked)}
                    />
                    <TextInputField defaultValue={supervisorPercentage} label="Supervisor Percentage" onChange={(e) => {

                        if (/^\d*\.?\d*$/.test(e.target.value)) {

                            setSupervisorPercentage(e.target.value);

                        }
                    }
                    }
                        placeholder="Enter Supervisor Percentage" />

                    <TextInputField label="Salesperson Percentage" onChange={(e) => {

                        if (/^\d*\.?\d*$/.test(e.target.value)) {

                            setSalesPersonPercentage(e.target.value);

                        }
                    }
                    }
                        defaultValue={salespersonPercentage}
                        placeholder="Enter Salesperson Percentage" />
                </div>
                <Mutation
                    onCompleted={() => history.push(`/${companyId}/pos/outlets`)} mutation={outletId ? SAVE_MUTATION : ADD_MUTATION} variables={{
                        company_id: companyId,
                        id: outletId,
                        name,
                        phone,
                        commission,
                        wholesale,
                        warehouse,
                        raw_materials: rawMaterials,
                        discount_limit: discountLimit,
                        supervisor_percent: supervisorPercentage,
                        salesperson_percent: salespersonPercentage
                    }}
                >
                    {(add, { loading }) => {

                        return (
                            <Button appearance="primary" intent="success" isLoading={loading} onClick={() => add()}>Save</Button>
                        )

                    }}
                </Mutation>
            </div>
        </div>
    )

}


class Outlet extends React.Component {

    state = {
        companyId: this.props.match.params.companyId,
        outletId: this.props.match.params.outletId
    }

    render() {

        const { companyId, outletId } = this.state;

        // console.log(outletId);

        return (
            <div className="max-w-lg w-full mx-auto my-12">
                <BackButton appearance="default" marginBottom={15} onClick={() => {

                    this.props.history.push(`/${companyId}/pos/outlets`);

                }} children="Back to outlets" />
                <Query
                    query={GET_OUTLET}
                    skip={(!outletId) ? true : false}
                    variables={{
                        id: outletId
                    }}
                >
                    {({ loading, data, error, refetch }) => {

                        return (

                            loading ? <div className="flex items-center justify-center py-10">
                                <Spinner />
                            </div> : !outletId || data && data.outlet ? <OutletWrapper
                                outletId={outletId}
                                outlet={outletId && data ? data.outlet : {}}
                                history={this.props.history}
                                companyId={companyId}
                            /> : null
                        )

                    }}
                </Query>
            </div>

        );

    }

}

export default Outlet;