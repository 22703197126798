import React from 'react';
import {Query, Mutation} from 'react-apollo';
import {Select, InputNumber, Button} from 'antd';
import {Spinner} from 'evergreen-ui';
import apollo from '../../../../services/apollo';

import gql from 'graphql-tag';

const SEARCH_PRODUCT = gql`
    query(
        $q: String!,
        $company_id: Int!
    ) {

        products(where: {
            name: {_ilike: $q},
            has_variants: {
                _eq: true
            },
            company_id: {
                _eq: $company_id
            }
        }) {

            id
            name
        }

    }
`;

const GET_PRODUCT = gql`
    query(
        $id: Int!
    ) {

        product: products_by_pk(id: $id) {
            id
            name
            variants{
                id
                name
                cost
                price
                attributes {
                    id
                    name: key
                    position
                    value
                }
                sku: skus{
                    id
                    code
                }
            }
        }

    }
`;

const CREATE_SKU = gql`
    mutation(
        $variant_id: Int!,
        $code: String!,
        $company_id: Int!
    ) {
        insert_products_sku(objects: {
            code: $code,
            variant_id: $variant_id,
            company_id: $company_id
        }) {
            returning {
                code
            }
        }
    }
  
`;

class SearchProduct extends React.Component{

    state = {
        searchValue: '',
        productId: null
    }

    render() {

        const {searchValue, productId, variantId, qty} = this.state;
        const {onOk, code, companyId} = this.props;

        return (
            <div>
                <div className="my-2">
                    <Query
                        query={SEARCH_PRODUCT}
                        variables={{
                            q: `%${searchValue}%`,
                            company_id: companyId
                        }}
                        skip={!searchValue}
                    >
                        {({data, error, loading}) => {


                            return (
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="Search Products"
                                    notFoundContent={loading ? <div className="flex items-center justify-center p-4"><Spinner size="small" /></div>: null}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={variantId}
                                    onSearch={(searchValue) => this.setState({searchValue})}
                                    value={productId}
                                    onChange={(productId) => {

                                        this.setState({
                                            productId,
                                            // searchValue: ''
                                        })

                                    }}
                                >
                                    {!loading && data && data.products && data.products.length ? data.products.map((v) => {

                                        return (
                                            <Select.Option key={v.id} value={v.id}>{v.name}</Select.Option>
                                        )

                                    }) :null}
                                </Select>
                            )

                        }}
                    </Query>
                </div>
                {productId ? <Query
                    query={GET_PRODUCT}
                    variables={{
                        id: parseInt(productId)
                    }}
                >
                    {({data, error, loading}) => {

                        if (loading) {

                            return (
                                'Loading...'
                            );

                        }

                        if (!data || error) {

                            return (
                                'No Variants Found'
                            )

                        }

                        // console.log(data);

                        return (
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Search Variants"
                                notFoundContent={loading ? <div className="flex items-center justify-center p-4"><Spinner size="small" /></div>: null}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                // disabled={qty}
                                // onSearch={(searchValue) => this.setState({searchValue})}
                                value={variantId}
                                onChange={(variantId) => {

                                    this.setState({
                                        variantId,
                                        qty: 1
                                        // searchValue: ''
                                    })

                                }}
                            >
                                {!loading && data && data.product.variants && data.product.variants.length ? data.product.variants.map((p) => {

                                    return (
                                        <Select.Option key={p.id} value={p.id}>{p.name}</Select.Option>
                                    )

                                }) :null}
                            </Select>
                        )

                    }}
                </Query> : null}
                {variantId ? <div className="flex items-center my-2">
                    <InputNumber
                        defaultValue={qty}
                        min={1}
                        onClick={(e) => e.target.select()}
                        onChange={(qty) => {

                            this.setState({qty});

                        }}
                    /> 
                    <Button onClick={() => {

                        this.setState({
                            productId: null,
                            variantId: null,
                            qty: 1
                        });

                    }} className="flex items-center justify-center mx-2" type="danger" size="small" ghost>Clear</Button>
                </div> : null}
                {variantId ? <Mutation
                    mutation={CREATE_SKU}
                    variables={{
                        code: `${code}`,
                        variant_id: variantId,
                        company_id: companyId
                    }}
                    onCompleted={() => onOk(code, qty)}
                >
                    {(save, {loading}) => {

                        return (
                            <Button loading={loading} onClick={() => save()} className="flex items-center justify-center my-4" type="primary" size="large" ghost>Save</Button>
                        )

                    }}
                </Mutation>: null}
            </div>
        )

    }

}

export default SearchProduct;