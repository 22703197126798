import React from 'react';
import {Input, Table} from 'antd';
import {includes} from 'lodash';
import firebase from '../services/firebase';


class SearchAddEmployees extends React.Component{

    state = {
        ...this.props,
        employees: []
    }

    columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Department',
          dataIndex: 'department',
          key: 'department',
          render: (department, record) => {
            
                return this.state.departments[department].name
            
          }
        }
    ];

    async componentDidMount() {

        const {companyId} = this.props;

        const companyRef = firebase.firestore().collection('companies').doc(companyId);

        const employees = await companyRef.collection('employees').get();

        this.setState({
            employees: employees.docs.map((doc) => ({...doc.data(), employeeId: doc.id}))
        });

    }

    onSelectChange = async (selectedRowKeys) => {
        
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        
        this.setState({ selectedRowKeys }, () => {

            this.props.onChange(this.state.employees.filter((employee) => {

                return includes(selectedRowKeys, employee.employeeId);

            }));

        });

    };

    componentDidUpdate(prevProps) {

        if (this.props.searchVisible !== prevProps.searchVisible) {

            this.setState({
                selectedRowKeys: [],
                existingEmployees: this.props.existingEmployees
            });

        }

    }

    render() {

        const {employees, existingEmployees, selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            getCheckboxProps: (record) => ({
                disabled: existingEmployees.indexOf(record.employeeId) > -1,
                name: record.name
            }),
            onChange: this.onSelectChange,
        };

        return (
            <div className="">
                <p className="font-bold text-lg text-black">Search and add employee</p>
                <div className="my-4">
                    <Input.Search placeholder="Search employee" />
                </div>
                <div className="my-6">
                    <Table rowKey={record => record.employeeId} rowSelection={rowSelection} pagination={false} dataSource={employees} columns={this.columns} />
                </div>
            </div>
        );

    }

}

export default SearchAddEmployees;