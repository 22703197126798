import React from 'react';
import {Input, Button as AntButton, Table, InputNumber, Switch, Modal} from 'antd';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {Move} from 'react-feather';
import {TagInput, Button as EverButton} from 'evergreen-ui';
import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import {reduce, flatten, map, intersection, values, sortBy, some, keys, includes, groupBy, uniq, orderBy, toPairs, findIndex, difference} from 'lodash';
import { CurrencyWrapper } from '../../services/Currency';

function cartesianProductOf() {
    return reduce(arguments, function(a, b) {
        return flatten(map(a, function(x) {
            return map(b, function(y) {
                return x.concat([y]);
            });
        }), true);
    }, [ [] ]);
}

const DragHandle = SortableHandle(() => <div className="cursor-move px-2">
    <Move size={18} />
</div>)

const SortableItem = SortableElement(({index, i, variant, productId, onChange, onDelete}) => <div className="my-4 flex items-center justify-center -mx-2">
    <div className="flex-0 px-2 flex flex-col">
        <p className="font-medium text-black mb-2">Option</p>
        <div className="flex items-center">
            <DragHandle />
            <Input placeholder="e.g. Size" onChange={(e) => {
                
                onChange(i, 'name', e.target.value)}
                
            } value={variant.name || ''} />
        </div>
    </div>
    <div className="flex-1 mx-2">
        <p className="font-medium text-black mb-2">Option Values</p>        
        <div className="flex items-center">
            <TagInput
                key={i}
                width="100%"
                inputProps={{ placeholder: 'Add values...' }}
                values={variant.values}

                onChange={productId ? null :(values) => {

                   onChange(i, 'values', [...values], productId);

                }}
                // onAdd={(value) => console.log(value, 'add')}
                // onRemove={(value) => console.log(value, 'remove')}
                onAdd={productId ? (value) => onChange(i, 'values', value[0], 'add'): null}
                onRemove={productId ? (value) => onChange(i, 'values', value, 'remove'): null}
            />
            {i !== 0 && !productId ? <AntButton onClick={() => onDelete(i)} className="ml-4 flex items-center justify-center" type="danger" shape="circle" ghost icon="delete" />: null}
        </div>
    </div>
</div>);

const SortableList = SortableContainer(({items, productId, onChange, onDelete}) => {
    return (
        <div>
            {items.map((variant, index) => (
                <SortableItem key={`item-${index}`} productId={productId} index={index} i={index} onDelete={onDelete} variant={variant} onChange={onChange} />
            ))}
        </div>
    );

});

class Variants extends React.Component {

    state = {
        variants: this.props.variants,
        productId: this.props.productId,
        autoSKU: this.props.autoSKU,
        variantOptions: this.props.variantOptions,
        optionValues: {},
        optionErrors: {},
        showEditAttributes: false,
        mutations: {
            skus: {
                update: {}
            },
            variants: {
                delete: {},
                update: {}
            },
            attributes: {
                update: {}
            }
        }
    }

    columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            render: (sku, v, i) => {

                return (
                    this.state.productId ? Array.isArray(sku) && sku.length > 1 ? 'Multiple' : sku.length > 0 ? <Input disabled={true} defaultValue={sku[0].code} size="small" onChange={(e) => {

                        this.state.variants[i].sku[0].code = e.target.value;
                        this.state.mutations.skus.update[sku[0].id] = e.target.value;

                        this.setState({
                            variants: this.state.variants,
                            mutations: this.state.mutations
                        });

                    }} />: null : this.state.autoSKU ? 'Auto Generated' :<Input defaultValue={sku} size="small" onChange={(e) => {

                        this.state.variants[i].sku = e.target.value;

                        this.setState({
                            variants: this.state.variants
                        });

                    }} />
                )

            }
        },
        {
            title: 'Min Qty',
            dataIndex: 'sku',
            key: '_sku',
            render: (sku, v, i) => {

                return (
                    <InputNumber
                        min={1}
                        value={v.min_qty}
                        onClick={(e) => { 

                            e.target.select();

                        }}
                        onChange={(min_qty) => {

                            this.state.variants[i].min_qty = min_qty;

                            if (this.state.mutations.variants.update[v.id]) {
                                
                                this.state.mutations.variants.update[v.id].min_qty = min_qty;

                            } else {

                                this.state.mutations.variants.update[v.id] = {
                                    min_qty
                                };

                            }

                            this.setState({
                                mutations: this.state.mutations,
                                variants: this.state.variants
                            }, () => this.props.onChange(this.state.variants));

                        }}

                    />
                )

            }
        },
        {
            title: 'Inventory',
            dataIndex: 'inventory',
            key: 'inventory',
            render: (inventory) => inventory && inventory.aggregate && inventory.aggregate.sum ? inventory.aggregate.sum.qty || 0: 0
        },
        {
            title: 'Markup',
            dataIndex: 'name',
            key: 'name_',
            render: (name, v, i) => {

                return (
                    <InputNumber
                        min={0}
                        value={(( (v.price - v.cost) / v.cost ) * 100 || 0).toFixed(2)}
                        onClick={(e) => { 

                            e.target.select();

                        }}
                        onChange={(percent) => {

                            const price = parseFloat((((percent + 100) / 100) * v.cost).toFixed(2));

                            this.state.variants[i].price = price;


                            if (this.state.mutations.variants.update[v.id]) {
                                
                                // this.state.mutations.variants.update[v.id].cost = cost;
                                this.state.mutations.variants.update[v.id].price = price;

                            } else {

                                this.state.mutations.variants.update[v.id] = {
                                    // cost: '',
                                    price
                                };

                            }

                            this.setState({
                                mutations: this.state.mutations,
                                variants: this.state.variants
                            }, () => this.props.onChange(this.state.variants));

                        }}
                        style={{width: '100%'}}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                        size="small"
                    />
                )

            }
        },
        {
            title: 'Supply Price',
            dataIndex: 'cost',
            key: 'cost',
            render: (cost, v, i) => {

                return (
                    <CurrencyWrapper
                        component={({symbol}) => (
                            <InputNumber
                                value={cost}
                                min={0}
                                onClick={(e) => { 

                                    e.target.select();

                                }}
                                onChange={(cost) => {

                                    this.state.variants[i].cost = cost;

                                    // if (this.state.variants[i].price <= 1) {

                                    //     this.state.variants[i].price = cost;
                                    
                                    // }

                                    if (this.state.mutations.variants.update[v.id]) {
                                        
                                        this.state.mutations.variants.update[v.id].cost = cost;

                                    } else {

                                        this.state.mutations.variants.update[v.id] = {cost};

                                    }

                                    this.setState({
                                        mutations: this.state.mutations,
                                        variants: this.state.variants
                                    }, () => this.props.onChange(this.state.variants));

                                }}
                                style={{width: '100%'}}
                                parser={value => value.replace(/[^0-9.]/g, '')}
                                formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                size="small"
                            />
                        )}
                    />
                )

            }
        },
        {
            title: 'Retail Price',
            dataIndex: 'price',
            key: 'price',
            render: (price, v, i) => {

                return (
                    <CurrencyWrapper
                        component={({symbol}) => (
                            <InputNumber
                                min={0}
                                onClick={(e) => { 

                                    e.target.select();

                                }}
                                value={price}
                                style={{width: '100%'}}
                                onChange={(price) => {

                                    this.state.variants[i].price = price;

                                    if (this.state.mutations.variants.update[v.id]) {
                                        
                                        this.state.mutations.variants.update[v.id].price = price;

                                    } else {
                                        
                                        this.state.mutations.variants.update[v.id] = {price};

                                    }

                                    this.setState({
                                        mutations: this.state.mutations,
                                        variants: this.state.variants
                                    }, () => this.props.onChange(this.state.variants));

                                }}
                                parser={value => value.replace(/[^0-9.]/g, '')}
                                formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                size="small"
                            />
                        )}
                    />
                )

            }
        },
        // {
        //     title: 'Enabled',
        //     dataIndex: 'enabled',
        //     key: 'enabled',
        //     render: (enabled, v, i) => {

        //         return (
        //             <Switch checked={enabled} size="small" onChange={(enabled) => {

        //                 this.state.variants[i].enabled = enabled;

        //                 this.setState({
        //                     variants: this.state.variants
        //                 });

        //             }} />
        //         )

        //     }
        // }
    ];

    componentDidMount() {

        const {productId, variants}  = this.state;

        if (productId && variants.length) {

            let _options = toPairs(groupBy(flatten(map(variants, 'attributes')), 'name'));

            _options = sortBy(_options, (o) => o[1][0].position).map((v) => {

                return {
                    name: v[0],
                    values: uniq(map(v[1], 'value'))
                };

            });

            this.setState({
                variantOptions: _options
            });
            

        } else {

            this.addOption();

        }

    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.autoSKU !== prevProps.autoSKU) {

            this.setState({
                autoSKU: this.props.autoSKU
            })

        }

        // if (this.state.variants !== prevState.variants) {

        //     if (this.props.onChange) this.props.onChange(this.state.variants);

        // }

    }

    updateVariants = () => {

        let {productId, variantOptions, variants, mutations} = this.state;

        if (productId) {

            variants = variants.map((v) => {

                const attributes = v.attributes.map((a) => {

                    // console.log(variantOptions, a);

                    const position = findIndex(variantOptions, {name: a.name});

                    if(a.id) {

                        if (mutations.attributes.update[a.id]) {

                            mutations.attributes.update[a.id].position = position;

                        } else {

                            mutations.attributes.update[a.id] = {
                                position
                            };

                        }

                    }

                    return {
                        ...a,
                        position
                    };

                });

                const name = map(orderBy(attributes, ['position'], 'asc'), 'value').join(' / ');

                if (this.state.mutations.variants.update[v.id]) {
                                
                    this.state.mutations.variants.update[v.id].name = name;

                } else {
                    
                    this.state.mutations.variants.update[v.id] = {name};

                }

                return {
                    ...v,
                    name,
                    attributes
                }
                
            })


            this.setState({
                variants,
                mutations
            }, () => this.props.onChange(variants))

            return;

        }

        const _variants = cartesianProductOf.apply(null, map(variantOptions, 'values'));
        const ___variants = _variants.map((values) => {

            return {
                sku: values.join('-'),
                name: values.join(' / '),
                price: 0,
                cost: 0,
                enabled: true,
                attributes: values.map((value, index) => {

                    const position = findIndex(variantOptions, {name: variantOptions[index].name});

                    return {
                        name: variantOptions[index].name,
                        position,
                        value
                    };
                    
                })
            };

        });

        this.setState({
            variants: ___variants
        }, () => this.props.onChange(___variants));



    }

    onSortEnd = ({oldIndex, newIndex}) => {

        // const {productId} = this.state;
        
        this.setState(({variantOptions}) => ({
            variantOptions: arrayMove(variantOptions, oldIndex, newIndex)
        }), this.updateVariants);


    };

    addOption = () => {

        const {variantOptions} = this.state;

        this.setState({
            variantOptions: [...variantOptions, {name: '', values: []}]
        })

    }

    canMutate = () => {

        const {mutations} = this.state;

        return (
            Object.keys(mutations.skus.update).length ||
            Object.keys(mutations.variants.update).length ||
            Object.keys(mutations.variants.delete).length ||
            Object.keys(mutations.attributes.update).length
        );

    }



    onChange = (index, key, value, type = 'change') => {

        const {productId, variantOptions, variants, mutations} = this.state;

        if (productId) {

            let change;

            if (key === 'name') {

                change = variants.filter((v) => {

                    return includes(map(v.attributes, 'name'), variantOptions[index][key]);

                });

                change = change.map((v) => {

                    return {
                        ...v,
                        attributes: v.attributes.map((a) => {

                            if(a.id && a.name == variantOptions[index][key]) {

                                if (mutations.attributes.update[a.id]) {

                                    mutations.attributes.update[a.id].key = value;

                                } else {

                                    mutations.attributes.update[a.id] = {
                                        key: value
                                    };

                                }

                            }

                            return a.name == variantOptions[index][key] ? {
                                ...a,
                                name: value
                            } : a;

                        })
                    }
                    
                });

                variantOptions[index][key] = value;

                this.setState({
                    mutations,
                    variantOptions,
                    variants: change || variants
                }, () => this.props.onChange(change || variants));

            } else if (type === 'remove') {

                if (key === 'values') {

                    if (variantOptions[index][key].length > 1) {

                        variantOptions[index][key] = variantOptions[index][key].filter((a) => a !== value);

                        change = variants.filter((v) => {

                            return !includes(map(v.attributes, 'value'), value);

                        });

                        variants.filter((v) => {

                            return includes(map(v.attributes, 'value'), value);

                        }).forEach(v => {
                            
                            if (v.id) {

                                mutations.variants.delete[v.id] = true;

                            }


                        });

                    }



                }


                // console.log(change);

                this.setState({
                    mutations,
                    variantOptions,
                    variants: change || variants
                }, () => this.props.onChange(change || variants));

            } else if (type === 'add') {

                if (variantOptions[index].values.indexOf(value) > -1) return;

                let _args = [...map(variantOptions.filter((o) => o.name !== variantOptions[index].name), 'values')];

                _args.splice(index, 0, [value])

                const _variants = cartesianProductOf.apply(null, _args);

                // console.log(_variants, value);

                variantOptions[index][key] = [...variantOptions[index][key], value];

                const ____variants = [
                    ...variants,
                    ..._variants.map((values) => {

                        return {
                            sku: values.join('-'),
                            name: values.join(' / '),
                            price: 0,
                            cost: 0,
                            min_qty: 1,
                            enabled: true,
                            attributes: values.map((value, index) => {
            
                                return {
                                    name: variantOptions[index].name,
                                    value
                                };
                                
                            })
                        };
            
                    })
                ];

                this.setState({
                    variantOptions,
                    variants: ____variants
                }, () => this.props.onChange(____variants));


            }

            this.setState({
                mutations,
                variantOptions: [...variantOptions]
            }, this.updateVariants);

            return;

        }


        variantOptions[index][key] = value;

        // console.log(cartesianProductOf.apply(null, map(variantOptions, 'values')));


        this.setState({
            variantOptions: [...variantOptions]
        }, this.updateVariants);

    }

    onDelete = (index) => {

        const {variantOptions} = this.state;

        this.setState({
            variantOptions: variantOptions.filter((v, i) => i !== index)
        });

    }

    get MUTATION() {

        const {mutations} = this.state;
        const {skus, variants, attributes} = mutations;

        
        return this.canMutate() ? gql`
            mutation {
                ${keys(skus.update).map((key) => {

                    return `
                        sku_${key}: update_products_sku(where: {id: {_eq: ${key}}}, _set: {
                            code: "${skus.update[key]}"
                        }) {
                            affected_rows
                        }
                    `;

                }).join('\n')}
                ${keys(attributes.update).map((key) => {

                    return `
                        attr_${key}: update_products_variants_attributes(where: {id: {_eq: ${key}}}, _set: {
                            ${keys(attributes.update[key]).map((_key) => {

                                const value = attributes.update[key][_key];

                                return `${_key}: ${typeof value == 'number' ? value : "\"" +value + "\"" }`;

                            }).join(', ')}
                        }) {
                            affected_rows
                        }
                    `;

                }).join('\n')}
                ${keys(variants.update).map((key) => {

                    return `
                        variant_${key}: update_products_variants(where: {id: {_eq: ${key}}}, _set: {
                            ${keys(variants.update[key]).map((_key) => {

                                const value = variants.update[key][_key];

                                return `${_key}: ${typeof value == 'number' ? value : "\"" +value + "\"" }`;

                            }).join(', ')}
                        }) {
                            affected_rows
                        }
                    `;

                }).join('\n')}
            }
        ` : gql`
            mutation{
                update_products_variants_attributes(where: {id: {_eq: 1}}, _set: {}) {
                    affected_rows
                }
            }
        `;

    }

    render() {

        const {variantOptions, variants, productId, mutations, showEditAttributes, optionValues, optionErrors} = this.state;

        // console.log(variants);


        return (
            <div>

                <p className="text-black text-lg font-bold">Product Variants</p>
                {productId ? null : <AntButton disabled={variantOptions.length >= 5} type="primary" ghost size="small" onClick={this.addOption}>Add Option</AntButton>}
                <SortableList
                    useDragHandle
                    productId={productId}
                    onDelete={this.onDelete}
                    items={variantOptions}
                    onChange={this.onChange}
                    onSortEnd={this.onSortEnd}
                />
                {variantOptions.length ?<AntButton type="primary" ghost onClick={() => this.setState({showEditAttributes: true})}>edit attributes</AntButton> : null}
                {variants.length ? <div className="my-8">
                    <p className="font-medium text-lg text-black mb-4">This product has {variants.length} variants.</p>
                    <div className="flex items-center my-4 -mx-4">
                        <div className="px-4 w-1/4">
                            <p className="font-medium text-black mb-2">Supply Price</p>
                            <CurrencyWrapper
                                component={({symbol}) => (
                                    <InputNumber
                                        // value={cost}
                                        min={1}
                                        onClick={(e) => { 

                                            e.target.select();

                                        }}
                                        onChange={(cost) => {

                                            let variants = this.state.variants;

                                            variants = variants.map((v) => {

                                                v.cost = cost;

                                                if (this.state.mutations.variants.update[v.id]) {
                                                    
                                                    this.state.mutations.variants.update[v.id].cost = cost;

                                                } else {

                                                    this.state.mutations.variants.update[v.id] = {cost};

                                                }

                                                return v;

                                            })

                                            this.setState({
                                                mutations: this.state.mutations,
                                                variants
                                            }, () => this.props.onChange(this.state.variants));

                                        }}
                                        style={{width: '100%'}}
                                        parser={value => value.replace(/[^0-9.]/g, '')}
                                        formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        size="small"
                                    />
                                )}
                            />
                        </div>
                        <div className="px-4 w-1/4">
                            <p className="font-medium text-black mb-2">Retail Price</p>
                            <CurrencyWrapper
                                component={({symbol}) => (
                                    <InputNumber
                                        // value={price}
                                        min={1}
                                        onClick={(e) => { 

                                            e.target.select();

                                        }}
                                        onChange={(price) => {

                                            let variants = this.state.variants;

                                            variants = variants.map((v) => {

                                                v.price = price;

                                                if (this.state.mutations.variants.update[v.id]) {
                                                    
                                                    this.state.mutations.variants.update[v.id].price = price;

                                                } else {

                                                    this.state.mutations.variants.update[v.id] = {price};

                                                }

                                                return v;

                                            })

                                            this.setState({
                                                mutations: this.state.mutations,
                                                variants
                                            }, () => this.props.onChange(this.state.variants));

                                        }}
                                        style={{width: '100%'}}
                                        parser={value => value.replace(/[^0-9.]/g, '')}
                                        formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        size="small"
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <Table
                        size="small"
                        pagination={false}
                        rowKey={(r, index) => index}
                        dataSource={variants}
                        columns={this.columns}
                    />
                    {productId ?  <div className="my-4">
                        <Mutation
                            mutation={this.MUTATION}
                            onCompleted={() => this.setState({
                                mutations: {
                                    skus: {
                                        update: {}
                                    },
                                    variants: {
                                        delete: {},
                                        update: {}
                                    },
                                    attributes: {
                                        update: {}
                                    }
                                }
                            })}
                        >
                            {(add, {loading}) => {

                                return (
                                    <EverButton disabled={!this.canMutate()} appearance="primary" intent="success" isLoading={loading} onClick={() => add()}>Save Variants</EverButton>
                                 )

                            }}
                        </Mutation>
                    </div>: null}
                    <Modal
                        title="Edit Attributes"
                        visible={showEditAttributes}
                        onCancel={() => this.setState({optionValues: {}, optionErrors: {}, showEditAttributes: false})}
                        okButtonProps={{
                            disabled: some(Object.values(optionErrors).map((v) => Object.keys(v).length), (v) => v > 0)
                        }}
                        onOk={() => {

                            let _variants = variants;

                            keys(optionValues).forEach((k) => {

                                keys(optionValues[k]).forEach((kk) => {

                                    const {name} = variantOptions[k];

                                    _variants = _variants.map((v) => {

                                        if (includes(map(v.attributes, 'value'), variantOptions[k].values[kk])) {

                                            const attributes = v.attributes.map((a) => {
                        
                                                if(a.id && a.value == variantOptions[k].values[kk]) {

                                                    if (mutations.attributes.update[a.id]) {

                                                        mutations.attributes.update[a.id].value = optionValues[k][kk]

                                                    } else {

                                                        mutations.attributes.update[a.id] = {
                                                            value: optionValues[k][kk]
                                                        };

                                                    }

                                                }
                    
                                                return a.value == variantOptions[k].values[kk] ? {
                                                    ...a,
                                                    value: optionValues[k][kk]
                                                } : a;
                    
                                            });

                                            const name = map(orderBy(attributes, ['position'], 'asc'), 'value').join(' / ');

                                            if (mutations.variants.update[v.id]) {
                                                            
                                                mutations.variants.update[v.id].name = name;

                                            } else {
                                                
                                                mutations.variants.update[v.id] = {name};

                                            }
                        
                                            return {
                                                ...v,
                                                name,
                                                attributes
                                            }

                                        }  else {

                                            return v;

                                        }
                                        
                                    });

                                    variantOptions[k].values[kk] = optionValues[k][kk];

                                });

                            });

                            this.setState({
                                variantOptions,
                                showEditAttributes: false,
                                variants: _variants,
                                optionValues: {},
                                optionErrors: {}
                            });

                        }}
                        okText="Save"
                    >
                        {variantOptions.map((option, index) => {

                            return (
                                <div className="flex -mx-2 mb-4 pb-4 border-b" key={index}>
                                    <div className="w-1/2 px-2">
                                        <p className="font-bold text-black">{option.name}</p>
                                    </div>
                                    <div className="w-1/2 px-2">
                                        {option.values.map((val, _index) => {

                                            return (
                                                <div className="mb-2" key={_index}>
                                                    <Input placeholder={val} className="mb-1" onChange={(e) => {

                                                        if (option.values.indexOf(e.target.value) > -1 || values(optionValues[index]).indexOf(e.target.value) > -1) {

                                                            if (optionErrors[index]) {

                                                                optionErrors[index][_index] = e.target.value;
    
                                                            } else {
    
                                                                optionErrors[index] = {
                                                                    [_index]: true
                                                                }
    
                                                            }

                                                        } else {

                                                            if (optionErrors[index] && optionErrors[index][_index]) {

                                                                delete optionErrors[index][_index];
    
                                                            }

                                                        }

                                                        if (optionValues[index]) {

                                                            optionValues[index][_index] = e.target.value;

                                                        } else {

                                                            optionValues[index] = {
                                                                [_index]: e.target.value
                                                            }

                                                        }

                                                        this.setState({optionValues, optionErrors});

                                                    }} />
                                                    <p className="text-xs text-black front-light">Previous Value: {val}</p>
                                                    {optionErrors[index] && optionErrors[index][_index] ? <p className="text-red-500 text-xs">Some values already exists</p>: null}
                                                </div>
                                            );


                                        })}
                                    </div>
                                </div>
                            );

                        })}
                    </Modal>
                </div>: null}
            </div>
        )


    }

}

export default Variants;