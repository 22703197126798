import React from 'react';
import firebase from '../services/firebase';
import {Table, InputNumber, Spin, Input, Select, Button} from 'antd';
import {Badge, FormField, Button as EverButton, Spinner} from 'evergreen-ui';
import moment from 'moment';
import {withRouter} from 'react-router-dom';
import {flatten, keyBy, keys, toPairs} from 'lodash';
import {Query, Mutation} from 'react-apollo';
import gql from 'graphql-tag';
import {DeleteOutlined} from '@ant-design/icons';

const SEARCH_EMPLOYEES = gql`
        query(
            $company_id: Int!,
            $name: String!
        ) {
            employees(where: {company_id: {_eq: $company_id}, name: {_ilike: $name}}) {
                id
                employeeId: id
                name
                designation
                date_joined
                department{
                    id
                    name
                }
            }

        }
`;

const GET_AGGREGATORS = gql`
    query($company_id: Int!) {

        aggregators: employee_aggregators(where: {company_id: {_eq: $company_id}}) {
            aggregatorId: id
            id
            name
            unit
            rate
            qty

        }

    }
`;

const CREATE_MUTATIONS = gql`
    mutation($aggregators: [employee_aggregators_activity_insert_input!]!) {
        insert_employee_aggregators_activity(objects: $aggregators) {
            affected_rows
        }
    }
`;

const dateFormat = 'YYYY/MM/DD';


class Aggregators extends React.Component{

    state = {
        companyId: this.props.match.params.companyId,
        searchValue: '',
        aggregators: {},
        employeesHidden: {},
        employees: [],
        employeesAuto: [],
        companyAggregators: this.props.companyAggregators,
        companyAggregatorsIndex: keyBy(this.props.companyAggregators, 'id')
    }

    columns = [{
        title: 'Employee Name',
        dataIndex: 'name',
        key: 'name',
        render: (name, record) => {

            return (
                <div className="">
                    <p className="font-medium">{name}</p>
                    <p className=""><Badge color="teal">{record.department.name}</Badge></p>
                </div>
            )

        }
    }, {
        title: 'Aggregations',
        dataIndex: 'aggregations',
        key: 'aggregations',
        render: (n, employee) => {

            let {companyAggregators, aggregators, activeAgg} = this.state;
            const {employeeId} = employee;

            return (
                <div className="flex items-center">
                    <div className="mr-2">
                        <Select style={{width: 240}} onChange={(activeAgg) => this.setState({activeAgg})} showSearch defaultActiveFirstOption={true}>
                            {companyAggregators.map((a) => {

                                const _a = a;

                                return (
                                    <Select.Option key={a.aggregatorId} value={a.aggregatorId}>{_a.name}</Select.Option>
                                )

                            })}
                        </Select>
                    </div>
                    {activeAgg ? <div className="">
                        <InputNumber onChange={(value) => {

                            aggregators = {
                                ...aggregators,
                                [employeeId]: {
                                    ...aggregators[employeeId],
                                    [activeAgg]: value
                                }
                            };

                            // console.log(aggregators);

                            this.setState({
                                aggregators
                            });

                        }} min={1} value={aggregators[employeeId][activeAgg] || 0} />
                    </div>: null}
                </div>
            )

        }
    }, {
        title: 'Review',
        dataIndex: 'review',
        key: 'review',
        render: (e, employee) => {

            const {activeAgg, aggregators, companyAggregatorsIndex} = this.state;
            const {employeeId} = employee;

            return (
                activeAgg ? <div>
                    {keys(aggregators[employeeId]).map((a) => {

                        const _a = companyAggregatorsIndex[a];
                        const __a = aggregators[employeeId][a];

                        return (
                            <div className="font-bold mb-2" key={a} value={a}>{_a.name} - {__a}</div>
                        )

                    })}
                </div>: null
            )

        }
    },{
        title: '',
        dataIndex: 'remove',
        key: 'remove',
        render: (e, employee) => {

            const {employees, aggregators} = this.state;
            const {employeeId} = employee;

            return (
                <div>
                    <Button onClick={() => {

                        delete aggregators[employeeId];

                        this.setState({
                            aggregators,
                            employees: employees.filter((e) => e.employeeId !== employeeId)
                        });

                    }} type="danger" className="flex items-center justify-center" shape="circle" ghost icon={<DeleteOutlined />} />
                </div>
            )

        }
    }];


    clear = () => this.setState({employees: [], aggregators:{}, searchValue: '', activeAgg: null, isSaving: false})


    load = async () => {}


    save = async () => {

        const {aggregators, companyId, activeAgg, companyAggregatorsIndex, employees} = this.state;

        if (!employees.length) return;

        const _aggregators = toPairs(aggregators);

        this.setState({isSaving: true});

        const batch = firebase.firestore().batch();
        const employeesRef = firebase.firestore().collection('companies').doc(companyId).collection('employees');

        const start_date = moment().format('YYYY-MM-DD');
        const end_date = moment().format('YYYY-MM-DD');

        _aggregators.forEach((d) => {

            batch.set(employeesRef.doc(d[0]).collection('activity').doc(), {
                type: 'aggregation',
                companyId,
                employeeId: d[0],
                aggregation: keys(d[1]).map((key) => ({
                    id: key,
                    ...companyAggregatorsIndex[key],
                    value: d[1][key]
                })),
                // date_range: `${end_date}-${start_date}`,
                start_date,
                end_date
            });

        });

        await batch.commit();

        this.clear();

    }

    render() {

        const {employees, companyId, searchValue, aggregators, companyAggregatorsIndex} = this.state;

        // const options = employeesAuto.map(d => <Select.Option key={d.value}>{d.text}</Select.Option>);


        return (

            <div className="overflow-scroll h-full w-full my-12">
                <div className="max-w-6xl mx-auto w-full">
                    <div className="flex items-center">
                        <div className="flex items-center">
                            <h1 className="text-4xl font-bold mr-4 my-4">
                                Aggregators
                            </h1>
                            {employees.length ? <Button type="danger" size="small" shape="round" ghost onClick={() => this.clear()}>Clear</Button>: null}
                        </div>
                    </div>
                    <div className="">
                        <div className="my-3">
                            <FormField label="Search Employees" />
                            <div className="">

                                <Query
                                    query={SEARCH_EMPLOYEES}
                                    variables={{
                                        name: `%${searchValue}%`,
                                        company_id: companyId
                                    }}
                                    skip={!searchValue}
                                >
                                    {({data, loading}) => {

                                        const employeesIndexed = keyBy(data && data.employees && data.employees.length ? data.employees: [], 'id');

                                        return (
                                            <Select
                                                size="large"
                                                showSearch
                                                loading={loading}
                                                value={searchValue}
                                                placeholder="Filter Employee"
                                                style={{width: '100%'}}
                                                notFoundContent={loading ? <div className="flex items-center justify-center p-4"><Spin size="small" /></div> : null}
                                                // defaultActiveFirstOption={false}
                                                // showArrow={false}
                                                // allowClear
                                                filterOption={false}
                                                onSearch={(searchValue) => this.setState({searchValue})}
                                                onChange={(e) => {

                                                    this.setState({
                                                        searchValue: '',
                                                        aggregators: {
                                                            ...aggregators,
                                                            [e]: {}
                                                        },
                                                        employees: [
                                                            {
                                                                ...employeesIndexed[e]
                                                            },
                                                            ...this.state.employees
                                                        ]
                                                    });

                                                }}
                                                // notFoundContent={null}
                                            >
                                                {!loading && data && data.employees.length ? data.employees.map(d => <Select.Option key={d.id}>{d.name}</Select.Option>): null}
                                            </Select>
                                        );

                                    }}
                                </Query>
                            </div>
                        </div>
                        {employees.length ? <div className="">
                            <div className="my-10">
                                <Table rowKey={(row) => row.employeeId} size="middle" pagination={false} columns={this.columns} dataSource={employees} />
                            </div>
                            <div className="my-3">
                                <Mutation
                                    mutation={CREATE_MUTATIONS}
                                    onCompleted={() => this.clear()}
                                    variables={{
                                        aggregators: flatten(keys(aggregators).map((id) => {

                                            const agg = aggregators[id];

                                            return keys(agg).map((_aa) => {

                                                const __a = companyAggregatorsIndex[_aa];

                                                return {
                                                    aggregator_id: parseInt(_aa),
                                                    company_id: parseInt(companyId),
                                                    value: agg[_aa],
                                                    employee_id: parseInt(id),
                                                    start_date: moment().format('YYYY-MM-DD'),
                                                    end_date: moment().format('YYYY-MM-DD'),
                                                    name: __a.name,
                                                    rate: __a.rate,
                                                    qty: __a.qty,
                                                    unit: __a.unit,
                                                    total: (agg[_aa] / __a.qty) * __a.rate
                                                }

                                            })

                                        }))
                                    }}
                                >
                                    {(save, {loading}) => {

                                        return (
                                            <EverButton isLoading={loading} appearance="primary" intent="success" onClick={() => save()}>Save</EverButton>
                                        )

                                    }}
                                </Mutation>
                            </div>
                        </div>: null}
                    </div>
                </div>
            </div>

        );

    }

}

const AggregatorsContainer = withRouter(Aggregators);

class AggregatorsWrapper extends React.Component{

    render() {

        return (

            <Query
                query={GET_AGGREGATORS}
                variables={{
                    company_id: this.props.match.params.companyId
                }}
            >
                {({data, loading, error}) => {

                    return (
                        !loading && data ? <AggregatorsContainer companyAggregators={data.aggregators} /> : <div className="flex items-center justify-center py-10">
                            <Spinner />
                        </div>
                    )

                }}
            </Query>

        )

    }

}

export default AggregatorsWrapper;