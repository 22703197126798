import React from 'react';
import {Button, Spinner} from 'evergreen-ui';
import {Modal} from 'antd';
import {RefreshCw} from 'react-feather';

import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import Deduction from './Deduction';


const GET_DEDUCTIONS = gql`
    query($company_id: Int!) {

        deductions: employee_deductions(where: {company_id: {_eq: $company_id}}) {
            id
            name
        }

    }
`;


class DeductionsSettings extends React.Component{

    state = {
        companyId: this.props.companyId,
        showModal: false,
        deductionId: null
    }

    render() {

        const {companyId, showModal, deductionId} = this.state;

        return (
            <div className="overflow-scroll h-full w-full py-12">
                <Query
                    query={GET_DEDUCTIONS}
                    variables={{
                        company_id: companyId
                    }}
                >
                    
                    {({data, error, loading, refetch}) => {

                        return (
                            <React.Fragment>
                                <div className="w-full max-w-3xl mx-auto px-4">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <h1 className="font-bold text-4xl mr-2">
                                                Deductions
                                            </h1>
                                            {loading ? null :<button className="ml-4 focus:outline-none text-black" onClick={() => refetch()}>
                                                <RefreshCw className={(loading) ? "rotating": null} size={24} />
                                            </button>}
                                        </div>
                                        <div className="">
                                            <Button appearance="primary" intent="success" onClick={() => {

                                                this.setState({
                                                    showModal: true
                                                });

                                            }} className="flex items-center justify-center">Add Deduction</Button>
                                        </div>
                                    </div>
                                    {loading ? <div className="flex items-center justify-center py-10">
                                        <Spinner />
                                    </div> : data && data.deductions && data.deductions.length ? <div className="my-4">
                                        {data.deductions.map((deduction) => {

                                            return (
                                                <div className="border-b py-4" key={deduction.id}>
                                                    <p className="font-medium text-lg text-black">{deduction.name}</p>
                                                </div>
                                            )

                                        })}
                                    </div>:<p className="text-center py-10 text-black">No Deductions found.</p>}
                                </div>
                                <Modal
                                    visible={showModal}
                                    footer={null}
                                    onCancel={() => this.setState({showModal: false})}
                                >
                                    <Deduction
                                        companyId={companyId}
                                        deductionId={deductionId}
                                        close={() => this.setState({showModal: false})}
                                    />
                                </Modal>
                            </React.Fragment>
                        )

                    }}

                </Query>
            </div>
        )

    }

}

export default DeductionsSettings;