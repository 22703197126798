import React from 'react';
import {Button, Spinner} from 'evergreen-ui';
import {Table, Select, Empty} from 'antd';
import firebase from '../services/firebase';
import FlutterWaveImage from '../images/flwbadge-2.png';
import {CurrencyWrapper, CurrencyFormat} from '../services/Currency';

class Subscription extends React.Component{

    columns = [{
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
    },{
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },{
        title: 'Transaction ID',
        dataIndex: 'transactionId',
        key: 'transactionId',
    },{
        title: () => <CurrencyWrapper component={({symbol}) => `Amount (${symbol})`} />,
        dataIndex: 'amount',
        key: 'amount',
    }]

    state = {
        selectedMonths: '6',
        loading: true,
        user: null
    }

    async componentDidMount() {

        const {currentUser} = firebase.auth();
        const user = await firebase.firestore().collection('users').doc(currentUser.uid).get();

        this.setState({
            user: {
                ...user.data(),
                uid: currentUser.uid
            },
            loading: false
        })

    }

    pay = async () => {

        var PBFPubKey = "FLWPUBK-768cbc1fab6c09612046c02027abd080-X";

        const {selectedMonths, user} = this.state;
        const {email} = user;
        const amount = parseFloat(parseInt(selectedMonths) * parseFloat(user.amount_due))
    
        window.getpaidSetup({
            PBFPubKey,
            customer_email: email,
            customer_firstname: "",
            customer_lastname: "",
            // custom_description: "Pay Internet",
            // custom_logo: "http://localhost/communique-3/skin/frontend/ultimo/communique/custom/images/logo.svg",
            // custom_title: "Communique Global System",
            amount,
            // customer_phone: ,
            country: "GH",
            currency: "GHS",
            payment_options: "card,ussd",
            txref: "rave-123456",
            // integrity_hash: "6800d2dcbb7a91f5f9556e1b5820096d3d74ed4560343fc89b03a42701da4f30",
            onclose: function() {},
            callback: function(response) {
                
                var flw_ref = response.tx.flwRef; // collect flwRef returned and pass to a 					server page to complete status check.
                console.log("This is the response returned after a charge", response);

                if (
                    response.tx.chargeResponseCode == "00" ||
                    response.tx.chargeResponseCode == "0"
                ) {
                    
                    // redirect to a success page

                } else {
                    
                    // redirect to a failure page.


                }

            }
        });

    }

    render() {

        const {user, loading, selectedMonths} = this.state;

        return (
            loading ? <div className="flex items-center justify-center py-10">
                <Spinner />
            </div> : <div className="">
                <div className="max-w-4xl w-full mx-auto rounded-lg shadow mt-12">
                    <div className="text-center p-8">
                        <p className="text-black font-bold text-3xl">Subscription</p>
                    </div>
                    <div className="px-8 pb-8 border-b">
                        <div className="flex-1">
                            {/* <p className="">Current Plan</p>
                            <p className="text-black font-bold text-2xl uppercase">
                                Free
                            </p> */}
                            <div className="my-4">
                                <div className="flex items-center justify-between my-2 border-b py-2">
                                    <div className="font-bold text-black">Companies</div>
                                    <div className="">{user.companyCount} / {user.maxCompanies || 0}</div>
                                </div>
                                <div className="flex items-center justify-between my-2">
                                    <div className="font-bold text-black">Employees</div>
                                    <div className="">{user.employeeCount} / {user.maxEmployees || 0}</div>
                                </div>
                            </div>
                            {user.paid && user.expires_at ? <p className="">Next payment: <CurrencyFormat amount={450.00} /> on 02/08/2020</p>: null}
                        </div>
                        {user.amount_due ? <div className="flex items-center justify-between">
                            <div className="flex flex-col mt-3 mb-4">
                                <Select defaultValue={selectedMonths} className="mb-3" onChange={(selectedMonths) => this.setState({selectedMonths})}>
                                    <Select.Option value="3">3 Months</Select.Option>
                                    <Select.Option value="6">6 Months</Select.Option>
                                    <Select.Option value="12">1 Year</Select.Option>
                                    <Select.Option value="24">2 Years</Select.Option>
                                </Select>
                                <Button onClick={() => this.pay()} appearance="primary" justifyContent="center" intent="success">{!user.paid && !user.expires_at ? 'PAY': 'RENEW'}</Button>
                            </div>
                            <div className="">
                                <p className="font-bold text-black text-lg">
                                    <CurrencyFormat
                                        amount={parseFloat(parseInt(selectedMonths) * parseFloat(user.amount_due)).toFixed(2)}
                                    />
                                </p>
                                <p className=""><CurrencyFormat amount={parseFloat(user.amount_due).toFixed(2)} /> /mo</p>
                            </div>
                        </div>: null}
                    </div>
                    <div className="px-8 py-6">
                        <p className="text-black font-bold text-2xl">Billing History</p>
                        <div className="mt-6 mb-4">
                            <Table size="small" pagination={false} columns={this.columns} dataSource={[{
                                date: '01/08/2019',
                                type: 'FREE (6 Months)',
                                transactionId: 'fdsf87sdfsdfsf',
                                amount: '150.00'
                            }]} />
                            <Empty className="flex flex-col items-center" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>
                    </div>
                </div>
                <div className="my-6 flex items-center justify-center">
                    <a href="" target="_blank" className="">
                        <img src={FlutterWaveImage} alt="Rave Logo"/>
                    </a>
                </div>
            </div>
        )

    }

}

export default Subscription;