import React, { useEffect, useState } from 'react';
import { Popover, Position, Menu, Avatar, FormFieldLabel, Dialog } from 'evergreen-ui';
import firebase from '../services/firebase';
import { Bell } from 'react-feather';
import { withRouter, Link } from 'react-router-dom';
import { Badge, Input, message } from 'antd';
import HR from './menus/hr';
import POS from './menus/pos';
import Accounts from './menus/accounts';
import Procument from './menus/procument';
import RawMaterials from './menus/raw-materials';
import roles from '../services/roles';
import api from '../services/api';
import { checkRestricted } from './misc/restrictView';
import { decode } from 'mout/queryString';
import { CurrencyFormat } from '../services/Currency';
import apollo from '../services/apollo';

class Header extends React.Component {

    signOut = () => firebase.auth().signOut();

    state = {
        requests: [],
        currentUser: firebase.auth().currentUser.toJSON()
    }


    list = () => {

        const { section, company, currentCompany } = this.props;

        if (section === 'hr' && !checkRestricted(company.allowed_services, 'hr')) {

            return (<HR currentCompany={currentCompany} company={company} />);

        } else if (section === 'pos' && !checkRestricted(company.allowed_services, 'pos')) {

            return (<POS currentCompany={currentCompany} company={company} />);


        } else if (section === 'accounting' && !checkRestricted(company.allowed_services, 'accounting')) {

            return (<Accounts currentCompany={currentCompany} company={company} />);


        } else if (section === 'procument') {

            return (<Procument currentCompany={currentCompany} company={company} />);


        } else {

            return null;

        }

    }

    changePassword = async () => {

        const {
            old_password,
            repeat_password,
            password
        } = this.state;
        const { account, user } = this.props;

        if (password.length < 8) {

            return message.error('Password must be 8 characters or more.');

        }

        if (password !== repeat_password) {

            return message.error('Passwords do no match');

        }

        this.setState({ isChangingPassword: true });


        try {

            const res = await api.post(`/auth/password/change`, {
                user_id: user.id,
                account_id: account.id,
                password,
                current_password: old_password
            });

            this.setState({ isChangingPassword: false, showChangePassword: false, repeat_password: '', password: '', old_password: '' });

            // this.props.history.push(`/users/${res.data.id}`);

        } catch (e) {

            if (e.response && e.response.data.message) {

                message.error(e.response.data.message);

            }

            this.setState({ isChangingPassword: false });

        }


    }

    render() {

        const { requests, isChangingPassword, showChangePassword } = this.state;
        const { currentCompany, company, paddingLeft, user } = this.props;

        const query = decode(window.location.search);
        const isReport = query.report;


        return (
            isReport ? null : <React.Fragment>
                <div className="header w-full right-0 border-b border-gray-200 w-full h-16 print-hidden bg-white" style={{
                    // paddingLeft: paddingLeft,
                    zIndex: 9
                }}>
                    <div className="w-full h-full px-6">
                        <div className="flex items-center justify-between h-full">
                            <div className="flex items-center text-black">
                                {currentCompany ? <div className="">
                                    {this.list()}
                                </div> : null}
                            </div>
                            <div className="flex items-center">

                                <div className="">
                                    <PendingSalesNotification />
                                </div>
                                <div className="ml-8">
                                    <Popover
                                        position={Position.BOTTOM_RIGHT}
                                        content={
                                            ({ close }) => {

                                                return <Menu>
                                                    {/* <Menu.Divider /> */}
                                                    <div className="flex py-4 px-4 -mx-1">
                                                        <div className="px-1">
                                                            <Avatar
                                                                // src="https://upload.wikimedia.org/wikipedia/commons/a/a1/Alan_Turing_Aged_16.jpg"
                                                                name={user.display_name}
                                                                size={32}
                                                            />
                                                        </div>
                                                        <div className="px-1">
                                                            <p className="text-sm">{user.display_name}</p>
                                                            <p className="text-xs">{user.email}</p>
                                                        </div>
                                                    </div>
                                                    <Menu.Divider />
                                                    <Menu.Item
                                                        onSelect={() => {

                                                            this.setState({ showChangePassword: true });

                                                            close();

                                                        }}
                                                    >
                                                        Change Password
                                                    </Menu.Item>
                                                    {/* <Menu.Divider />
                                                        <Menu.Item
                                                            onSelect={() => {

                                                                close();

                                                            }}
                                                        >
                                                            Change PIN
                                                        </Menu.Item> */}
                                                    <Menu.Divider />
                                                    <Menu.Item
                                                        onSelect={() => {

                                                            close();

                                                            firebase.auth().signOut();

                                                        }}
                                                    >
                                                        Logout
                                                    </Menu.Item>
                                                </Menu>
                                            }
                                        }
                                    >
                                        {({ getRef, toggle, isShown }) => (

                                            <button ref={getRef} onClick={toggle} className="w-10 h-10 rounded-full bg-gray-200 active:outline-none focus:outline-none">
                                                <Avatar
                                                    // src="https://upload.wikimedia.org/wikipedia/commons/a/a1/Alan_Turing_Aged_16.jpg"
                                                    name={user.display_name}
                                                    size={40}
                                                />
                                            </button>

                                        )}
                                    </Popover>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    isShown={showChangePassword}
                    title="Change Password"
                    intent="success"
                    isConfirmLoading={isChangingPassword}
                    onConfirm={() => this.changePassword()}
                    onCloseComplete={() => this.setState({ showChangePassword: false, repeat_password: '', password: '', old_password: '' })}
                    confirmLabel="Save Password"
                >
                    <div className="my-3">
                        <FormFieldLabel>Old Password</FormFieldLabel>
                        <Input.Password onChange={(e) => this.setState({ old_password: e.target.value })} placeholder="Enter Old Password" />
                    </div>
                    <div className="my-3">
                        <FormFieldLabel>Password</FormFieldLabel>
                        <Input.Password onChange={(e) => this.setState({ password: e.target.value })} placeholder="Enter New Password" />
                    </div>
                    <div className="my-3">
                        <FormFieldLabel>Repeat Password</FormFieldLabel>
                        <Input.Password onChange={(e) => this.setState({ repeat_password: e.target.value })} placeholder="Repeat New Password" />
                    </div>
                </Dialog>
            </React.Fragment>
        )

    }

}

export default withRouter(Header);

const PendingSalesNotification = () => {


    let _sales = [];

    for (const key in localStorage) {
        if (localStorage.hasOwnProperty(key)) {

            if (key.includes('gomerp.mutations')) {

                _sales.push(key);

            }


        }
    };

    _sales = _sales.map((k) => JSON.parse(localStorage.getItem(k)));

    const [sales, setSales] = useState([..._sales]);


    useEffect(() => {

        // console.log('sales:', sales);

        const timer = setInterval(() => {

            let _sales = [];

            for (const key in localStorage) {
                if (localStorage.hasOwnProperty(key)) {

                    if (key.includes('gomerp.mutations')) {

                        _sales.push(key);

                    }


                }
            };

            _sales = _sales.map((k) => JSON.parse(localStorage.getItem(k)));

            setSales([
                ..._sales
            ]);

        }, 8000);


        return () => {

            clearInterval(timer);

        }

    }, [sales])

    return (

        <Popover
            position={Position.BOTTOM_RIGHT}
            content={
                ({ close }) => {

                    return <div className="w-full max-w-xs">
                        {sales.length ? sales.map((sale) => {

                            return (
                                <div key={sale.id} className="p-4 border-b">
                                    <p className="">
                                        <span className="font-bold">{sale.variables.order_items.length}</span> item(s) at <span className="font-bold"><CurrencyFormat amount={sale.variables.total_amt} /></span> in total
                                    </p>
                                    {/* <p className="mt-2">
                                        <a href="#" onClick={() => this.acceptRequest(request)} className="text-green-600 underline">Accept</a>
                                        <a href="#" onClick={() => this.declineRequest(request)}  className="ml-3 text-red-600 underline">Decline</a>
                                    </p> */}
                                </div>
                            )

                        }) : <p className="text-center p-6">You don't have any pending sales.</p>}
                    </div>
                }
            }
        >
            {({ getRef, toggle, isShown }) => (

                <button ref={getRef} onClick={toggle} className="active:outline-none focus:outline-none">
                    <Badge count={sales.length}>
                        <Bell size={28} />
                    </Badge>
                </button>

            )}
        </Popover>
    );

}