import React from 'react';
import {currencyFormat} from 'mout/number';
import {sumBy} from 'lodash';
import {Table} from 'antd';
import { CurrencyWrapper } from '../../services/Currency';

class Banks extends React.Component{

    columns = [{
        title: 'Name of Staff',
        dataIndex: 'name',
        key: 'name',
        render: (name, employee) => (employee.employeeId == 'total') ? <div className="text-black font-bold">{name}</div>  : name
    },{
        title: 'Bank Name',
        dataIndex: 'bank_name',
        key: 'bank_name',
    },{
        title: 'Branch',
        dataIndex: 'bank_branch',
        key: 'bank_branch',
    },{
        title: 'Sort Code',
        dataIndex: 'bank_sort_code',
        key: 'bank_sort_code',
    },{
        title: 'Account No.',
        dataIndex: 'bank_account_number',
        key: 'bank_account_number',
    },{
        title: () => <CurrencyWrapper component={({symbol}) => (`Amount (${symbol})`)} />,
        dataIndex: 'net_income',
        key: 'net_income',
        render: (net_income, employee) => (employee.employeeId == 'total') ? <div className="text-black font-bold">{currencyFormat(net_income)}</div>  : currencyFormat(net_income)
    }]

    render() {

        const {employees, month, company} = this.props;

        return (
            <div className="print-only text-black">
                <div className="text-center">
                    <h3 className="text-3xl font-bold text-black my-6 mb-12">{company.name}</h3>
                    <div className="flex my-4">
                        <h6>Month :</h6>
                        <div className="ml-3 border-b max-w-xs w-full font-bold text-black">
                            {month}
                        </div>
                    </div>
                </div>
                <div className="mt-10">
                    <Table rowKey={(e) => e.employeeId} size="small" columns={this.columns} dataSource={[
                        ...employees,
                        {
                            employeeId: 'total',
                            name: 'Grand Total',
                            bankName: '',
                            bankBranch: '',
                            bankAccountNumber: '',
                            net_income: sumBy(employees, (e) => (parseFloat(e.net_income)))
                        }
                    ]} pagination={false} />
                </div>
                {/* <div className="">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th className="text-xs">Name of Staff</th>
                                <th className="text-xs">Bank Name</th>
                                <th className="text-xs">Branch</th>
                                <th className="text-xs">Account No.</th>
                                <th className="text-xs">Amount (cedis)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employees.map((employee) => {

                                return (
                                    <tr key={employee.employeeId}>
                                        <td>{employee.name}</td>
                                        <td>{employee.bankName}</td>
                                        <td>{employee.bankBranch}</td>
                                        <td>{employee.bankAccount}</td>
                                        <td>{currencyFormat(employee.net_income)}</td>
                                    </tr>
                                )

                            })}
                            <tr>
                                <td><span className="font-bold">Grand Total</span></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><span className="font-bold">{currencyFormat(sumBy(employees, 'net_income'))}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div> */}
                <div className="flex my-8 -mx-4">
                    <div className="flex flex-col flex-1 px-4">
                        <div className="flex my-4">
                            <div className="uppercase text-black font-bold">
                                Prepared By:
                            </div>
                            <div className="border-b flex-1"></div>
                        </div>
                        <div className="flex my-4">
                            <div className="uppercase text-black font-bold">
                                Signature:
                            </div>
                            <div className="border-b flex-1"></div>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1 px-4">
                        <div className="flex my-4">
                            <div className="uppercase text-black font-bold">
                                Approved By:
                            </div>
                            <div className="border-b flex-1"></div>
                        </div>
                        <div className="flex my-4">
                            <div className="uppercase text-black font-bold">
                                Signature:
                            </div>
                            <div className="border-b flex-1"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
        
    }

}

export default Banks;