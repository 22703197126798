import React from 'react';
import { Button } from 'antd';
import { RefreshCw } from 'react-feather';
import { Spinner } from 'evergreen-ui';
//stocktable specific imports
import { Table, Input } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import jsPDF from "jspdf";
import "jspdf-autotable";
import ReactExport from "react-export-excel";
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import { currencyFormat } from 'mout/number';

const GET_MOVEMENTS = gql`
    query(
        $company_id: Int!
    ){
        movements: products_history_items(where:{
            history:{
                company_id:{_eq: $company_id}
            }
        }, order_by:{
              created_at: desc
        }) {
            id
            price
            qty
            created_at
            variant {
              name
              product{
                name
              }
            }
            history {
              type
              destination {
                name
              }
              source {
                name
              }
              employee {
                display_name
              }
            }
          }
    }
`;

class StockControl extends React.Component {

    state = {
        companyId: this.props.match.params.companyId
    }

    render() {

        const { companyId } = this.state;

        return (
            <React.Fragment>
                <div className="overflow-scroll h-full pb-16">
                    <div className="px-16 flex items-center justify-between my-10">
                        <div className="flex items-center">
                            <h1 className="font-bold text-4xl mr-2">
                                Stock Control
                            </h1>
                            <button className="ml-4 focus:outline-none text-black">
                                <RefreshCw size={24} />
                            </button>
                        </div>
                    </div>
                    <div className="px-16">
                        <div className="flex flex-wrap -mx-4">
                            <div className="my-3 lg:w-1/6 sm:w-1/3 w-full px-4">
                                <Button onClick={() => this.props.history.push(`/${companyId}/pos/stocks/new`)} type="primary" ghost className="mx-2 flex items-center justify-center w-full h-16 px-3">New Stock</Button>
                            </div>
                            <div className="my-3 lg:w-1/6 sm:w-1/3 w-full px-4">
                                <Button onClick={() => this.props.history.push(`/${companyId}/pos/stocks/bill-of-material`)} type="primary" ghost className="mx-2 flex items-center justify-center w-full h-16 px-3">Bill Of Material</Button>
                            </div>
                            <div className="my-3 lg:w-1/6 sm:w-1/3 w-full px-4">
                                <Button onClick={() => this.props.history.push(`/${companyId}/pos/stocks/transfer`)} type="primary" ghost className="mx-2 flex items-center justify-center w-full h-16 px-3">Transfer</Button>
                            </div>
                            {/* <div className="w-1/6 px-4"> */}
                            {/* <Button onClick={() => this.props.history.push(`/${companyId}/pos/stocks/return`)} type="primary" ghost className="mx-2 flex items-center justify-center w-full h-16">Return</Button> */}
                            {/* </div> */}
                            <div className="my-3 lg:w-1/6 sm:w-1/3 w-full px-4">
                                <Button onClick={() => this.props.history.push(`/${companyId}/pos/stocks/take`)} type="primary" ghost className="mx-2 flex items-center justify-center w-full h-16 px-3">Stock Take</Button>
                            </div>
                            <div className="my-3 lg:w-1/6 sm:w-1/3 w-full px-4">
                                <Button onClick={() => this.props.history.push(`/${companyId}/pos/stocks/adjustment`)} type="primary" ghost className="mx-2 flex items-center justify-center w-full h-16 px-3">Stock Adjustment</Button>
                            </div>
                            <div className="my-3 lg:w-1/6 sm:w-1/3 w-full px-4">
                                <Button onClick={() => this.props.history.push(`/${companyId}/pos/stocks/price-change`)} type="primary" ghost className="mx-2 flex items-center justify-center w-full h-16 px-3">Location Prices</Button>
                            </div>
                            <div className="my-3 lg:w-1/6 sm:w-1/3 w-full px-4">
                                <Button onClick={() => this.props.history.push(`/${companyId}/pos/stocks/base-price-change`)} type="primary" ghost className="mx-2 flex items-center justify-center w-full h-16 px-3">Price Change</Button>
                            </div>
                        </div>
                    </div>
                    {/* <div className="px-16">
                        <ControlWrapper
                            companyId={companyId}
                        />
                    </div> */}
                </div>
            </React.Fragment>
        )

    }

}

const ControlWrapper = ({ companyId }) => {

    return (
        <Query
            query={GET_MOVEMENTS}
            variables={{
                company_id: companyId
            }}
        >
            {({ loading, data, error }) => {

                if (loading) {

                    return <Spinner />

                }

                return (
                    <StockTable
                        data={((data || {}).movements || []).map((m) => ({
                            key: `${m.id}`,
                            type: m.history.type,
                            source: `${m.history.source ? m.history.source.name : ''} ${m.history.source && m.history.destination ? '->' : ''} ${m.history.destination ? `${m.history.destination.name}` : ''}`,
                            name: `${m.variant.product.name}`,
                            variant_name: m.variant.name,
                            qty: m.qty,
                            price: currencyFormat(m.price),
                            total: currencyFormat(m.qty * m.price),
                            employee: m.history.employee.display_name,
                            dateCreated: moment(m.created_at).format('DD/MM/YYYY'),
                            full: m
                        }))}
                    />
                )

            }}
        </Query>
    )

}


class StockTable extends React.Component {

    state = {
        searchText: '',
        searchedColumn: '',
        filteredInfo: null,
        sortedInfo: null,
        data: this.props.data || []
    }

    componentDidMount() {
        // fetch data from database
    }

    exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Stock Report";
        const headers = [["Type", "Product Name", "Outlet(s)", "Qty", "Unit Price", "Total", "Employee", "Date Created"]];

        const data = this.state.data.map(elt => [elt.type, elt.name, elt.source, elt.qty, elt.price, elt.total, elt.employee, elt.dateCreated,]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("report.pdf")
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.handleReset(this.clearFilters)
        this.setState({
            filteredInfo: null,
            sortedInfo: null,
        });
    };


    render() {
        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};
        const columns = [
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                width: 120,
                ...this.getColumnSearchProps('type'),
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
                ellipsis: true,
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: '10%',
                ...this.getColumnSearchProps('name'),
                sorter: (a, b) => a.age - b.age,
                sortOrder: sortedInfo.columnKey === 'age' && sortedInfo.order,
                ellipsis: true,
            },
            {
                title: 'Type',
                width: '10%',
                dataIndex: 'variant_name',
                key: 'variant_name',
                ...this.getColumnSearchProps('variant_name'),
                sorter: (a, b) => a.age - b.age,
                sortOrder: sortedInfo.columnKey === 'age' && sortedInfo.order,
                ellipsis: true,
            },
            {
                title: 'Outlet(s)',
                dataIndex: 'source',
                key: 'source',
                ...this.getColumnSearchProps('source'),
            },
            {
                title: 'Quantity',
                dataIndex: 'qty',
                key: 'qty',
                ...this.getColumnSearchProps('qty'),
                sorter: (a, b) => a.age - b.age,
                sortOrder: sortedInfo.columnKey === 'age' && sortedInfo.order,
                ellipsis: true,
            },
            {
                title: 'Unit Price',
                dataIndex: 'price',
                key: 'price',
                ...this.getColumnSearchProps('price'),
                sorter: (a, b) => a.age - b.age,
                sortOrder: sortedInfo.columnKey === 'age' && sortedInfo.order,
                ellipsis: true,
            },
            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
                ...this.getColumnSearchProps('total')
            },
            {
                title: 'Employee',
                dataIndex: 'employee',
                key: 'employee',
                ...this.getColumnSearchProps('employee'),
                sorter: (a, b) => a.age - b.age,
                sortOrder: sortedInfo.columnKey === 'age' && sortedInfo.order,
                ellipsis: true,
            },
            {
                title: 'Date Created',
                dataIndex: 'dateCreated',
                key: 'dateCreated',
                ...this.getColumnSearchProps('dateCreated'),
                sorter: (a, b) => a.age - b.age,
                sortOrder: sortedInfo.columnKey === 'age' && sortedInfo.order,
                ellipsis: true,
            }
        ];

        //excel export variables
        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
        return (
            <div className="">
                <div className="table-operations mb-3">
                    <Button.Group>
                        <Button onClick={this.clearFilters}>Clear filters</Button>
                        <Button onClick={this.clearAll}>Clear filters and sorters</Button>
                        <Button onClick={this.exportPDF}>Export to PDF</Button>
                    </Button.Group>
                </div>
                <Table columns={columns} dataSource={this.state.data} onChange={this.handleChange} />
                <ExcelFile element={<Button>Export to Excel</Button>}>
                    <ExcelSheet data={this.state.data} name="Stock">
                        <ExcelColumn label="Type" value="type" />
                        <ExcelColumn label="Name" value="name" />
                        <ExcelColumn label="Source" value="source" />
                        <ExcelColumn label="Destination" value="destination" />
                        <ExcelColumn label="Quantity" value="qty" />
                        <ExcelColumn label="Total" value="total" />
                        <ExcelColumn label="Employee" value="employee" />
                        <ExcelColumn label="Date Created" value="dateCreated" />
                    </ExcelSheet>

                </ExcelFile>
            </div>

        )
    }
}

export default StockControl;