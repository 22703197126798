import React from 'react'
import { TextInputField, SelectField, Checkbox, Button } from 'evergreen-ui';
import { InputNumber, Radio,  } from 'antd';
import {Query, Mutation} from 'react-apollo';
import gql from 'graphql-tag';
import {CurrencyWrapper} from '../../services/Currency';

const UPDATE_DISCOUNT = gql`
    mutation(
        $id: Int,
        $company_id: Int,
        $name: String!,
        $type: String!,
        $active: Boolean!,
        $code: String,
        $value: float8!,
        $employee_id: Int,
        $outlet_id: Int,
        $one_time: Boolean!,
        $created_by: Int!,
    ){
        update_discounts(where:{
            id:{_eq: $id}
        }, _set:{
            name: $name,
            type: $type,
            active: $active,
            value: $value,
            employee_id: $employee_id,
            outlet_id: $outlet_id,
            one_time: $one_time
        }){
            affected_rows
        }
    }
`;



const GET_OUTLETS = gql`

    query ($company_id: Int!) {
        outlets(where: {
            company_id:{_eq: $company_id},
            warehouse: {_eq: false}
        }) {
            id
            name
        }
    }

`;

const GET_EMPLOYEES = gql`

    query ($company_id: Int!) {
        employees: company_users(where: {
            company_id:{_eq: $company_id}
        }) {
            employee: user{
                id
                display_name
            }
        }
    }

`;


export default class Discount extends React.Component {

    state = {
        name: this.props.discount ? this.props.discount.name : '',
        discount: '',
        location: '',
        available: null,
        
        status: this.props.discount && this.props.discount.active ? '1': '0',
        voucherCode: this.props.discount ? this.props.discount.code : '',
        oneTime: this.props.discount ? this.props.discount.one_time :false,

        employee: this.props.discount ? this.props.discount.employee_id : 'all',
        outlet: this.props.discount ? this.props.discount.outlet_id : 'all',
        value: this.props.discount ? this.props.discount.value : 10.00,
        type: this.props.discount ? this.props.discount.type : 'percent'
    }

    get CREATE_DISCOUNT() {

        const {voucherCode} = this.state;
        
        return gql`
            mutation(
                $id: Int,
                $company_id: Int,
                $name: String!,
                $type: String!,
                $active: Boolean!,
                $code: String,
                $value: float8!,
                $employee_id: Int,
                $outlet_id: Int,
                $one_time: Boolean!,
                $created_by: Int!
            ){
                insert_discounts(objects: {
                    name: $name,
                    type: $type,
                    active: $active,
                    ${voucherCode ? `code: $code,`: ''}
                    value: $value,
                    employee_id: $employee_id,
                    outlet_id: $outlet_id,
                    one_time: $one_time,
                    created_by: $created_by,
                    company_id: $company_id
                }){
                    returning {
                        id
                    }
                }
            }
        `;

    }

    render() {

        const {name, status, voucherCode, oneTime, type, value, outlet, employee} = this.state;
        const {companyId, discount, user, history} = this.props;

        // console.log(discount);

        const isPercent = type === 'percent';

        return (

            <div className="p-8">
                <div className="h-full w-full">
                    <div className="max-w-2xl mx-auto px-16 sm:px-16">
                        <h1 className="text-black text-lg font-bold">Add Discount</h1>

                        <div className="mt-4 mb-6">
                            <TextInputField
                                label="Discount Name"
                                placeholder="Enter discount name"
                                value={name}
                                onChange={(e) => {
                                    
                                    this.setState({ name: e.target.value })

                                }}
                                autoFocus
                                required
                            />
                        </div>

                        <div className="my-4">
                            <p className="font-medium text-black">Discount Type</p>
                            <Radio.Group onChange={(e) => this.setState({type: e.target.value})} value={type}>
                                <Radio value={"percent"}>Percent</Radio>
                                <Radio value={"fixed"}>Fixed</Radio>
                            </Radio.Group>
                        </div>
                        <div className="my-4">
                            <p className="font-medium text-black">Discount Value</p>
                            {isPercent ? <InputNumber
                                value={value}
                                style={{width: '100%'}}
                                min={0}
                                max={100}
                                // disabled={discountId}
                                formatter={value => `${value}%`}
                                parser={value => value.replace('%', '')}
                                onChange={(value) => this.setState({value})}
                            /> : <CurrencyWrapper
                                component={({symbol}) => (
                                    <InputNumber
                                        style={{width: '100%'}}
                                        value={value}
                                        // disabled={discountId}
                                        formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/[^0-9.]/g, '')}
                                        onChange={(value) => this.setState({value})}
                                    />
                                )}
                            />}
                        </div>

                        <div className="mt-4">
                            <Query
                                query={GET_OUTLETS}
                                variables={{
                                    company_id: `${companyId}`
                                }}
                            >
                                {({loading, refetch, error, data}) => {

                                    return (

                                        <SelectField
                                            label="Restrict to outlet"
                                            onChange={(e) => this.setState({ outlet: e.target.value })}
                                            value={outlet}
                                        >
                                            <option value="all">All Outlets</option>
                                            {!loading && data && data.outlets ? data.outlets.map((o) => {

                                                return (
                                                    <option value={o.id} key={o.id}>{o.name}</option>
                                                );
                                            }): null}
                                        </SelectField>

                                    )
                                }}
                            </Query>
                        </div>

                        <div className="mt-4">
                            <Query
                                query={GET_EMPLOYEES}
                                variables={{
                                    company_id: `${companyId}`
                                }}
                            >
                                {({loading, refetch, error, data}) => {

                                    return (
                                        <SelectField
                                            label="Restrict to employee"
                                            onChange={(e) => this.setState({ employee: e.target.value })}
                                            value={employee}
                                        >
                                            <option value="all">All Employees</option>
                                            {!loading && data && data.employees ? data.employees.map((o) => {

                                                return (
                                                    <option value={o.employee.id} key={o.employee.id}>{o.employee.display_name}</option>
                                                );
                                            }): null}
                                        </SelectField>
                                    );

                                }}
                            </Query>
                        </div>

                        <div className="mt-4">
                            <SelectField
                                label="State"
                                onChange={(e) => this.setState({ status: e.target.value })}
                                value={this.state.status}
                            >
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </SelectField>
                        </div>

                        <div className="mt-4">
                            <Checkbox
                                label="one-time usage of voucher"
                                checked={this.state.oneTime}
                                onChange={(e) => this.setState({ oneTime: e.target.checked })}
                            />
                        </div>

                        <div className="mt-4">
                            <TextInputField
                                placeholder="eg. VOUCHERONE"
                                label="Discount code"
                                disabled={discount && discount.id}
                                value={this.state.voucherCode}
                                onChange={(e) => this.setState({ voucherCode: e.target.value })}
                            />
                        </div>

                        <div>
                            <Mutation
                                mutation={discount && discount.id ? UPDATE_DISCOUNT: this.CREATE_DISCOUNT}
                                variables={{
                                    id: discount ? discount.id: null,
                                    name,
                                    type,
                                    active: status === '1' ? true: false,
                                    code: voucherCode.trim() || null,
                                    value,
                                    employee_id: employee !== 'all'? employee: null,
                                    outlet_id: outlet !== 'all'? outlet: null,
                                    one_time: oneTime,
                                    created_by: user.id,
                                    company_id: companyId
                                }}
                                onCompleted={(data) => {

                                    history.push(`/${companyId}/pos/discounts`);

                                }}
                            >
                                {(save, {loading}) => {

                                    return (
                                        <Button
                                            isLoading={loading}
                                            appearance="primary"
                                            intent="success"
                                            onClick={() => save()} 
                                        >
                                            Save
                                        </Button>
                                    )

                                }}
                            </Mutation>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}