import React from 'react';

class NotFound extends React.Component{

    render() {

        const {permission} = this.props;

        return (
            permission ? <div className="overflow-scroll h-full w-full bg-gray-100">

                <div className="flex w-full h-full flex-col items-center justify-center">
                    <h1 className="font-bold text-black text-4xl">Permission Denied</h1>
                    <p className="text-black text-lg max-w-md text-center my-4">You don't have the appropriate permission to view this page. Please refer to your system administrator</p>
                </div>

            </div> : <div className="overflow-scroll h-full w-full">
                <div className="flex items-center justify-center h-full">  
                    <p className="text-2xl font-bold">Oh no! We couldn't find the page you were looking for</p>
                </div>
            </div>
        );

    }

}

export default NotFound;