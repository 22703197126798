import React from 'react';
import ProfileSetup from './ProfileSetup';
import firebase from '../services/firebase';

class Profile extends React.Component{

    state = {
        currentUser: firebase.auth().currentUser
    }

    render() {

        const {currentUser} = this.state;

        return (
            <div className="">

                <h1 className="text-3xl font-bold text-center my-8">
                    Profile
                </h1>
                <ProfileSetup
                    email={currentUser.email}
                    currentUser={currentUser}
                    photoURL={currentUser.photoURL}
                    displayName={currentUser.displayName}
                    isUpdate={true}
                />

            </div>
        )

    }

}

export default Profile;