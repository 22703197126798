import React, { useEffect } from 'react';
import {Select} from 'antd';
import {keyBy, map} from 'lodash';
import { withDatabase } from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from "@nozbe/with-observables";
import { Q } from '@nozbe/watermelondb'


export default withDatabase(withObservables([], ({ companyId, database }) => ({
    users: database.collections.get('users').query(
        Q.where('company_id', Q.eq(parseInt(companyId))),
        Q.where('role', Q.eq('pos-sales-exec'))
    ).observe()
}))(({
    users,
    salespersonId,
    setSalesPerson,
    setDefault
}) => {
    
    useEffect(() => {

        if (!users.length) {

            setDefault();

        }

    }, [users.length]);

    return (
        <Select
            style={{width: 240}}
            onChange={setSalesPerson}
            defaultValue={salespersonId || ''} placeholder=""
        >
            {users.map((user) => {

                return <Select.Option value={user.userId} key={user.userId}>{user.displaName}</Select.Option>

            })}
        </Select>
    )

}));