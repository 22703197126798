import React from 'react';
import {TextInputField, Button} from 'evergreen-ui';
import {Camera} from 'react-feather';
import ProfilePicture from './ProfilePicture'
import firebase from '../services/firebase';

class ProfileSetup extends React.Component{

    onSubmit = async (e) => {

        e.preventDefault();

        const {currentUser} = this.props;
        const {displayName, email, photoURL, isUpdate} = this.state;

        this.setState({isSaving: true});

        try {

            if (isUpdate) {

                await firebase.firestore().collection('users').doc(currentUser.uid).update({
                    displayName,
                    photoURL
                });

                await currentUser.updateProfile({
                    displayName,
                    photoURL
                });

                this.setState({isSaving: false});

            } else {

                const user = await firebase.firestore().collection('users').doc(currentUser.uid).set({
                    email,
                    displayName: displayName || null,
                    photoURL: photoURL || null,
                    companyCount: 0,
                    employeeCount: 0,
                    maxEmployees: 0,
                    maxCompanies: 0,
                    created_at: Date.now()
                }, {
                    merge: true
                });

                await currentUser.updateProfile({
                    displayName,
                    photoURL
                });

                this.setState({isSaving: false});
        
                this.props.onSet();

            }

        } catch(e) {

            console.log(e);

            this.setState({isSaving: false});

        }

    }

    state = {
        displayName: '',
        photoURL: '',
        ...this.props
    }

    render() {

        const {displayName, email, photoURL, isUpdate, isSaving} = this.state;

        return (
            <form onSubmit={this.onSubmit} className="mt-8">
                <div className="mx-auto container max-w-xs">
                    <div className="shadow p-6 rounded">
                        <ProfilePicture url={photoURL} width={32} height={32} onUploadComplete={(photoURL) => this.setState({photoURL})} />
                        <div className="my-6">
                            <TextInputField
                                label="Email"
                                required
                                disabled
                                placeholder="Eg. John Doe"
                                value={email || ""}
                            />
                            <TextInputField
                                label="Full Name"
                                required
                                placeholder="Eg. John Doe"
                                value={displayName || ""}
                                onChange={e => this.setState({displayName: e.target.value})}
                            />
                            <div className="text-center">
                                <Button isLoading={isSaving} appearance="primary" iconAfter={isUpdate ? '': "arrow-right"} height={40} justifyContent="center" width="100%" intent="success">
                                    {isUpdate ? 'Save' : 'Continue'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );

    }

}

export default ProfileSetup;