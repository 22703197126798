import React from 'react';
import {DatePicker} from 'antd';
import moment from 'moment';
import {Spinner, BackButton, Button} from 'evergreen-ui';
import PayrollEmployees from './PayrollEmployees';

import {Query} from 'react-apollo';
import gql from 'graphql-tag';

const GET_PAYROLL = gql`
    query(
        $payroll_id: Int!,
        $company_id: Int!
    ) {
        company: company_by_pk(id: $company_id) {
            id
            name
            basic_salary_percent
            allowance_percent
            departments {
                id
                name
            }
        }
        ledgers: ledgers(where:{
            company_id: {
                _eq: $company_id
            },
            prefix: {
                _in: [
                    "current-liability",
                    "admin-exp",
                    "deferred-statutory",
                    "deferred-taxes",
                    "deferred-pensions",
                    "deferred-salaries",
                    "deferred-paye",
                    "salaries",
                    "paye",
                    "pensions",
                    "current-assets",
                    "cash-and-bank",
                    "cash",
                    "momo",
                    "card"
                ]
            }
        }){
            id
            prefix
            parent_id
            name
            balance{
                id
                amount
            }
        }
        payrolls(where: {
            id: {_eq: $payroll_id},
            company_id: {_eq: $company_id}
        }) {
            id
            title
            date
            created_at
            employees {
                payments{
                    id
                    type
                    amount
                }
                activities{
                    id
                    name
                    value
                    type
                }
                employeeId: employee_id
                allowance
                bank_account_number
                bank_branch
                bank_name
                bank_sort_code
                basic_salary
                daily
                days_absent
                department_id
                department_name
                hourly
                hours_absent
                name
                net_income
                others
                _income_tax: paye
                salary_aggregated
                ssnit_employee
                ssnit_employer
                ssnit_number
                sum_aggregations
                sum_deductions
                taxable_income
                tier_1
                tier_2
                tin
                total_deductions
                total_pension
            }
        }

    }
`;

const monthFormat = 'MMMM, YYYY';

class PayrollSingle extends React.Component{

    state = {
        loading: true,
        payroll: {},
        employees: [],
        companyId: this.props.match.params.companyId,
        payrollId: this.props.match.params.payrollId,
    }

    render() {

        const {company, companyId, payrollId, employees, payroll, loading} = this.state;

        return (
            <Query
                query={GET_PAYROLL}
                variables={{
                    company_id: companyId,
                    payroll_id: payrollId
                }}
            >
                {({data, error, loading}) => {

                    return (
                        <div className="">
                            {loading ? <div className="flex items-center justify-center py-10">
                                <Spinner />
                            </div> : data && data.payrolls.length ? <div className="">
                                <div className="print-hidden mx-auto mt-10 px-8">
                                    <div className="mt-12 mb-4 print-hidden">
                                        <BackButton appearance="default" marginRight={8} onClick={()=>{

                                            this.props.history.push(`/${companyId}/hr/payroll`);

                                        }} children="Back to payrolls" />
                                    </div>
                                    <div className="text-black font-bold text-3xl">Payroll - {data.payrolls[0].title}</div>
                                </div>
                                <PayrollEmployees
                                    isSingle={true}
                                    month={data.payrolls[0].title}
                                    employees={data.payrolls[0].employees.map((e) => {

                                        return {
                                            ...e,
                                            department: {
                                                id: e.department_id,
                                                name: e.department_name
                                            }
                                        }

                                    })}
                                    ledgers={data.ledgers}
                                    payrollId={payrollId}
                                    companyId={companyId}
                                    company={{...data.company}}
                                    history={this.props.history}
                                    user={this.props.user}
                                />
                            </div>: null}
                        </div>
                    )

                }}
            </Query>
        );

    }

}

export default PayrollSingle;