import React from 'react';
import {Checkbox, Button, InputNumber, Select} from 'antd';
import gql from "graphql-tag";
import { Query } from "react-apollo";
import {Spinner} from 'evergreen-ui';
import {withRouter} from 'react-router-dom';
import Barcode from 'react-barcode';
import {keys, chunk, flatten, keyBy, fromPairs, isObject} from 'lodash'
import {decode} from 'mout/queryString';
import {currencyFormat} from 'mout/number';
import { CurrencyFormat } from '../../services/Currency';


class Label extends React.Component{

    render() {

        const {
            companyName,
            name,
            variant,
            logo,
            price,
            code,
            showLogo,
            showName,
            showPrice,
            showSKU
        } = this.props;

        return (
            <div className="w-1/4 px-2 barcode border" style={{
                fontSize: '1vw'
            }}>
                <div className="flex flex-col items-center">
                    {showLogo ? logo ? <img src={logo} alt="" className="w-full" style={{maxWidth: '6rem'}} /> : <p className="font-bold text-black">{companyName}</p> : null}
                    {showName ? <p style={{
                        lineHeight: '1.5em',
                        maxHeight: '3em',
                        overflow: 'hidden'
                    }} className="text-black">{name}</p>: null}
                    {showSKU ? <p style={{
                        lineHeight: '1.5em',
                        maxHeight: '3em',
                        overflow: 'hidden'
                    }} className="text-black">{variant}</p>: null}
                    {showPrice ? <p
                        style={{
                            lineHeight: '1.5em',
                            maxHeight: '3em',
                            overflow: 'hidden'
                        }}
                        className="font-bold text-black"
                    >
                        <CurrencyFormat amount={price} />
                    </p>: null}
                    <div className="w-full flex items-center justify-center">
                        <Barcode className="class" value={code} height={40} width={1} />
                    </div>
                </div>
            </div>
        )

    }

}

const GET_VARIANTS = gql`
    query(
        $ids: [Int!],
        $company_id: Int!
    ) {

        variants: products_variants(where:{
            id: {_in: $ids},
            product:{
                company_id: {
                    _eq: $company_id
                }
            }
        }){
            id
            name
            price
            product{
                id
                name
                has_variants
            }
            skus(order_by: {created_at: desc}, limit: 1){
                id
                code
            }
        }

    }
`;

const SEARCH_VARIANT = gql`
    query (
        $q: String!,
        $company_id: Int!
    ) {
        products_sku(where: {
            _or: [
                {variant: {
                    name: {_ilike: $q},
                }},
                {variant: {
                    product: {
                        name: {_ilike: $q}
                    }
                }}
            ],
            variant:{
                product:{
                    company_id: {
                        _eq: $company_id
                    }
                }
            }
        }) {
            id
            variant {
                id
                cost
                name
                price
                product {
                    id
                    name
                    has_variants
                }
            }
        }
    }
  
`;

class ProductBarcodes extends React.Component{

    state = {
        companyId: this.props.companyId,
        variants: this.props.variants,
        company: this.props.company,
        qty: this.props.qty,
        showLogo: false,
        showName: true,
        showPrice: true,
        showSKU: true,
        searchValue: ''
    }

    fetchVariantFromSearch = async (value) => {

        const _item = JSON.parse(value);


        const {variants, qty} = this.state;
        const code = _item.variant.id;
        
        const item = variants[code];


        if (item) {

            qty[code]++;

        } else {

    
            variants.unshift(_item.variant);

            qty[code] = 1;

        }

        this.setState({
            variants,
            qty,
            searchValue: ''
        });

    }

    render() {

        const {
            showLogo,
            showName,
            showPrice,
            showSKU,
            variants,
            qty,
            company,
            companyId,
            searchValue
        } = this.state;

        const _variants = keyBy(variants, (v) => v.id);

        // console.log(_variants);

        const __qty = keys(qty);

        return (
            <div className="w-full flex overflow-hidden h-full">
                <div className="w-64 h-full p-6 border-r overflow-scroll mb-16 print-hidden">
                    <p className="font-bold text-black text-lg">Labels</p>
                    <div className="my-4">
                        <div className="mb-8">
                            {/* <p className="font-light text-xl text-black my-2">{product.name}</p> */}
                            <div className="my-2">
                                <Checkbox checked={showLogo} onChange={(e) => this.setState({showLogo: e.target.checked})} className="ml-0"><span className="font-medium text-black">Show Logo</span></Checkbox>
                                <br/>
                                <Checkbox checked={showName} onChange={(e) => this.setState({showName: e.target.checked})} className="ml-0"><span className="font-medium text-black">Show Name</span></Checkbox>
                                <br/>
                                <Checkbox checked={showPrice} onChange={(e) => this.setState({showPrice: e.target.checked})} className="ml-0"><span className="font-medium text-black">Show Price</span></Checkbox>
                                <br/>
                                <Checkbox checked={showSKU} onChange={(e) => this.setState({showSKU: e.target.checked})} className="ml-0"><span className="font-medium text-black">Show SKU</span></Checkbox>
                                <br/>
                            </div>
                        </div>
                        <div className="">
                            {variants.map((v) => (<div key={v.id} className="my-3">

                                <div className="mb-1">
                                    <p className="font-medium text-black">{v.product.name}</p>
                                    <p className="font-medium text-black">{v.name}</p>
                                </div>
                                <div className="">
                                    <InputNumber style={{width: '100%'}} min={0} value={qty[v.id] || 0} onChange={(value) => {

                                        qty[v.id] = value;

                                        this.setState({qty});

                                    }} />
                                </div>

                            </div>))}
                        </div>
                    </div>
                </div>
                <div className="p-6 flex-1 print-hidden">
                    <div className="my-8">
                        <p className="font-bold text-black">Search Products</p>
                        <Query
                            query={SEARCH_VARIANT}
                            variables={{
                                q: `%${searchValue}%`,
                                company_id: companyId
                            }}
                            skip={!searchValue}
                        >
                            {({data, error, loading, refetch}) => {

                                return (
                                    <Select
                                        showSearch
                                        loading={loading}
                                        // ref={this.inputRef}
                                        value={searchValue}
                                        placeholder="Search for products"
                                        style={{width: '100%'}}
                                        // disabled={!source_id || !destination_id}
                                        notFoundContent={loading ? <div className="flex items-center justify-center p-4"><Spinner /></div> : null}
                                        showArrow={false}
                                        allowClear
                                        filterOption={false}
                                        onSearch={(searchValue) => this.setState({searchValue})}
                                        onChange={(value) => {

                                            if (value) {

                                                this.fetchVariantFromSearch(value);

                                            }


                                        }}
                                        // notFoundContent={null}
                                    >
                                        {!loading && data && data.products_sku.length ? data.products_sku.map(d => d.variant.product ? (
                                            <Select.Option key={d.id} value={JSON.stringify(d)}>
                                                {d.variant.product.name} - {d.variant.name} - <CurrencyFormat amount={d.variant.price} />
                                            </Select.Option>
                                        ):null): null}
                                    </Select>
                                )

                            }}
                        </Query>
                    </div>
                    {__qty.length ? <div className="flex items-center">
                        <p className="text-black text-4xl font-bold">Preview</p>
                        <Button className="mx-3" size="small" ghost type="primary" onClick={() =>  window.print()}>Print</Button>
                    </div>: null}
                    <div className="w-full print-hidden">
                        <div className="flex flex-wrap mt-4 print-hidden">
                            {__qty.map((k) => (
                                
                                qty[k] > 0 ? <Label
                                    key={k}
                                    companyName={company.name}
                                    name={_variants[k].product.name}
                                    variant={_variants[k].name}
                                    logo={company.logo}
                                    price={_variants[k].price}
                                    code={k}
                                    showLogo={showLogo}
                                    showName={showName}
                                    showPrice={showPrice}
                                    showSKU={showSKU}
                                />: null
                            ))}
                        </div>
                    </div>
                </div>
                <div className="print-only w-full mx-auto">
                    {chunk(flatten(__qty.map((k) => {

                        return new Array(qty[k] || 0).fill(k);

                    })), 16).map((c, index) => {

                        return (
                            <React.Fragment key={index}>
                                <div className="flex flex-wrap w-full -mx-2 mt-4" >
                                    {c.map((k, index) => <Label
                                            key={index}
                                            name={_variants[k].product.name}
                                            variant={_variants[k].name}
                                            logo={company.logo}
                                            price={_variants[k].price}
                                            code={k}
                                            showLogo={showLogo}
                                            showName={showName}
                                            showPrice={showPrice}
                                            showSKU={showSKU}
                                        />
                                    )}
                                </div>
                                <div style={{pageBreakAfter: "always"}}></div>
                            </React.Fragment>
                        )

                    })}
                </div>
            </div>
        );

    }

}

const ProductBarcodesWrapper = withRouter(ProductBarcodes);

class ProductBarcodesContainer extends React.Component{

    state = {
        companyId: this.props.match.params.companyId
    }

    render() {

        const {companyId} = this.state;
        const {company, location} = this.props;

        const variants = decode(location.search);
        const qty = Array.isArray(variants.v) ? fromPairs(variants.v.map((v) => v.split(',').map((k) => parseInt(k)))) : variants.v ? fromPairs([variants.v.split(',').map((k) => parseInt(k))]) :{};

        return (
            <Query
                query={GET_VARIANTS}
                variables={{
                    ids: keys(qty).map((k) => parseInt(k)),
                    company_id: companyId
                }}
                skip={!isObject(qty)}
            >
                {({data, error, loading}) => {

                    // console.log(data, error);

                    return (
                        !loading ? <ProductBarcodesWrapper
                            company={company}
                            companyId={companyId}
                            qty={qty}
                            variants={data ? data.variants : []}
                        />: null
                    )

                }}
            </Query>
        )

    }

}

export default ProductBarcodesContainer;