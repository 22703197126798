
import React from 'react';
import {Spinner} from "evergreen-ui";

class Loading extends React.Component{

    render() {

        return (
            <div className="w-full h-full flex items-center justify-center"><Spinner/></div>
        )

    }

};

export default Loading;