import React from 'react';
import { TextInputField, FormField, Button, Spinner, BackButton, FormFieldValidationMessage } from 'evergreen-ui';
import { InputNumber, Select } from 'antd';

import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import {withRouter} from 'react-router-dom';

const GET_SUPPLIER = gql`
    query($id: Int!) {

        supplier: suppliers_by_pk(id: $id) {
            id
            name
            firstName: contact_first_name
            lastName: contact_last_name
            phone: contact_phone
            email: contact_email
            BAN: bank_account_number
            address
            company_id
        }

    }
`;

const SAVE_MUTATION = gql`

    mutation add(
        $id: Int!,
        $name: String!,
        $company_id: String,
        $parentId: Int,
        $firstName: String,
        $lastName: String,
        $phone: String,
        $email: String,
        $BAN: String,
        $address: String
        $transactions: [ledger_transactions_insert_input]
    ) {
        update_suppliers(where: {id: {_eq: $id}}, _set: {
            name: $name,
            contact_first_name: $firstName,
            contact_last_name: $lastName,
            contact_phone: $phone,
            contact_email: $email,
            bank_account_number: $BAN,
            address: $address
        }) {
            affected_rows
        }
    }  

`;

const ADD_MUTATION = gql`

    mutation add(
        $id: Int,
        $name: String!,
        $company_id: Int,
        $parentId: Int,
        $firstName: String,
        $lastName: String,
        $phone: String,
        $email: String,
        $BAN: String,
        $address: String
        $transactions: [ledger_transactions_insert_input!]!
    ){
        insert_suppliers(objects: {
            name: $name,
            contact_first_name: $firstName,
            contact_last_name: $lastName,
            contact_phone: $phone,
            contact_email: $email,
            bank_account_number: $BAN,
            address: $address,
            company_id: $company_id,
            ledger: {
                data: {
                    archived: false,
                    currency: "GHS",
                    group_id: 2,
                    description: "",
                    is_default: true,
                    name: $name,
                    company_id: $company_id,
                    prefix: null,
                    parent_id: $parentId,
                    transactions: {
                        data: $transactions
                    }
                }
            }
        }) {
            affected_rows
        }
    }
  

`;


class Supplier extends React.Component {

    state = {
        companyId: this.props.match.params.companyId,
        supplierId: this.props.match.params.supplierId
    }

    render() {

        const { companyId, supplierId } = this.state; //name is no-longer in use
        const { company, user } = this.props;

        return (
            <div className="h-full w-full overflow-scroll">
                <div className="max-w-lg w-full mx-auto my-12">
                    <BackButton appearance="default" marginBottom={15} onClick={() => {

                        this.props.history.push(`/${companyId}/procument/suppliers`);

                    }} children="Back to suppliers" />
                    <Query
                        query={GET_SUPPLIER}
                        skip={(!supplierId) ? true : false}
                        variables={{
                            id: supplierId
                        }}
                    >
                        {({ loading, data, error, refetch }) => {

                            return (
                                <div className="p-8 rounded-lg shadow-lg">
                                    {loading ? <div className="flex items-center justify-center py-10">
                                        <Spinner />
                                    </div> : <div className="">
                                        <SupplierInner
                                            companyId={companyId}
                                            supplierId={supplierId}
                                            {...((data || {}).supplier || {})}
                                            company={company}
                                            user={user}
                                        />
                                    </div>}
                                </div>
                            )

                        }}
                    </Query>
                </div>

            </div>

        );

    }

}


class SupplierInnerRaw extends React.Component{

    state = {
        companyId: this.props.companyId,
        supplierId: this.props.supplierId,
        firstName: this.props.firstName || '',
        lastName: this.props.lastName || '',
        phone: this.props.phone || '',
        email: this.props.email || '',
        BAN: this.props.BAN || '', // Bank Account Number
        address: this.props.address || '', //GPS

        //validation variables
        inValid: false,
        inValidEmail: false,
        isBANInvalid: false,
        isAddressInvalid: false,
        emailErrorMsg: null,
        phoneErrorMsg: null,
        BANErrorMsg: null,
        addressErrorMsg: null,
        name: this.props.name || '', //to be removed
    }

    //phone number validation
    handleNumbers = e => {
        if (/^\d+$/.test(e.target.value)) {
            console.log("is working")
            if (e.target.value.length <= 10) {
                this.setState({
                    phone: e.target.value,
                    inValid: false,
                    phoneErrorMsg: null,
                });
            }
            else {
                this.setState({
                    inValid: true,
                    phoneErrorMsg: 'Phone number is not up to 10'
                })
            }

        }
        else {
            if (e.target.value === '') {
                this.setState({
                    phone: '',
                    inValid: false,
                    phoneErrorMsg: null
                })
            }
            else {
                this.setState({
                    inValid: true,
                    phoneErrorMsg: "Phone number invalid"
                })
            }


        }
    }

    //email validation
    handleEmails = e => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
            this.setState({
                email: e.target.value,
                inValidEmail: false,
                emailErrorMessage: null
            });
        }
        else {
            this.setState({
                inValidEmail: true,
                emailErrorMessage: "Enter a valid Email"
            })
        }
    }

    //bank account number validation
    handleBAN = e => {
        if (/^\d+$/.test(e.target.value) && e.target.value.length === 9) {
            this.setState({
                BAN: e.target.value,
                isBANInvalid: false,
                BANErrorMsg: null,
            })
        }
        else {
            this.setState({
                isBANInvalid: true,
                BANErrorMsg: "Invalid Bank Account Number length"
            })
        }
    }

    //GPS address validation
    handleAddress = e => {
        const reg = RegExp("[A-Za-z]{2}-[0-9]{3}-[0-9]{4}")
        if (reg.test(e.target.value)) {
            this.setState({
                address: e.target.value,
                isAddressInvalid: false,
                addressErrorMsg: null
            })
        }
        else {
            this.setState({
                isAddressInvalid: true,
                addressErrorMsg: "Invalid GPS address format"
            })
        }
    }

    render() {

        const {
            companyId,
            supplierId,
            firstName,
            lastName,
            phone,
            email,
            BAN,
            address,
            inValid,
            inValidEmail,
            isBANInvalid,
            isAddressInvalid,
            emailErrorMsg,
            phoneErrorMsg,
            BANErrorMsg,
            addressErrorMsg,
            name
        } = this.state;

        let {company, user} = this.props;

        return (
            <div>
                <p className="text-black text-lg font-bold">{supplierId ? 'Edit' :'Create'} Supplier</p>
                {/* Supplier's first name */}
                <div className="mt-4 mb-6">
                    <TextInputField
                        label="Supplier's Name"
                        defaultValue={name}
                        onChange={(e) => {

                            this.setState({
                                name: e.target.value
                            });

                        }}
                        placeholder="Supplier's Name"
                        required
                        autoFocus
                    />
                </div>
                <div className="mt-4">
                    <TextInputField
                        label="First Name"
                        defaultValue={firstName}
                        onChange={(e) => {

                            this.setState({
                                firstName: e.target.value
                            });

                        }}
                        placeholder="First Name"
                        required
                    />
                </div>

                {/* Supplier's last name */}
                <div className="mt-4">
                    <TextInputField
                        label="Last Name"
                        defaultValue={lastName}
                        onChange={(e) => {

                            this.setState({
                                lastName: e.target.value
                            });

                        }}
                        placeholder="Last Name"
                        required
                    />
                </div>

                {/* Supplier's phone number */}
                <div className="mt-4">
                    <TextInputField
                        label="Phone number"
                        defaultValue={phone}
                        onChange={this.handleNumbers}
                        placeholder="Phone"
                        isInvalid={inValid}
                        validationMessage={phoneErrorMsg}
                        required
                    />
                </div>

                {/* Supplier's email address */}
                <div className="mt-4">
                    <TextInputField
                        label="Email"
                        defaultValue={email}
                        onChange={this.handleEmails}
                        placeholder="Eg. John@example.com"
                        isInvalid={inValidEmail}
                        validationMessage={emailErrorMsg}
                        required
                    />
                </div>

                {/* Supplier's bank account number*/}
                <div className="mt-4">
                    <TextInputField
                        label="Bank Account Number"
                        defaultValue={BAN}
                        onChange={this.handleBAN}
                        placeholder="Bank Account Number"
                        isInvalid={isBANInvalid}
                        validationMessage={BANErrorMsg}
                        required
                    />
                </div>

                {/* Supplier's GPS address */}
                <div className="mt-4">
                    <TextInputField
                        required
                        label="Ghana Post Address"
                        defaultValue={address}
                        onChange={this.handleAddress}
                        isInvalid={isAddressInvalid}
                        validationMessage={addressErrorMsg}
                        placeholde="Eg. GM-098-1234"
                    />
                </div>

                <Mutation
                    onCompleted={() => this.props.history.push(`/${companyId}/procument/suppliers`)}
                    mutation={supplierId ? SAVE_MUTATION : ADD_MUTATION}
                    variables={{
                        company_id: companyId,
                        id: supplierId,
                        name,
                        firstName,
                        lastName,
                        phone,
                        email,
                        BAN,
                        address,
                        parentId: company ? company.supplier_ledger[0].id : 0,
                        transactions: [{
                            description: "INITIAL BALANCE",
                            initial: true,
                            user_id: user.id
                        }]
                    }}
                >
                    {(add, { loading }) => {

                        return (
                            <Button appearance="primary" intent="success" isLoading={loading} onClick={() => add()}>Save</Button>
                        )

                    }}
                </Mutation>
            </div>
        )

    }

}

const SupplierInner = withRouter(SupplierInnerRaw);


export default Supplier;