import React from 'react';
import { RefreshCw, MoreHorizontal, FileText } from 'react-feather';
import { Input, Radio, Button, Select, DatePicker, Row, Modal } from 'antd';
import { capitalize, debounce } from 'lodash';
import { Spinner, Badge, TextInputField } from 'evergreen-ui';
import moment from 'moment';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import {PlusOutlined} from '@ant-design/icons';

const { MonthPicker } = DatePicker;
const monthFormat = 'MMMM, YYYY';


const GET_PAYROLLS = gql`
    query(
        $company_id: Int!,
        $start_month: date!,
        $end_month: date!
    ) {

        payrolls(where: {
            company_id: {_eq: $company_id},
            date: {
                _gte: $start_month,
                _lte: $end_month
            }
        }) {
            id
            title
            date
            created_at
            employees_aggregate {
                aggregate {
                    count
                }
            }
        }

    }
`;


class Payroll extends React.Component {

    state = {
        start_month: moment().startOf('year').format('YYYY-MM-DD'),
        end_month: moment().endOf('year').format('YYYY-MM-DD'),
        companyId: this.props.match.params.companyId,
        showModal: false,
        deptName: ""
    }

    componentWillUnmount() {

        window.onscroll = null;

    }

    render() {

        const { start_month, end_month, companyId } = this.state;

        return (
            <div className="py-12">
                <Query
                    query={GET_PAYROLLS}
                    variables={{
                        start_month,
                        end_month,
                        company_id: companyId
                    }}
                >
                    {({ data, loading, error, refetch }) => {

                        return (
                            <div className="w-full max-w-3xl mx-auto px-4">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <h1 className="font-bold text-4xl mr-2">
                                            Payroll
                                        </h1>
                                        {loading ? null : <button className="ml-4 focus:outline-none text-black" onClick={() => refetch()}>
                                            <RefreshCw className={(loading) ? "rotating" : null} size={24} />
                                        </button>}
                                    </div>
                                    <div className="payrollButtons">
                                        <Button type="primary" onClick={() => this.props.history.push(`/${companyId}/hr/payroll/generate`)} shape="round" ghost icon={<PlusOutlined />} className="flex items-center justify-center">Generate</Button>
                                    </div>
                                </div>
                                <div className="mt-8">
                                    <p className="font-bold text-black mb-2">Filter</p>
                                    <div className="flex -mx-2">
                                        <div className="w-1/2 px-2">
                                            <MonthPicker placeholder="Start Month" style={{ width: '100%' }} onChange={(start_month) => this.setState({
                                                start_month: moment(start_month, monthFormat).startOf('month').format('YYYY-MM-DD')
                                            })} defaultValue={moment(start_month, 'YYYY-MM-DD')} format={monthFormat} />
                                        </div>
                                        <div className="w-1/2 px-2">
                                            <MonthPicker placeholder="End Month" style={{ width: '100%' }} onChange={(end_month) => this.setState({
                                                end_month: moment(end_month, monthFormat).endOf('month').format('YYYY-MM-DD')
                                            })} defaultValue={moment(end_month, 'YYYY-MM-DD')} format={monthFormat} />
                                        </div>
                                    </div>
                                </div>
                                {loading ? <div className="flex items-center justify-center py-10">
                                    <Spinner />
                                </div> : data && data.payrolls.length ? <div className="my-4">
                                    {data.payrolls.map((payroll) => {

                                        // const isGenerating = ;

                                        return (
                                            <div className="border-b my-4 py-4" key={payroll.id}>
                                                <div className="flex items-center justify-between">
                                                    <div onClick={(e) => {

                                                        e.stopPropagation();

                                                        this.props.history.push(`/${companyId}/hr/payroll/${payroll.id}`);

                                                    }} className="cursor-pointer flex-1">
                                                        <div className="font-bold text-xl text-black">
                                                            <div className="">
                                                                {payroll.title}
                                                            </div>
                                                        </div>
                                                        <p className="text-sm text-black font-medium">{payroll.employees_aggregate.aggregate.count} Employees</p>
                                                        <p className="mt-3">Created {moment(payroll.created_at).fromNow()}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    })}
                                </div> : <p className="text-center py-10 text-black">No Payrolls found.</p>}
                            </div>
                        );

                    }}
                </Query>
            </div>
        )

    }

}

export default Payroll;