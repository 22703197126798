import React from 'react';
import {Popover, Position, Menu} from 'evergreen-ui';
import {MoreHorizontal} from 'react-feather';
import {withRouter, Link} from 'react-router-dom';


class HR extends React.Component{

    render() {

        const {currentCompany, company} = this.props;

        return (
            <ul className="list-reset flex items-center -mx-4">
                <li>
                    <Link to={`/${currentCompany}/hr`} className="px-4 font-medium">Employees</Link>
                </li>
                <li>
                    <Link to={`/${currentCompany}/hr/payroll`} className="px-4 font-medium">Payroll</Link>
                </li>
                <li>
                    <Link to={`/${currentCompany}/hr/aggregators`} className="px-4 font-medium">Aggregators</Link>
                </li>
                <li>
                    <Link to={`/${currentCompany}/hr/deductions`} className="px-4 font-medium">Deductions</Link>
                </li>
                <li>
                    <Link to={`/${currentCompany}/hr/allowances`} className="px-4 font-medium">Allowances</Link>
                </li>
                {/* <li>
                    <Popover
                        position={Position.BOTTOM_RIGHT}
                        content={
                            ({close}) => {

                                return <Menu>
                                    <Menu.Item
                                        onSelect={() => {

                                            close();

                                            this.props.history.push(`/${currentCompany}/hr/deductions/settings`);

                                        }}
                                    >
                                        Deduction Settings
                                    </Menu.Item>
                                    <Menu.Divider />
                                    <Menu.Item
                                        onSelect={() => {

                                            close();

                                            this.props.history.push(`/${currentCompany}/hr/aggregators/settings`);

                                        }}
                                    >
                                        Aggregatior Settings
                                    </Menu.Item>
                                </Menu>
                            }
                        }
                    >
                        {({getRef, toggle, isShown}) => (

                            <button ref={getRef} onClick={toggle} className="px-4 flex flex-col items-center active:outline-none focus:outline-none">
                                <MoreHorizontal size={21} />
                            </button>

                        )}
                    </Popover>
                </li> */}
            </ul>
        )

    }

}

export default withRouter(HR);