import React from 'react';
import { Route, Switch, Redirect, withRouter, matchPath, Link } from 'react-router-dom';
import { Spinner, Popover, Position, Avatar } from 'evergreen-ui';
import { ChevronDown, ChevronUp, ChevronLeft, ChevronRight, Briefcase, Users, Clipboard, ShoppingCart, BookOpen, Settings, Folder, FileText } from 'react-feather';
import { Tooltip, Modal } from 'antd';
import { find, includes, truncate, keys, findIndex } from 'lodash';
import Error from './Error';
import Permission from '../services/Permission';
import { Offline, Online } from "react-detect-offline";

import Header from './Header';
import routes from '../routes';
import RestrictView, { checkRestricted } from './misc/restrictView';
import RemoteScanner from './misc/RemoteScanner';
import { context as PermissionContext, PermissionView } from '../services/Permission';
import { CurrencyContext } from '../services/Currency';
import apollo from '../services/apollo';
import gql from 'graphql-tag';
import { decode } from 'mout/queryString';
import sync from '../services/sync';
import Promise from 'bluebird';

import { withDatabase } from '@nozbe/watermelondb/DatabaseProvider';

let isSalesSyncing = false;

const CURRENT_COMPANY = gql`
    subscription($id: Int!) {

        company: company_by_pk(id: $id){

            id
            tax_type
            tax_option
            vat
            nhil
            tourism_levy
            flat_rate
            discount_before_tax
            country{
                id
                code
                name
            }
            ledgers(where: {
                prefix: {
                    _in: [
                        "current-asset",
                        "cash-and-bank",
                        "cash",
                        "momo",
                        "card",
                        "discounts",
                        "deferred-statutory",
                        "trade-receivables",
                        "deferred-taxes",
                        "deferred-vat",
                        "deferred-tourism-levy",
                        "sales",
                        "cos"
                    ]
                }
            }){
                id
                prefix
                parent_id
                group_id
            }
            currency{
                id
                name
                precision                    
                decimal_separator
                code
                symbol
                swap_currency_symbol
                thousand_separator
            }
        }

    }
`;

function warning(title) {

    Modal.warning({
        title: 'Upgrade Required',
        content: `You\'re GomERP subscription doesn\'t include ${title}, please contact 0206835553 or info@defpal.com for assistance.`,
    });

}



class Dashboard extends React.Component {

    state = {
        showSideMenu: JSON.parse(window.localStorage['menuOpen'] || 'true'),
        displayName: '',
        companies: this.props.account.companies,
        currentCompany: null,
        account: this.props.account,
        user: this.props.user,
        email: 'dsirakoff@gmail.com'
    }


    async componentDidMount() {

        // console.log(window.location.pathname);

        this.setCurrentCompany(window.location.pathname);

        this.props.history.listen((location, action) => {

            this.setCurrentCompany(location.pathname);

        });

        sync(this.props.database, this.props.account);

        this.salesTimer = setInterval(async () => {

            if (isSalesSyncing) return;

            isSalesSyncing = true;

            let sales = [];

            for (const key in localStorage) {
                if (localStorage.hasOwnProperty(key)) {

                    if (key.includes('gomerp.mutations')) {

                        sales.push(key);

                    }


                }
            };

            sales = sales.map((k) => JSON.parse(localStorage.getItem(k)));

            Promise.each(sales, (sale) => {

                const KEY = `gomerp.mutations.${sale.id}`;

                sale.variables.transactions = (sale.variables.transactions || []).map((t) => {

                    if (!t.credit) t.credit = 0;
                    if (!t.debit) t.debit = 0;

                    return t;

                });

                return apollo.mutate({
                    mutation: sale.mutation,
                    variables: sale.variables
                }).then(() => {

                    localStorage.removeItem(KEY);

                });

            }).then(() => {

            }).catch((e) => {

                console.log(e);

            }).finally(() => {

                isSalesSyncing = false;

            });


        }, 90000);

        this.syncTimer = setInterval(async () => {

            await sync(this.props.database, this.props.account);

        }, 30000)

    }

    componentWillUnmount() {

        if (this.syncTimer) {

            clearInterval(this.syncTimer);

        }

        if (this.salesTimer) clearInterval(this.salesTimer);

    }

    async componentDidUpdate(prevProps, prevState) {

        const { currentCompany } = this.state;
        const _this = this;

        if (currentCompany !== prevState.currentCompany) {

            if (this.sub) this.sub.unsubscribe();

            this.sub = apollo.subscribe({
                query: CURRENT_COMPANY,
                variables: {
                    id: currentCompany
                }
            }).subscribe({
                next({ data }) {

                    let index = findIndex(_this.state.companies, { id: parseInt(currentCompany) });

                    if (index > -1) {

                        _this.state.companies[index] = {
                            ..._this.state.companies[index],
                            ...data.company
                        };

                        _this.setState({
                            companies: _this.state.companies
                        });

                    }

                    // console.log('company subscription', data);
                },
                error(err) { console.error('err', err); },
            });

        }

    }


    setCurrentCompany = (pathname) => {

        const match = matchPath(pathname, {
            path: "/:companyId/:menuType",
            exact: false,
            strict: false
        });

        // console.log('match: ', match);
        // console.log('pathname: ', pathname);

        if (match) {

            if (includes(['company', 'users'], match.params.companyId) || includes(['create'], match.params.menuType)) {

                return this.setState({
                    currentCompany: null,
                    isSettings: true
                });

            }

            this.setState({
                section: match.params.menuType,
                currentCompany: match.params.companyId,
                isSettings: false
            });

        } else {

            if (pathname.includes('users') || pathname == '/') {

                return this.setState({
                    currentCompany: null,
                    isSettings: true
                });


            }

            this.setState({
                currentCompany: null,
                isSettings: false
            });

        }

    }

    render() {

        const { companies, currentCompany, section, showSideMenu, loading, account, user, isSettings } = this.state;

        const company = find(companies, { id: parseInt(currentCompany) });
        const isAdmin = user.role === 'admin';
        const allowed_companies = keys(user.companies);
        const query = decode(window.location.search);
        const isReport = query.report;

        return (
            <PermissionContext.Provider value={company && user.companies[company.id] ? {
                allowed_companies,
                role: user.companies[company.id].role,
                id: user.companies[company.id].company_id,
                outlet_id: user.companies[company.id].outlet_id
            } : {
                isAdmin,
                allowed_companies
            }}>
                <div className="flex h-full w-full">
                    {!isReport ? <div className="fixed top-0 left-0 bottom-0 border-r print-hidden" style={{
                        width: showSideMenu ? 230 : 65,
                        backgroundColor: '#ebeff4',
                        zIndex: 10
                    }}>
                        <div className="flex h-full flex-col items-center justify-between px-2">
                            <div className="flex flex-col items-center">
                                <div className="font-bold my-6">
                                    {/* Logo */}
                                </div>
                                <div className="w-full px-4">
                                    <Popover
                                        position={Position.BOTTOM}
                                        content={
                                            ({ close }) => {

                                                return <div className="text-black">
                                                    {companies.filter((c) => isAdmin ? c : allowed_companies.indexOf(`${c.id}`) > -1).map((company) => {

                                                        return (
                                                            <div onClick={() => {

                                                                this.props.history.push(`/${company.id}/home`);

                                                                close();

                                                            }} key={company.id} className="text-black hover:bg-gray-200 px-4 py-1 cursor-pointer">
                                                                {truncate(company.name, {
                                                                    'length': 22
                                                                })}
                                                            </div>
                                                        );

                                                    })}
                                                    <div onClick={() => {

                                                        this.props.history.push(`/`);

                                                        close();

                                                    }} className="hover:bg-gray-200 text-black px-4 py-2 mt-1 border-t cursor-pointer">
                                                        See all companies
                                                    </div>
                                                    {isAdmin && (companies.length < account.max_companies) ? <div onClick={() => {

                                                        this.props.history.push(`/company/create`);

                                                        close();

                                                    }} className="text-black hover:bg-gray-200 px-4 py-2 cursor-pointer">
                                                        Create a new company
                                                    </div> : null}
                                                </div>
                                            }
                                        }
                                    >
                                        {({ getRef, toggle, isShown }) => (

                                            <Tooltip placement="right" title={!showSideMenu ? <span>{company ? company.name : 'Choose Company'}</span> : null}>
                                                {showSideMenu ? <button ref={getRef} onClick={toggle} className="active:outline-none focus:outline-none shadow rounded-full bg-white px-4 py-2 w-full flex items-center justify-between">
                                                    <span className="font-bold text-black truncate w-24">{company ? truncate(company.name, {
                                                        length: 22
                                                    }) : 'Choose Company'}</span>
                                                    <span className="ml-2">{isShown ? <ChevronUp size={26} /> : <ChevronDown size={26} />}</span>
                                                </button> : <button ref={getRef} onClick={toggle} className="active:outline-none focus:outline-none shadow rounded-full w-10 h-10 bg-white">
                                                    <Avatar name={company ? company.name : ''} size={40} />
                                                </button>}
                                            </Tooltip>

                                        )}
                                    </Popover>
                                </div>
                                <div className="w-full my-6 px-4">
                                    {currentCompany ? <ul className={[
                                        "w-full",
                                        "flex",
                                        "flex-col",
                                        !showSideMenu ? "items-center" : 'items-start'
                                    ].join(' ')}>
                                        <PermissionView allowedGroups={["pos", "admin"]}>
                                            <RestrictView allowed={company.allowed_services} value="pos">
                                                <li className="my-4">
                                                    <Link to={`/${currentCompany}/pos/sales`} className="hover:text-black text-black flex items-center">
                                                        <Tooltip placement="right" title={!showSideMenu ? <span>Point of Sale</span> : null}>
                                                            <ShoppingCart size={28} />
                                                        </Tooltip>
                                                        {showSideMenu ? <span className="px-4 font-bold font-16">Point of Sale</span> : null}
                                                    </Link>
                                                </li>
                                            </RestrictView>
                                        </PermissionView>
                                        <PermissionView allowedGroups={["pos", "admin"]}>
                                            <RestrictView allowed={company.allowed_services} value="pos">
                                                <li className="my-4">
                                                    <Link to={`/${currentCompany}/pos`} className="hover:text-black text-black flex items-center">
                                                        <Tooltip placement="right" title={!showSideMenu ? <span>Inventory</span> : null}>
                                                            <FileText size={28} />
                                                        </Tooltip>
                                                        {showSideMenu ? <span className="px-4 font-bold font-16">Inventory</span> : null}
                                                    </Link>
                                                </li>
                                            </RestrictView>
                                        </PermissionView>
                                        {/* <RestrictView allowed={company.allowed_services} value="pos"> */}
                                        <PermissionView allowedGroups={["admin"]}>
                                            <li className="my-4">
                                                <Link to={`/${currentCompany}/procument`} className="hover:text-black text-black flex items-center">
                                                    <Tooltip placement="right" title={!showSideMenu ? <span>Procument</span> : null}>
                                                        <Folder size={28} />
                                                    </Tooltip>
                                                    {showSideMenu ? <span className="px-4 font-bold font-16">Procument</span> : null}
                                                </Link>
                                            </li>
                                        </PermissionView>
                                        {/* </RestrictView> */}
                                        <PermissionView allowedGroups={["accounts", "admin"]}>
                                            <RestrictView allowed={company.allowed_services} value="accounting">
                                                <li className="my-4">
                                                    <Link to={`/${currentCompany}/accounting`} className="hover:text-black text-black flex items-center">
                                                        <Tooltip placement="right" title={!showSideMenu ? <span>Accounting</span> : null}>
                                                            <BookOpen size={28} />
                                                        </Tooltip>
                                                        {showSideMenu ? <span className="px-4 font-bold font-16">Accounting</span> : null}
                                                    </Link>
                                                </li>
                                            </RestrictView>
                                        </PermissionView>
                                        <PermissionView allowedGroups={["payroll", "admin"]}>
                                            <RestrictView allowed={company.allowed_services} value="hr">
                                                <li className="my-4">
                                                    <Link to={`/${currentCompany}/hr`} className="hover:text-black text-black flex items-center">
                                                        <Tooltip placement="right" title={!showSideMenu ? <span>HR / Admin</span> : null}>
                                                            <Clipboard size={28} />
                                                        </Tooltip>
                                                        {showSideMenu ? <span className="px-4 font-bold font-16">HR / Admin</span> : null}
                                                    </Link>
                                                </li>
                                            </RestrictView>
                                        </PermissionView>
                                        <PermissionView allowedGroups={["payroll", "admin", "accounts", "pos"]}>
                                            {/* <RestrictView allowed={company.allowed_services} value="hr"> */}
                                            <li className="my-4">
                                                <Link to={`/${currentCompany}/reports`} className="hover:text-black text-black flex items-center">
                                                    <Tooltip placement="right" title={!showSideMenu ? <span>Reports</span> : null}>
                                                        <Briefcase size={28} />
                                                    </Tooltip>
                                                    {showSideMenu ? <span className="px-4 font-bold font-16">Reports</span> : null}
                                                </Link>
                                            </li>
                                            {/* </RestrictView> */}
                                        </PermissionView>
                                        <PermissionView allowed={['admin']}>
                                            <li className="my-4">
                                                <Link to={`/${currentCompany}/settings`} className="hover:text-black text-black flex items-center">
                                                    <Tooltip placement="right" title={!showSideMenu ? <span>Settings</span> : null}>
                                                        <Settings size={28} />
                                                    </Tooltip>
                                                    {showSideMenu ? <span className="px-4 font-bold font-16">Settings</span> : null}
                                                </Link>
                                            </li>
                                        </PermissionView>
                                    </ul> : null}
                                    <PermissionView allowed={['admin']}>
                                        {isAdmin ? <ul className={[
                                            "mt-6",
                                            "w-full",
                                            "flex",
                                            "flex-col",
                                            !showSideMenu ? "items-center" : 'items-start'
                                        ].join(' ')}>
                                            <li className="my-4">
                                                <Link to={`/users`} className="hover:text-black text-black flex items-center">
                                                    <Tooltip placement="right" title={!showSideMenu ? <span>Users</span> : null}>
                                                        <Users size={28} />
                                                    </Tooltip>
                                                    {showSideMenu ? <span className="px-4 font-bold font-16">Users</span> : null}
                                                </Link>
                                            </li>
                                        </ul> : null}
                                    </PermissionView>
                                </div>
                            </div>
                            <div className="w-full flex flex-col items-center justify-center py-4">
                                <div className="w-full px-2">
                                    <Tooltip
                                        placement="right"
                                        title={!showSideMenu ? <span>Remote Scan</span> : null}
                                    >
                                        <RemoteScanner
                                            hideText={!showSideMenu}
                                            accountId={account.id}
                                        />
                                    </Tooltip>
                                </div>
                                <div className="w-full">
                                    <Online>
                                        <Tooltip placement="right" title={!showSideMenu ? <span>You're Online</span> : null}>
                                            <div className="flex items-center justify-center my-6">
                                                <div className="w-3 h-3 rounded-full bg-green-500"></div>
                                                {showSideMenu ? <div className="font-bold text-black pl-3">Online</div> : null}
                                            </div>
                                        </Tooltip>
                                    </Online>
                                    <Offline>
                                        <Tooltip visible={!showSideMenu} placement="right" title={!showSideMenu ? <span>You're Offline</span> : null}>
                                            <div className="flex items-center justify-center my-6">
                                                <div className="w-3 h-3 rounded-full bg-red-500"></div>
                                                {showSideMenu ? <div className="font-bold text-black pl-3">Offline</div> : null}
                                            </div>
                                        </Tooltip>
                                    </Offline>
                                </div>
                                <button className="focus:outline-none active:outline-none w-full flex items-center justify-center" onClick={() => this.setState({ showSideMenu: !showSideMenu }, () => {

                                    window.localStorage.setItem('menuOpen', this.state.showSideMenu);

                                })}>
                                    {showSideMenu ? <ChevronLeft size={26} /> : <ChevronRight size={26} />}
                                </button>
                            </div>
                        </div>
                    </div> : null}
                    <div className="flex-1 flex flex-col relative overflow-hidden dashboard-container" style={{
                        paddingLeft: isReport ? 0 : showSideMenu ? 230 : 65
                    }}>
                        {(currentCompany && company) || isSettings ? <CurrencyContext.Provider value={{
                            ...company
                        }}>
                            <Header paddingLeft={showSideMenu ? 230 : 65} account={account} user={user} currentCompany={currentCompany} company={company} section={section} />
                            <div className="overflow-scroll flex-1">
                                <Switch>
                                    {routes.map((route, index) => {

                                        const key = `key-${index}`

                                        const { component: Component, path, exact } = route;


                                        return <Route
                                            key={key}
                                            path={path}
                                            render={(props) => {

                                                if (company && path.indexOf('/hr') > -1) {

                                                    if (checkRestricted(company.allowed_services, 'hr')) {

                                                        warning('Hr / Admin');

                                                        return null;

                                                    }

                                                }

                                                if (company && path.indexOf('/accounting') > -1) {


                                                    if (checkRestricted(company.allowed_services, 'accounting')) {

                                                        warning('Accounting');

                                                        return null;

                                                    }

                                                }

                                                if (company && path.indexOf('/pos') > -1) {

                                                    if (checkRestricted(company.allowed_services, 'pos')) {

                                                        warning('Point of Sale');

                                                        return null;

                                                    }

                                                }

                                                return !Permission(path, user, company) || company && (route.roles && !route.roles.includes(`${user.companies[company.id].role}`)) ? <Error permission={true} /> : <Component {...props} company={company} account={account} user={user} />

                                            }}
                                            exact={exact}
                                        />

                                    })}
                                    <Route path="*" component={Error} exact={true} />
                                </Switch>
                            </div>
                        </CurrencyContext.Provider> : null}
                    </div>
                </div>
            </PermissionContext.Provider>
        )

    }

}

export default withDatabase(withRouter(Dashboard));