import React from 'react';
import {TextInputField, FormField, Button, Spinner, BackButton} from 'evergreen-ui';
import {InputNumber, Select} from 'antd';
import firebase from '../services/firebase';

import {withRouter} from 'react-router-dom';

import {Query, Mutation} from 'react-apollo';
import gql from 'graphql-tag';
import slugify from '@sindresorhus/slugify';

const GET_DEDUCTION = gql`
    query($company_id: Int!, $deduction_id: Int!) {

        deductions: employee_deductions(where: {company_id: {_eq: $company_id}, id: {_eq: $deduction_id}}) {
            id
            name
            type
            is_default

        }

    }
`;

const CREATE_MUTATION = gql`
    mutation (
        $deduction_id: Int,
        $company_id: Int!,
        $name: String!,
        $type: String!
    ) {
        insert_employee_deductions(objects: {
            company_id: $company_id,
            name: $name,
            type: $type
        }) {
            returning {
              id
            }
        }
    }
`;

const UPDATE_MUTATION = gql`

    mutation (
        $deduction_id: Int,
        $company_id: Int!,
        $name: String!
    ) {
        update_employee_deductions(where: {id: {_eq: $deduction_id}, company_id: {_eq: $company_id}}, _set: {
            name: $name,
            type: $type
        }) {
            affected_rows
        }
    }

`;
class Deduction extends React.Component{

    state = {
        companyId: this.props.companyId,
        deductionId: this.props.deductionId,
        name: this.props.deduction.name || '',
        type: this.props.deduction.type || '',
        is_default: this.props.deduction.is_default || false
    }

    render() {

        const {deductionId, name, is_default, companyId} = this.state;

        return (
            <div className="max-w-lg w-full mx-auto my-12">
                {/* <BackButton appearance="default" marginBottom={15} onClick={()=>{

                    this.props.history.push(`/${companyId}/hr/deductions/settings`);

                }} children="Back to deductions" /> */}
                <div className="p-8">
                    <div className="">
                        <p className="text-black text-lg font-bold">Create Deduction</p>
                        <div className="mt-4">
                            <TextInputField disabled={is_default} label="Deduction Name" defaultValue={name} onChange={(e) => {

                                this.setState({
                                    name: e.target.value
                                });

                            }} placeholder="Deduction Name" />
                        </div>
                        <Mutation
                            mutation={deductionId ? UPDATE_MUTATION : CREATE_MUTATION}
                            onCompleted={() => this.props.close()}
                            variables={{
                                deduction_id: deductionId,
                                company_id: companyId,
                                name,
                                type: slugify(name)
                            }}
                        >
                            {(save, {loading}) => {

                                return (
                                    <Button disabled={is_default} appearance="primary" intent="success" isLoading={loading} onClick={() => save()}>Save</Button>
                                )

                            }}
                        </Mutation>
                    </div>
                </div>
            </div>

        );

    }

}


class DeductionWrapper extends React.Component{

    state = {
        companyId: this.props.companyId,
        deductionId: this.props.deductionId,
    }

    render() {

        const {
            companyId,
            deductionId
        } = this.state;

        return (
            <Query
                query={GET_DEDUCTION}
                variables={{
                    company_id: companyId,
                    deduction_id: deductionId
                }}
                skip={!deductionId}
            >
                {({data, error, loading}) => {

                    // console.log(data);

                    return (
                        !loading ? <Deduction {...this.props} deductionId={deductionId} deduction={deductionId && data ? data.deductions[0] : {}} /> : <div className="flex items-center justify-center py-10">
                            <Spinner />
                        </div> 
                    )

                }}
            </Query>
        )

    }

}

export default DeductionWrapper;