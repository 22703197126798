import React from 'react';
import { currencyFormat } from 'mout/number';
import { sumBy } from 'lodash';
import { Table } from 'antd';

class IRS extends React.Component {

    employeeTax5 = (index) => {

        const { taxable_income } = typeof index === 'object' ? index : this.employees[index];
        const percent = 5;
        const amt = 100;
        const _taxable_income = taxable_income - 288;
        const _minus = _taxable_income;

        return (_minus > 0 && _minus < 100) ? (percent / 100) * (_minus) : (_minus < 0) ? 0 : (percent / 100) * amt;

    }
    employeeTax10 = (index) => {

        const { taxable_income } = typeof index === 'object' ? index : this.employees[index];
        const percent = 10;
        const amt = 140;
        const _taxable_income = taxable_income - 288 - 100;
        const _minus = _taxable_income;

        return (_minus > 0 && _minus < amt) ? (percent / 100) * (_minus) : (_minus < 0) ? 0 : (percent / 100) * amt;

    }
    employeeTax175 = (index) => {

        const { taxable_income } = typeof index === 'object' ? index : this.employees[index];
        const percent = 17.5;
        const amt = 3000;
        const _taxable_income = taxable_income - 288 - 100 - 140;
        const _minus = _taxable_income;

        return (_minus > 0 && _minus < amt) ? (percent / 100) * (_minus) : (_minus < 0) ? 0 : (percent / 100) * amt;

    }
    employeeTax25 = (index) => {

        const { taxable_income } = typeof index === 'object' ? index : this.employees[index];
        const percent = 25;
        const amt = 16472;
        const _taxable_income = taxable_income - 288 - 100 - 140 - 3000;
        const _minus = _taxable_income;

        return (_minus > 0 && _minus < amt) ? (percent / 100) * (_minus) : (_minus < 0) ? 0 : (percent / 100) * amt;

    }
    employeeTax30 = (index) => {

        const { taxable_income } = typeof index === 'object' ? index : this.employees[index];
        const percent = 30;
        const amt = 20000;
        const _taxable_income = taxable_income - 288 - 100 - 140 - 3000 - 16472;
        const _minus = _taxable_income;

        return (_minus > 0) ? (percent / 100) * (_minus) : 0;

    }
    incomeTax = (index) => {

        // console.log('5%', this.employeeTax5(index))
        // console.log('10%', this.employeeTax10(index))
        // console.log('17.5%', this.employeeTax175(index))
        // console.log('25%', this.employeeTax25(index))
        // console.log('30%', this.employeeTax30(index))

        return this.employeeTax5(index) +
            this.employeeTax10(index) +
            this.employeeTax175(index) +
            this.employeeTax25(index) +
            this.employeeTax30(index);

    }

    columns = [{
        title: 'Employee Name',
        dataIndex: 'name',
        key: 'name',
        render: (name, employee) => <div className={employee.employeeId == 'total' ? 'font-bold' : ''}>{name}</div>
    }, {
        title: 'Basic Salary',
        dataIndex: 'net_income',
        key: 'net_income',
        render: (basic_salary, employee) => <div className={employee.employeeId == 'total' ? 'font-bold' : ''}>{currencyFormat(basic_salary)}</div>
    }, {
        title: 'SSNIT Contribution',
        dataIndex: 'ssnit_employee',
        key: 'ssnit_employee',
        render: (ssnit_employee, employee) => <div className={employee.employeeId == 'total' ? 'font-bold' : ''}>{currencyFormat(ssnit_employee)}</div>
    }, {
        title: 'Net Taxable Pay',
        dataIndex: 'taxable_income',
        key: 'taxable_income',
        render: (taxable_income, employee) => <div className={employee.employeeId == 'total' ? 'font-bold' : ''}>{currencyFormat(taxable_income)}</div>
    }, {
        title: 'Tax Deductable',
        dataIndex: 'net_income_1',
        key: 'net_income_1',
        render: (_n, employee) => <div className={employee.employeeId == 'total' ? 'font-bold' : ''}>{currencyFormat(sumBy(this.props.employees, (e) => (this.incomeTax(e))))}</div>
    }, {
        title: 'Tax deducted & Paid to IRS',
        dataIndex: 'net_income_1',
        key: 'net_income_2',
        render: (_n, employee) => <div className={employee.employeeId == 'total' ? 'font-bold' : ''}>{currencyFormat(sumBy(this.props.employees, (e) => (this.incomeTax(e))))}</div>

    }]

    render() {

        const { employees, month, company } = this.props;

        return (
            <div className="print-only text-black">
                <div className="text-center">
                    <div className="flex my-4 items-center">
                        <h6>Employer's File No. :</h6>
                        <div className="ml-3 border-b max-w-xs w-full"></div>
                    </div>
                    <div className="flex my-4 items-center">
                        <h6 className="m-0 p-0">Name of Employer:</h6>
                        <div className="font-bold ml-3">{company.name}</div>
                    </div>
                    <div className="flex my-4 items-center">
                        <h6>Address of Employer:</h6>
                        <div className="ml-3 border-b max-w-xs w-full"></div>
                    </div>
                    <div className="flex my-4 items-center">
                        <h6>RETURN OF INCOME TAX FOR THE MONTH OF:</h6>
                        <div className="ml-3 border-b max-w-xs w-full font-bold">
                            {month}
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="mt-10">
                        <Table rowKey={(e) => e.employeeId} size="small" columns={this.columns} dataSource={[
                            ...employees,
                            {
                                employeeId: 'total',
                                name: 'Grand Total',
                                net_income: sumBy(employees, (e) => (parseFloat(e.net_income))),
                                ssnit_employee: sumBy(employees, (e) => (parseFloat(e.ssnit_employee))),
                                taxable_income: sumBy(employees, (e) => (parseFloat(e.taxable_income))),
                                net_income: sumBy(employees, (e) => (parseFloat(e.net_income)))
                            }
                        ]} pagination={false} />
                    </div>
                    <div className="my-6">
                        <div className="mb-6">
                            <p className="font-bold italic">I certify that I have deducted the correct amount of tax from the total remuneration of each member of the staff employed by us for the month of:</p>
                            <div className="max-w-xs w-full border-b pt-4 pb-2 font-bold">{month}</div>
                        </div>
                        <div className="flex items-center -mx-4">
                            <div className="flex flex-1 px-4 my-4">
                                <div className="uppercase text-black font-bold">
                                    Date:
                                </div>
                                <div className="border-b flex-1"></div>
                            </div>
                            <div className="flex flex-1 px-4 my-4">
                                <div className="uppercase text-black font-bold">
                                    Signature:
                                </div>
                                <div className="border-b flex-1"></div>
                            </div>
                            <div className="flex flex-1 px-4 my-4">
                                <div className="uppercase text-black font-bold">
                                    Designation:
                                </div>
                                <div className="border-b flex-1"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );

    }

}

export default IRS;