import React from 'react';
import { Table, Button } from 'antd';

import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { CurrencyFormat } from '../../services/Currency';

const GET_DISCOUNTS = gql`

    query ($company_id: Int!) {
        discounts(where: {
            company_id:{_eq: $company_id}
        }) {
            id
            name
            type
            active
            code
            value
            one_time
            orders(limit: 1, order_by:{
                created_at: desc
            }){
                id
                created_at
            }
            createdBy{
                id
                display_name
            }
            created_at
            expires_at
        }
    }

`;

export default ({history, match}) => {

    const {companyId} = match.params;

    const columns = [{
        title: 'ID',
        render: (d) => <Link to={`discounts/${d.id}`}>#{d.id}</Link>
    },{
        title: 'Name',
        render: (d) => d.name || 'No Name'
    },{
        title: 'Type',
        render: (d) => d.type === 'percent' ? 'Percentage': `Fixed`
    }, {
        title: 'Usage',
        render: (d) => d.one_time? 'One Time': 'Multiple'
    },{
        title: 'Active',
        render: (d) => d.active ? `Active`: 'Not Active'
    },{
        title: 'Code',
        render: (d) => `${d.code}`
    },{
        title: 'Value',
        render: (d) => d.type === 'percent' ? `${d.value}%` : <CurrencyFormat amount={d.value} />
    },{
        title: 'Last used',
        render: (d) => d.orders && d.orders.length ? `${moment(d.orders[0].created_at).format('DD/MM/YYYY')}`: '-'
    },{
        title: 'Created by',
        render: (d) => d.createdBy ? `${d.createdBy.display_name}`: '-'
    },{
        title: 'Expires at',
        render: (d) => d.expires_at ? `${moment(d.expires_at).format('DD/MM/YYYY')}`: '-'
    },{
        title: 'Created at',
        render: (d) => d.created_at ? `${moment(d.created_at).format('DD/MM/YYYY')}`: '-'
    }];


    

    return (
        <div className="p-4">
            <div className="flex items-center mb-6">
                <h1 className="text-black text-2xl font-bold">Discounts</h1>
                <div className="pl-4">
                    <Button onClick={() => {

                        history.push(`discounts/create`);

                    }} shape="round" type="primary" ghost>
                        Create
                    </Button>
                </div>
            </div>
            <Query
                query={GET_DISCOUNTS}
                variables={{
                    company_id: companyId
                }}
            >
                {({error, data, loading, refetch}) => {

                    if (error) {

                        return (
                            <div className="flex h-full items-center justify-center">
                                <div className="">
                                    <p className="">
                                        Error Occurred
                                    </p>
                                    <Button onClick={() => refetch()}>
                                        Retry
                                    </Button>
                                </div>
                            </div>
                        )

                    }

                    return (
                        <Table
                            loading={loading}
                            pagination={false}
                            columns={columns}
                            dataSource={data && data.discounts? data.discounts : []}
                            rowKey={(r) => `${r.id}`}
                        />
                    )

                }}
            </Query>
        </div>
    )

}