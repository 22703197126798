import React from 'react';
import { Input, Table, Select, Button as AntButton, Spin, Checkbox, InputNumber, message, Modal } from 'antd';
import { Button as EverButton, Spinner } from 'evergreen-ui';
import { values, orderBy, sum, sumBy, every, keyBy } from 'lodash';
import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import { currencyFormat } from 'mout/number';
import AddStock from './Modals/AddStock';
import apollo from '../../../services/apollo';
import { DeleteOutlined } from '@ant-design/icons';
import { CurrencyFormat } from '../../../services/Currency';



const GET_OUTLETS = gql`
    query($company_id: Int!) {

        outlets (where: {company_id: {_eq: $company_id}, raw_materials: {_eq: true}}) {
            id
            name
            company_id
            raw_materials
        }

    }
`;

const PRODUCT_FRAGMENT = `
    code
    id
    variant {
        id
        cost
        price
        name
        product {
            id
            name
            supplier {
            name
            }
        }
        exists: inventory_aggregate(where: {outlet_id: {_eq: $outlet_id}}, distinct_on: outlet_id) {
                aggregate{
                    count(columns: outlet_id)
                }
        }
        inventory_i: inventory(where: {outlet_id: {_eq: $outlet_id}}) {
            id
            price
            cost
        }
        inventory: inventory_aggregate(where: {outlet_id: {_eq: $outlet_id}}) {
            aggregate {
            sum {
                qty
            }
            }
        }
    }
`;

const GET_VARIANT = gql`
    query (
        $code: String!,
        $outlet_id: Int!,
        $company_id: Int!
    ) {
        products_sku(where: {
            code: {_eq: $code},
            variant:{
                product:{
                    archived: {_is_null: true},
                    company_id: {
                        _eq: $company_id
                    }
                }
            }
        }) {
            ${PRODUCT_FRAGMENT}
        }
    }
  
`;

const GET_VARIANT_RAW = gql`
    query (
        $code: String!,
        $outlet_id: Int!,
        $company_id: Int!
    ) {
        products_sku(where: {
            code: {_eq: $code},
            variant:{
                product:{
                    archived: {_is_null: true},
                    category: {
                        raw_materials:{
                            _eq: true
                        }
                    },
                    company_id: {
                        _eq: $company_id
                    }
                }
            }
        }) {
            ${PRODUCT_FRAGMENT}
        }
    }
  
`;

const SEARCH_VARIANT = gql`
    query (
        $q: String!,
        $outlet_id: Int!,
        $company_id: Int!
    ) {
        products_sku(where: {
            _or: [
                {variant: {
                    name: {_ilike: $q},
                }},
                {variant: {
                    product: {
                        name: {_ilike: $q}
                        has_variants: {_eq: true}
                    }
                }}
            ],
            variant:{
                product:{
                    archived: {_is_null: true},
                    company_id: {
                        _eq: $company_id
                    }
                }
            }
        }) {
            
            ${PRODUCT_FRAGMENT}
        }
    }
  
`;

const SEARCH_VARIANT_RAW = gql`
    query (
        $q: String!,
        $outlet_id: Int!,
        $company_id: Int!
    ) {
        products_sku(where: {
            _or: [
                {variant: {
                    name: {_ilike: $q},
                }},
                {variant: {
                    product: {
                        name: {_ilike: $q}
                        has_variants: {_eq: true}
                    }
                }}
            ],
            variant:{
                product:{
                    archived: {_is_null: true},
                    company_id: {
                        _eq: $company_id
                    },
                    category: {
                        raw_materials:{
                            _eq: true
                        }
                    }
                }
            }
        }) {
            
            ${PRODUCT_FRAGMENT}
        }
    }
  
`;


class BillOfMaterial extends React.Component {

    state = {
        companyId: this.props.match.params.companyId,
        productId: this.props.match.params.productId,
        outlet_id: null,
        isSearch: false,
        items: {},
        qty: {},
        searchValue: ''
    }

    inputRef = React.createRef()

    columns = [{
        title: 'Order #',
        dataIndex: 'code',
        key: 'code',
        render: (sku, v, i) => <span className="font-bold text-black">{i + 1}</span>
    }, {
        title: 'Product',
        render: (v) => v.variant ? <div className="">
            <p className="font-bold text-black">{v.variant.product.name}</p>
            <p className="text-black font-light">{v.variant.name}</p>
        </div> : null
    }, {
        title: 'Stock on Hand',
        render: (v) => v.variant ? v.variant.inventory.aggregate.sum.qty || 0 : null
    }, {
        title: 'Quantity',
        render: (v) => {

            const { qty } = this.state;

            console.log(qty, v.code);

            return (
                <InputNumber
                    // key={`${v.code}-${qty[v.code]}`}
                    value={qty[v.code] || 0}
                    min={1}
                    step={1}
                    max={v.variant.inventory.aggregate.sum.qty || 0}
                    onClick={(e) => e.target.select()}
                    // formatter={value => `${value}  ${v.product.unit.toUpperCase()}`}
                    // parser={value => value.replace(`  ${v.product.unit.toUpperCase()}`, '')}
                    onChange={(_qty) => {

                        qty[v.code] = _qty;

                        this.setState({ qty });

                    }}
                />
            )

        }
    }, {
        title: 'Supply Price',
        render: (v) => v.variant ? <CurrencyFormat amount={v.variant.cost} /> : null
    }, {
        title: 'Total',
        render: (v) => v.variant ? <CurrencyFormat amount={(this.state.qty[v.code] || 0) * v.variant.cost} /> : null
    }, {
        title: '',
        render: (v) => v.loading ? <Spin size="small" /> : <AntButton onClick={() => {

            let { qty, items } = this.state;

            delete items[v.code];
            delete qty[v.code];


            this.setState({
                items: { ...items },
                qty: { ...qty }
            });

        }} className="flex items-center justify-center" type="danger" size="small" ghost icon={<DeleteOutlined />} shape="circle" />
    }];

    fetchVariant = async (_code, _qty) => {

        const { outlet_id, items, qty, companyId, isRawMaterials } = this.state;

        const { data } = await apollo.query({
            query: isRawMaterials ? GET_VARIANT_RAW : GET_VARIANT,
            variables: {
                code: _code,
                outlet_id,
                company_id: companyId
            }
        });

        // console.log(data);

        if (data.products_sku.length) {

            const code = data.products_sku[0].variant.id;

            const _item = data.products_sku[0];

            if (!_item.variant.inventory.aggregate.sum.qty) {

                Modal.error({
                    title: 'Out of Stock',
                    content: 'This item is either unavailable or out of stock at this location',
                });

                return;

            }

            if (!qty[code]) {

                items[code] = {
                    loading: true,
                    price: 0.00,
                    code,
                    updatedAt: Date.now()
                };

                items[code].loading = false;
                items[code] = {
                    ...items[code],
                    ...data.products_sku[0],
                    code
                };

            }

            qty[code] = _qty || ((qty[code] || 0) + 1);

            this.setState({
                loading: false,
                qty: { ...qty },
                items
            });


        } else {

            this.setState({
                barcode: _code,
                loading: false,
                showModal: true
            });

            // alert('Show Modal');

        }

    }

    get SAVE_MUTATION() {

        const { items, qty, outlet_id } = this.state;

        return gql`
            mutation(
                $qty: Int!,
                $total: float8!,
                $company_id: Int!,
                $destination_id: Int,
                $employee_id: Int!,
                $items:  [products_history_items_insert_input!]!,
                $comment: String
            ){
                new_stock: insert_products_history(objects: {
                    items: {data: $items},
                    company_id: $company_id,
                    source_id: $destination_id,
                    employee_id: $employee_id,
                    total_amount: $total,
                    total_qty: $qty,
                    type: "BILL_OF_MATERIAL",
                    comment: $comment
                }) {
                    returning{
                        id
                    }
                }
                ${values(items).map((i, index) => {

            // console.log(i);

            const { code, variant } = i;
            const exists = variant.exists.aggregate.count;
            const _qty = qty[code];
            const _e = variant.inventory_i;

            return !exists ? `

                        
                                        
                    ` : `
                    
                        u_${index}: update_products_inventory(where: {
                            outlet_id: {_eq: ${parseInt(outlet_id)}},
                            variant_id: {_eq: ${parseInt(variant.id)}}
                        }, _inc: {
                            qty: -${parseFloat(_qty) || 0}
                        }, _set:{
                            product_id: ${parseInt(variant.product.id)},
                            company_id: $company_id,
                            ${_e.length ? `
                                ${!_e[0].price ? `price: ${parseFloat(variant.price)},` : ''}
                                ${!_e[0].cost ? `cost: ${parseFloat(variant.cost)}` : ''}
                            `: ''}
                        }) {
                            affected_rows
                        }

                    `;

        })}
            }
        `;

    }

    clear = () => {

        this.setState({
            qty: {},
            items: {},
            barcode: '',
            searchValue: '',
            outlet_id: null
        });

    }

    fetchVariantFromSearch = async (value) => {

        const _item = JSON.parse(value);


        const { items, qty } = this.state;
        const code = _item.variant.id;

        const item = items[code];

        if (!_item.variant.inventory.aggregate.sum.qty) {

            Modal.error({
                title: 'Out of Stock',
                content: 'This item is either unavailable or out of stock at this location',
            });

            return;

        }


        if (item) {

            qty[code]++;

            this.setState({
                items,
                qty,
                searchValue: ''
            });

            return;

        } else {


            items[code] = {
                updatedAt: Date.now(),
                ..._item,
                code: `${_item.variant.id}`,

            };

            qty[code] = 1;

            this.setState({
                items,
                qty,
                searchValue: ''
            });


        }

    }

    render() {

        const {
            companyId,
            outlet_id,
            qty,
            items,
            searchValue,
            isSearch,
            loading,
            barcode,
            showModal,
            comment,
            isRawMaterials
        } = this.state;
        const { user } = this.props;
        const itemsCount = Object.keys(items).length;
        const _items = values(items);

        const total = sumBy(_items, (v) => {

            return v.variant ? v.variant.cost * (qty[v.code] || 0) : 0;

        });

        const total_qty = sum(values(qty));

        return (
            <React.Fragment>
                <div className="overflow-scroll h-full">
                    <div className="max-w-4xl mx-auto w-full px-6">
                        <div className="flex items-center justify-between my-10">
                            <div className="flex items-center">
                                <h1 className="font-bold text-4xl mr-2">
                                    Bill Of Material
                                </h1>
                            </div>
                        </div>
                        <div className="flex items-end my-2">
                            <div className="max-w-md w-full flex -mx-4">
                                <div className="w-1/2 px-4">
                                    <p className="font-bold text-black">Source Outlet</p>
                                    <div className="py-2">
                                        <Query
                                            query={GET_OUTLETS}
                                            variables={{
                                                company_id: companyId
                                            }}
                                        >
                                            {({ loading, data, error, refetch }) => {

                                                return (
                                                    loading ? <Select placeholder="Select Outlet" key="loading" value={loading ? "loading" : "choose"} style={{ width: '100%' }}>
                                                        <Select.Option value="loading" disabled={true}>Loading</Select.Option>
                                                    </Select> : <Select disabled={itemsCount > 0} value={outlet_id} onChange={(outlet_id) => {

                                                        const _outlets = keyBy(data ? data.outlets : [], 'id');

                                                        this.setState({ outlet_id, isRawMaterials: _outlets[outlet_id].raw_materials });

                                                    }} placeholder="Select Outlet" style={{ width: '100%' }}>
                                                        {data.outlets.map((outlet) => {

                                                            return <Select.Option key={outlet.id} value={outlet.id}>{outlet.name}</Select.Option>

                                                        })}
                                                    </Select>
                                                )

                                            }}
                                        </Query>
                                    </div>
                                </div>
                                <div className="w-1/2 px-4">
                                    <p className="font-bold text-black">Destination Outlet</p>
                                    <p className="font-bold text-black text-2xl mt-2">Production</p>
                                </div>
                            </div>
                            {outlet_id ? <AntButton onClick={() => {

                                this.clear();

                            }} className="flex items-center justify-center my-2 mx-2" type="danger" ghost>Clear</AntButton> : null}
                        </div>
                        <div className="my-8">
                            {isSearch ? <Query
                                query={isRawMaterials ? SEARCH_VARIANT_RAW : SEARCH_VARIANT}
                                variables={{
                                    q: `%${searchValue}%`,
                                    outlet_id,
                                    company_id: companyId
                                }}
                                skip={!searchValue}
                            >
                                {({ data, error, loading, refetch }) => {

                                    return (
                                        <Select
                                            showSearch
                                            loading={loading}
                                            ref={this.inputRef}
                                            value={searchValue}
                                            placeholder="Search for products"
                                            style={{ width: '100%' }}
                                            // disabled={!source_id || !destination_id}
                                            notFoundContent={loading ? <div className="flex items-center justify-center p-4"><Spinner /></div> : null}
                                            showArrow={false}
                                            allowClear
                                            filterOption={false}
                                            onSearch={(searchValue) => this.setState({ searchValue })}
                                            onChange={(value) => {

                                                if (value) {

                                                    this.fetchVariantFromSearch(value);

                                                }


                                            }}
                                        // notFoundContent={null}
                                        >
                                            {!loading && data && data.products_sku.length ? data.products_sku.map(d => d.variant.product ? (
                                                <Select.Option key={d.id} value={JSON.stringify(d)}>
                                                    {d.variant.product.name} - {d.variant.name} - <CurrencyFormat amount={d.variant.price} />
                                                </Select.Option>
                                            ) : null) : null}
                                        </Select>
                                    )

                                }}
                            </Query> : <Input.Search
                                loading={loading}
                                disabled={!outlet_id}
                                ref={this.inputRef}
                                value={searchValue}
                                onChange={(e) => this.setState({ searchValue: e.target.value })} onSearch={(code) => {

                                    if (!code) return;

                                    code = code.trim();

                                    this.setState({ loading: true });

                                    if (!qty[code]) {

                                        setTimeout(async () => {

                                            this.fetchVariant(code);

                                        }, 1);

                                    } else {

                                        this.state.qty[code] = this.state.qty[code] + 1;

                                    }


                                    this.setState({
                                        loading: false,
                                        searchValue: '',
                                        qty: { ...this.state.qty }
                                    });

                                }}
                                placeholder={isSearch ? "Search Products" : "Search By Barcode"}
                            />}
                            <div className="mt-3">
                                <Checkbox
                                    checked={isSearch}
                                    disabled={!outlet_id}
                                    onChange={(e) => this.setState({ isSearch: e.target.checked }, () => this.inputRef.current.focus())}
                                >Search By Name</Checkbox>
                            </div>
                        </div>
                        <div className="my-8 shadow rounded">
                            <Table
                                dataSource={orderBy(_items, ['updatedAt'], ['desc'])}
                                columns={this.columns}
                                pagination={false}
                                rowKey={(i) => `${i.variant.id}`}
                            />
                        </div>
                        <div className="flex justify-between my-8 -mx-4">
                            <div className="flex-1 px-4">
                                <div className="mb-4">
                                    <Input.TextArea
                                        value={comment}
                                        onChange={(e) => this.setState({ comment: e.target.value })}
                                        autosize={{ minRows: 6, maxRows: 6 }}
                                        placeholder="Notes / Comments"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                {outlet_id && _items.length ? <Mutation
                                    mutation={this.SAVE_MUTATION}
                                    onCompleted={() => this.clear()}
                                    variables={{
                                        qty: total_qty,
                                        total,
                                        comment,
                                        employee_id: user.id,
                                        company_id: companyId,
                                        destination_id: outlet_id,
                                        items: _items.map((i) => ({
                                            price: i.variant.cost,
                                            qty: qty[i.code],
                                            variant_id: i.variant.id
                                        }))
                                    }}
                                >
                                    {(save, { loading }) => {

                                        return (
                                            <EverButton isLoading={loading} onClick={() => {

                                                const hasValidQty = every(values(qty), (q) => q > 0);

                                                // console.log(qty);

                                                if (values(qty).length && hasValidQty) {

                                                    save();

                                                } else {

                                                    message.error('Invalid Quantities Entered!');

                                                }

                                            }} disabled={itemsCount < 1 || !every(_items, (v) => v.variant)} intent="success" appearance="primary">
                                                Save
                                            </EverButton>
                                        )

                                    }}
                                </Mutation> : null}
                            </div>
                            <div className="px-4">
                                <div className="rounded w-64 bg-gray-100 px-6 py-3">
                                    <div className="border-b py-2 my-2 flex items-center justify-between">
                                        <p className="text-black font-bold">Total Units</p>
                                        <p className="font-bold text-black">{total_qty}</p>
                                    </div>
                                    <div className="py-2 my-2 flex items-center justify-between">
                                        <p className="font-bold text-black">Total</p>
                                        <p className="font-bold text-black">
                                            <CurrencyFormat amount={total} />
                                        </p>
                                    </div>
                                </div>
                                {/* <div className="my-4">
                                    <EverButton intent="success" appearance="primary">Save</EverButton>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* {showModal ? <AddStock
                        value={barcode}
                        companyId={companyId}
                        onOk={(sku, qty) => {


                            this.fetchVariant(sku, qty);

                            this.setState({ showModal: false });

                        }}
                        onCancel={() => this.setState({ showModal: false })}
                    /> : null} */}
                </div>
            </React.Fragment>
        )

    }

}

export default BillOfMaterial;