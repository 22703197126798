import { synchronize } from '@nozbe/watermelondb/sync';
import api from './api';
import { message } from 'antd';

let isSyncing;

export default async (database, account) => {


    if (isSyncing) return false;

    message.destroy();

    const hide = message.loading('Synchronizing..', 0);

    try {

        isSyncing = true;

        await synchronize({
            database,
            pullChanges: async ({ lastPulledAt }) => {

                const res = await api.post(`/sync/${account.id}?lastPulledAt=${lastPulledAt || 0}`, {}, {
                    noError: true
                });

                // console.log(res);

                const { changes, timestamp } = res.data;

                return { changes, timestamp };


            },
            pushChanges: async ({ changes, lastPulledAt }) => {



            },
        });

        hide();

        isSyncing = false;

    } catch (e) {

        console.log(e);

        hide();

        message.destroy();

        message.warning({
            content: 'An error occurred while synchronizing.',
            duration: 0,
            style: {
                marginTop: '60px',
            }
        });

        isSyncing = false;

    }





}