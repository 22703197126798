import React from 'react';
import {Select} from 'antd';
import {keyBy, map} from 'lodash';
import { withDatabase } from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from "@nozbe/with-observables";
import { Q } from '@nozbe/watermelondb'

export default withDatabase(withObservables([], ({ companyId, database }) => ({
    outlets: database.collections.get('outlets').query(
        Q.where('company_id', Q.eq(parseInt(companyId))),
        Q.or(
            Q.where('warehouse', false),
            Q.where('wholesale', true)
          )
    ).observe()
}))(({
    outletId,
    setOutlet,
    outlets
}) => {

    const _outlets = keyBy(map(outlets, '_raw'), 'id');

    return (
        <Select
            value={outletId}
            onChange={(outlet_id) => setOutlet({
                outlet_id,
                outlet: _outlets[outlet_id]
            })}
            style={{width: '100%'}}
        >
            {outlets.map((o) => {

                return (
                    <Select.Option
                        key={o.id}
                        value={o.id}
                    >
                        {o.name}
                    </Select.Option>
                );

            })}
        </Select>
    );

}));