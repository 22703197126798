import React from 'react';

import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { DatePicker, Statistic } from 'antd';
import { map } from 'lodash';
import { Spinner, Button, Badge } from 'evergreen-ui';

import moment from 'moment';
import { currencyFormat } from 'mout/number';
import Trend from 'react-trend';
import {CurrencyFormat} from '../../services/Currency';

import Chart from "react-apexcharts";

const dateFormat = 'YYYY-MM-DD';

const GET_SALES_ALL = gql`
    query(
        $company_id: Int!,
        $from_date: date!,
        $to_date: date!
    ) {
        company: company_by_pk(id: $company_id) {
            employees_aggregate{
                aggregate{
                  count
                }
            }
            products_aggregate{
                aggregate{
                  count
                }
            }
            outlets_aggregate{
                aggregate{
                  count
                }
            }
            users_aggregate{
                aggregate{
                  count
                }
            }
            customers_aggregate{
                aggregate{
                  count
                }
            }
            daily_sales(where:{
                created_at: {
                    _gte: $from_date,
                    _lte: $to_date
                }
            }) {
                created_at
                total
            }
        }
    }  
`;

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export default ({company}) => {

    const from_date = moment().subtract(1, 'month').format(dateFormat);
    const to_date = moment().format(dateFormat);

    return (
        <div className="h-full w-full overflow-scroll bg-gray-200">
            <div className="max-w-4xl mx-auto my-10">
                <div className="mb-3">
                    <p className="text-3xl font-semibold">{company.name}</p>
                </div>
                <Query
                    query={GET_SALES_ALL}
                    variables={{
                        company_id: company.id,
                        from_date: moment(from_date, dateFormat).startOf('day').format(),
                        to_date: moment(to_date, dateFormat).endOf('day').format(),
                    }}
                >
                    {({data, error, loading, refetch}) => {

                        if (loading) {

                            return (
                                <Spinner />
                            )

                        }

                        if (error) {

                            return (


                                <Button onClick={() => refetch()}>Retry</Button>

                            )

                        }

                        if (!loading && !data) {

                            return null;

                        }

                        const totals = data ? map(((data.company || {}).daily_sales || []), 'total') : [0];

                        // console.log(totals);


                        return (
                            <React.Fragment>
                                <div className="flex -mx-4">
                                    <div className="w-2/3 px-4">
                                        
                                        <div className="shadow rounded-lg h-64 bg-white relative"> 
                                            <p className="font-bold text-black text-2xl truncate p-4">Sales Summary</p>
                                            <div className="my-2">
                                                <Chart
                                                    options={{
                                                        noData: {
                                                            text: 'No Data Available',
                                                            align: 'center',
                                                            verticalAlign: 'middle',
                                                            offsetX: 0,
                                                            offsetY: 0,
                                                            style: {
                                                            color: 'inherit',
                                                            fontSize: '14px',
                                                            fontFamily: 'inherit'
                                                            }
                                                        },
                                                        chart: {
                                                            type: "area",
                                                            fontFamily: 'inherit',
                                                            height: 40.0,
                                                            sparkline: {
                                                                enabled: true
                                                            },
                                                            animations: {
                                                                enabled: false
                                                            },
                                                        },
                                                        dataLabels: {
                                                            enabled: false,
                                                        },
                                                        fill: {
                                                            opacity: .16,
                                                            type: 'solid'
                                                        },
                                                        stroke: {
                                                            width: 2,
                                                            lineCap: "round",
                                                            curve: "smooth",
                                                        },
                                                        grid: {
                                                            strokeDashArray: 4,
                                                        },
                                                        xaxis: {
                                                            labels: {
                                                                padding: 0,
                                                            },
                                                            tooltip: {
                                                                enabled: false
                                                            },
                                                            axisBorder: {
                                                                show: false,
                                                            },
                                                            type: 'datetime',
                                                        },
                                                        yaxis: {
                                                            labels: {
                                                                padding: 4
                                                            },
                                                        },
                                                        labels: map(((data.company || {}).daily_sales || []), 'created_at'),
                                                        colors: ["#206bc4"],
                                                        legend: {
                                                            show: false,
                                                        }
                                                    }}
                                                    series={[{
                                                        name: "SALES",
                                                        data: totals
                                                    }]}
                                                    height={175}
                                                    type="area"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-1/3 px-4">
                                        <div className="shadow rounded-lg h-64 bg-white flex flex-col items-center justify-center">
                                            <Statistic
                                                className="text-center"
                                                prefix={''}
                                                title="Products"
                                                value={((data.company || {}).products_aggregate?.aggregate.count || 0)}
                                            />
                                        </div>                               
                                    </div>
                                </div>
                                <div className="flex -mx-4 pt-8">
                                    <div className="w-1/3 px-4">
                                        <div className="shadow rounded-lg h-64 bg-white flex flex-col items-center justify-center">
                                            <Statistic
                                                className="text-center"
                                                prefix={''}
                                                title="Employees"
                                                value={((data.company || {}).users_aggregate?.aggregate.count || 0)}
                                            />
                                        </div>            
                                    </div>
                                    <div className="w-1/3 px-4">
                                        <div className="shadow rounded-lg h-64 bg-white flex flex-col items-center justify-center">
                                            <Statistic
                                                className="text-center"
                                                prefix={''}
                                                title="Customers"
                                                value={((data.company || {}).customers_aggregate?.aggregate.count || 0)}
                                            />
                                        </div>                               
                                    </div>
                                    <div className="w-1/3 px-4">
                                        <div className="shadow rounded-lg h-64 bg-white flex flex-col items-center justify-center">
                                            <Statistic
                                                className="text-center"
                                                prefix={''}
                                                title="Outlets"
                                                value={((data.company || {}).outlets_aggregate?.aggregate.count || 0)}
                                            />
                                        </div>                               
                                    </div>
                                </div>
                            </React.Fragment>
                        )

                    }}
                </Query>
            </div>
        </div>
    );

}