import { Model } from '@nozbe/watermelondb'
import { field, date, readonly } from '@nozbe/watermelondb/decorators'

export default class ProductVariant extends Model {
    
    static table = 'products_variants'

    @field('cost') cost
    @field('min_qty') minQty
    @field('name') name
    @field('price') price
    @field('product_id') productId
    
    @field('remote_id') remoteId
    @readonly @date('created_at') createdAt
    @readonly @date('updated_at') updatedAt
}