import React from 'react';
import { Table, Statistic, Input, DatePicker, InputNumber, Select, Button as AntButton, TreeSelect, Tooltip } from 'antd';
import { Badge, Button, Dialog, Label } from 'evergreen-ui';
import { compact, sumBy, keyBy, values, includes } from 'lodash';
import moment from 'moment';
import { currencyFormat } from 'mout/number';
import SearchAddEmployees from './SearchAddEmployees';
import BanksTable from './tables/Banks';
import IRSTable from './tables/IRS';
import PensionsTable from './tables/Pensions';
import firebase from '../services/firebase';
import { CurrencyFormat, CurrencyWrapper } from '../services/Currency';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

const MAKE_PAYMENTS = gql`

    mutation(
       $payments: [payroll_payments_insert_input!]!,
       $transactions: [ledger_transactions_insert_input!]!
    ){
        insert_payroll_payments(objects: $payments) {
            affected_rows
        }
        insert_ledger_transactions(objects: $transactions){
            affected_rows
        }
    }

`;

const { MonthPicker } = DatePicker;

const monthFormat = 'MMMM, YYYY';

// const UPDATE_MUTATION = gql`

//     mutation{
//         insert_payroll_payments(objects: {
//             amount: "",
//             employee_id: 10,
//             payroll_id: 10,
//             type: ""
//         }) {
//             affected_rows
//         }
//     }

// `;

const MINIMUM_WAGE = 319;


class PayrollEmployees extends React.Component {

    state = {
        status: 'draft',
        ...this.props,
        showPaymentDialog: false,
        department: 'all',
        searchValue: '',
        activeTable: null,
        employees: [],
        selectedEmployees: [],
        employeesIndexed: {}
    };

    columns = compact([
        // {
        //     title: '',
        //     // dataIndex: 'remove',
        //     key: 'remove',
        //     render: () => {

        //         const isApproved = this.state.status == 'approved';

        //         return isApproved? null: (
        //             <div className="">
        //                 <AntButton className="flex items-center justify-center" size="small" ghost type="danger" icon="minus" shape="circle" />
        //             </div>
        //         );

        //     }
        // },
        {
            title: 'Employee Name',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => {

                // console.log(this);

                const { departments } = this.state.company;

                return (
                    <div className="">
                        <p className="font-medium">{name}</p>
                        <p className=""><Badge color="teal">{record.department.name}</Badge></p>
                    </div>
                )

            }
        }, {
            title: () => <CurrencyWrapper
                component={({ symbol }) => (`Basic Salary (${symbol})`)}
            />,
            dataIndex: 'basic_salary',
            key: 'basic_salary',
            render: (basic_salary) => currencyFormat(basic_salary)
        }, {
            title: () => <CurrencyWrapper
                component={({ symbol }) => (`Allowance (${symbol})`)}
            />,
            dataIndex: 'allowance',
            key: 'allowance',
            render: (allowance) => currencyFormat(allowance)
        },
        {
            title: () => <CurrencyWrapper component={({ symbol }) => (`Others (${symbol})`)} />,
            dataIndex: 'others',
            key: 'others',
            render: (others) => currencyFormat(others)
        },
        {
            title: 'SSF (5.5%)',
            dataIndex: 'ssnit_employee',
            key: 'ssnit_employee',
            render: (total_deductions) => currencyFormat(total_deductions)
        }, {
            title: 'SSF (13%)',
            dataIndex: 'ssnit_employer',
            key: 'ssnit_employer',
            render: (total_deductions) => currencyFormat(total_deductions)
        }, {
            title: () => <CurrencyWrapper component={({ symbol }) => (`Total Emoluments (${symbol})`)} />,
            dataIndex: 'taxable_income',
            key: 'taxable_income',
            render: (total_deductions) => currencyFormat(total_deductions)
        }, {
            title: () => <CurrencyWrapper component={({ symbol }) => (`Other Deductions (${symbol})`)} />,
            dataIndex: 'total_deductions',
            key: 'total_deductions',
            render: (total_deductions) => currencyFormat(total_deductions)
        }, {
            title: () => <CurrencyWrapper component={({ symbol }) => (`Taxes (${symbol})`)} />,
            dataIndex: '_income_tax',
            key: '_income_tax',
            render: (_income_tax) => currencyFormat(_income_tax)
        }, {
            title: () => <CurrencyWrapper component={({ symbol }) => (`Net Income (${symbol})`)} />,
            dataIndex: 'net_income',
            key: 'net_income',
            render: (net_income) => currencyFormat(net_income)

        }, this.props.isSingle ? {
            title: () => <CurrencyWrapper component={({ symbol }) => (`Amount Paid (${symbol})`)} />,
            render: (e) => currencyFormat(sumBy(e.payments.filter((p) => p.type === 'income'), 'amount'))

        } : null, this.props.isSingle ? {
            title: () => <CurrencyWrapper component={({ symbol }) => (`Amount Remaining (${symbol})`)} />,
            render: (e) => currencyFormat(e.net_income - sumBy(e.payments.filter((p) => p.type === 'income'), 'amount'))
        } : null, this.props.isSingle ? {
            title: () => <CurrencyWrapper component={({ symbol }) => (`Amount to Pay (${symbol})`)} />,
            render: (e, _i, i) => {

                const _max = (e.net_income - sumBy(e.payments.filter((p) => p.type === 'income'), 'amount'));
                const max = _max.toFixed(2);

                // console.log(_max);

                return (
                    <CurrencyWrapper
                        component={({ symbol }) => (
                            <InputNumber
                                value={e.amtToPay || 0}
                                min={0}
                                max={max}
                                disabled={_max === 0 || this.state.selectedEmployees.indexOf(e.employeeId) > -1}
                                onClick={(e) => {

                                    e.target.select();

                                }}
                                onChange={(amt) => {

                                    this.state.employees[i].amtToPay = amt;

                                    this.setState({
                                        employees: this.state.employees,
                                    })

                                }}
                                style={{ width: '100%' }}
                                parser={value => value.replace(/[^0-9.]/g, '')}
                                formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            // size="small"
                            />
                        )}
                    />
                )
            }

        } : null
    ]);

    componentDidMount() {

        const { payrollId } = this.state;

        if (!payrollId) {

            const employees = this.props.employees.map((employee) => {

                return this.cleanEmployee({
                    ...employee
                });

            });

            if (this.props.onEmployeesChange) this.props.onEmployeesChange(employees);

            this.setState({
                employees
            });

        } else {

            const _employees = this.props.employees.map((e) => {

                const _max = (e.net_income - sumBy(e.payments.filter((p) => p.type === 'income'), 'amount'));
                const max = _max;

                return {
                    ...e,
                    amtToPay: max
                };

            });

            this.setState({
                employees: _employees,
                employeesIndexed: keyBy(_employees, 'employeeId')
            });


        }

        var beforePrint = () => {
            console.log('Functionality to run before printing.');
        };

        var afterPrint = () => {

            console.log('Functionality to run after printing');

            this.setState({
                activeTable: null
            });

        };

        if (window.matchMedia) {

            var mediaQueryList = window.matchMedia('print');
            mediaQueryList.addListener(function (mql) {
                if (mql.matches) {
                    beforePrint();
                } else {
                    afterPrint();
                }
            });

        }

        window.onbeforeprint = beforePrint;
        window.onafterprint = afterPrint;

    }

    cleanEmployee = (employee) => {

        const { company } = this.state;
        const { basic_salary_percent, allowance_percent } = company;

        if (employee.basic_salary < MINIMUM_WAGE) {

            employee.basic_salary = MINIMUM_WAGE;

        }

        if (employee.salary_aggregated && allowance_percent && basic_salary_percent) {

            employee.basic_salary = (basic_salary_percent / 100) * parseFloat(employee.sum_aggregations || 0);
            employee.allowance = (allowance_percent / 100) * parseFloat(employee.sum_aggregations || 0);

            // console.log(basic_salary_percent, allowance_percent, employee.sum_aggregations);


        }

        employee.daily = (parseFloat(employee.basic_salary) + parseFloat(employee.allowance)) / 22;
        employee.hourly = (employee.daily) / 8;
        employee.others = parseFloat(employee.sum_allowances);

        employee.total_deductions = this.deductions(employee).toFixed(2);

        employee.ssnit_employee = (5.5 / 100) * employee.basic_salary;
        employee.ssnit_employer = (13 / 100) * employee.basic_salary;
        employee.total_pension = employee.ssnit_employee + employee.ssnit_employer;
        employee.tier_1 = (13.5 / 18.5) * employee.total_pension;
        employee.tier_2 = (5 / 18.5) * employee.total_pension;
        employee.taxable_income = parseFloat(employee.basic_salary) + parseFloat(employee.allowance) + parseFloat(employee.others) - parseFloat(employee.ssnit_employee);
        employee.net_income = parseFloat((parseFloat(employee.basic_salary) + parseFloat(employee.allowance) + parseFloat(employee.others)) - parseFloat(employee.ssnit_employee) - this.incomeTax(employee) - employee.total_deductions).toFixed(2);

        employee._income_tax = this.incomeTax(employee).toFixed(2);

        return employee;

    }
    deductions = (employee) => {

        return parseFloat(employee.sum_deductions || 0) + (employee.hourly * parseInt(employee.hours_absent || 0)) + (employee.daily * parseInt(employee.days_absent || 0));

    }
    employeeTax5 = (index) => {

        const { taxable_income } = typeof index === 'object' ? index : this.employees[index];
        const percent = 5;
        const amt = 100;
        const _taxable_income = taxable_income - MINIMUM_WAGE;
        const _minus = _taxable_income;

        return (_minus > 0 && _minus < 100) ? (percent / 100) * (_minus) : (_minus < 0) ? 0 : (percent / 100) * amt;

    }
    employeeTax10 = (index) => {

        const { taxable_income } = typeof index === 'object' ? index : this.employees[index];
        const percent = 10;
        const amt = 140;
        const _taxable_income = taxable_income - MINIMUM_WAGE - 100;
        const _minus = _taxable_income;

        return (_minus > 0 && _minus < amt) ? (percent / 100) * (_minus) : (_minus < 0) ? 0 : (percent / 100) * amt;

    }
    employeeTax175 = (index) => {

        const { taxable_income } = typeof index === 'object' ? index : this.employees[index];
        const percent = 17.5;
        const amt = 3000;
        const _taxable_income = taxable_income - MINIMUM_WAGE - 100 - 140;
        const _minus = _taxable_income;

        return (_minus > 0 && _minus < amt) ? (percent / 100) * (_minus) : (_minus < 0) ? 0 : (percent / 100) * amt;

    }
    employeeTax25 = (index) => {

        const { taxable_income } = typeof index === 'object' ? index : this.employees[index];
        const percent = 25;
        const amt = 16472;
        const _taxable_income = taxable_income - MINIMUM_WAGE - 100 - 140 - 3000;
        const _minus = _taxable_income;

        return (_minus > 0 && _minus < amt) ? (percent / 100) * (_minus) : (_minus < 0) ? 0 : (percent / 100) * amt;

    }
    employeeTax30 = (index) => {

        const { taxable_income } = typeof index === 'object' ? index : this.employees[index];
        const percent = 30;
        const amt = 20000;
        const _taxable_income = taxable_income - MINIMUM_WAGE - 100 - 140 - 3000 - 16472;
        const _minus = _taxable_income;

        return (_minus > 0) ? (percent / 100) * (_minus) : 0;

    }
    incomeTax = (index) => {

        // console.log('5%', this.employeeTax5(index))
        // console.log('10%', this.employeeTax10(index))
        // console.log('17.5%', this.employeeTax175(index))
        // console.log('25%', this.employeeTax25(index))
        // console.log('30%', this.employeeTax30(index))

        return this.employeeTax5(index) +
            this.employeeTax10(index) +
            this.employeeTax175(index) +
            this.employeeTax25(index) +
            this.employeeTax30(index);

    }

    toggleTable = (activeTable) => {

        this.setState({
            activeTable
        }, () => {

            window.print();

        })

    }

    render() {

        const {
            employees,
            payrollId,
            selectedEmployees,
            company,
            department,
            month,
            searchValue,
            activeTable,
            employeesIndexed,
            showPaymentDialog,
            ledgers = [],
            paymentLedger,
            dialogType,
            isSingle,
            user
        } = this.state;

        const _employees = searchValue.length || department !== 'all' ? employees.filter((e) => {

            const containsKeyword = searchValue.length ? e.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 : true;
            const isDepartment = department !== 'all' ? e.department.id == department : true;

            return containsKeyword && isDepartment;

        }) : employees;

        const payables = {
            income: sumBy(selectedEmployees, (i) => {

                const e = employeesIndexed[i];

                return e.amtToPay || 0;

            }),
            paye: sumBy(selectedEmployees, (i) => {

                const e = employeesIndexed[i];

                return e._income_tax - sumBy(e.payments.filter((p) => p.type === 'paye'), 'amount');

            }),
            ssnit: sumBy(selectedEmployees, (i) => {

                const e = employeesIndexed[i];

                return (e.ssnit_employee + e.ssnit_employer) - sumBy(e.payments.filter((p) => p.type === 'ssnit'), 'amount');

            })
        }

        const payableTitles = {
            income: 'Salaries',
            ssnit: 'SSNIT',
            paye: 'P.A.Y.E'
        };

        const accountLabels = {
            income: 'salaries',
            ssnit: 'pensions',
            paye: 'paye'
        };

        let accounts = keyBy(ledgers, 'prefix');

        const payments = dialogType ? selectedEmployees.map((id) => {

            const e = employeesIndexed[id];

            const amount = dialogType === 'paye' ? e._income_tax : dialogType === 'ssnit' ? e.ssnit_employee + e.ssnit_employer : e.amtToPay;

            return { amount, employee_id: id, payroll_id: payrollId, type: dialogType };

        }) : [];

        const transactions = dialogType ? [{
            date: moment().format('YYYY-MM-DD'),
            cheque_no: '',
            debit: payables[dialogType],
            credit: 0,
            description: `Payment of ${payableTitles[dialogType]} for ${month}`,
            ledger_id: accounts[`deferred-${accountLabels[dialogType]}`].id,
            user_id: user.id,
            company_id: company.id,
            accounts: [
                2,
                accounts["current-liability"].id,
                accounts["deferred-statutory"].id,
                accounts["deferred-taxes"].id,
                accounts[`deferred-${accountLabels[dialogType]}`].id
            ]
        }, {
            date: moment().format('YYYY-MM-DD'),
            cheque_no: '',
            debit: 0,
            credit: payables[dialogType],
            description: `Payment of ${payableTitles[dialogType]} for ${month}`,
            ledger_id: paymentLedger,
            user_id: user.id,
            company_id: company.id,
            accounts: [
                1,
                accounts["current-assets"].id,
                accounts["cash-and-bank"].id,
                paymentLedger
            ]
        }] : [];

        return (
            <div className="">
                {activeTable === 'banks' ? <BanksTable company={company} month={month} employees={_employees} /> : null}
                {activeTable === 'irs' ? <IRSTable company={company} month={month} employees={_employees} /> : null}
                {activeTable === 'pensions' ? <PensionsTable company={company} month={month} employees={_employees} /> : null}



                <div className="overflow-scroll w-full h-full px-8">
                    <div className="mx-auto">
                        <div className="flex items-start justify-between my-12 print-hidden">
                            <div className="">
                                <CurrencyWrapper
                                    component={({ symbol }) => (
                                        <Statistic
                                            prefix={symbol}
                                            title="Total Employee Cost"
                                            value={(employees.length ? sumBy(employees, (e, index) => {

                                                return e.basic_salary +
                                                    e.allowance +
                                                    e.others +
                                                    e.ssnit_employer;

                                            }) : 0).toFixed(2)}
                                        />
                                    )}
                                />
                            </div>
                            <div className="">
                                <CurrencyWrapper
                                    component={({ symbol }) => (
                                        <Statistic
                                            prefix={symbol}
                                            title="Total Pensions"
                                            value={sumBy(employees, (e) => {

                                                return e.ssnit_employee + e.ssnit_employer;

                                            }).toFixed(2)}
                                        />
                                    )}
                                />
                                <Button onClick={() => this.toggleTable('pensions')} appearance="primary" intent="success" height={24}>
                                    Review & Print
                                </Button>
                            </div>
                            <div className="">
                                <CurrencyWrapper
                                    component={({ symbol }) => (
                                        <Statistic
                                            prefix={symbol}
                                            title="Total Taxes"
                                            value={(employees.length ? sumBy(employees, (e, index) => {

                                                return this.incomeTax(e);

                                            }) : 0).toFixed(2)}
                                        />
                                    )}
                                />
                                <Button onClick={() => this.toggleTable('irs')} appearance="primary" intent="success" height={24}>
                                    Review & Print
                                </Button>
                            </div>
                            <div className="">
                                <CurrencyWrapper
                                    component={({ symbol }) => (
                                        <Statistic
                                            prefix={symbol}
                                            title="Total Net Income Payable"
                                            value={parseFloat(sumBy(employees, (e) => (parseFloat(e.net_income)))).toFixed(2)}
                                        />
                                    )}
                                />
                                <Button onClick={() => this.toggleTable('banks')} appearance="primary" intent="success" height={24}>
                                    Review & Print
                                </Button>
                            </div>
                        </div>
                        <div className="print-hidden mb-16">
                            <div className="flex items-center my-4">
                                <div className="flex-1 pr-3">
                                    <Input.Search value={searchValue} onChange={(e) => this.setState({ searchValue: e.target.value })} placeholder="Filter Employees" />
                                </div>
                                <div className="flex-0">
                                    <Select onChange={(department) => this.setState({ department })} defaultValue={department} style={{ width: 240 }}>
                                        <Select.Option value="all">All Departments</Select.Option>
                                        {(values(company.departments)).map((department) => {

                                            return <Select.Option value={department.id} key={department.id}>{department.name}</Select.Option>

                                        })}
                                    </Select>
                                </div>
                            </div>
                            <Table
                                rowKey={record => record.employeeId}
                                size="small"
                                pagination={false}
                                rowSelection={payrollId ? { onChange: (selectedEmployees) => this.setState({ selectedEmployees }) } : null}
                                columns={this.columns}
                                dataSource={_employees}
                                sticky
                            // scroll={{ y: 500 }}
                            />
                        </div>
                    </div>
                </div>
                {payrollId && selectedEmployees.length ? <div className="w-full fixed bottom-0 print-hidden border-t">
                    <div className="container mx-auto">
                        <div className="flex items-center">
                            <div className="flex items-start -mx-6 py-3">
                                <div className="pr-6">
                                    <div className="">
                                        <p className="">Employees Selected</p>
                                        <p className="font-bold text-black text-lg">{selectedEmployees.length} {selectedEmployees.length === 1 ? 'Employee' : 'Employees'}</p>
                                    </div>
                                </div>
                                <div className="px-6">
                                    <div className="">
                                        <p className="">Net Income Payable</p>
                                        <p className="font-bold text-black text-lg">
                                            <CurrencyFormat
                                                amount={payables.income}
                                            />
                                        </p>
                                    </div>
                                </div>
                                <div className="px-6">
                                    <div className="">
                                        <p className="">P.A.Y.E Payable</p>
                                        <p className="font-bold text-black text-lg">
                                            <CurrencyFormat
                                                amount={payables.paye}
                                            />
                                        </p>
                                    </div>
                                </div>
                                <div className="px-6">
                                    <div className="">
                                        <p className="">SSNIT Payable</p>
                                        <p className="font-bold text-black text-lg">
                                            <CurrencyFormat
                                                amount={payables.ssnit}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-start h-full -mx-2 pl-10">
                                <div className="px-2">
                                    {/* <Tooltip
                                        trigger="hover"
                                        placement="top"
                                        visible={payables.income <= 0}
                                        title={"Selected employees salaries have been paid in full"}
                                    > */}
                                    <Button
                                        appearance="primary"
                                        intent="success"
                                        height={32}
                                        disabled={payables.income <= 0}
                                        onClick={() => {

                                            this.setState({
                                                dialogType: 'income',
                                                showPaymentDialog: true,
                                            })

                                        }}
                                    >
                                        Pay Net Income
                                    </Button>
                                    {/* </Tooltip> */}
                                </div>
                                <div className="px-2">
                                    {/* <Tooltip
                                        trigger="hover"
                                        placement="top"
                                        visible={payables.paye <= 0}
                                        title={"Selected employees P.A.Y.E have been paid in full"}
                                    > */}
                                    <Button
                                        appearance="primary"
                                        intent="success"
                                        height={32}
                                        disabled={payables.paye <= 0}
                                        onClick={() => {

                                            this.setState({
                                                dialogType: 'paye',
                                                showPaymentDialog: true,
                                            })

                                        }}
                                    >
                                        Pay PAYE
                                    </Button>
                                    {/* </Tooltip> */}
                                </div>
                                <div className="px-2">
                                    {/* <Tooltip
                                        trigger="hover"
                                        placement="top"
                                        // visible={payables.ssnit <= 0}
                                        title={"Selected employees SSNIT have been paid in full"}
                                    > */}
                                    <Button
                                        appearance="primary"
                                        intent="success"
                                        height={32}
                                        disabled={payables.ssnit <= 0}
                                        onClick={() => {

                                            this.setState({
                                                dialogType: 'ssnit',
                                                showPaymentDialog: true,
                                            })

                                        }}
                                    >
                                        Pay SSNIT
                                    </Button>
                                    {/* </Tooltip> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}
                {isSingle ? <Mutation
                    mutation={MAKE_PAYMENTS}
                    variables={{
                        payments,
                        transactions
                    }}
                    onCompleted={() => {

                        const __employees = employees.map((e) => {

                            if (selectedEmployees.indexOf(e.employeeId) > -1) {

                                return {
                                    ...e,
                                    amtToPay: 0,
                                    payments: [
                                        ...e.payments,
                                        ...payments.filter((p) => p.employee_id === e.employeeId)
                                    ]
                                }

                            } else {

                                return e;

                            }

                        });

                        this.setState({
                            selectedEmployees: [],
                            showPaymentDialog: false,
                            dialogType: null,
                            employeesIndexed: keyBy(__employees, 'employeeId'),
                            employees: __employees
                        })

                    }}
                >
                    {(save, { loading }) => (

                        <Dialog
                            isShown={showPaymentDialog}
                            title={`Make payment for ${payableTitles[dialogType]}`}
                            onCloseComplete={() => this.setState({ showPaymentDialog: false, dialogType: null })}
                            confirmLabel={`Pay ${payableTitles[dialogType]}`}
                            intent="success"
                            isConfirmLoading={loading}
                            onConfirm={() => {

                                save();

                            }}
                        >
                            <div>
                                <Label>Which account do you want to pay from? Select from below.</Label>
                                <CurrencyWrapper
                                    component={({
                                        symbol,
                                        precision,
                                        decimal_separator,
                                        thousand_separator
                                    }) => (
                                        <TreeSelect
                                            showSearch
                                            allowClear
                                            treeNodeFilterProp="title"
                                            style={{ width: '100%' }}
                                            value={paymentLedger}
                                            dropdownStyle={{
                                                maxHeight: 400,
                                                overflow: 'auto'
                                            }}
                                            treeData={ledgers.filter((l) => includes([
                                                'cash',
                                                'momo',
                                                'card'
                                            ], l.prefix)).map((l) => ({
                                                ...l,
                                                title: `${l.name} - (${symbol} ${l.balance ? currencyFormat(
                                                    l.balance.amount,
                                                    precision,
                                                    decimal_separator,
                                                    thousand_separator
                                                ) : 0})`,
                                                value: l.id,
                                                children: []
                                            }))}
                                            placeholder="Select Account"
                                            treeDefaultExpandAll
                                            onChange={(account) => {

                                                this.setState({
                                                    paymentLedger: account
                                                });

                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="mt-4">
                                You are about to pay <span className="font-bold"><CurrencyFormat amount={payables[dialogType]} /></span> in {payableTitles[dialogType]}
                            </div>
                        </Dialog>

                    )}
                </Mutation> : null}
            </div>
        );

    }

}

export default PayrollEmployees;