import React, { useState, useEffect } from 'react';
import { TextInputField, Button, Dialog } from 'evergreen-ui';
import { Select, InputNumber, Button as AntButton, message } from 'antd';
import { Query, Mutation } from "react-apollo";
import { DeleteOutlined } from '@ant-design/icons';
import { every, keyBy } from 'lodash';
import gql from "graphql-tag";
import { CurrencyWrapper } from '../../services/Currency';
import readXlsxFile from 'read-excel-file'

const GET_CATEGORIES = gql`
    query($company_id: Int!) {

        categories (where: {company_id: {_eq: $company_id}}) {
            id
            name
            company_id
            raw_materials
        }

    }
`;

export default ({ user, company, match: { params } }) => {

    // console.log(props);


    const [products, setProducts] = useState([]);
    const [showImport, setShowImport] = useState(false);
    const [imports, setImports] = useState(null);

    const companyId = params.companyId;


    useEffect(() => {

        const _products = localStorage.getItem(`${params.companyId}-${user.id}-multiple-products`, JSON.stringify(products));

        setProducts(JSON.parse(_products) || []);

        return () => { };


    }, []);

    useEffect(() => {


        localStorage.setItem(`${params.companyId}-${user.id}-multiple-products`, JSON.stringify(products));

        return () => { };


    }, [products]);

    const productLength = products.length;

    const CREATE_MUTATION = gql`
        mutation(
            $company_id: Int!
        ){

            insert_products(objects: [${products.map((product, index) => {

        return `
                {
                    name: "${product.name}",
                    variants: {
                        data: [{
                            skus: {
                                data: {}
                            },
                            company_id: $company_id,
                            name: "${product.name || ""}",
                            price: ${parseFloat(product.price) || 0},
                            cost: ${parseFloat(product.cost) || 0},
                            attributes: {
                                data: [{ key: "Default", value: "${product.name}" }]
                            }
                        }]
                    },
                    has_variants: true,
                    raw_material: ${product.raw_material},
                    company_id: $company_id,
                    category_id: ${parseInt(product.category_id)}
                }
                `;

    })}]) {
                returning {
                    id
                }
            }
        }
    `;

    return (
        <div className="max-w-5xl mx-auto w-full px-6 py-12">
            <div className="flex items-center justify-between">
                <p className="font-bold text-2xl text-black my-4">Add multiple products</p>
                <div className="mx-4">
                    <Mutation
                        mutation={CREATE_MUTATION}
                        variables={{
                            company_id: companyId
                        }}
                        onCompleted={() => {

                            message.success('Products saved successfully.');

                            setProducts([{
                                name: '',
                                category_id: null,
                                cost: 0,
                                price: 0
                            }]);

                        }}
                        onError={() => message.error('An error occurred while trying to save products.')}
                    >
                        {(save, { loading }) => (
                            <Button
                                isLoading={loading}
                                appearance="primary"
                                intent="success"
                                // disabled={!productLength}
                                onClick={() => {

                                    if (every(products, (p) => {

                                        return p.name && p.category_id && p.cost >= 0 && p.price >= 0;

                                    })) {

                                        save();

                                    } else {

                                        message.error('Please ensure all product fields have been filled.')

                                    }


                                }}
                            >
                                Save Products
                            </Button>
                        )}
                    </Mutation>
                </div>
            </div>
            {productLength > 1 ? <div className="my-4">
                <AntButton
                    onClick={() => {

                        setProducts([{
                            name: '',
                            category_id: null,
                            cost: 0,
                            price: 0
                        }]);

                    }}
                    className="flex items-center justify-center my-2"
                    type="danger"
                    ghost
                >
                    Clear ({productLength})
                </AntButton>
            </div> : null}
            {products.map((product, index) => {

                return (
                    <div key={`product-${index}`} className="flex item-center -mx-2 relative">
                        {productLength > 1 ? <div className="absolute" style={{
                            left: -30,
                            top: 30
                        }}>
                            <AntButton
                                onClick={() => {

                                    products.splice(index, 1);

                                    setProducts([...products]);

                                }}
                                className="flex items-center justify-center"
                                type="danger"
                                size="small"
                                ghost
                                icon={<DeleteOutlined />}
                                shape="circle"
                            />
                        </div> : null}
                        <div className="w-1/4 px-2">
                            <p className="font-medium text-black mb-2">Product Name</p>
                            <TextInputField
                                label=""
                                onChange={(e) => {

                                    products[index].name = e.target.value;

                                    setProducts([...products]);

                                }}
                                value={product.name}
                                placeholder="Name"
                            />
                        </div>
                        <div className="w-1/4 px-2">
                            <p className="font-medium text-black mb-2">Category</p>
                            <Query
                                query={GET_CATEGORIES}
                                variables={{
                                    company_id: parseInt(companyId)
                                }}
                            // on
                            >
                                {({ loading, data, error, refetch }) => {

                                    return (
                                        !loading && data && data.categories ? <Select
                                            value={product.category_id}
                                            onChange={(category_id) => {

                                                const _categories = keyBy(data ? data.categories : [], 'id');

                                                products[index].category_id = category_id;
                                                products[index].raw_material = _categories[category_id].raw_materials;

                                                setProducts([...products]);

                                            }}
                                            placeholder="Select Category"
                                            style={{ width: '100%' }}>
                                            {data.categories.map((category) => {

                                                return <Select.Option key={category.id} value={category.id}>{category.name}</Select.Option>

                                            })}
                                        </Select> : null
                                    )

                                }}
                            </Query>

                        </div>
                        <div className="px-2 w-1/4">
                            <p className="font-medium text-black mb-2">Supply Price</p>
                            <CurrencyWrapper
                                component={({ symbol }) => (
                                    <InputNumber
                                        value={product.cost}
                                        min={0}
                                        onClick={(e) => {

                                            e.target.select();

                                        }}
                                        onChange={(cost) => {

                                            products[index].cost = cost;

                                            setProducts([...products]);

                                        }}
                                        style={{ width: '100%' }}
                                        parser={value => value.replace(/[^0-9.]/g, '')}
                                        formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    // size="small"
                                    />
                                )}
                            />
                        </div>
                        <div className="px-2 w-1/4">
                            <p className="font-medium text-black mb-2">Retail Price</p>
                            <CurrencyWrapper
                                component={({ symbol }) => (
                                    <InputNumber
                                        value={product.price}
                                        min={0}
                                        onClick={(e) => {

                                            e.target.select();

                                        }}
                                        onChange={(price) => {

                                            products[index].price = price;

                                            setProducts([...products]);

                                        }}
                                        style={{ width: '100%' }}
                                        parser={value => value.replace(/[^0-9.]/g, '')}
                                        formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    // size="small"
                                    />
                                )}
                            />
                        </div>
                    </div>
                )

            })}
            <div className="flex items-center">
                <Button
                    appearance="primary"
                    intent="success"
                    onClick={() => setProducts([...products, {
                        name: '',
                        category_id: null,
                        cost: 0,
                        price: 0
                    }])}
                // iconBefore={AddIcon}
                >
                    Add Product
                </Button>
                <span className="mx-5">
                    or
                </span>
                <Button
                    appearance="primary"
                    intent="success"
                    onClick={() => {

                        setShowImport(true);

                    }}
                // iconBefore={AddIcon}
                >
                    Import Products
                </Button>
            </div>
            <Dialog
                isShown={showImport}
                title="Import Products from excel"
                onCloseComplete={() => setShowImport(false)}
                confirmLabel="Add Products"
                onConfirm={() => {

                    const _products = imports.rows.map((r) => {

                        return {
                            name: r[imports.fields.name].toUpperCase(),
                            price: r[imports.fields.price],
                            cost: r[imports.fields.cost],
                            category_id: imports.fields.category_id,
                            raw_material: false
                        }

                    });

                    setProducts([
                        ...products,
                        ..._products
                    ]);

                    setShowImport(false);
                    setImports(null);

                }}
            >
                <input type="file" id="input" onChange={(e) => {

                    readXlsxFile(e.target.files[0]).then((rows) => {

                        setImports({
                            headers: rows[0],
                            rows: rows.slice(1)
                        });

                    });

                }} />

                {imports ? <>
                    <div className="my-4">
                        You are about to import <span className="font-bold">{imports.rows.length} product(s)</span>. Please select the corresponding fields from the excel sheet.
                    </div>
                    <div className="my-4">
                        <p className="font-medium text-black mb-2">Product Name</p>
                        <Select
                            value={imports.fields?.name}
                            onChange={(index) => {

                                setImports({
                                    ...imports,
                                    fields: {
                                        ...imports.fields,
                                        name: index
                                    }
                                })

                            }}
                            placeholder="Product Name"
                            style={{ width: '100%' }}>
                            {imports.headers.map((header, index) => {

                                return <Select.Option key={header} value={index}>{header}</Select.Option>

                            })}
                        </Select>
                    </div>
                    <div className="my-4">
                        <p className="font-medium text-black mb-2">Category</p>
                        <Query
                            query={GET_CATEGORIES}
                            variables={{
                                company_id: parseInt(companyId)
                            }}
                        // on
                        >
                            {({ loading, data, error, refetch }) => {

                                return (
                                    !loading && data && data.categories ? <Select
                                        value={imports.fields?.category_id}
                                        onChange={(category_id) => {

                                            setImports({
                                                ...imports,
                                                fields: {
                                                    ...imports.fields,
                                                    category_id
                                                }
                                            })

                                        }}
                                        placeholder="Select Category"
                                        style={{ width: '100%' }}>
                                        {data.categories.map((category) => {

                                            return <Select.Option key={category.id} value={category.id}>{category.name}</Select.Option>

                                        })}
                                    </Select> : null
                                )

                            }}
                        </Query>

                    </div>
                    <div className="my-4">
                        <p className="font-medium text-black mb-2">Supply Price</p>
                        <Select
                            value={imports.fields?.cost}
                            onChange={(index) => {

                                setImports({
                                    ...imports,
                                    fields: {
                                        ...imports.fields,
                                        cost: index
                                    }
                                })

                            }}
                            placeholder="Supply Price"
                            style={{ width: '100%' }}>
                            {imports.headers.map((header, index) => {

                                return <Select.Option key={header} value={index}>{header}</Select.Option>

                            })}
                        </Select>
                    </div>
                    <div className="my-4">
                        <p className="font-medium text-black mb-2">Retail Price</p>
                        <Select
                            value={imports.fields?.price}
                            onChange={(index) => {

                                setImports({
                                    ...imports,
                                    fields: {
                                        ...imports.fields,
                                        price: index
                                    }
                                })

                            }}
                            placeholder="Retail Price"
                            style={{ width: '100%' }}>
                            {imports.headers.map((header, index) => {

                                return <Select.Option key={header} value={index}>{header}</Select.Option>

                            })}
                        </Select>
                    </div>
                </> : null}


            </Dialog>
        </div>
    );

}