import React, {PureComponent} from 'react';
import {Spin, Button} from 'antd';
import {Camera} from 'react-feather';
import {fileTypes, onFileChange, uploadImage} from '../services/imageUpload';



class ProfilePicture extends PureComponent{

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            url: props.url || ""
        };

    }

    onChange = (e) => {

        const file = onFileChange(e);

        if (file) {

            this.setState({loading: true});

            uploadImage(file, (url) => {

                this.setState({
                    loading: false,
                    url
                });
                
                if (this.props.onUploadComplete) this.props.onUploadComplete(url);

            });

        }

    }

    render() {

        const {loading, url} = this.state;
        const {width, height, disabled} = this.props;

        return (
            <div className={`cursor-pointer mb-6 flex items-center justify-center ${url ? 'bg-white' :'bg-gray-100'} mx-auto rounded-full shadow relative w-${width} h-${height}`}>
                {!url ? <div className="absolute top-0 left-0 right-0 bottom-0 z-6 flex items-center justify-center">
                    <Camera className={url ? 'text-white': 'text-black'} size={height} />
                </div>: null}
                <input className="absolute top-0 left-0 right-0 bottom-0 z-6" accept={fileTypes.join(",")} onChange={this.onChange} style={{
                    opacity: 0,
                    cursor: "pointer",
                    // fontSize: 0
                }} type="file" disabled={disabled} name="" id=""/>
                {loading ? <Spin className="flex" />: (url) ? <div style={{
                    backgroundImage: `url(${url})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain'
                }} className={`rounded-full w-${width} h-${height}`} /> : null}
            </div>
        );

    }

}

export default ProfilePicture;