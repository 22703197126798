import React from 'react';
import {BackButton, Spinner} from 'evergreen-ui';
import Discount from '../misc/discount';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';

const GET_DISCOUNT = gql`
    query ($id: Int!, $company_id: Int!) {
        discounts(where: {
            company_id:{_eq: $company_id},
            id: {_eq: $id}
        }) {
            id
            name
            type
            active
            code
            value
            employee_id
            outlet_id
            one_time
            createdBy{
                id
                display_name
            }
            expires_at
        }
    }
`;

export default ({match, history, user}) => {

    const {companyId, discountId} = match.params;

    return (
        <div className="p-4 overflow-scroll h-full">
            <BackButton
                appearance="default"
                marginBottom={15}
                onClick={()=>{

                    history.push(`/${companyId}/pos/discounts`);

                }}
                children="Back to discounts"
            />
            <Query
                query={GET_DISCOUNT}
                variables={{
                    id: discountId,
                    company_id: companyId
                }}
                skip={!discountId}
            >
                {({data, error, loading, refetch}) => {

                    return (
                        loading ? <div className="flex items-center justify-center py-10">
                            <Spinner />
                        </div> : !discountId || data && data.discounts.length? <Discount
                            discount={data && data.discounts.length ? data.discounts[0]: null}
                            companyId={companyId}
                            user={user}
                            history={history}
                        />: null
                    )

                }}
            </Query>
        </div>
    )

}