import React from 'react';
import {RefreshCw} from 'react-feather';
import {Button} from 'antd';

class Inventory extends React.Component{

    state = {
        companyId: this.props.match.params.companyId
    };

    render(){

        const {loading, companyId} = this.state;

        return (
            <div className="py-12">
                <div className="w-full max-w-3xl mx-auto px-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <h1 className="font-bold text-4xl mr-2">
                                Inventory 
                            </h1>
                            {loading ? null :<button className="ml-4 focus:outline-none text-black" onClick={this.refresh}>
                                <RefreshCw size={24} />
                            </button>}
                        </div>
                        <div className="">
                            <Button type="primary" onClick={() => this.props.history.push(`/${companyId}/pos/products/add`)} shape="round" ghost icon="plus" className="flex items-center justify-center">Add</Button>
                        </div>
                    </div>

                </div>
            </div>
        )

    }

}

export default Inventory