import React, { useState } from "react";
import { Button, FormField, Spinner, BackButton } from 'evergreen-ui';
import { DatePicker, Select, Table, Button as AntButton } from 'antd';
import { Query } from 'react-apollo';
import roles from '../../services/roles';
import moment from 'moment';
import reports from '../../services/reports';
import { sumBy, keyBy, keys } from 'lodash';
import { currencyFormat } from 'mout/number';
import { Link } from 'react-router-dom';
import jsPDF from "jspdf";
import "jspdf-autotable";
import gql from 'graphql-tag';
import ReactExport from "react-export-excel";

import { getColumnSearchProps } from './Stocks/StockTake';

import { decode } from 'mout/queryString';
import { CurrencyContext, CurrencyFormat, CurrencyWrapper } from "../../services/Currency";

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

// console.log(Table)

const GET_OUTLETS = gql`
    query($company_id: Int!) {

        outlets(
            where: {
                company_id: {_eq: $company_id}
            }
        ){
            id
            name
        }

    }
`;
const GET_EMPLOYEES = gql`
    query($company_id: Int!) {

        company: company_by_pk(id: $company_id){
            id
            users{
                role
                user{
                    id
                    display_name
                }
            }
        }

    }
`;
const GET_SUPPLIERS = gql`
    query($company_id: Int!) {

        suppliers(
            where: {
                company_id: {_eq: $company_id}
            }
        ){
            id
            name
        }

    }
`;

const types = [{
    id: 'STOCK_BALANCE',
    name: "Stock Balance",
    roles: ["admin", "pos-inventory-manager", "pos-inventory-officer", "pos-sales-supervisor", "pos-sales-manager", "pos-audit-manager"]
}, {
    id: 'TRANSFERS',
    name: "Transfers",
    roles: ["admin", "pos-inventory-manager", "pos-inventory-officer", "pos-sales-supervisor", "pos-sales-manager", "pos-audit-manager"]
}, {
    id: 'NEW_STOCK',
    name: "New Stock",
    roles: ["admin", "pos-inventory-manager", "pos-inventory-officer", "pos-sales-supervisor", "pos-sales-manager", "pos-audit-manager"]
}, {
    id: 'BILL_OF_MATERIAL',
    name: "Bill Of Material",
    roles: ["admin", "pos-inventory-manager", "pos-inventory-officer", "pos-sales-supervisor", "pos-sales-manager", "pos-audit-manager"]
}, {
    id: 'RECEIVINGS',
    name: "Receivings",
    roles: ["admin", "pos-inventory-manager", "pos-inventory-officer", "pos-sales-supervisor", "pos-sales-manager", "pos-audit-manager"]
}, {
    id: 'SALE',
    name: "Sales",
    roles: ["admin", "pos-sales-supervisor", "pos-sales-manager", "pos-inventory-manager", "pos-inventory-officer", "pos-audit-manager"]
}, {
    id: 'RETURN_SALES',
    name: "Sales Returns",
    roles: ["admin", "pos-sales-supervisor", "pos-sales-manager", "pos-inventory-manager", "pos-inventory-officer", "pos-audit-manager"]
}, {
    id: 'RETURN_STOCK',
    name: "Stock Returns",
    roles: ["admin", "pos-inventory-manager", "pos-inventory-officer", "pos-sales-supervisor", "pos-sales-manager", "pos-audit-manager"]
}, {
    id: 'RETURN_PURCHASE_ORDER',
    name: "Purchase Order Returns",
    roles: ["admin", "pos-inventory-manager", "pos-inventory-officer", "pos-audit-manager"]
}, {
    id: 'STOCK_ADJUSTMENT',
    name: "Stock Adjustment",
    roles: ["admin", "pos-inventory-manager", "pos-inventory-officer", "pos-sales-manager", "pos-audit-manager"]
}, {
    id: 'STOCK_TAKE',
    name: "Stock Take",
    roles: ["admin", "pos-inventory-manager", "pos-inventory-officer", "pos-sales-manager", "pos-audit-manager"]
}, {
    id: 'PURCHASE_ORDERS',
    name: "Purchase Orders",
    roles: ["admin", "pos-inventory-manager", "pos-inventory-officer", "pos-audit-manager"]
}, {
    id: 'PAYMENT_MODES',
    name: "Payment Modes",
    roles: ["admin", "pos-sales-supervisor", "pos-sales-manager", "pos-audit-manager"]
}];

const typesTitle = keyBy(types, 'id');

const hideEmployee = ['STOCK_BALANCE', 'PAYMENT_MODES'];
const hideSupplier = [
    'PAYMENT_MODES',
    'STOCK_BALANCE',
    'TRANSFERS',
    'SALE',
    'RETURNS',
    'PURCHASE_ORDERS',
    'RECEIVINGS',
    'STOCK_TAKE',
    'STOCK_ADJUSTMENT',
    'NEW_STOCK',
    'BILL_OF_MATERIAL',
    'RETURN_SALES',
    'RETURN_STOCK',
    'RETURN_PURCHASE_ORDER'
];

const columns = ({ companyId, type }) => [{
    title: 'Transfer ID',
    key: 'transfer_id',
    dataIndex: 'transfer_id',
    width: 120,
    render: (transfer_id) => (
        <Link target="_blank" to={`/${companyId}/pos/transfers/${transfer_id}`}>#{transfer_id}</Link>
    )
}, {
    title: 'ID',
    key: 'id',
    dataIndex: 'id',
    width: 120,
    render: (id) => {

        if (type === 'SALE') {

            return (
                <Link target="_blank" to={`/${companyId}/pos/sales/${id}`}>
                    #{id}
                </Link>
            );

        } else if (type === 'STOCK_TAKE') {

            return (
                <Link target="_blank" to={`/${companyId}/pos/stock-take/${id}`}>
                    #{id}
                </Link>
            );

        } else {

            return id;

        }

    }
}, {
    title: 'Description',
    key: 'description',
    width: 120,
    dataIndex: 'description',
    ...getColumnSearchProps({
        placeholder: 'Filter',
        dataIndex: ['description']
    })
}, {
    title: 'Specification',
    key: 'specs',
    dataIndex: 'specs',
    width: 120,
    ...getColumnSearchProps({
        placeholder: 'Filter',
        dataIndex: ['specs']
    })
}, {
    title: 'Outlet',
    key: 'outlet',
    dataIndex: 'outlet',
    width: 120,
    ...getColumnSearchProps({
        placeholder: 'Filter',
        dataIndex: ['outlet']
    })
}, {
    title: 'Supplier',
    key: 'supplier',
    dataIndex: 'supplier',
    width: 120,
    ...getColumnSearchProps({
        placeholder: 'Filter',
        dataIndex: ['supplier']
    })
}, {
    title: 'Employee',
    key: 'employee',
    dataIndex: 'employee',
    width: 120,
    ...getColumnSearchProps({
        placeholder: 'Filter',
        dataIndex: ['employee']
    })
}, {
    title: 'Transfer By',
    key: 'transfer_by',
    dataIndex: 'transfer_by',
    width: 120,
    ...getColumnSearchProps({
        placeholder: 'Filter',
        dataIndex: ['transfer_by']
    })
}, {
    title: 'Date',
    key: 'date',
    dataIndex: 'date',
    width: 120,
    sorter: (a, b) => a.date_raw.toDate() - b.date_raw.toDate(),
}, {
    title: 'Source',
    key: 'source',
    dataIndex: 'source',
    width: 120,
    ...getColumnSearchProps({
        placeholder: 'Filter',
        dataIndex: ['source']
    })
}, {
    title: 'Destination',
    key: 'destination',
    dataIndex: 'destination',
    width: 120,
    ...getColumnSearchProps({
        placeholder: 'Filter',
        dataIndex: ['destination']
    })
}, {
    title: () => <CurrencyWrapper component={({ symbol }) => (`Unit Price (${symbol})`)} />,
    title_template: (symbol) => (`Unit Price (${symbol})`),
    key: 'unit_price',
    dataIndex: 'unit_price',
    width: 120,
    sorter: (a, b) => a.unit_price - b.unit_price,
    render(unit_price) {

        return (
            `${currencyFormat(unit_price)}`
        )

    }
}, {
    title: 'Min Qty',
    key: 'min_qty',
    dataIndex: 'min_qty',
    align: 'right',
    width: 120,
    // defaultSortOrder: 'descend',
    sorter: (a, b) => a.min_qty - b.min_qty,
}, {
    title: 'Opening',
    key: 'opening',
    dataIndex: 'opening',
    align: 'right',
    width: 120,
    // defaultSortOrder: 'descend',
    sorter: (a, b) => a.opening - b.opening,
}, {
    title: 'Closing',
    key: 'closing',
    dataIndex: 'closing',
    align: 'right',
    width: 120,
    sorter: (a, b) => a.closing - b.closing,
}, {
    title: () => <CurrencyWrapper component={({ symbol }) => (`Subtotal (${symbol})`)} />,
    title_template: (symbol) => (`Subtotal (${symbol})`),
    key: 'subtotal',
    dataIndex: 'subtotal',
    width: 120,
    sorter: (a, b) => a.subtotal - b.subtotal,
    render(subtotal) {

        return (
            `${currencyFormat(subtotal)}`
        )

    }
}, {
    title: () => <CurrencyWrapper component={({ symbol }) => (`Discount (${symbol})`)} />,
    title_template: (symbol) => (`Discount (${symbol})`),
    key: 'discount_amt',
    dataIndex: 'discount_amt',
    width: 120,
    sorter: (a, b) => a.discount_amt - b.discount_amt,
    render(discount_amt) {

        return (
            `${currencyFormat(discount_amt)}`
        )

    }
}, {
    title: 'Qty',
    key: 'qty',
    dataIndex: 'qty',
    align: 'right',
    width: 120,
    sorter: (a, b) => a.qty - b.qty,
}, {
    title: 'Stock Qty',
    key: 'stock_qty',
    dataIndex: 'stock_qty',
    align: 'right',
    width: 120,
    sorter: (a, b) => a.stock_qty - b.stock_qty,
}, {
    title: 'Variance',
    key: 'variance',
    dataIndex: 'variance',
    align: 'right',
    width: 120,
    sorter: (a, b) => a.variance - b.variance,
}, {
    title: () => <CurrencyWrapper component={({ symbol }) => (`Total (${symbol})`)} />,
    title_template: (symbol) => (`Total (${symbol})`),
    key: 'total',
    dataIndex: 'total',
    align: 'right',
    width: 120,
    sorter: (a, b) => a.total - b.total,
    render(total) {

        return (
            `${currencyFormat(total)}`
        )

    }
}, {
    title: () => <CurrencyWrapper component={({ symbol }) => (`Stock Value (${symbol})`)} />,
    title_template: (symbol) => (`Stock Value (${symbol})`),
    key: 'stock_value',
    dataIndex: 'stock_value',
    align: 'right',
    width: 120,
    sorter: (a, b) => a.stock_value - b.stock_value,
    render(stock_value) {

        return (
            `${currencyFormat(stock_value)}`
        )

    }
}];

const paymentModes = {
    'cash': {
        name: 'Cash'
    },
    'gift_voucher': {
        name: 'Gift Voucher'
    },
    'momo': {
        name: 'MoMo'
    },
    'card': {
        name: 'Debit / Credit Card'
    }
};

const blueprint = {
    id: '',
    description: '',
    specs: '',
    outlet: '',
    employee: '',
    date: '',
    unit_price: '',
    qty: '',
    total: ''
};

const dateFormat = "YYYY-MM-DD";

const Filters = ({ user, companyId, onChange }) => {

    const _user = user.companies[companyId];

    const role = _user.role;

    // console.log(_user);


    const [type, setType] = useState(null);
    const [outlet, setOutlet] = useState(_user.outlet_id ? _user.outlet_id : "all");
    const [source, setSource] = useState("all");
    const [destination, setDestination] = useState("all");
    const [supplier, setSupplier] = useState("all");
    const [employee, setEmployee] = useState(_user.outlet_id ? user.id : "all");
    const [fromDate, setFromDate] = useState(moment().format(dateFormat));
    const [toDate, setToDate] = useState(moment().format(dateFormat));

    const [disableEmployee] = useState(_user.outlet_id ? true : false);
    const [disableOutlet] = useState(_user.outlet_id ? true : false);

    return (
        <>
            <div className="">
                <FormField isRequired label="Report Type" />
                <Select
                    style={{ width: '100%' }}
                    placeholder=""
                    onChange={(type) => setType(type)}
                    value={type}
                >
                    {types.map((type) => {

                        return (
                            type.roles.includes(`${role}`) ? <Select.Option
                                value={type.id}
                                key={type.id}
                            >
                                {type.name}
                            </Select.Option> : null
                        );

                    })}
                </Select>
            </div>
            <div className="my-2">
                <FormField isRequired label="From Date" />
                <DatePicker
                    // showTime={{ format: 'HH:mm' }}
                    format={dateFormat}
                    value={moment(fromDate, dateFormat)}
                    style={{
                        width: "100%"
                    }}
                    onChange={(date) => setFromDate(date.format(dateFormat))}
                />
            </div>
            <div className="my-2">
                <FormField isRequired label="To Date" />
                <DatePicker
                    // showTime={{ format: 'HH:mm' }}
                    format={dateFormat}
                    value={moment(toDate, dateFormat)}
                    style={{
                        width: "100%"
                    }}
                    onChange={(date) => setToDate(date.format(dateFormat))}
                />
            </div>
            {type == "TRANSFERS" ? <div className="my-2">
                <FormField isRequired label="Source" />
                <Query
                    query={GET_OUTLETS}
                    variables={{
                        company_id: companyId
                    }}
                >
                    {({ data, error, loading }) => {

                        return (
                            !loading && data ? <Select
                                style={{ width: '100%' }}
                                onChange={(source) => setSource(source)}
                                value={source}
                                placeholder=""
                            >
                                <Select.Option value="all">All Outlets</Select.Option>
                                {data.outlets.map((outlet) => {

                                    return <Select.Option value={outlet.id} key={outlet.id}>{outlet.name}</Select.Option>

                                })}
                            </Select> : null
                        )

                    }}
                </Query>
            </div> : null}
            {type == "TRANSFERS" ? <div className="my-2">
                <FormField isRequired label="Destination" />
                <Query
                    query={GET_OUTLETS}
                    variables={{
                        company_id: companyId
                    }}
                >
                    {({ data, error, loading }) => {

                        return (
                            !loading && data ? <Select
                                style={{ width: '100%' }}
                                onChange={(destination) => setDestination(destination)}
                                value={destination}
                                placeholder=""
                            >
                                <Select.Option value="all">All Outlets</Select.Option>
                                {data.outlets.map((outlet) => {

                                    return <Select.Option value={outlet.id} key={outlet.id}>{outlet.name}</Select.Option>

                                })}
                            </Select> : null
                        )

                    }}
                </Query>
            </div> : null}
            {type != "TRANSFERS" ? <div className="my-2">
                <FormField isRequired label="Outlet" />
                <Query
                    query={GET_OUTLETS}
                    variables={{
                        company_id: companyId
                    }}
                >
                    {({ data, error, loading }) => {

                        return (
                            !loading && data ? <Select
                                style={{ width: '100%' }}
                                onChange={(outlet) => setOutlet(outlet)}
                                value={outlet}
                                placeholder=""
                                disabled={disableOutlet}
                            >
                                <Select.Option value="all">All Outlets</Select.Option>
                                {data.outlets.map((outlet) => {

                                    return <Select.Option value={outlet.id} key={outlet.id}>{outlet.name}</Select.Option>

                                })}
                            </Select> : null
                        )

                    }}
                </Query>
            </div> : null}
            {hideEmployee.indexOf(type) > -1 ? null : <div className="my-2">
                <FormField isRequired label="Employee" />
                <Query
                    query={GET_EMPLOYEES}
                    variables={{
                        company_id: companyId
                    }}
                >
                    {({ data, error, loading }) => {

                        return (
                            !loading && data ? <Select
                                style={{ width: '100%' }}
                                onChange={(employee) => setEmployee(employee)}
                                value={employee}
                                placeholder=""
                                disabled={disableEmployee}
                            >
                                <Select.Option value="all">All Employees</Select.Option>
                                {data.company.users.map((employee) => {

                                    return <Select.Option value={employee.user.id} key={employee.user.id}>
                                        {employee.user.display_name} ({roles[employee.role].name})
                                    </Select.Option>

                                })}
                            </Select> : null
                        )

                    }}
                </Query>
            </div>}
            {hideSupplier.indexOf(type) > -1 ? null : <div className="my-2">
                <FormField isRequired label="Supplier" />
                <Query
                    query={GET_SUPPLIERS}
                    variables={{
                        company_id: companyId
                    }}
                >
                    {({ data, error, loading }) => {

                        return (
                            !loading && data ? <Select
                                style={{ width: '100%' }}
                                onChange={(supplier) => setSupplier(supplier)}
                                value={supplier}
                                placeholder=""
                            >
                                <Select.Option value="all">All Suppliers</Select.Option>
                                {data.suppliers.map((supplier) => {

                                    return <Select.Option value={supplier.id} key={supplier.id}>{supplier.name}</Select.Option>

                                })}
                            </Select> : null
                        )

                    }}
                </Query>
            </div>}
            <div className="my-4">
                <Button
                    intent="success"
                    appearance="primary"
                    width="100%"
                    justifyContent="center"
                    height={42}
                    onClick={() => {

                        if (onChange) onChange({
                            type,
                            supplier,
                            outlet,
                            employee,
                            fromDate: moment(fromDate, dateFormat).startOf('day').format('YYYY-MM-DD[T]HH:mm'),
                            toDate: moment(toDate, dateFormat).endOf('day').format('YYYY-MM-DD[T]HH:mm'),
                            source,
                            destination
                        });

                    }}
                >
                    Generate
                </Button>
            </div>
        </>
    )

}

const exportPDF = (companyName, dates, title, cols, data) => {

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const headers = [cols.map((c) => c.title)];

    const _data = data.map(r => {

        return cols.map((c) => c.render ? c.render(r[c.dataIndex]) : r[c.dataIndex]);

    });

    let content = {
        startY: 100,
        head: headers,
        body: _data
    };

    doc.text(companyName, marginLeft, 40);
    doc.text(title, marginLeft, 60);
    doc.text(dates, marginLeft, 80);
    doc.autoTable(content);
    doc.save("report.pdf")
}

export default (props) => {

    const { companyId } = props.match.params;
    const [variables, setVariables] = useState({});
    const [showMinQty, setShowMinQty] = useState(false);
    const [showFilters, setShowFilters] = useState(true);

    const action = reports[variables.type];
    const __columns = columns({
        type: variables.type,
        companyId
    });

    const query = decode(window.location.search);
    const isReport = query.report;

    return (

        <div className="flex lg:flex-row flex-col h-full overflow-auto">
            {showFilters ? <div className="h-full w-full lg:max-w-xs max-w-full border-r p-4 print-hidden" style={{
                // maxWidth: 320
            }}>
                {!isReport ? <BackButton
                    appearance="default"
                    marginBottom={15}
                    onClick={() => {

                        props.history.push(`/${companyId}/reports/`);

                    }}
                    children="Back to reports"
                /> : null}
                <Filters
                    user={props.user}
                    companyId={companyId}
                    onChange={(variables) => {

                        setVariables(variables);

                        setShowMinQty(false);

                        if (isReport) {

                            setShowFilters(false);

                        }

                    }}
                />
                {isReport && showFilters ? <button
                    className="bg-gray-300 w-full text-center py-2 rounded text-medium"
                    onClick={() => {

                        setShowFilters(false);

                    }}
                >
                    Hide filters
                </button> : null}
            </div> : <div className="">
                <button
                    className="bg-gray-300 w-full text-center py-2 text-medium"
                    onClick={() => {

                        setShowFilters(true);

                    }}
                >
                    Show filters
                </button>
            </div>}
            <div className="overflow-scroll h-full w-full">
                {variables.type && action ? <Query
                    query={gql`
                        query(
                            $type: String,
                            $supplier: Int,
                            $outlet: Int,
                            $employee: Int,
                            $fromDate: ${variables.type === 'STOCK_BALANCE' ? 'date' : 'timestamptz'},
                            $toDate: ${variables.type === 'STOCK_BALANCE' ? 'date' : 'timestamptz'},
                            $company_id: Int,
                            $source: Int,
                            $destination: Int
                        ) {

                            ${action ? action.query(variables) : ''}
                            ${variables.type === 'PAYMENT_MODES' ? 'payload' : 'products'}: products(where: {
                                id: {_eq: 0}
                            }){
                                id
                            }


                        }
                    `}
                    variables={{
                        ...variables,
                        company_id: companyId
                    }}
                    skip={!variables.type && !action}
                >
                    {({ data, loading, error, refetch }) => {

                        if (loading) {

                            return (
                                <div className="flex h-full items-center justify-center">
                                    <Spinner />
                                </div>
                            )

                        }

                        if (error || !loading && (!data || !data.payload)) {

                            return (
                                <div className="flex h-full items-center justify-center">
                                    <div className="">
                                        <p className="">
                                            Error Occurred
                                        </p>
                                        <AntButton onClick={() => refetch()}>
                                            Retry
                                        </AntButton>
                                    </div>
                                </div>
                            )

                        }

                        const dataSource = !action ? [] : variables.type === "PAYMENT_MODES" ? keys(data).filter((k) => k !== 'payload').map((k) => ({
                            id: '',
                            description: `${paymentModes[k].name}`,
                            specs: '-',
                            outlet: '-',
                            employee: '-',
                            date: '-',
                            date_raw: '',
                            unit_price: 0,
                            qty: '-',
                            total: data[k].aggregate.sum.value || 0
                        })) : data.payload.map(action.mapAttrs).filter((r) => showMinQty ? r.closing <= r.min_qty : r);

                        const _columns = !action ? [] : __columns.filter((c) => action.columns.indexOf(c.key) > -1);

                        // console.log(_columns);

                        const lastCol = _columns.length - 1;
                        const lastOneCol = _columns.length - 2;
                        const lastTwoCol = _columns.length - 3;

                        return (
                            <div className="w-full h-full mx-auto">
                                <div className="p-4">
                                    <div className="my-4 flex flex-col md:flex-row md:items-center justify-between">
                                        <div className="flex items-center flex-1">
                                            <div className="w-full">
                                                <p className="text-black font-bold text-3xl">{props.company.name}</p>
                                                <p className="text-black font-bold text-2xl">{typesTitle[variables.type].name}</p>
                                                <p className="text-black font-bold">
                                                    {moment(variables.fromDate).format('DD/MM/YYYY')} - {moment(variables.toDate).format('DD/MM/YYYY')}
                                                </p>
                                            </div>
                                            {variables.type == 'STOCK_BALANCE' ? <AntButton
                                                onClick={() => setShowMinQty(!showMinQty)}
                                                className="ml-3 print-hidden"
                                                ghost
                                                size="small"
                                                type="primary"
                                            >
                                                {showMinQty ? 'Hide' : 'Show'} Minimum Stock
                                            </AntButton> : null}
                                        </div>
                                        <div className="print-hidden">
                                            <Button
                                                appearance="primary"
                                                marginLeft={10}
                                                onClick={() => {

                                                    if (isReport) {

                                                        if (window.ReactNativeWebView) {

                                                            window.ReactNativeWebView.postMessage(document.body.parentElement.outerHTML);

                                                        }

                                                    } else {

                                                        window.print();

                                                    }

                                                }}
                                            >
                                                Print
                                            </Button>
                                            {/* <Button
                                                intent="danger"
                                                appearance="primary"
                                                marginLeft={10}
                                                onClick={() => {

                                                    exportPDF(props.company.name, `${moment(variables.fromDate).format('DD/MM/YYYY')} - ${moment(variables.toDate).format('DD/MM/YYYY')}` ,typesTitle[variables.type].name, _columns, dataSource);

                                                }}
                                            >
                                                Export to PDF
                                            </Button> */}
                                            <CurrencyContext.Consumer>
                                                {({ currency: { symbol } }) => {

                                                    console.log(symbol);

                                                    return (
                                                        <ExcelFile
                                                            element={
                                                                <Button
                                                                    intent="success"
                                                                    appearance="primary"
                                                                    marginLeft={10}
                                                                >
                                                                    Export to Excel
                                                                </Button>
                                                            }
                                                            filename={`${typesTitle[variables.type].name.split(' ').join('_')}_${moment().format('YYYYMMDD')}`}

                                                        >

                                                            <ExcelSheet
                                                                data={dataSource}
                                                                name={`${typesTitle[variables.type].name.split(' ').join('_')}_${moment().format('YYYYMMDD')}`}
                                                            >
                                                                {_columns.map((c) => (
                                                                    <ExcelColumn
                                                                        key={c.key}
                                                                        label={c.title_template ? c.title_template(symbol) : c.title}
                                                                        value={c.dataIndex}
                                                                    />
                                                                ))}
                                                            </ExcelSheet>

                                                        </ExcelFile>
                                                    )

                                                }}
                                            </CurrencyContext.Consumer>
                                        </div>
                                    </div>
                                </div>
                                <div className="h-full w-full">
                                    <Table
                                        pagination={false}
                                        size="small"
                                        columns={_columns}
                                        dataSource={dataSource}
                                        scroll={isReport ? { y: 500 } : null}
                                        // pagination={{ pageSize: 50 }}
                                        rowKey={(i) => `${i.id}`}
                                        // bordered
                                        summary={() => {

                                            return (
                                                <>
                                                    <Table.Summary.Row>
                                                        {_columns.map((c, i) => {

                                                            const key = `summary-${i}`;

                                                            if (i == 0) {

                                                                return (

                                                                    <Table.Summary.Cell key={key}>
                                                                        <span className="font-bold text-black">Total</span>
                                                                    </Table.Summary.Cell>

                                                                )

                                                            } else if (i == lastCol) {

                                                                return (
                                                                    <Table.Summary.Cell key={key}>
                                                                        <p className="font-bold text-black text-right">
                                                                            <CurrencyFormat amount={sumBy(dataSource, 'total')} />
                                                                        </p>
                                                                    </Table.Summary.Cell>
                                                                )

                                                            } else if (i == lastOneCol) {

                                                                const isClosing = _columns[lastOneCol].key === 'closing';

                                                                return (
                                                                    <Table.Summary.Cell key={key}>
                                                                        <p className="font-bold text-black text-right">
                                                                            {sumBy(dataSource, isClosing ? 'closing' : 'qty')}
                                                                        </p>
                                                                    </Table.Summary.Cell>
                                                                )

                                                            } else if (i === lastTwoCol && _columns[lastTwoCol].key === 'opening') {

                                                                return (
                                                                    <Table.Summary.Cell key={key}>
                                                                        <p className="font-bold text-black text-right">
                                                                            {sumBy(dataSource, 'opening')}
                                                                        </p>
                                                                    </Table.Summary.Cell>
                                                                )

                                                            } else {

                                                                return (
                                                                    <Table.Summary.Cell key={key}></Table.Summary.Cell>
                                                                );

                                                            }

                                                        })}
                                                    </Table.Summary.Row>
                                                </>
                                            );

                                        }}
                                    />
                                </div>
                            </div>
                        )

                    }}
                </Query> : null}
            </div>
        </div>

    )

};