import React from 'react';
import {DatePicker, TreeSelect, Button, Table} from 'antd';
import moment from 'moment';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import {withRouter} from 'react-router-dom';
import {keys, last} from 'lodash';
import {Spinner} from 'evergreen-ui';
import { convert, buildTree } from 'react-sortly';
import {currencyFormat} from 'mout/number';
import { CurrencyFormat } from '../../../services/Currency';



const dateFormat = 'YYYY-MM-DD';

const groups = {
    1: {
        name: 'Assets'
    },
    2: {
        name: 'Liabilities'
    },
    3: {
        name: 'Income'
    },
    4: {
        name: 'Expenses'
    },
    5: {
        name: 'Equity'
    }
}

const GET_ACCOUNTS = gql`
    query ($company_id: Int!) {
        accounts: ledgers(where: {company_id: {_eq: $company_id}}) {
            archived
            company_id
            currency
            description
            id
            is_default
            group_id
            name
            prefix
            parentId: parent_id
        }
    }  
`;

const GET_TRANSACTIONS = gql`
    query (
        $company_id: Int!,
        $account_id: Int!,
        $from_date: date!,
        $to_date: date!
    ) {
        transactions: _ledger_transactions(where: {
            company_id: {_eq: $company_id},
            id: {_eq: $account_id},
            date: {_gte: $from_date, _lte: $to_date}
        }) {
            description
            date,
            debit,
            credit,
            balance
        }
    }
`;

class BalanceReports extends React.Component{

    state = {
        account_id: null,
        companyId: this.props.match.params.companyId,
        from_date: moment().startOf('year').format(dateFormat),
        to_date: moment().endOf('year').format(dateFormat)
    }

    buildAccount = (account, parents) => {

        const key = [...parents, account.id];

        return ({
            ...account,
            title: account.name,
            key: account.id,
            value: account.id,
            key: key.join('-'),
            value: key.join('-'),
            disabled: account.children.length ? true: false,
            children: account.children.length ? account.children.map((account) => this.buildAccount(account, key)): []
        })

    }

    componentDidMount() {

        const _groups = keys(groups);
        const _accounts = buildTree(convert(this.props.accounts.map((a) => ({...a, title: a.name, key: a.id, value: a.id}))));

        const accounts = _groups.map((key) => {

            return {
                title: groups[key].name,
                id: key,
                key,
                value: key,
                disabled: true,
                children: _accounts.filter((a) => parseInt(a.group_id) === parseInt(key)).map((account) => this.buildAccount(account, [key]))
            }

        });

        this.setState({
            accounts
        });

    }

    render() {

        const {companyId, accounts, _account_id, account_id, from_date, to_date} = this.state;

        return (
            <div className="overflow-scroll h-full">
                <div className="flex h-full my-12">
                    <div className="w-full max-w-5xl mx-auto">
                        <div className="text-4xl font-bold text-black mb-4">
                            Account Balance
                        </div>
                        <div className="p-6 bg-gray-200 rounded flex items-center justify-between">
                            <div className="flex items-center -mx-2 w-64">
                                <p className="font-medium text-black px-2">Account</p>
                                <div className="flex-1">
                                    <TreeSelect
                                        showSearch
                                        allowClear
                                        treeNodeFilterProp="title"
                                        style={{ width: '100%' }}
                                        value={_account_id}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        treeData={accounts}
                                        placeholder="Filter by account"
                                        treeDefaultExpandAll
                                        onChange={(account) => {

                                            // journal.debit[i].account = account;

                                            this.setState({_account_id: account, account_id: parseInt(last(account.split('-')))});

                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center -mx-2">
                                <p className="font-medium text-black px-2">Date Range</p>
                                <DatePicker.RangePicker
                                    value={[moment(from_date, dateFormat), moment(to_date, dateFormat)]}
                                    format={dateFormat}
                                    onChange={((dates, dateString) => {

                                        this.setState({
                                            from_date: dateString[0],
                                            to_date: dateString[1]
                                        })

                                    })}
                                />
                            </div>
                            <div className="">
                                <Button type="primary" shape="round" ghost>Update Report</Button>
                            </div>
                        </div>
                        {account_id ? <div className="">
                            <Query
                                query={GET_TRANSACTIONS}
                                variables={{
                                    company_id: companyId,
                                    account_id,
                                    from_date,
                                    to_date
                                }}
                            >
                                {({loading, data, error}) => {

                                    // console.log(data);

                                    return (
                                        !loading && data ? !data.transactions.length ? <div className="lex h-full items-center justify-center py-10">
                                            <p className="text-center text-lg">No Transactions found.</p>
                                        </div> :<div className="my-10">
                                            <Table
                                                dataSource={data.transactions}
                                                size="small"
                                                columns={[{
                                                    title: 'Date',
                                                    key: 'date',
                                                    dataIndex: 'date',
                                                    render: (date) => moment(date, 'YYYY-MM-DD').format('MMM DD, YYYY')
                                                },{
                                                    title: 'Description',
                                                    key: 'description',
                                                    dataIndex: 'description'
                                                },{
                                                    title: 'Debit',
                                                    key: 'debit',
                                                    dataIndex: 'debit',
                                                    render: (debit) => debit ? <CurrencyFormat amount={Math.abs(debit)} />: null
                                                },{
                                                    title: 'Credit',
                                                    key: 'credit',
                                                    dataIndex: 'credit',
                                                    render: (credit) => credit ? <CurrencyFormat amount={Math.abs(credit)} />: null
                                                },{
                                                    title: 'Balance',
                                                    key: 'balance',
                                                    dataIndex: 'balance',
                                                    render: (balance) => <CurrencyFormat amount={Math.abs(balance)} />
                                                }]}
                                                pagination={false}
                                            />
                                        </div>: <div className="flex h-full items-center justify-center py-10">
                                            <Spinner />
                                        </div>
                                    )

                                }}
                            </Query>
                        </div>: null}
                    </div>
                </div>
            </div>
        )

    }

}

const BalanceReportContainer = withRouter(BalanceReports);

class BalanceReportWrapper extends React.Component{

    state = {
        companyId: this.props.match.params.companyId
    }

    render() {

        const {companyId} = this.state;

        return (
            <Query
                query={GET_ACCOUNTS}
                variables={{
                    company_id: companyId
                }}
            >
                {({data, loading, error}) => {

                    return (
                        !loading && data ? <BalanceReportContainer accounts={data.accounts} /> : <div className="flex h-full items-center justify-center py-10">
                            <Spinner />
                        </div>
                    )

                }}
            </Query>
        )

    }

}

export default BalanceReportWrapper;