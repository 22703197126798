import React from 'react';
import {RefreshCw} from 'react-feather';
import {Button} from 'antd';
import {Spinner, Badge} from 'evergreen-ui';
import {Link} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';

import gql from "graphql-tag";
import { Query } from "react-apollo";

const GET_OUTLETS = gql`
    query($company_id: Int!) {

        outlets (where: {company_id: {_eq: $company_id}}, order_by: {warehouse: desc}) {
            id
            name
            warehouse
            company_id
        }

    }
`;

class Outlets extends React.Component{

    state = {
        companyId: this.props.match.params.companyId
    };

    componentDidCatch(error, errorInfo) {
    
        console.log(error, errorInfo);

    }

    render(){

        const {companyId} = this.state;

        return (
            <div className="py-12">
                <div className="w-full max-w-3xl mx-auto px-4">
                    <Query
                        variables={{
                            company_id: companyId
                        }}
                        fetchPolicy="cache-and-network" errorPolicy="ignore"
                        query={GET_OUTLETS}
                    >
                        {({loading, data, error, refetch}) => {

                            // console.log(data);

                            return (
                                <React.Fragment>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <h1 className="font-bold text-4xl mr-2">
                                                Outlets 
                                            </h1>
                                            {loading ? null :<button className="ml-4 focus:outline-none text-black" onClick={() => refetch()}>
                                                <RefreshCw size={24} />
                                            </button>}
                                        </div>
                                        <div className="">
                                            <Button type="primary" onClick={() => this.props.history.push(`/${companyId}/pos/outlets/add`)} shape="round" ghost icon={<PlusOutlined />} className="flex items-center justify-center">Add Outlet</Button>
                                        </div>
                                    </div>
                                    {loading ? <div className="flex items-center justify-center"><Spinner /></div> : <div className="">
                                        {data && data.outlets ? data.outlets.map((outlet) => {

                                            return (
                                                <div key={outlet.id} className="py-4 border-b">
                                                    <div className="flex items-center">
                                                        <p className="font-bold text-lg text-black">{outlet.name}</p>
                                                        {outlet.warehouse ? <Badge color="blue" marginLeft={8}>WAREHOUSE</Badge>: null}
                                                    </div>
                                                    <Link to={`/${companyId}/pos/outlets/${outlet.id}`}>View</Link>
                                                </div>
                                            )

                                        }) :null}
                                    </div>}
                                </React.Fragment>
                            )

                        }}
                    </Query>

                </div>
            </div>
        )

    }

}

export default Outlets