import { Model } from '@nozbe/watermelondb'
import { field, date, readonly } from '@nozbe/watermelondb/decorators'

export default class Product extends Model {

    static table = 'products'

    @field('archived') archived
    @field('category_id') categoryId
    @field('company_id') companyId
    @field('default_variant') defaultVariant
    @field('has_variants') hasVariants
    @field('raw_material') rawMaterial
    @field('name') name
    @field('supplier_id') supplierId
    @field('track_inventory') trackInventory

    @field('remote_id') remoteId
    @readonly @date('created_at') createdAt
    @readonly @date('updated_at') updatedAt
}