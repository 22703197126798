import React from 'react'
import { FormField, SelectField, toaster, Spinner, Switch } from 'evergreen-ui';
import {InputNumber} from 'antd';

export default class TaxSettings extends React.Component {
    state = {
        taxOption : this.props.data.taxOption || 'standard',
        option : this.props.data.option || 'inclusive',
        VAT : this.props.data.VAT,
        NHIL : this.props.data.NHIL,
        Tourism : this.props.data.Tourism,
        flat_rate: this.props.data.flat_rate
    }

    

    handleVATNumbers = (VAT) => {
        // if(/^\d+$/.test(e.target.value) && e.target.value <= 100) {
            this.setState({VAT})
        //     console.log(e.target.value)
        // }
    }
    handleNHILNumbers = (NHIL) => {
        // if(/^\d+$/.test(e.target.value) && e.target.value <= 100) {
            this.setState({NHIL})
        //     console.log(e.target.value)
        // }
    }

    handleTourismNumbers = (Tourism) => {
        // if(/^\d+$/.test(e.target.value) && e.target.value <= 100) {
            this.setState({Tourism})
        //     console.log(e.target.value)
        // }
    }
    render() {

        return (
            <div className="h-full w-full overflow-scroll">
                <div className="max-w-2xl mx-auto px-16 sm:px-16 mt-16 sm:my-16">
                    <div className="h-full w-full">
                        <div className="mb-1">
                            <SelectField
                                label="Tax Options"
                                onChange={(e) => this.setState({taxOption: e.target.value})}
                                value={this.state.taxOption}
                            >
                                <option value="standard">Standard</option>
                                <option value="hospitality">Hospitality</option>
                            </SelectField>
                        </div>
                        <div className="mb-3">
                            <p className="font-medium">Flat rate</p>
                            <Switch height={24} checked={this.state.flat_rate} onChange={e => this.setState({ flat_rate: e.target.checked })} />
                        </div>
                        <div>
                            <SelectField
                                label="Options"
                                onChange={(e) => this.setState({option:e.target.value})}
                                value={this.state.option}
                            >
                                <option value="inclusive">Inclusive</option>
                                <option value="exclusive">Exclusive</option>
                            </SelectField>
                        </div>
                        <div className="my-4">
                            <FormField required label={this.state.flat_rate ? "VAT + NHIL %" :"VAT %"}/>
                            <InputNumber
                                style={{width: '100%'}}
                                placeholder={`Enter ${this.state.flat_rate ? 'VAT + NHIL' :'VAT'} %`}
                                onChange={this.handleVATNumbers}
                                value={this.state.VAT}
                            />
                        </div>

                        {this.state.flat_rate ? null :<div className="my-4">
                            <FormField required label="NHIL %"/>
                            <InputNumber
                                style={{width: '100%'}}
                                placeholder="Enter NHIL %"
                                onChange={this.handleNHILNumbers}
                                value={this.state.NHIL}
                            />
                        </div>}
                        {this.state.taxOption === "hospitality" && (
                            <div className="my-4">
                                <FormField required label="Tourism Levy %"/>
                                <InputNumber
                                    style={{width: '100%'}}
                                    placeholder="Enter Tourism Levy %"
                                    onChange={this.handleTourismNumbers}
                                    value={this.state.Tourism}
                                />
                            </div>
                        )}
                        <div>
                            {this.props.renderSaveButton(this.state)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
