import { appSchema, tableSchema } from '@nozbe/watermelondb'

const Schema = appSchema({
    version: 2,
    tables: [
        // tableSchema({
        //     name: 'ledger_transactions',
        //     columns: [
        //         { name: 'accounts', type: 'string' },
        //         { name: 'approved', type: 'boolean' },
        //         { name: 'cheque_no', type: 'string' },
        //         { name: 'company_id', type: 'number' },
        //         { name: 'credit', type: 'string' },
        //         { name: 'date', type: 'string' },
        //         { name: 'debit', type: 'string' },
        //         { name: 'description', type: 'string' },
        //         { name: 'initial', type: 'boolean' },
        //         { name: 'ledger_id', type: 'number' },
        //         { name: 'meta', type: 'string' },
        //         { name: 'user_id', type: 'number' },
        //         { name: 'void_reason', type: 'string' },
        //         { name: 'voided', type: 'boolean' },
        //         { name: 'remote_id', type: 'number'},
        //         { name: 'created_at', type: 'number' },
        //         { name: 'updated_at', type: 'number' },
        //     ]
        // }),
        // tableSchema({
        //     name: 'orders',
        //     columns: [
        //         { name: 'comment', type: 'string' },
        //         { name: 'company_id', type: 'number' },
        //         { name: 'customer_id', type: 'number' },
        //         { name: 'discount_amt', type: 'string' },
        //         { name: 'discount_id', type: 'number' },
        //         { name: 'is_wholesale', type: 'boolean' },
        //         { name: 'outlet_id', type: 'number' },
        //         { name: 'salesperson_id', type: 'string' },
        //         { name: 'status', type: 'string' },
        //         { name: 'subtotal', type: 'string' },
        //         { name: 'tax_amt', type: 'string' },
        //         { name: 'supervisor_id', type: 'number' },
        //         { name: 'total_amt', type: 'string' },
        //         { name: 'total_qty', type: 'number' },
        //         { name: 'remote_id', type: 'number'},
        //         { name: 'created_at', type: 'number' },
        //         { name: 'updated_at', type: 'number' },
        //     ]
        // }),
        // tableSchema({
        //     name: 'order_items',
        //     columns: [
        //         { name: 'discount_amt', type: 'string' },
        //         { name: 'employee_id', type: 'number' },
        //         { name: 'order_id', type: 'number' },
        //         { name: 'price', type: 'string' },
        //         { name: 'qty', type: 'number' },
        //         { name: 'subtotal', type: 'string' },
        //         { name: 'tax_amt', type: 'string' },
        //         { name: 'tax_flat_rate', type: 'boolean' },
        //         { name: 'tax_inclusive', type: 'boolean' },
        //         { name: 'tax_nhil', type: 'string' },
        //         { name: 'tax_tourism_levy', type: 'string' },
        //         { name: 'tax_vat', type: 'string' },
        //         { name: 'total', type: 'string' },
        //         { name: 'variant_id', type: 'number' },
        //         { name: 'remote_id', type: 'number'},
        //         { name: 'created_at', type: 'number' },
        //         { name: 'updated_at', type: 'number' },
        //     ]
        // }),
        // tableSchema({
        //     name: 'order_payments',
        //     columns: [
        //         { name: 'amount', type: 'string' },
        //         { name: 'company_id', type: 'number' },
        //         { name: 'currency', type: 'string' },
        //         { name: 'employee_id', type: 'number' },
        //         { name: 'exchange', type: 'string' },
        //         { name: 'mobile_network', type: 'string' },
        //         { name: 'mobile_number', type: 'string' },
        //         { name: 'order_id', type: 'number' },
        //         { name: 'outlet_id', type: 'number' },
        //         { name: 'title', type: 'string' },
        //         { name: 'type', type: 'string' },
        //         { name: 'value', type: 'string' },
        //         { name: 'remote_id', type: 'number'},
        //         { name: 'created_at', type: 'number' },
        //         { name: 'updated_at', type: 'number' },
        //     ]
        // }),
        tableSchema({
            name: 'customers',
            columns: [
                { name: 'company_name', type: 'string' },
                { name: 'company_id', type: 'number' },
                { name: 'contact_name', type: 'string' },
                { name: 'first_name', type: 'string' },
                { name: 'is_wholesale', type: 'boolean' },
                { name: 'last_name', type: 'string' },
                { name: 'name', type: 'string' },
                { name: 'phone', type: 'string' },
                { name: 'website', type: 'string' },
                { name: 'ledger_id', type: 'number' },
                { name: 'remote_id', type: 'number' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ]
        }),
        tableSchema({
            name: 'outlets',
            columns: [
                { name: 'commission', type: 'boolean' },
                { name: 'company_id', type: 'number' },
                { name: 'discount_limit', type: 'string' },
                { name: 'name', type: 'string' },
                { name: 'phone', type: 'string' },
                { name: 'raw_materials', type: 'boolean' },
                { name: 'salesperson_percent', type: 'string' },
                { name: 'supervisor_percent', type: 'string' },
                { name: 'warehouse', type: 'boolean' },
                { name: 'wholesale', type: 'boolean' },
                { name: 'remote_id', type: 'number' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ]
        }),
        tableSchema({
            name: 'users',
            columns: [
                { name: 'display_name', type: 'string' },
                { name: 'role', type: 'string' },
                { name: 'outlet_id', type: 'number' },
                { name: 'company_id', type: 'number' },
                { name: 'user_id', type: 'number' },
                { name: 'remote_id', type: 'number' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ]
        }),
        tableSchema({
            name: 'products',
            columns: [
                { name: 'archived', type: 'boolean' },
                { name: 'category_id', type: 'number' },
                { name: 'company_id', type: 'number' },
                { name: 'default_variant', type: 'string' },
                { name: 'has_variants', type: 'boolean' },
                { name: 'name', type: 'string' },
                { name: 'supplier_id', type: 'number' },
                { name: 'track_inventory', type: 'boolean' },
                { name: 'remote_id', type: 'number' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ]
        }),
        // tableSchema({
        //     name: 'products_history',
        //     columns: [
        //         { name: 'approved_by', type: 'number' },
        //         { name: 'comment', type: 'string' },
        //         { name: 'company_id', type: 'number' },
        //         { name: 'destination_id', type: 'number' },
        //         { name: 'employee_id', type: 'number' },
        //         { name: 'received', type: 'boolean' },
        //         { name: 'source_id', type: 'number' },
        //         { name: 'supplier_id', type: 'number' },
        //         { name: 'total_amount', type: 'string' },
        //         { name: 'total_qty', type: 'number' },
        //         { name: 'transfer_id', type: 'number' },
        //         { name: 'type', type: 'string' },
        //         { name: 'remote_id', type: 'number'},
        //         { name: 'created_at', type: 'number' },
        //         { name: 'updated_at', type: 'number' },
        //     ]
        // }),
        // tableSchema({
        //     name: 'products_history_items',
        //     columns: [
        //         { name: 'cost', type: 'string' },
        //         { name: 'e_qty', type: 'number' },
        //         { name: 'history_id', type: 'number' },
        //         { name: 'price', type: 'string' },
        //         { name: 'qty', type: 'number' },
        //         { name: 'sku_id', type: 'number' },
        //         { name: 'variance', type: 'number' },
        //         { name: 'variant_id', type: 'number' },
        //         { name: 'remote_id', type: 'number'},
        //         { name: 'created_at', type: 'number' },
        //         { name: 'updated_at', type: 'number' },
        //     ]
        // }),
        tableSchema({
            name: 'products_inventory',
            columns: [
                { name: 'company_id', type: 'number' },
                { name: 'cost', type: 'string' },
                { name: 'outlet_id', type: 'number' },
                { name: 'price', type: 'string' },
                { name: 'product_id', type: 'number' },
                { name: 'qty', type: 'number' },
                { name: 'variant_id', type: 'number' },
                { name: 'remote_id', type: 'number' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ]
        }),
        tableSchema({
            name: 'products_sku',
            columns: [
                { name: 'code', type: 'string' },
                { name: 'company_id', type: 'number' },
                { name: 'variant_id', type: 'number' },
                { name: 'remote_id', type: 'number' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ]
        }),
        tableSchema({
            name: 'products_variants',
            columns: [
                { name: 'cost', type: 'string' },
                { name: 'min_qty', type: 'number' },
                { name: 'name', type: 'string' },
                { name: 'price', type: 'string' },
                { name: 'product_id', type: 'number' },
                { name: 'company_id', type: 'number' },
                { name: 'remote_id', type: 'number' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ]
        }),
        tableSchema({
            name: 'discounts',
            columns: [

                { name: 'active', type: 'boolean' },
                { name: 'code', type: 'string' },
                { name: 'company_id', type: 'number' },
                { name: 'created_by', type: 'number' },
                { name: 'customer_id', type: 'number' },
                { name: 'employee_id', type: 'number' },
                { name: 'expires_at', type: 'number' },
                { name: 'name', type: 'string' },
                { name: 'one_time', type: 'boolean' },
                { name: 'outlet_id', type: 'number' },
                { name: 'type', type: 'string' },
                { name: 'value', type: 'string' },

                { name: 'remote_id', type: 'number' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ]
        }),
    ]
})

export default Schema