import React from 'react';
import 'antd/dist/antd.css';
import './App.css';
import Login from './components/Login';
import DashBoard from './components/Dashboard';
import firebase from './services/firebase';
import {keyBy} from 'lodash';
import {Route, Switch, Redirect} from 'react-router-dom';
import {Spinner} from 'evergreen-ui';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import {decode} from 'mout/queryString';
import PasswordReset from './components/PasswordReset';

const GET_USER = gql`
	query ($account_id: Int!, $id: Int!)  {
		user: users_by_pk(account_id: $account_id, id: $id) {
			display_name
			email
			id
			photo_url
			role,
			companies {
				role
				company_id
				outlet_id
				outlet {
					id
					name
					warehouse
					wholesale
				}
			}
		}
		account: accounts_by_pk(id: $account_id) {
			id
			name
			slug
			max_companies
			users{
				id
				display_name
			}
			companies{
				id
				name
				logo
				email
				phone
				file_number
				allowed_services
				tax_type
				tax_option
				vat
				nhil
				tourism_levy
				flat_rate
				discount_before_tax
				ledgers(where: {
					prefix: {
						_in: [
							"current-asset",
							"cash-and-bank",
							"cash",
							"momo",
							"card",
							"discounts",
							"trade-receivables",
							"deferred-statutory",
							"deferred-taxes",
							"deferred-vat",
							"deferred-tourism-levy",
							"sales",
							"cos"
						]
					}
				}){
					id
					prefix
					parent_id
					group_id
				}
				supplier_ledger: ledgers(where: {prefix: {_eq: "suppliers"}}) {
					id
				}
				customer_ledger: ledgers(where: {prefix: {_eq: "trade-receivables"}}) {
					id
				}
				country{
					id
					code
					name
				}
				currency{
					id
					name
					precision                    
					decimal_separator
					code
					symbol
					swap_currency_symbol
					thousand_separator
				}
			}
		}
	}
`;



class App extends React.Component {

	state = {
		user: null,
		init: null,
		account: this.props.account
	}

	componentDidMount() {

		const query = decode(window.location.search);
		const isReport = query.report;
		
		if (isReport && query.token) {

			firebase.auth().signInWithCustomToken(query.token);

		}

		firebase.auth().onAuthStateChanged( (user) => {

			const {init, user: exists} = this.state;

			if (init && user) {

				this.setState({user, init: true});

				firebase.firestore().collection('users').doc(user.uid).collection('logs').add({
					device: window.navigator.userAgent,
					user_id: user.uid,
					type: 'login',
					time: Date.now()
				}).then(() => {

				}).catch((err) => console.log(err));

			} else if (!user && exists) {

				this.setState({user, init: true});

				firebase.firestore().collection('users').doc(exists.uid).collection('logs').add({
					device: window.navigator.userAgent,
					user_id: exists.uid,
					type: 'logout',
					time: Date.now()
				}).then(() => {

				}).catch((err) => console.log(err));

			} else {

				this.setState({user, init: true});

			}

		});

	}

	render() {

		const {init, user, account} = this.state;

		const query = decode(window.location.search);
        const isReport = query.report;


		return (
			<div className="App">
				{/* <div className="flex-1"> */}
					{(init) ? (user) ? <Query
						query={GET_USER}
						fetchPolicy="cache-and-network"
						variables={{
							account_id: account.id,
							id: user.uid
						}}
						onCompleted={(data) => {

							data.account.companies.forEach((c) => {

								if (c.logo) {

									var img = new Image();
									img.onload = function() {}
									img.src = c.logo;

								}

							});

						}}
					>
						{({error, loading, data}) => {
							
							return (
								loading ? <div className="flex h-full items-center justify-center py-10">
									<Spinner />
								</div>: data && data.user ? <DashBoard
									user={{
										...data.user,
										companies: keyBy(data.user.companies, 'company_id')
									}}
									account={data.account}
								/>: null
							);

						}}
					</Query> : <Switch>
						<Route path={'/password/reset'} exact={true} render={(props) => {

							return <PasswordReset {...props} account={account} />

						}} />
						{!isReport ? <Route
							path={'/login'}
							exact={true}
							render={(props) => {

								return <Login clearEndpoint={this.props.clearEndpoint} {...props} account={account} />

							}}
						/>: null}
						<Redirect to="/login" />
					</Switch>: <div className="flex h-full items-center justify-center py-10">
						<Spinner />
					</div>}
				{/* </div> */}
				{/* <div className="flex items-center justify-center -mx-3 py-6">
					<a href="#" className="px-3">Privacy Policy</a>
					<a href="#" className="px-3">Contact</a>
					<a href="#" className="px-3">&copy; DeftPal</a>
				</div> */}
			</div>
		);

	}
	

}

export default App;
