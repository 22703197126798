import React from 'react';
import { Spinner, BackButton } from 'evergreen-ui';

import gql from "graphql-tag";
import { Query } from "react-apollo";
import CustomerForm from '../misc/customerForm';
import {CurrencyWrapper, CurrencyFormat} from '../../services/Currency';


import { Tabs, Statistic, Table } from 'antd';
import currencyFormat from 'mout/number/currencyFormat';
import moment from 'moment';
import {Link} from 'react-router-dom';


const { TabPane } = Tabs;


const GET_CUSTOMER = gql`
    query($id: Int!) {

        customer: customers_by_pk(id: $id) {
            id
            first_name
            last_name
            phone
            email
            date_of_birth
            company_id
            is_wholesale
            ledger_id
            orders {
                id
                subtotal
                status
                outlet {
                  id
                  name
                }
                total_amt
                total_qty
                created_at
            }
            ledger {
                balance {
                  amount
                  id
                }
            }
        }
    }
`;

const CustomerWrapper = ({customerId, customer, history, companyId, company, user}) => {

    // const [name, setName] = useState(customer.name || '');
    // const [commission, setCommission] = useState(customer.commission);
    // const [supervisorPercentage, setSupervisorPercentage] = useState(customer.supervisor_percent || 0.00);
    // const [salespersonPercentage, setSalesPersonPercentage] = useState(customer.salesperson_percent || 0.00);

    return (
        <div className="p-8 rounded-lg shadow-lg">
            <Tabs defaultActiveKey="1">
                <TabPane tab="Overview" key="1">
                    <div className="">
                        <CurrencyWrapper
                            component={({symbol}) => (
                                <Statistic
                                    prefix={symbol}
                                    title="Credit Balance"
                                    value={currencyFormat(customer.ledger && customer.ledger.balance ?customer.ledger.balance.amount: 0)}
                                />
                            )}
                        />
                        <Table
                            columns={[{
                                title: 'ID',
                                key: 'id',
                                dataIndex: 'id',
                                render: (id) => <Link target="_blank" to={`/${companyId}/pos/sales/${id}`}>
                                    #{id}
                                </Link>
                            },{
                                title: 'Date',
                                render: (r) => moment(r.created_at).format('DD/MM/YYYY')
                            },{
                                title: 'Items',
                                key: 'total_qty',
                                dataIndex: 'total_qty',
                            },{
                                title: 'Outlet',
                                render: (r) => r.outlet.name 
                            },{
                                title: 'Total',
                                key: 'total_amt',
                                dataIndex: 'total_amt',
                                render: (a) => <CurrencyFormat amount={a} />
                            }]}
                            dataSource={customer.orders}
                            pagination={false}
                        />
                    </div>
                </TabPane>
                <TabPane tab="Edit Customer" key="2">
                    <div className="">
                        <p className="text-black text-lg font-bold">{customerId ? 'Edit' :'Create'} Customer</p>
                        <CustomerForm
                            customerId={customerId}
                            companyId={companyId}
                            company={company}
                            user={user}
                            data={{
                                is_wholesale: customer.is_wholesale,
                                ledger_id: customer.ledger_id,
                                firstName: customer.first_name || '',
                                lastName: customer.last_name || '',
                                phone: customer.phone || '',
                                email: customer.email || '',
                                DOB: customer.date_of_birth || '',
                            }}
                            onSave={() => {

                                history.push(`/${companyId}/pos/customers`);

                            }}
                        />
                    </div>
                </TabPane>
            </Tabs>
        </div>
    )

}


class Customer extends React.Component {

    state = {
        companyId: this.props.match.params.companyId,
        customerId: this.props.match.params.customerId
    }

    render() {

        const {companyId, customerId } = this.state;

        // console.log(customerId);

        return (
            <div className="max-w-xl w-full mx-auto my-12">
                <BackButton appearance="default" marginBottom={15} onClick={() => {

                    this.props.history.push(`/${companyId}/pos/customers`);

                }} children="Back to customers" />
                <Query
                    query={GET_CUSTOMER}
                    skip={(!customerId) ? true : false}
                    variables={{
                        id: customerId
                    }}
                >
                    {({ loading, data, error, refetch }) => {

                        return (
                            
                            loading ? <div className="flex items-center justify-center py-10">
                                <Spinner />
                            </div> : !customerId || data && data.customer ? <CustomerWrapper
                                customerId={customerId}
                                customer={customerId && data ? data.customer: {}}
                                history={this.props.history}
                                companyId={companyId}
                                user={this.props.user}
                                company={this.props.company}
                            />: null
                        )

                    }}
                </Query>
            </div>

        );

    }

}

export default Customer;