import React from 'react'

export const checkRestricted = (allowed = [], value = '') => {

    return !allowed.includes(`${value}`);

}

export default (props) => {


    const {allowed = [], value = '', children = []} = props;

    return (
        <div>
            {allowed.includes(`${value}`) ? (
                <div>{children}</div>
            ) : null}
        </div>
    )


};