import React from 'react'
import {Paragraph, FormField, Pane, Tablist, Tab, Button} from 'evergreen-ui';

import { InputNumber } from 'antd'

class GoodsReceived extends React.Component {


    constructor(props) {

        super(props);

        const companyId = props.match.params.companyId;

        const {company, user} = props;
        const role = user.companies[companyId].role;

        this.state = {
            companyId,
            id: null,
            tabs: ['Purchase Order', "Receive Stock"],
            selectedIndex: ["pos-sales-supervisor"].includes(`${role}`) ? 1: 0
        };


    }

    render() {

        const {id, companyId, tabs, selectedIndex} = this.state;
        const {user} = this.props;
        const role = user.companies[companyId].role;

        const canPurchase = ["pos-inventory-manager", "pos-inventory-officer", "admin"].includes(`${role}`);
    
        return (
            <div className="h-full w-full overflow-scroll">
                <div className="max-w-4xl mx-auto py-12">
                    <h1 className="text-2xl text-black font-bold">Goods Received Note</h1>
                    <Pane>
                        <Tablist marginTop={16} marginBottom={16} flexBasis={240} marginRight={24}>
                            {tabs.map((tab, index) => (
                                <Tab
                                    key={tab}
                                    id={tab}
                                    disabled={index === 0 && !canPurchase}
                                    onSelect={() => this.setState({ selectedIndex: index })}
                                    isSelected={index === selectedIndex}
                                    aria-controls={`panel-${tab}`}
                                >
                                    {tab}
                                </Tab>
                            ))}
                        </Tablist>
                        <Pane padding={16} flex="1">
                            {tabs.map((tab, index) => {

                                const isStock = index == 1;

                                return (
                                    <div
                                        key={tab}
                                        id={`panel-${tab}`}
                                        role="tabpanel"
                                        aria-labelledby={tab}
                                        aria-hidden={index !== selectedIndex}
                                        style={{
                                            display: index === selectedIndex ? 'block' : 'none'
                                        }}
                                    >
                                        <div className="max-w-xs my-3">
                                            <FormField
                                                isRequired
                                                label={isStock? "Stock Transfer #" :"Purchase Order #"}
                                                description={`Enter the ${isStock ? "Stock Transfer" : "Purchase Order"} #`}
                                            />
                                            <InputNumber
                                                style={{
                                                    width: '100%'
                                                }}
                                                value={id}
                                                onClick={(e) => { 
    
                                                    e.target.select();
                                    
                                                }}
                                                onChange={(id) => this.setState({id})}
                                                size="large"
                                                parser={value => value.replace(/\#\s?|(,*)/g, '')}
                                                formatter={value => `# ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            />
                                        </div>
                                        <Button
                                            appearance="primary"
                                            isLoading={false}
                                            disabled={!id}
                                            intent="success"
                                            onClick={() => {
    
                                                this.props.history.push(`/${companyId}/pos/${isStock ? 'transfers' : 'purchase-order'}/${id}/receive`);
    
                                            }}
                                        >
                                            Receive
                                        </Button>
                                    </div>
                                );

                            })}
                        </Pane>
                    </Pane>

                </div>
            </div>
        )
    }
};

export default GoodsReceived;