import React from 'react';
import {Input} from 'antd';
import {Button} from 'evergreen-ui';
import { useState } from 'react';

export default ({
    save
}) => {

    const [slug, setSlug] = useState('test');

    return (

        <div className="py-8 flex flex-col justify-center items-center h-full w-full">
            <div className="mx-auto container max-w-sm">
                <img src="/icon.png" className="w-24 h-auto mx-auto" alt="GomERP Logo"/>
                <p className="text-center font-bold text-2xl text-black mb-6">Enter your account domain.</p>
                <div className="rounded-lg shadow-lg p-8">
                    <Input
                        size="large"
                        addonBefore="https://"
                        addonAfter=".gomerp.com"
                        defaultValue={slug}
                        onChange={(e) => setSlug(e.target.value)}
                    />
                    <div className="flex flex-col items-center my-6">
                        <Button onClick={() => save(slug)} height={45} width={`100%`} justifyContent="center" alignItems="center" appearance="primary" intent="success">
                            Save
                        </Button>
                        {/* <div className="mt-4">
                            <Link className="text-sm text-grey-darker hover:text-grey-dark no-underline" to="/password/reset">Forgot Password?</Link>
                        </div> */}
                    </div>
                </div>
                <div className="text-center my-10">
                    <p className="">Powered by <span className="font-bold">GOMERP</span> &trade;</p>
                </div>
            </div>
        </div>

    );

}