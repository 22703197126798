import React from 'react';
import {Input, Table, Select, Button as AntButton, Spin, Checkbox, Modal, InputNumber} from 'antd';
import {Button as EverButton, Spinner} from 'evergreen-ui';
import {values, orderBy, sum, sumBy, every, keyBy, fromPairs, compact} from 'lodash';
import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import {currencyFormat} from 'mout/number';
import apollo from '../../../services/apollo';
import {DeleteOutlined, WarningOutlined} from '@ant-design/icons';
import {CurrencyFormat, CurrencyContext} from '../../../services/Currency';
import moment from 'moment';

import ReactExport from "react-export-excel";
const {ExcelFile} = ReactExport;
const {ExcelSheet, ExcelColumn} = ExcelFile;

const GET_STOCKTAKE = gql`

    query(
        $id: Int!
    ) {

        stocktake: products_history_by_pk(id: $id) {
            received
            source{
              id
              name
            }
            destination{
              id
              name
            }
            comment
            total_amount
            total_qty
            
            employee{
                id
                display_name
            }
            created_at
            type
            items{
              qty
              price
              variance
              e_qty
              variant{
                id
                cost
                name
                price
                product {
                    id
                    name
                    has_variants
                    supplier {
                        name
                    }
                }
              }
            }
            
        }

    } 

`;


class StockTakeSingle extends React.Component{

    state = {
        companyId: this.props.match.params.companyId,
        productId: this.props.match.params.productId,
        outlet_id: null,
        comment: this.props.transfer ? this.props.stocktake.comment: '',
        isReverse: false,
        searchValue: ''
    }

    inputRef = React.createRef()

    columns = compact([{
        title: '#',
        render: (sku, v, i) => <span className="font-bold text-black">{i + 1}</span>
    },{
        title: 'Product',
        render: (v) => v.variant ? <div className="">
            <p className="font-bold text-black">{v.variant.product.name}</p>
            <p className="text-black font-light">{v.variant.name}</p>
        </div>: null
    }, {
        title: () => <center>Expected Quantity</center>,
        render: (v) => <center>{v.e_qty}</center>
    },{
        title: () => <center>Counted Quantity</center>,
        render: (v) => (
            <center>{v.qty}</center>
        )
    },{
        title: () => <center>Variance (Qty)</center>,
        render: (v) => <center>{v.variance}</center>
    },{
        title: 'Unit Price',
        render: (v) => <CurrencyFormat amount={v.price} />
    },{
        title: 'Total',
        render: (v) => <CurrencyFormat amount={v.qty * v.price} /> 
    },{
        title: 'Variance (Amount)',
        render: (v) => <CurrencyFormat amount={v.variance * v.price} />
    }]);

    excelColumns = compact([{
        title: '#',
        dataIndex: 'id',
        render: (sku, v, i) => i + 1
    },{
        title: 'Product',
        dataIndex: 'name',
        render: (v) => v.variant ? `${v.variant.product.name} - ${v.variant.name}`: ''
    }, {
        title: 'Expected Quantity',
        dataIndex: 'e_qty',
        render: (v) => v.e_qty
    },{
        title: 'Counted Quantity',
        dataIndex: 'qty',
        render: (v) => v.qty
    },{
        title: 'Variance (Qty)',
        dataIndex: 'variance',
        render: (v) => v.variance
    },{
        title: 'Unit Price',
        dataIndex: 'price',
        render: (v) => <CurrencyFormat amount={v.price} />
    },{
        title: 'Total',
        dataIndex: 'total',
        render: (v) => <CurrencyFormat amount={v.qty * v.price} /> 
    },{
        title: 'Variance (Amount)',
        dataIndex: 'total_variance',
        render: (v) => <CurrencyFormat amount={v.variance * v.price} />
    }]);

    render() {

        const {companyId, comment, qty, items, searchValue, isReverse, source_id, destination_id} = this.state;
        const {user, id, stocktake} = this.props;
        const total_qty = stocktake.total_qty;
        const total = stocktake.total_amount;
        
        return (
            <React.Fragment>
                <div className="overflow-scroll h-full">
                    <div className="max-w-6xl mx-auto w-full px-6">
                        <div className="flex items-center justify-between my-10">
                            <div className="flex items-center">
                                <h1 className="font-bold text-4xl mr-2">
                                    Stock Take {id ? `#${id}` : ''}
                                </h1>
                            </div>
                            <CurrencyContext.Consumer>
                                {({currency: {symbol}}) => {

                                    // console.log(symbol);

                                    return (
                                        <ExcelFile
                                            element={
                                                <EverButton
                                                    intent="success"
                                                    appearance="primary"
                                                    marginLeft={10}
                                                >
                                                    Export to Excel
                                                </EverButton>
                                            }
                                            filename={`StockTake_${moment().format('YYYYMMDD')}`}

                                        >
                                            
                                            <ExcelSheet
                                                data={stocktake.items.map((v, i) => {

                                                    return {
                                                        ...i,
                                                        id: i + 1,
                                                        name: v.variant ? `${v.variant.product.name} - ${v.variant.name}`: '',
                                                        e_qty: v.e_qty,
                                                        qty: v.qty,
                                                        variance: v.variance,
                                                        price: `${currencyFormat(v.price)}`,
                                                        total: `${v.qty * v.price}`, 
                                                        total_variance: `${v.variance * v.price}`
                                                    }

                                                })}
                                                name={`StockTake_${moment().format('YYYYMMDD')}`}
                                            >
                                                {this.excelColumns.map((c) => (
                                                    <ExcelColumn
                                                        key={c.key}
                                                        label={c.title}
                                                        value={c.dataIndex}
                                                    />
                                                ))}
                                            </ExcelSheet>
                                                    
                                        </ExcelFile>
                                    )

                                }}
                            </CurrencyContext.Consumer>
                        </div>
                        {id ? <div className="mb-3">
                            <p className="font-bold text-black">Date Created</p>
                            <p className="font-bold text-black text-lg my-1">
                                {moment(stocktake.created_at).format('DD/MM/YYYY')}
                            </p>
                        </div>: null}
                        {id && stocktake.employee ? <div className="mb-3">
                            <p className="font-bold text-black">Created By</p>
                            <p className="font-bold text-black text-lg my-1">
                                {stocktake.employee.display_name}
                            </p>
                        </div>: null}
                        <div className="flex w-full -mx-2">
                            <div className="w-1/3 px-2">
                                <p className="font-bold text-black">Outlet</p>
                                <div className="my-2">
                                    <p className="font-bold text-lg text-black">{stocktake.source.name}</p>
                                </div>
                            </div>
                            
                        </div>
                        <div className="my-8 shadow rounded">
                            <Table
                                dataSource={stocktake.items}
                                columns={this.columns}
                                pagination={false}
                                rowKey={(i) => `${i.variant.id}`}
                                size="small"
                            />
                        </div>
                        <div className="flex justify-between my-8 -mx-4">
                            <div className="flex-1 px-4">
                                <div className="mb-4">
                                    <>
                                        <p className="font-bold text-black">Notes / Comments</p>
                                        <p className="text-black">{comment? comment : "No Comment"}</p>
                                    </>
                                </div>
                            </div>
                            <div className="px-4">
                                <div className="rounded w-64 bg-gray-100 px-6 py-3">
                                    <div className="border-b py-2 my-2 flex items-center justify-between">
                                            <p className="text-black font-bold">Total Units</p>
                                            <p className="font-bold text-black">{total_qty}</p>
                                        </div>
                                        <div className="py-2 my-2 flex items-center justify-between">
                                            <p className="font-bold text-black">Total</p>
                                            <p className="font-bold text-black">
                                                <CurrencyFormat amount={total} />
                                            </p>
                                        </div>
                                    </div>
                                {/* <div className="my-4">
                                    <EverButton intent="success" appearance="primary">Save</EverButton>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )

    }

}

const StockTakeContainer = (props) => {

    const {companyId, id} = props.match.params;

    return (
        <Query
            query={GET_STOCKTAKE}
            skip={id ? false: true}
            variables={{
                id
            }}
        >
            {({loading, data, error, refetch}) => {

                return (
                    <div className="overflow-scroll h-full w-full">
                        {loading ? <div className="flex items-center justify-center py-10">
                            <Spinner />
                        </div> : (!id || (data && data.stocktake && data.stocktake.type == "STOCK_TAKE")) ? <StockTakeSingle
                            {...props}
                            id={id}
                            stocktake={!id ? null: {
                                ...data.stocktake
                            }}
                        />: <p className="font-bold text-lg p-6 text-black">Stock Take not Found</p>}
                    </div>
                );

            }}

        </Query>
    )

}

export default StockTakeContainer;