import React from 'react';
import { TextInputField, Button, Spinner, BackButton } from 'evergreen-ui';
import { Select, Input, Button as AntButton, Radio, InputNumber, message } from 'antd';

import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import { withRouter } from 'react-router-dom';

import Variants from '../misc/Variants';
import Sample from '../misc/sample-variants';
import { CurrencyWrapper } from '../../services/Currency';
import keyBy from 'lodash.keyby';

const GET_PRODUCT = gql`
    query($id: Int!) {

        product: products_by_pk(id: $id) {
            id
            name
            company_id
            supplier_id
            category_id
            has_variants
            variants{
                id
                name
                cost
                price
                min_qty
                inventory: inventory_aggregate{
                    aggregate{
                        sum{
                            qty
                        }
                    }
                }
                attributes {
                    id
                    name: key
                    position
                    value
                }
                sku: skus{
                    id
                    code
                }
                skus{
                    id
                    code
                }
            }
        }

    }
`;

const GET_SUPPLIERS = gql`
    query($company_id: Int!) {

        suppliers (where: {company_id: {_eq: $company_id}}) {
            id
            name
            company_id
        }

    }
`;

const GET_CATEGORIES = gql`
    query($company_id: Int!) {

        categories (where: {company_id: {_eq: $company_id}}) {
            id
            name
            company_id
            raw_materials
        }

    }
`;


class Product extends React.Component {

    state = {
        companyId: this.props.companyId,
        productId: this.props.productId,
        name: this.props.product.name || '',
        sku: !this.props.product.has_variants && this.props.product.variants.length ? this.props.product.variants[0].skus[0].code : '',
        cost: !this.props.product.has_variants && this.props.product.variants.length ? this.props.product.variants[0].cost : 0.00,
        price: !this.props.product.has_variants && this.props.product.variants.length ? this.props.product.variants[0].price : 0.00,
        autoSKU: true,
        hasVariants: this.props.product.has_variants || false,
        variantOptions: this.props.product.variants || [],
        supplier_id: this.props.product.supplier_id,
        category_id: this.props.product.category_id
    }

    get CREATE_MUTATION() {

        const { name, cost, price, sku, hasVariants, autoSKU, variantOptions, companyId } = this.state;


        return gql`
            mutation add(
                $id: Int,
                $name: String!,
                $company_id: Int!,
                $category_id: Int!,
                $has_variants: Boolean!,
                $raw_material: Boolean!
            ) {
                insert_products(objects: {
                    name: $name
                    variants: {
                        data: ${hasVariants ? `[${variantOptions.filter((v) => v.enabled).map((variant) => {

            const { name, price, cost, sku, attributes } = variant;

            // console.log(variant);

            return `
                                {
                                    skus: {
                                        data: {
                                            ${!autoSKU ? `code: "${sku}",` : ''}
                                        }
                                    },
                                    company_id: $company_id,
                                    name: "${name || ""}",
                                    price: ${parseFloat(price) || 0},
                                    cost: ${parseFloat(cost) || 0},
                                    attributes: {
                                        data: [${attributes.map((attr) => {

                const { name, value } = attr;

                console.log(attr);

                return `{ key: "${name}", value: "${value}" }`

            }).join(', ')}]
                                    }
                                }
                            `;

        }).join(', ')}]` : `
                            {
                                skus: {
                                    data: {
                                        ${!autoSKU ? `code: "${sku}",` : ''}
                                    }
                                },
                                company_id: $company_id,
                                name: "${name}",
                                price: ${price || 0},
                                cost: ${cost || 0}
                            }
                        `}
                    },
                    has_variants: $has_variants,
                    raw_material: $raw_material,
                    company_id: $company_id,
                    category_id: $category_id
                }) {
                    returning {
                        id
                    }
                }
            }
        `;

    }

    variantsRef = React.createRef();

    get UPDATE_MUTATION() {

        return gql`
        
            mutation update(
                $id: Int!,
                $name: String!,
                $company_id: Int,
                $category_id: Int,
                $has_variants: Boolean,
                $raw_material: Boolean
            ) {
                update_products(where: {id: {_eq: $id}}, _set: {
                    name: $name,
                    category_id: $category_id
                }) {
                    affected_rows
                }
            }        
        `;

    }

    render() {

        const { name, companyId, isRawMaterial, hasVariants, cost, price, sku, autoSKU, productId, supplier_id, category_id, variantOptions } = this.state;

        // console.log(productId);

        return (
            <div className="max-w-6xl w-full mx-auto my-12">
                <BackButton appearance="default" marginBottom={15} onClick={() => {

                    this.props.history.push(`/${companyId}/pos/products`);

                }} children="Back to products" />
                <React.Fragment>
                    <div className="max-w-2xl w-full">
                        <p className="text-black text-lg font-bold">{productId ? 'Edit' : 'Create'} Product</p>
                        <div className="mt-4">
                            <TextInputField label="Name" defaultValue={name} onChange={(e) => {

                                this.setState({
                                    name: e.target.value
                                });

                            }} placeholder="Name" />
                        </div>
                        <div className="flex flex-wrap- -mx-2 mb-4">
                            <div className="w-1/2 px-2">
                                <p className="font-medium text-black mb-2">Category</p>

                                <Query
                                    query={GET_CATEGORIES}
                                    variables={{
                                        company_id: parseInt(companyId)
                                    }}
                                // on
                                >
                                    {({ loading, data, error, refetch }) => {

                                        const _categories = keyBy(data ? data.categories : [], 'id');

                                        return (
                                            !loading && data && data.categories ? <Select onChange={(category_id) => {

                                                // console.log(_categories[category_id]);

                                                this.setState({ category_id, isRawMaterial: _categories[category_id].raw_materials })

                                            }} value={category_id || ''} placeholder="Select Category" style={{ width: '100%' }}>
                                                {data.categories.map((category) => {

                                                    return <Select.Option key={category.id} value={category.id}>{category.name}</Select.Option>

                                                })}
                                            </Select> : null
                                        )

                                    }}
                                </Query>

                            </div>
                            {/* <div className="w-1/2 px-2">
                                <p className="font-medium text-black mb-2">Supplier</p>
                                <Query
                                    query={GET_SUPPLIERS}
                                    variables={{
                                        company_id: parseInt(companyId)
                                    }}
                                >
                                    {({loading, data, error, refetch}) => {


                                        return (
                                            !loading && data && data.suppliers ? <Select onChange={(supplier_id) => this.setState({supplier_id})} value={supplier_id || ''} placeholder="Select Supplier" style={{width: '100%'}}>
                                                {data.suppliers.map((supplier) => {

                                                    return <Select.Option key={supplier.id} value={supplier.id}>{supplier.name}</Select.Option>

                                                })}
                                            </Select>: null
                                        )

                                    }}
                                </Query>
                            </div> */}
                        </div>
                        <div className="my-2">
                            <p className="font-medium text-black mb-2">Product Description</p>
                            <Input.TextArea placeholder="Product Description" />
                        </div>
                        {(!productId && !isRawMaterial) ? <div className="my-2">
                            <p className="font-medium text-black mb-2 text-lg">Inventory</p>
                            <Radio.Group defaultValue={hasVariants ? 'b' : "a"} size="large" onChange={(e) => this.setState({ hasVariants: e.target.value === 'b' })}>
                                <Radio.Button value="a">Service Product</Radio.Button>
                                <Radio.Button value="b">Product with Variants</Radio.Button>
                            </Radio.Group>
                            <div className="my-2">
                                <Radio.Group onChange={(e) => this.setState({ autoSKU: e.target.value === 1 })} value={autoSKU ? 1 : 2}>
                                    <Radio value={1}>Automatically Generate a SKU</Radio>
                                    <Radio value={2}>Enter Custom SKU</Radio>
                                </Radio.Group>
                            </div>
                            {!hasVariants && !autoSKU ? <div className="my-2">
                                <TextInputField label="SKU" defaultValue={sku || ''} onChange={(e) => {

                                    this.setState({
                                        sku: e.target.value
                                    });

                                }} placeholder="Enter SKU" />
                            </div> : null}
                        </div> : !hasVariants && !isRawMaterial ? <div className="my-2">
                            <p className="font-medium text-black mb-2 text-lg">Inventory</p>
                            <div className="my-2">
                                <TextInputField label="SKU" disabled defaultValue={sku} onChange={(e) => {

                                    this.setState({
                                        sku: e.target.value
                                    });

                                }} placeholder="Enter SKU" />
                            </div>
                        </div> : null}
                        {!hasVariants ? <div className="my-4">
                            <p className="font-medium text-black mb-2 text-lg">Price</p>
                            <div className="flex items-center flex-wrap -mx-2 my-3">
                                <div className="w-1/2 px-2">
                                    <p className="font-medium text-black mb-2">Supply Price</p>
                                    <CurrencyWrapper
                                        component={({ symbol }) => (
                                            <InputNumber
                                                onClick={(e) => {

                                                    e.target.select();

                                                }}
                                                defaultValue={cost}
                                                style={{ width: '100%' }}
                                                onChange={(cost) => {

                                                    this.setState({
                                                        cost
                                                    });

                                                }}
                                                parser={value => value.replace(/[^0-9.]/g, '')}
                                                formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                size="large"
                                            />
                                        )}
                                    />
                                </div>
                                <div className="w-1/2 px-2">
                                    <p className="font-medium text-black mb-2">Retail Price</p>
                                    <CurrencyWrapper
                                        component={({ symbol }) => (
                                            <InputNumber
                                                onClick={(e) => {

                                                    e.target.select();

                                                }}
                                                defaultValue={price}
                                                style={{ width: '100%' }}
                                                onChange={(price) => {

                                                    this.setState({
                                                        price
                                                    });

                                                }}
                                                parser={value => value.replace(/[^0-9.]/g, '')}
                                                formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                size="large"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div> : null}
                        <div className="mt-6">
                            <Mutation
                                onCompleted={() => {

                                    if (productId) {

                                        this.props.history.push(`/${companyId}/pos/product/${productId}`)

                                    } else {

                                        this.props.history.push(`/${companyId}/pos/products`)

                                    }

                                }}
                                mutation={productId ? this.UPDATE_MUTATION : this.CREATE_MUTATION}
                                variables={{
                                    company_id: `${companyId}`,
                                    // supplier_id,
                                    category_id,
                                    id: productId,
                                    has_variants: isRawMaterial || hasVariants,
                                    raw_material: isRawMaterial,
                                    name
                                }}
                            >
                                {(add, { loading }) => {

                                    return (
                                        <Button
                                            appearance="primary"
                                            intent="success"
                                            isLoading={loading}
                                            onClick={() => {

                                                if (!name) {

                                                    message.error('Please enter a product name');

                                                } else if (hasVariants && !variantOptions.length) {

                                                    message.error('Please add product variants');


                                                } else if (!hasVariants && (!cost || !price)) {

                                                    message.error('Please enter a supply and retail price');


                                                } else if (!category_id) {

                                                    message.error('Please select a product category');


                                                } else {

                                                    add();

                                                }


                                            }}
                                        >
                                            Save
                                        </Button>
                                    )

                                }}
                            </Mutation>
                        </div>
                    </div>
                    {hasVariants ? <div className="my-6">
                        <Variants
                            onChange={(variantOptions) => this.setState({ variantOptions })}
                            variantOptions={[]}
                            variants={productId ? variantOptions : []}
                            autoSKU={autoSKU}
                            productId={productId}
                        />
                    </div> : null}
                </React.Fragment>
            </div>

        );

    }

}

const ProductWrapper = withRouter(Product);

class ProductContainer extends React.Component {

    state = {
        companyId: this.props.match.params.companyId,
        productId: this.props.match.params.productId
    }

    render() {

        const { companyId, productId } = this.state;

        return (

            <Query
                query={GET_PRODUCT}
                skip={(!productId) ? true : false}
                variables={{
                    id: productId
                }}
            >
                {({ loading, data, error, refetch }) => {

                    return (
                        <div className="py-8 overflow-scroll h-full w-full">
                            {loading ? <div className="flex items-center justify-center py-10">
                                <Spinner />
                            </div> : <ProductWrapper companyId={companyId} productId={productId} product={!productId ? { variants: [] } : data.product} />}

                        </div>

                    );

                }}

            </Query>

        )

    }

}

export default ProductContainer;