import React from 'react';
import { Button, Input, InputNumber, Select, message, Modal, Checkbox, Switch, Table } from 'antd';
import { DeleteOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';

import { Dialog, Spinner } from 'evergreen-ui';
import apollo from '../../services/apollo';
import gql from 'graphql-tag';
import { keys, find, sumBy, values, compact, flatten, orderBy, keyBy, map, omit } from 'lodash';
import { currencyFormat } from 'mout/number';
import { Mutation, Query } from 'react-apollo';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import Moment from 'react-moment';
import Receipt from '../../services/components/Receipt';
import { CurrencyFormat, CurrencyWrapper } from '../../services/Currency';
import { fetchAccounts } from '../../utils/accounts';
import Invoice from '../../services/components/Invoice';
import useScanDetection from 'use-scan-detection';
import ProductSearch from './components/ProductSearch';
import { withDatabase } from '@nozbe/watermelondb/DatabaseProvider';
import { Q } from '@nozbe/watermelondb'
import OutletSelector from './components/OutletSelector';
import SalesPersonSelector from './components/SalesPersonSelector';
import CustomerSelector from './components/CustomerSelector';

import { database } from '../../models/Database';
import Proforma from '../../services/components/Proforma';

const GET_OUTLETS = gql`

    query ($company_id: Int!) {
        outlets(where: {
            company_id:{_eq: $company_id},
            _or:[
                {warehouse: {_eq: false}},
                {wholesale: {_eq: true}},
            ]
        }) {
            id
            name
            wholesale
            discount_limit
        }
    }

`;

const paymentModes = {
    'cash': {
        name: 'Cash'
    },
    'change': {
        name: 'Change'
    },
    // 'gift_voucher': {
    //     name: 'Gift Voucher'
    // },
    'momo': {
        name: 'MoMo'
    },
    'card': {
        name: 'Card'
    },
    'credit': {
        name: 'Credit'
    }
};

const Item = ({
    isService,
    productName,
    variantName,
    price,
    qty,
    code,
    onDelete,
    onChange,
    availableQty
}) => {

    return (
        <div className="p-4 border-b border-blue-300">
            <div className="">
                <p className="font-bold ">{productName}</p>
                <p className="text-xs">{variantName} - <CurrencyFormat amount={price} /></p>
                {availableQty ? <a onClick={() => {

                    return false;

                }} href="#" className="text-xs mb-2 block">{availableQty} Available</a> : null}
            </div>
            <div className="flex justify-between">
                <div className="flex items-center">
                    <div className="flex items-center">
                        <InputNumber
                            value={qty}
                            min={1}
                            max={isService ? Infinity : availableQty}
                            step={1}
                            onClick={(e) => e.target.select()}
                            // formatter={value => `${value}  ${v.product.unit.toUpperCase()}`}
                            // parser={value => value.replace(`  ${v.product.unit.toUpperCase()}`, '')}
                            onChange={(_qty) => {

                                onChange(_qty);

                            }}
                        />
                        {/* <div className="">
                            <Button onClick={() => {

                                if (isService || (qty < availableQty)) {
                                    
                                    onChange(qty+1);

                                }

                            }} className="flex items-center justify-center" shape="circle" type="primary" size="small" ghost icon={<PlusOutlined />} />
                        </div>
                        <div className="mx-3 text-lg font-bold">
                            <span className="">
                                {qty || 0}
                            </span>
                        </div>
                        <div className="">
                            <Button onClick={() => {

                                if (qty === 1) {

                                    return ;

                                }

                                onChange(qty-1);


                            }} className="flex items-center justify-center" shape="circle" type="primary" size="small"ghost icon={<MinusOutlined />} />
                        </div> */}
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="font-bold px-4">
                        <CurrencyFormat amount={parseFloat(price) * parseFloat(qty)} />
                    </div>
                    <div className="">
                        <Button onClick={() => {

                            onDelete(code)

                        }} type="danger" size="small" ghost icon={<DeleteOutlined />} className="flex items-center justify-center" shape="circle" />
                    </div>
                </div>
            </div>
        </div>
    );

}

const ScanDetector = ({
    getVariant
}) => {

    useScanDetection({
        onComplete: async (code) => await getVariant(code),
        minLength: 13 // EAN13
    });

    return null;

}

class PaymentMethods extends React.Component {

    render() {

        const { payments, onChange, customer_id, customer_ledger } = this.props;

        return (
            <div className="">
                {keys(paymentModes).filter((p) => customer_id && customer_ledger ? p : p !== 'credit').map((key) => {

                    return (
                        <div key={key} className="mb-3 pb-4 border-b-4">
                            <p className="font-bold text-black">{paymentModes[key].name}</p>
                            {key === 'cash' ? <div className="flex items-center">
                                <div className="flex items-end">

                                    <div className="pr-3" style={{ width: 100 }}>
                                        <p className="font-bold text-black">Currency</p>
                                        <Select
                                            style={{ width: '100%' }}
                                            value={payments[key].currency}
                                            onChange={(currency) => {

                                                payments[key].currency = currency;
                                                payments['change'].currency = currency;

                                                if (currency === 'GHS') {

                                                    payments[key].exchange = 1;
                                                    payments['change'].exchange = 1;

                                                }

                                                onChange(payments);

                                            }}
                                            size="large"
                                        >
                                            <Select.Option value="GHS">GHS</Select.Option>
                                            <Select.Option value="USD">USD</Select.Option>
                                            <Select.Option value="EUR">EUR</Select.Option>
                                            <Select.Option value="GBP">GBP</Select.Option>
                                        </Select>
                                    </div>
                                    <div className="flex-1">
                                        <p className="font-bold text-black">Amount</p>
                                        <InputNumber
                                            disabled={(key === 'change') ? true : false}
                                            onClick={(e) => e.target.select()}
                                            onFocus={(e) => e.target.select()}
                                            value={payments[key] && payments[key].value ? payments[key].value : 0}
                                            size="large"
                                            style={{ width: '100%' }}
                                            onChange={(value) => {

                                                if (payments[key]) payments[key].value = value
                                                else payments[key] = { value }

                                                onChange(payments);

                                            }}
                                        />
                                    </div>
                                </div>
                                {payments[key].currency && payments[key].currency !== 'GHS' ? <div className="pl-4">
                                    <p className="font-bold text-black">Exchange Rate</p>
                                    <InputNumber
                                        // disabled={(orderId && isReturn && __balance === 0) ? true: false}
                                        onClick={(e) => e.target.select()}
                                        onFocus={(e) => e.target.select()}
                                        value={payments[key] && payments[key].exchange ? payments[key].exchange : 0}
                                        size="large"
                                        parser={value => value.replace(/[^0-9.]/g, '')}
                                        onChange={(value) => {

                                            if (payments[key]) {


                                                payments[key].exchange = value;
                                                payments['change'].exchange = value;

                                            }

                                            onChange(payments);

                                        }}
                                    />

                                </div> : null}
                                {payments[key].currency && payments[key].currency !== 'GHS' ? <div className="pl-4">
                                    <p className="font-bold text-black">Value</p>
                                    <p className="">GHS {(parseFloat(payments[key].value) * parseFloat(payments[key].exchange)).toFixed(2)}</p>
                                </div> : null}
                            </div> : <div className="">
                                {/* <p className="font-bold text-black">{paymentModes[key].name}</p> */}
                                <CurrencyWrapper
                                    component={({ symbol }) => (
                                        <InputNumber
                                            disabled={(key === 'change') ? true : false}
                                            onClick={(e) => e.target.select()}
                                            onFocus={(e) => e.target.select()}
                                            value={payments[key] && payments[key].value ? payments[key].value : 0}
                                            size="large"
                                            style={{ width: '100%' }}
                                            formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/[^0-9.]/g, '')}
                                            onChange={(value) => {

                                                if (payments[key]) payments[key].value = value
                                                else payments[key] = { value }

                                                onChange(payments);

                                            }}
                                        />
                                    )}
                                />
                            </div>}
                        </div>
                    );

                })}
            </div>
        )

    }

}

const GET_SALESPERSONS = gql`
    query($company_id: Int!) {

        company: company_by_pk(id: $company_id){
            users(where: {role:{_eq: "pos-sales-exec"}}){
              user{
                id
                display_name
              }
            }
        }

    }
`;

const GET_CUSTOMERS = gql`

    query (
        $q: String!,
        $company_id: Int!
    ) {

        customers(where:{
            company_id: {_eq: $company_id},
            name: {_ilike: $q}
        }){
            id
            name
            ledger_id
        }

    }

`;

const GET_VARIANT_BY_CODE = gql`
    query (
        $code: String!,
        $outlet_id: Int!,
        $company_id: Int!
    ) {
        products_sku(where: {
            code: {_eq: $code},
            variant:{
                product:{
                    archived: {_is_null: true},
                    company_id: {
                        _eq: $company_id
                    }
                }
            }
        }) {
            code
            id
            variant {
              id
              cost
              price
              name
              product {
                id
                name
                supplier {
                  name
                }
              }
              prices: inventory(where: {outlet_id: {_eq: $outlet_id}}){
                id
                price
                cost
              }
              exists: inventory_aggregate(where: {outlet_id: {_eq: $outlet_id}}, distinct_on: outlet_id) {
                    aggregate{
                        count(columns: outlet_id)
                    }
              }
              inventory_i: inventory(where: {outlet_id: {_eq: $outlet_id}}) {
                id
                price
                cost
            }
              inventory: inventory_aggregate(where: {outlet_id: {_eq: $outlet_id}}) {
                aggregate {
                  sum {
                    qty
                  }
                }
              }
            }
        }
    }
  
`;

const GET_VARIANT = gql`
    query (
        $code: String!,
        $q: String!,
        $outlet_id: Int!
        $company_id: Int!
    ) {
        products_sku(where: {
            _or: [
                {code: {_eq: $code}},
                {variant: {
                    name: {_ilike: $q},
                }},
                {variant: {
                    product: {
                        name: {_ilike: $q}
                    }
                }}
            ],
            variant:{
                product:{
                    archived: {_is_null: true},
                    company_id: {
                        _eq: $company_id
                    }
                }
            }
        }) {
            id
            variant {
              id
              cost
              name
              price
              product {
                name
                has_variants
                supplier {
                  name
                }
              }
              prices: inventory(where: {outlet_id: {_eq: $outlet_id}}){
                id
                price
                cost
              }
              inventory: inventory_aggregate(where: {outlet_id: {_eq: $outlet_id}}) {
                aggregate {
                  sum {
                    qty
                  }
                }
              }
            }
        }
    }
  
`;

const SEARCH_VARIANT = gql`
    query (
        $q: String!,
        $outlet_id: Int!
        $company_id: Int!
    ) {
        products_sku(where: {
            _or: [
                {variant: {
                    name: {_ilike: $q},
                }},
                {variant: {
                    product: {
                        name: {_ilike: $q}
                    }
                }}
            ],
            variant:{
                product:{
                    archived: {_is_null: true},
                    company_id: {
                        _eq: $company_id
                    }
                }
            }
        }) {
            id
            variant {
              id
              cost
              name
              price
              product {
                name
                has_variants
                supplier {
                  name
                }
              }
              prices: inventory(where: {outlet_id: {_eq: $outlet_id}}){
                id
                price
                cost
              }
              inventory: inventory_aggregate(where: {outlet_id: {_eq: $outlet_id}}) {
                aggregate {
                  sum {
                    qty
                  }
                }
              }
            }
        }
    }
  
`;

const HOSPITALITY = 'hospitality';

class ParkedItem extends React.Component {

    calcSum = (items) => {

        return sumBy(items, (i) => i.variant.price * i.qty);

    }

    render() {

        const { item, unpark, recover } = this.props;

        return (
            <div onClick={() => recover(item)} className="bg-white rounded-lg shadow p-4 relative cursor-pointer">
                {/* <button className="focus:outline-none font-bold text-black text-xl absolute right-0 top-0 mt-1 mr-3" onClick={() => unpark(item.key)}>x</button> */}
                <div className="absolute right-0 top-0 mt-2 mr-3">

                    <Button
                        className="flex items-center justify-center"
                        icon={<DeleteOutlined />}
                        ghost
                        type="danger"
                        shape="circle"
                        size="small"
                        onClick={(e) => {

                            e.stopPropagation();

                            unpark(item.key, true);

                        }}
                    />

                </div>
                <div className="mb-4">
                    <h1 className="text-lg font-bold">{item.name}</h1>
                    <h1 className="text-md">{item.contact}</h1>
                </div>
                <p className="">
                    {sumBy(item.items, 'qty')} Items
                </p>
                <p className="font-bold text-black mb-2">
                    <CurrencyFormat amount={this.calcSum(item.items)} />
                </p>
                {/* {item.created_at ? <p className="text-xs">{moment(item.created_at).fromNow()}</p>: null} */}
                {item.created_at ? <Moment className="text-xs" fromNow>{item.created_at}</Moment> : null}
            </div>
        )

    }

}

class SalesInner extends React.Component {

    inputRef = React.createRef()
    customersRef = React.createRef();

    state = {
        isSearch: true,
        companyId: this.props.match.params.companyId,
        outlet_id: this.props.outlet_id,
        showPayments: false,
        new_items: [],
        items: [],
        return_items: [],
        searchValue: '',
        comment: '',
        parked: [],
        orderId: null,
        order: null,
        receipt: null,
        printLayout: 'receipt',
        payments: {
            cash: {
                value: 0,
                currency: 'GHS',
                exchange: 1
            },
            change: {
                value: 0,
                currency: 'GHS',
                exchange: 1
            },
            gift_voucher: {
                value: 0
            },
            momo: {
                value: 0
            },
            card: {
                value: 0
            },
            credit: {
                currency: 'GHS',
                exchange: 1,
                value: 0
            }
        }
    };

    clear = () => {

        this.setState({
            showPayments: false,
            new_items: [],
            items: [],
            return_items: [],
            comment: '',
            orderId: null,
            order: null,
            payments: {
                change: {
                    value: 0,
                    currency: 'GHS',
                    exchange: 1
                },
                credit: {
                    currency: 'GHS',
                    exchange: 1,
                    value: 0
                },
                cash: {
                    value: 0,
                    currency: 'GHS',
                    exchange: 1
                },
                gift_voucher: {
                    value: 0
                },
                momo: {
                    value: 0
                },
                card: {
                    value: 0
                }
            }
        });

    }

    refinePrices = (i) => {

        const { variant } = i;
        const { prices } = variant;

        variant.price = prices.length ? prices[0].price : variant.price;
        variant.cost = prices.length ? prices[0].cost : variant.cost;

        return {
            ...i,
            variant
        }

    };

    getVariant = async (_code, _qty) => {

        const { items, companyId, isWholesale } = this.state;
        let { outlet_id, database } = this.props;

        try {


            const q = await database.collections.get('products_sku').query(
                Q.where('code', Q.eq(`${_code}`)),
                Q.where('company_id', Q.eq(parseInt(companyId)))
            ).fetch();

            if (q.length) {

                const variant = await database.collections.get('products_variants').find(`${q[0].variantId}`);
                const inventory = await database.collections.get('products_inventory').query(
                    Q.where('company_id', Q.eq(parseInt(companyId))),
                    Q.where('outlet_id', Q.eq(parseInt(outlet_id))),
                    Q.where('variant_id', Q.eq(parseInt(variant.id)))
                ).fetch();
                const product = await database.collections.get('products').find(`${variant.productId}`);

                // console.log('variant:', variant, inventory, product);

                const code = variant.id;

                const __qty = sumBy(inventory, 'qty');

                // console.log(`__qty: ${__qty}`);

                const _item = this.refinePrices({
                    variant: {
                        id: parseInt(variant.id),
                        cost: variant.cost,
                        name: variant.name,
                        price: variant.price,
                        product: {
                            name: product.name,
                            has_variants: product.hasVariants,
                            raw_material: product.rawMaterial,
                        },
                        prices: inventory.map((p) => ({
                            id: parseInt(p.id),
                            price: p.price,
                            cost: p.cost
                        })),
                        inventory: {
                            aggregate: {
                                sum: {
                                    qty: __qty
                                }
                            }
                        }
                    }
                });

                if (_item.variant.product.has_variants && !_item.variant.inventory.aggregate.sum.qty) {

                    Modal.error({
                        title: 'Out of Stock',
                        content: 'This item is either unavailable or out of stock at this location',
                    });

                    return;

                }


                const item = find(items, { code: `${code}` });

                console.log(_item, item);


                if (!item) {

                    // items.push({
                    //     ..._item,
                    //     qty: 1,
                    //     code
                    // });

                    if (isWholesale) {

                        items.unshift({
                            loading: true,
                            price: 0.00,
                            qty: 1,
                            updatedAt: Date.now(),
                            ...{
                                ..._item,
                                variant: {
                                    ..._item.variant,
                                    price: _item.variant.cost,
                                    _price: _item.variant.price
                                }
                            },
                            code: `${_item.variant.id}`,
                        });

                    } else {

                        items.unshift({
                            loading: true,
                            price: 0.00,
                            qty: 1,
                            updatedAt: Date.now(),
                            ..._item,
                            code: `${_item.variant.id}`,

                        });

                    }

                } else {

                    item.qty++;

                }

                this.setState({
                    searchLoading: false,
                    searchValue: '',
                    items
                });

            } else {

                message.error('No Product found with that bacode. Please try again');

                this.setState({
                    searchLoading: false,
                    searchValue: ''
                });

            }


        } catch (e) {

            console.log(e);

            message.error('An error occurred while trying to retrive product. Please try again.');

            this.setState({
                searchLoading: false,
                searchValue: ''
            });

        }

    }

    fetchVariant = async (value) => {

        const { isWholesale } = this.state;

        const _item = this.refinePrices(value);

        if (_item.variant.product.has_variants && !_item.variant.inventory.aggregate.sum.qty) {

            Modal.error({
                title: 'Out of Stock',
                content: 'This item is either unavailable or out of stock at this location',
            });

            return;

        }

        const { items } = this.state;

        const item = find(items, { code: `${_item.variant.id}` });


        if (item) {

            item.qty++;

            this.setState({
                items,
                searchValue: ''
            });

            return;

        } else {

            if (isWholesale) {

                items.unshift({
                    loading: true,
                    price: 0.00,
                    qty: 1,
                    code: `${_item.variant.id}`,
                    updatedAt: Date.now(),
                    ...{
                        ..._item,
                        variant: {
                            ..._item.variant,
                            price: _item.variant.cost,
                            _price: _item.variant.price
                        }
                    },

                });

            } else {

                items.unshift({
                    loading: true,
                    price: 0.00,
                    qty: 1,
                    code: `${_item.variant.id}`,
                    updatedAt: Date.now(),
                    ..._item

                });

            }

            this.setState({
                loading: false,
                items,
                searchValue: ''
            });


        }

    }

    getTLevy = (i) => {

        const { company } = this.props;
        const {
            tax_type,
            tourism_levy
        } = company;

        const subtotal = (parseFloat(i.variant.price) * parseFloat(i.qty));

        return tax_type === HOSPITALITY ? ((tourism_levy) / 100) * subtotal : 0

    }

    getVAT = (i) => {

        const { company } = this.props;
        const {
            tax_option,
            vat,
        } = company;

        const subtotal = (parseFloat(i.variant.price) * parseFloat(i.qty));

        const isInclusive = tax_option === 'inclusive';

        return isInclusive ? (vat / (100 + vat)) * subtotal : ((vat) / 100) * subtotal

    }

    getNHIL = (i) => {

        const { company } = this.props;
        const {
            nhil,
            flat_rate
        } = company;

        const subtotal = (parseFloat(i.variant.price) * parseFloat(i.qty));

        return !flat_rate ? ((nhil) / 100) * subtotal : 0;

    }

    getTaxAmt = (i) => {

        const { company } = this.props;
        const {
            tax_type,
            tax_option,
            flat_rate
        } = company;

        const isExclusive = tax_option === 'exclusive';

        const tourism = tax_type === HOSPITALITY ? this.getTLevy(i) : 0;

        return (isExclusive ? (flat_rate ? this.getVAT(i) : (this.getVAT(i) + this.getNHIL(i))) + tourism : 0);

    }

    getVATAmt = (i) => {

        const { company } = this.props;
        const {
            tax_type,
            tax_option,
            flat_rate
        } = company;

        const isExclusive = tax_option === 'exclusive';

        return isExclusive ? flat_rate ? (this.getVAT(i) + this.getNHIL(i)) : this.getVAT(i) : 0;

    }

    getTotal = (i) => {

        const { company } = this.props;

        const subtotal = this.getSubTotal(i);

        if (company.discount_before_tax) {

            return subtotal + this.getTaxAmt(i);

        } else {

            return (subtotal + this.getTaxAmt(i)) - this.itemDiscount(i);

        }

    }

    get DISCOUNT_VALUE() {

        const { discount } = this.state;
        const { company } = this.props

        const subtotal = this.SUBTOTAL_FN();
        const total = this.TOTAL_FN();

        return discount ? !discount.fixed ? (discount.value / 100) * (company.discount_before_tax ? subtotal : total) : discount.value : 0;

    }

    itemDiscount = (i) => {

        const { discount } = this.state;
        const subtotal = this.SUBTOTAL_FN();
        // const total = this.TOTAL_RAW;

        const discount_value = this.DISCOUNT_VALUE;

        const _subtotal = (parseFloat(i.variant.price) * parseFloat(i.qty));

        return discount_value > 0 ? discount.fixed ? (_subtotal / subtotal) * discount_value : (discount.value / 100) * _subtotal : 0;

    }


    get NHIL_RAW() {

        return sumBy(this.state.items, this.getNHIL);

    }

    get TLEVY_RAW() {

        return sumBy(this.state.items, this.getTLevy);

    }

    get TOTAL_TAX_RAW() {

        return sumBy(this.state.items, this.getTaxAmt);

    }

    get TOTAL_VAT_RAW() {

        return sumBy(this.state.items, this.getVAT);

    }

    get TOTAL_TAX() {

        return currencyFormat(this.TOTAL_TAX_RAW);

    }

    get TOTAL_RAW() {

        return parseFloat(sumBy(this.state.items, this.getTotal).toFixed(2));

    }

    get TOTAL() {

        return currencyFormat(this.TOTAL_RAW);

    }

    get SUBTOTAL_RAW() {

        return sumBy(this.state.items, this.getSubTotal);

    }

    SUBTOTAL_FN = () => {

        return sumBy(this.state.items, (i) => parseFloat(i.variant.price) * parseFloat(i.qty));

    }

    TOTAL_FN = () => {

        return sumBy(this.state.items, (i) => {

            return (parseFloat(i.variant.price) * parseFloat(i.qty)) + this.getTaxAmt(i);

        });

    }

    getSubTotal = (i) => {

        const { company } = this.props;

        const discount = this.itemDiscount(i);

        const subtotal = parseFloat(i.variant.price) * parseFloat(i.qty);

        // console.log(subtotal, discount, parseFloat(subtotal));

        if (company.discount_before_tax) {

            return parseFloat(subtotal - discount);

        } else {

            return parseFloat(subtotal);

        }

    }

    get SUBTOTAL() {

        return currencyFormat(this.SUBTOTAL_RAW);

    }

    get TOTAL_PAYMENTS_RAW() {

        return sumBy(values(omit(this.state.payments, 'change')), (p) => {

            return (parseFloat(p.value) * parseFloat(p.exchange || 1))

        });

    }

    get canProceed() {

        return this.TOTAL_RAW > 0;

    }

    get SAVE_MUTATION() {

        const { items } = this.state;

        console.log('items:', items);

        return gql`
            mutation(
                $createdAt: timestamptz!,
                $local_id: uuid!,
                $tax_amt: float8!,
                $total_qty: Int!,
                $total_amount: float8!,
                $company_id: Int!,
                $destination_id: Int,
                $employee_id: Int!,
                $source_id: Int!,
                $items:  [products_history_items_insert_input!]!,
                $comment: String,
                $salesperson_id: Int!,
                $subtotal: float8,
                $supervisor_id: Int!,
                $customer_id: Int,
                $total_amt: float8,
                $payments: [order_payments_insert_input!]!,
                $order_items: [order_items_insert_input!]!,
                $discount_amt: float8!,
                $discount_id: Int,
                $transactions: [ledger_transactions_insert_input!]!,
                $is_wholesale: Boolean
            ) {
                ${items.map((i, _i) => (i.variant.product.has_variants ? `
                    update_products_inventory_${_i}: update_products_inventory(where: {
                        outlet_id: {_eq: $source_id},
                        variant_id: {_eq: ${i.variant.id}}
                    }, _inc: {qty: -${(i.qty || 0)}}) {
                        affected_rows
                    }
                `: ``))}
                insert_products_history(objects: {
                    items: {data: $items},
                    company_id: $company_id,
                    destination_id: $destination_id,
                    employee_id: $employee_id,
                    source_id: $source_id,
                    total_amount: $total_amount,
                    total_qty: $total_qty,
                    type: "SALE"
                }) {
                    affected_rows
                }
                ledgers: insert_ledger_transactions(objects: $transactions) {
                    affected_rows
                }
                order: insert_orders(objects: {
                    created_at: $createdAt,
                    local_id: $local_id,
                    comment: $comment,
                    company_id: $company_id,
                    customer_id: $customer_id,
                    tax_amt: $tax_amt,
                    outlet_id: $source_id,
                    salesperson_id: $salesperson_id,
                    subtotal: $subtotal,
                    supervisor_id: $supervisor_id,
                    total_amt: $total_amt,
                    total_qty: $total_qty,
                    discount_amt: $discount_amt,
                    discount_id: $discount_id,
                    is_wholesale: $is_wholesale,
                    payments: {
                        data: $payments
                    },
                    items: {
                        data: $order_items
                    }
                }) {
                    returning {
                        id
                        subtotal
                        total_amt
                        total_qty
                        tax_amt
                        discount_amt
                        created_at
                        is_wholesale
                        outlet {
                            id
                          name
                          phone
                        }
                        salesperson {
                          display_name
                        }
                        supervisor {
                          display_name
                        }
                        customer{
                            id
                            name
                        }
                        discount{
                            id
                            value,
                            type
                        }
                        items {
                          price
                          qty
                          subtotal
                          tax_amt
                          total
                          discount_amt
                          variant {
                            name
                            product {
                              name
                            }
                          }
                        }
                        payments {
                          exchange
                          currency
                          amount
                          title
                          type
                        }
                    }
                }
            }
        `

    }

    parkSale = ({
        name,
        contact
    }) => {

        let { user, account, company, outlet_id } = this.props;
        const { items } = this.state;

        if (!items.length) return;

        localStorage.setItem(`gomerp_park_sale_${account.id}_${company.id}_${outlet_id}_${user.id}_${uuid()}`, JSON.stringify({
            items,
            name,
            contact,
            created_at: Date.now()
        }));

        this.updateParked();

        this.setState({
            saveParked: false,
            parkedContact: null,
            parkedName: null
        })

        this.clear();

    }

    unpark = (key, ask = false) => {

        if (ask) {

            Modal.confirm({
                title: 'Delete Parked Sale',
                content: 'Are you sure you want to delete this parked sale?',
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                onOk: () => {

                    this._unpark(key);

                },
                onCancel() {
                    console.log('Cancel');
                },
            });

        } else {

            this._unpark(key);

        }

    }

    _unpark = (key) => {

        localStorage.removeItem(key);

        this.updateParked();

    }

    displayParked = (item) => {

        this.setState({
            showParkedDetails: true,
            tempParked: item
        });

    }

    recover = (item) => {

        const { items } = this.state;

        if (items.length) {

            Modal.confirm({
                title: 'Park your exising order',
                content: 'Kindly park your existing order order before recovering a parked order.',
                onOk: () => {

                    // this.clear();

                    // this.setState({
                    //     items: item.items
                    // }, () => this.unpark(item.key))

                },
                onCancel() {
                    console.log('Cancel');
                },
            });

        } else {

            this.clear();

            this.setState({
                items: item.items
            }, () => this.unpark(item.key))

        }

    }

    updateParked = () => {

        const keys = [];

        for (const key in localStorage) {
            if (localStorage.hasOwnProperty(key)) {

                if (key.includes('gomerp_park_sale')) {

                    keys.push(key);

                }


            }
        }

        this.setState({
            parked: orderBy(keys.map((key) => ({
                ...(JSON.parse(localStorage.getItem(key))),
                key
            })), ['created_at'], ['desc'])
        });

    }

    componentDidMount() {

        this.updateParked();

    }

    applyCode = async (code) => {


        const { company, user, outlet_id, outlet, database } = this.props;
        const { discountLoading } = this.state;
        const user_id = user.id;


        if (!code || discountLoading) return;

        console.log(outlet);


        const customValue = parseFloat(code);

        if (customValue !== NaN && customValue <= outlet.discount_limit) {


            this.setState({
                discountCode: '',
                discount: {
                    id: null,
                    code,
                    fixed: true,
                    custom: true,
                    value: customValue
                }
            });


            return;

        }


        this.setState({ discountLoading: true });

        try {

            const q = await database.collections.get('discounts').query(
                Q.where('code', Q.eq(`${code}`))
            ).fetch();

            if (q.length) {


                const { id, code, type, value, employee_id, outlet_id, active, orders, one_time } = q[0]._raw;

                if (
                    !active
                ) {

                    alert('This discount code is not active. Please contact your systems Administrator');


                } else if (
                    outlet_id && outlet_id !== outlet_id
                ) {

                    alert('This discount code cannot be applied at this outlet. Please contact your systems Administrator');

                } else if (
                    employee_id && employee_id !== parseInt(user_id)
                ) {

                    alert('This discount code cannot be applied by you. Please contact your systems Administrator');


                }

                // else if (
                //     one_time && orders && orders.length
                // ) {

                //     alert('This discount code has already been used. Please contact your systems Administrator');


                // } 

                else {

                    // apply code

                    this.setState({
                        discountCode: '',
                        discount: {
                            id,
                            code,
                            fixed: type === 'fixed',
                            value
                        }
                    });


                }


            } else {

                alert('Unable to apply discount code.');

            }

            this.setState({ discountLoading: false });


        } catch (e) {

            this.setState({ discountLoading: false });

            alert('An error occurred while trying to apply discount code.');


        }




    }

    save = async (mutation, variables) => {

        const id = uuid();
        const { items, isWholesale } = this.state;
        const { company } = this.props;

        variables.local_id = id;

        // console.log(await database.collections.get('users').query().fetch())

        const outlet = await database.collections.get('outlets').find(`${variables.source_id}`);
        const salesperson = await database.collections.get('users').find(`${company.id}_${variables.salesperson_id}`);
        const supervisor = await database.collections.get('users').find(`${company.id}_${variables.supervisor_id}`);
        const variants = keyBy(await database.collections.get('products_variants').query(
            Q.where('id', Q.oneOf(map(variables.order_items, 'variant_id').map((id) => `${id}`)))
        ).fetch(), 'id');

        let customer;
        let discount;

        if (variables.customer_id) {

            customer = await database.collections.get('customers').find(`${variables.customer_id}`);

        }

        if (variables.discount_id) {

            discount = await database.collections.get('discounts').find(`${variables.discount_id}`);

        }

        const order = {
            id: id,
            subtotal: this.SUBTOTAL_RAW,
            total_amt: this.TOTAL_RAW,
            total_qty: sumBy(items, 'qty'),
            tax_amt: this.TOTAL_TAX_RAW,
            discount_amt: this.DISCOUNT_VALUE,
            created_at: Date.now(),
            is_wholesale: isWholesale,
            outlet: {
                id: outlet.id,
                name: outlet.name,
                phone: outlet.phone
            },
            salesperson: {
                display_name: salesperson.displayName
            },
            supervisor: {
                display_name: supervisor.displayName
            },
            customer: variables.customer_id ? {
                id: customer.id,
                name: customer.name
            } : null,
            discount: variables.discount_id ? {
                id: discount.id,
                value: discount.value,
                type: discount.type
            } : null,
            items: variables.order_items.map((i) => ({
                price: i.price,
                qty: i.qty,
                subtotal: i.subtotal,
                tax_amt: i.tax_amt,
                total: i.total,
                discount_amt: i.discount_amt,
                variant: {
                    name: variants[i.variant_id].name
                }
            })),
            payments: variables.payments.map((p) => ({
                exchange: p.exchange,
                currency: p.currency,
                amount: p.amount,
                title: p.title,
                type: p.type
            }))
        };

        const receipt = {
            local: true,
            id: order.id,
            companyName: company.name,
            companyPhone: company.phone,
            companyLogo: company.logo,
            companyEmail: company.email,
            date: moment(order.created_at).format('DD/MM/YYYY [a]t HH:mm A'),
            shop: order.outlet.name,
            is_wholesale: order.is_wholesale,
            salesperson: order.salesperson.display_name,
            supervisor: order.supervisor.display_name,
            phone: order.outlet.phone,
            reg_no: company.file_number,
            items: order.items.map((i) => ({
                name: `${i.variant.name}`,
                price: i.price,
                qty: i.qty
            })),
            customer: order.customer,
            total: order.total_amt,
            discount_amt: order.discount_amt,
            discount: order.discount,
            amt_due: order.total_amt,
            tax: order.tax_amt,
            paid: order.total_amt,
            // change: (this.TOTAL_PAYMENTS_RAW - this.TOTAL_RAW),
            change: 0.00,
            payments: order.payments.map(({ title, currency, amount }) => ({
                title,
                currency,
                amount
            }))
        };

        this.setState({
            receipt,
            confirmLoading: false
        }, () => this.clear());

        setTimeout(() => window.print(), 400);

        this.queueMutation(id, mutation, variables)

    }

    queueMutation = async (id, mutation, variables) => {

        const KEY = `gomerp.mutations.${id}`;

        try {

            await apollo.mutate({
                mutation,
                variables
            });

            this.setState({
                confirmLoading: false
            });

            localStorage.removeItem(KEY);

        } catch (e) {

            localStorage.setItem(KEY, JSON.stringify({ id, variables, mutation }));

            this.setState({
                confirmLoading: false
            });

            console.log(e);

        }


    }

    generateProforma = () => {

        const { items, isWholesale, discount } = this.state;
        let { company, outlet } = this.props;

        const proforma = {
            local: true,
            // id: order.id,
            companyName: company.name,
            companyPhone: company.phone,
            companyLogo: company.logo,
            companyEmail: company.email,
            date: moment().format('DD/MM/YYYY [a]t HH:mm A'),
            shop: outlet.name,
            is_wholesale: isWholesale,
            // salesperson: order.salesperson.display_name,
            // supervisor: order.supervisor.display_name,
            phone: outlet.phone,
            reg_no: company.file_number,
            items: items.map((i) => ({
                name: `${i.variant.name}`,
                price: i.variant.price,
                qty: i.qty
            })),
            customer: null,
            total: this.TOTAL_RAW,
            discount_amt: this.DISCOUNT_VALUE,
            discount,
            amt_due: this.TOTAL_RAW,
            tax: this.TOTAL_TAX_RAW,
            paid: 0,
            // change: (this.TOTAL_PAYMENTS_RAW - this.TOTAL_RAW),
            change: 0.00,
            payments: []
        };

        console.log(proforma);

        setTimeout(() => window.print(), 400);

        this.setState({
            proforma,
            printLayout: 'invoice'
        }, () => this.clear());

    }

    render() {

        let {
            companyId,
            orderId,
            order,
            searchLoading,
            receipt,
            proforma,
            showPayments,
            payments,
            parked,
            searchValue,
            items,
            comment,
            discount,
            discountCode,
            discountLoading,
            isSearch,
            isWholesale,
            salesperson_id,
            printLayout,
            customerSearch,
            customer_name,
            customer_id,
            customer_ledger,
            saveParked,
            parkedName,
            parkedContact,
            showParkedDetails,
            tempParked,
            confirmLoading
        } = this.state;

        let { user, account, outlet_id, company, outlet } = this.props;

        const {
            tax_type,
            tax_option,
            vat,
            nhil,
            tourism_levy,
            flat_rate
        } = company;

        const company_id = company.id;
        const user_id = user.id;

        const accounts = fetchAccounts(company.ledgers);

        const variables = {
            createdAt: new Date().toISOString(),
            company_id: companyId,
            destination_id: null,
            employee_id: user.id,
            source_id: outlet_id,
            is_wholesale: isWholesale,
            items: items.map((i) => ({ price: i.variant.price, qty: i.qty, variant_id: i.variant.id })),
            total_amount: this.TOTAL_RAW,
            total_qty: sumBy(items, 'qty'),
            comment,
            salesperson_id,
            customer_id,
            subtotal: this.SUBTOTAL_RAW,
            supervisor_id: user.id,
            total_amt: this.TOTAL_RAW,
            tax_amt: this.TOTAL_TAX_RAW,
            discount_id: discount && discount.id ? discount.id : null,
            discount_amt: this.DISCOUNT_VALUE,
            transactions: compact([{
                accounts: accounts['sales'].accounts,
                company_id,
                credit: tax_option === 'exclusive' ? this.SUBTOTAL_RAW + this.TOTAL_TAX_RAW : this.SUBTOTAL_RAW,
                // date: "",
                debit: 0,
                description: "SALE",
                ledger_id: accounts['sales'].id,
                user_id
            }, {
                accounts: accounts['sales'].accounts,
                company_id,
                credit: 0,
                // date: "",
                debit: this.TOTAL_TAX_RAW,
                description: "Taxes",
                ledger_id: accounts['sales'].id,
                user_id
            }, {
                accounts: accounts['sales'].accounts,
                company_id,
                credit: 0,
                // date: "",
                debit: this.TLEVY_RAW,
                description: "Tourism Levy",
                ledger_id: accounts['sales'].id,
                user_id
            }, {
                accounts: accounts['sales'].accounts,
                company_id,
                credit: 0,
                // date: "",
                debit: this.DISCOUNT_VALUE,
                description: "Discount",
                ledger_id: accounts['sales'].id,
                user_id
            }, {
                accounts: accounts['deferred-vat'].accounts,
                company_id,
                credit: this.TOTAL_VAT_RAW,
                // date: "",
                debit: 0,
                description: "SALES",
                ledger_id: accounts['deferred-vat'].id,
                user_id
            }, {
                accounts: accounts['deferred-tourism-levy'].accounts,
                company_id,
                credit: this.TLEVY_RAW,
                // date: "",
                debit: 0,
                description: "SALES",
                ledger_id: accounts['deferred-tourism-levy'].id,
                user_id
            }, {
                accounts: accounts['discounts'].accounts,
                company_id,
                credit: this.DISCOUNT_VALUE,
                // date: "",
                debit: 0,
                description: "SALES",
                ledger_id: accounts['discounts'].id,
                user_id
            }, customer_ledger ? {
                accounts: [...accounts['trade-receivables'].accounts, customer_ledger],
                company_id,
                credit: 0,
                // date: "",
                debit: this.TOTAL_RAW,
                description: "SALES",
                ledger_id: customer_ledger,
                user_id
            } : null, ...flatten(compact(keys(payments).filter((p) => p !== 'credit').map((p) => {

                const _p = p === 'change' ? 'cash' : p;

                // console.log(accounts, p, payments);

                // return accounts[p] ? {
                //     accounts: [...accounts[p].accounts],
                //     company_id,
                //     credit: 0,
                //     // date: "",
                //     debit: p === 'cash' ? payments[p].value * payments[p].exchange : payments[p].value,
                //     description: "SALES",
                //     ledger_id: accounts[p].id,
                //     user_id
                // }: null

                return payments[p].value > 0 && customer_ledger ? [{
                    accounts: [...accounts['trade-receivables'].accounts, customer_ledger],
                    company_id,
                    credit: payments[p].value,
                    // date: "",
                    debit: 0,
                    description: "PAYMENT",
                    ledger_id: customer_ledger,
                    user_id
                }, {
                    accounts: [...accounts[_p].accounts],
                    company_id,
                    credit: 0,
                    // date: "",
                    debit: payments[p].value,
                    description: "SALES",
                    ledger_id: accounts[_p].id,
                    user_id
                }] : p === 'change' || payments[p].value > 0 ? [
                    {
                        accounts: [...accounts[_p].accounts],
                        company_id,
                        credit: 0,
                        // date: "",
                        debit: payments[p].value,
                        description: "SALES",
                        ledger_id: accounts[_p].id,
                        user_id
                    }
                ] : [];

            })))]),
            payments: compact(keys(payments).map((p) => p === 'change' || payments[p].value > 0 ? ({
                amount: p === 'cash' || p === 'change' ? payments[p].value * payments[p].exchange : payments[p].value,
                currency: p === 'cash' || p === 'change' ? payments[p].currency : null,
                exchange: p === 'cash' || p === 'change' ? payments[p].exchange : null,
                title: paymentModes[p].name,
                type: p === 'change' ? 'cash' : p,
                value: payments[p].value,
                outlet_id: outlet_id,
                company_id: companyId,
                employee_id: user.id,
            }) : null)),
            order_items: items.map((i) => ({
                employee_id: user.id,
                discount_amt: this.itemDiscount(i),
                tax_amt: this.getTaxAmt(i),
                tax_inclusive: tax_option === 'inclusive',
                tax_flat_rate: flat_rate,
                tax_tourism_levy: this.getTLevy(i),
                tax_vat: this.getVAT(i),
                tax_nhil: this.getNHIL(i),
                price: i.variant.price,
                qty: i.qty,
                subtotal: i.variant.price * i.qty,
                total: this.getTotal(i),
                variant_id: i.variant.id
            }))
        };

        const mutation = this.SAVE_MUTATION;

        // console.log(user, account);

        const itemsCount = items.length;
        // const subTotal = sumBy(items, (i) => );
        // const _items = values(items);

        // console.log(payments, this.SUBTOTAL_RAW, this.TOTAL_PAYMENTS_RAW);

        // console.log(this.SUBTOTAL);

        // const change = this.TOTAL_PAYMENTS_RAW - this.TOTAL_RAW;

        return (
            <React.Fragment>
                <ScanDetector getVariant={this.getVariant} />
                <div className="print-only">
                    {proforma ? <Proforma {...proforma} /> : receipt && printLayout === 'receipt' ? <Receipt
                        {...receipt}
                    /> : receipt && printLayout === 'invoice' ? <Invoice {...receipt} /> : null}
                </div>
                <div className="print-hidden flex justify-between h-full">
                    <div className="flex-1 bg-blue-100 h-full p-10 overflow-scroll">
                        <div className="flex justify-between -mx-4">
                            <div className="w-1/4 p-4">
                                {/* <div className="bg-white rounded p-3 shadow border border-blue-500"> */}
                                {/* <p className="text-center">
                                        Scan Barcode
                                    </p> */}
                                {/* </div> */}
                            </div>
                            {/* <div className="w-1/4 p-4">
                                <div className="bg-white rounded p-3 shadow">
                                    <p className="text-center">
                                        Search Product & Service
                                    </p>
                                </div>
                            </div>
                            <div className="w-1/4 p-4">
                                <div className="bg-white rounded p-3 shadow">
                                    <p className="text-center">
                                        Retrieve Order
                                    </p>
                                </div>
                            </div> */}
                            <div className="w-1/4 p-4">
                                <button onClick={() => {

                                    const { items } = this.state;

                                    if (!items.length) return;

                                    this.setState({
                                        saveParked: true
                                    });

                                }} className="focus:outline-none w-full bg-blue-500 text-white rounded p-3 shadow">
                                    <p className="text-center">
                                        Park
                                    </p>
                                </button>
                            </div>
                            <Modal
                                visible={showParkedDetails}
                                title="Preview"
                                onOk={() => {

                                    console.log(tempParked);

                                    this.recover(tempParked);
                                    this.setState({ showParkedDetails: false, tempParked: null });

                                }}
                                onCancel={() => this.setState({ showParkedDetails: false, tempParked: null })}
                                okText="Continue"
                                cancelText="Close"
                            >
                                {tempParked ? <>
                                    <div className="my-2">
                                        <div className="font-bold">Customer Name</div>
                                        <div className="">{tempParked.name}</div>
                                    </div>
                                    <div className="my-2">
                                        <div className="font-bold">Customer Contact</div>
                                        <div className="">{tempParked.contact}</div>
                                    </div>
                                    <div className="my-2">
                                        <div className="font-bold">Items</div>
                                        <Table
                                            columns={[{
                                                title: "Product",
                                                key: 'product',
                                                render: (r) => (
                                                    <>
                                                        <p>{r.variant.product.name}</p>
                                                        <p>{r.variant.name}</p>
                                                    </>
                                                )
                                            }, {
                                                title: "Price",
                                                key: 'price',
                                                render: (r) => r.variant.price
                                            }, {
                                                title: "Quantity",
                                                key: 'qty',
                                                render: (r) => r.qty
                                            }]}
                                            dataSource={tempParked.items}
                                            rowKey={(r) => r.id}
                                            pagination={false}
                                        />
                                    </div>
                                </> : null}

                            </Modal>
                            <Modal
                                title="Save Parked Sale"
                                visible={saveParked}
                                okText="Save"
                                onOk={() => this.parkSale({
                                    name: parkedName,
                                    contact: parkedContact
                                })}
                                onCancel={() => this.setState({
                                    saveParked: false
                                })}
                            >
                                <div className="my-4">
                                    <Input placeholder="Enter Name" value={parkedName} onChange={(e) => this.setState({ parkedName: e.target.value })} />
                                </div>
                                <div className="my-4">
                                    <Input placeholder="Enter Phone Contact" value={parkedContact} onChange={(e) => this.setState({ parkedContact: e.target.value })} />
                                </div>
                            </Modal>
                        </div>
                        <div className="">
                            <p className="font-bold text-black mb-1">Search Product</p>
                            {/* <Input.Search onSearch={(code) => {

                                if (!code) return;

                                code = code.trim();
                                this.getVariant(code);


                            }} placeholder="Scan product barcode here" /> */}
                            {/* {isSearch ?  */}
                            <ProductSearch
                                onSelect={this.fetchVariant}
                                companyId={companyId}
                                outletId={outlet_id}
                            />
                        </div>
                        <div className="flex flex-wrap -mx-3 my-6">
                            {parked.map((p, i) => {

                                return (
                                    <div key={i} className="p-3 w-1/4">

                                        <ParkedItem
                                            item={p}
                                            unpark={this.unpark}
                                            recover={this.displayParked}
                                        />

                                    </div>
                                )

                            })}
                        </div>
                    </div>
                    <div className="flex flex-col justify-between max-w-sm w-full h-full" style={{
                        backgroundColor: '#d8f0fd'
                    }}>
                        <div className="flex-1 overflow-scroll">
                            <div className="flex px-4 items-center justify-between py-2 border-b border-blue-300">
                                <p className="uppercase text-xs m-0 font-medium text-black">Items</p>
                                {outlet.wholesale ? <div className>
                                    <span className="uppercase text-xs mr-2 font-medium text-black">Wholesale</span>
                                    <Switch
                                        checked={isWholesale}
                                        onChange={(checked) => {

                                            let _items = items;

                                            if (checked) {

                                                _items = _items.map((i) => {

                                                    const variant = {
                                                        ...i.variant,
                                                        price: i.variant.cost,
                                                        _price: i.variant.price
                                                    };

                                                    return {
                                                        ...i,
                                                        variant
                                                    }

                                                });


                                            } else {


                                                _items = _items.map((i) => {

                                                    // console.log(i);

                                                    const variant = {
                                                        ...i.variant,
                                                        price: i.variant._price,
                                                        _price: 0
                                                    };

                                                    return {
                                                        ...i,
                                                        variant
                                                    }

                                                });


                                            }

                                            this.setState({
                                                isWholesale: checked,
                                                items: [..._items]
                                            });

                                        }}
                                    />
                                </div> : null}
                            </div>
                            {items.map((i) => {

                                // console.log(i);

                                return (
                                    <Item
                                        key={i.variant.id}
                                        productName={i.variant.product.name}
                                        variantName={i.variant.name}
                                        price={i.variant.price}
                                        qty={i.qty}
                                        isService={!i.variant.product.has_variants}
                                        availableQty={i.variant.inventory.aggregate.sum.qty}
                                        code={i.code}
                                        onDelete={(code) => {

                                            items = items.filter((i) => i.code !== code);

                                            this.setState({
                                                items
                                            });

                                        }}
                                        onChange={(qty) => {

                                            i.qty = qty;

                                            this.setState({ items });

                                        }}
                                    />
                                )

                            })}
                        </div>
                        {itemsCount > 0 ? <div className="m-4">
                            <Button
                                size="large"
                                type="primary"
                                ghost
                                onClick={() => this.generateProforma()}
                                className="w-full"
                            >
                                Generate Pro-forma Invoice
                            </Button>
                        </div> : null}
                        {itemsCount > 0 ? <div className="m-4">
                            <Button
                                // size="small"
                                type="danger"
                                ghost
                                onClick={() => this.clear()}
                                className="w-full"
                            >
                                clear
                            </Button>
                        </div> : null}
                        <div className="pb-6 pt-0 border-t-2 border-blue-300 flex-0">
                            <div className="mb-4">
                                <div className="flex justify-between border-b border-blue-300 py-2 px-4">
                                    <div className="font-bold">Subtotal</div>
                                    <div className="font-bold">
                                        <CurrencyFormat amount={this.SUBTOTAL_RAW} />
                                    </div>
                                </div>
                                <div className="flex justify-between border-b border-blue-300 py-2 px-4">
                                    <div className="font-bold">VAT {flat_rate ? '+ NHIL' : ''} ({vat}%)</div>
                                    <div className="font-bold">
                                        <CurrencyFormat amount={this.TOTAL_TAX} />
                                    </div>
                                </div>
                                {!flat_rate ? <div className="flex justify-between border-b border-blue-300 py-2 px-4">
                                    <div className="font-bold">NHIL ({nhil}%)</div>
                                    <div className="font-bold">
                                        <CurrencyFormat amount={this.NHIL_RAW} />
                                    </div>
                                </div> : null}
                                {tax_type === HOSPITALITY ? <div className="flex justify-between border-b border-blue-300 py-2 px-4">
                                    <div className="font-bold">Tourism Levy ({tourism_levy}%)</div>
                                    <div className="font-bold">
                                        <CurrencyFormat amount={this.TLEVY_RAW} />
                                    </div>
                                </div> : null}
                                {items.length ? <div className="flex justify-between items-center border-b border-blue-300 py-2 px-4">
                                    {discount ? <div className="font-bold">
                                        <CurrencyWrapper
                                            component={({ symbol }) => (
                                                <p>
                                                    Discount ({!discount.fixed ? `${discount.value}%` : `${symbol} ${discount.value}`})
                                                </p>

                                            )}
                                        />

                                    </div> : <div className="font-bold">
                                        Discount
                                    </div>}
                                    <div className="">
                                        {discount ? <div>
                                            <Button size="small" type="danger" ghost onClick={() => this.setState({ discount: null })}>
                                                remove
                                            </Button>
                                        </div> : <Input.Search
                                            loading={discountLoading}
                                            placeholder="Enter discount code"
                                            onChange={(e) => this.setState({ discountCode: e.target.value })}
                                            onSearch={() => this.applyCode(discountCode)}

                                        // size="small"
                                        />}
                                    </div>
                                </div> : null}
                            </div>
                            <div className="px-4">
                                <button disabled={!this.canProceed} onClick={() => this.setState({ showPayments: true })} className="focus:outline-none w-full text-white rounded-lg w-full bg-blue-500 p-4 flex justify-between items-center">
                                    <span className="flex items-center">
                                        <span className="text-lg font-bold">Continue</span>
                                        <span className="text-xs px-2">{itemsCount} items</span>
                                    </span>
                                    <span className="">
                                        <span className="font-medium text-lg">
                                            <CurrencyFormat amount={this.TOTAL_RAW} />
                                        </span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <CurrencyWrapper
                        component={({ symbol }) => (
                            <Dialog
                                isShown={showPayments}
                                title="Add payment methods"
                                confirmLabel={`Pay ${symbol} ${this.TOTAL}`}
                                intent="success"
                                isConfirmLoading={confirmLoading}
                                // confirmL
                                onConfirm={() => {

                                    // setTimeout(() => {

                                    this.setState({
                                        confirmLoading: true
                                    });

                                    // }, 3000);



                                    if (!salesperson_id) {

                                        this.setState({
                                            confirmLoading: false
                                        });

                                        return message.error(`You must select a salesperson`);

                                    }

                                    if (this.TOTAL_PAYMENTS_RAW < this.TOTAL_RAW) {

                                        this.setState({
                                            confirmLoading: false
                                        });

                                        message.error(`Payments amount is not equal to subtotal of ${symbol} ${this.TOTAL}`);

                                    } else {

                                        this.save(
                                            mutation,
                                            variables
                                        );

                                    }

                                }}
                                onCloseComplete={() => this.setState({ showPayments: false })}
                            >
                                <div className="flex my-4">
                                    {salesperson_id === user_id ? null : <div className="flex-1">
                                        <p className="font-bold text-black">Select Salesperson</p>
                                        <SalesPersonSelector
                                            companyId={companyId}
                                            salespersonId={salesperson_id}
                                            setSalesPerson={(salesperson_id) => this.setState({ salesperson_id })}
                                            setDefault={() => this.setState({ salesperson_id: user_id })}
                                        />
                                    </div>}
                                    <div className="flex-1">
                                        <p className="font-bold text-black">Print Layout</p>
                                        <Select placeholder="Print Layout" value={printLayout} onChange={(printLayout) => this.setState({ printLayout })}>
                                            <Select.Option value="receipt">Receipt</Select.Option>
                                            <Select.Option value="invoice">Invoice</Select.Option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="my-4">
                                    <p className="font-bold text-black">Select Customer</p>
                                    <CustomerSelector
                                        customerName={customer_name}
                                        companyId={companyId}
                                        setState={(obj) => this.setState({ ...obj })}
                                        customerSearch={customerSearch}
                                    />
                                </div>
                                {/* {change > 0 ?<div className="">
                                    <p className="font-bold text-black">Change</p>
                                    <CurrencyFormat amount={change} />
                                </div> : null} */}
                                <PaymentMethods
                                    customer_id={customer_id}
                                    customer_ledger={customer_ledger}
                                    payments={payments}
                                    onChange={(payments) => {

                                        this.setState({ payments: { ...payments } }, () => {

                                            // console.log(this.TOTAL_PAYMENTS_RAW - this.TOTAL_RAW);

                                            payments['change'].value = - (this.TOTAL_PAYMENTS_RAW - this.TOTAL_RAW);

                                            this.setState({ payments: { ...payments } });

                                        })

                                    }}
                                />
                                <div className="">
                                    <p className="font-bold text-black">Comment</p>
                                    <Input.TextArea
                                        defaultValue={comment}
                                        onChange={(e) => this.setState({ comment: e.target.value })}
                                        style={{
                                            width: '100%'
                                        }}
                                    >

                                    </Input.TextArea>
                                </div>
                            </Dialog>
                        )}
                    />
                </div>
            </React.Fragment>
        )

    }

}

const Sales = withDatabase(SalesInner);

class SaleContainer extends React.Component {

    state = {
        companyId: this.props.match.params.companyId,
        outlet_id: null
    }

    render() {

        const { outlet_id, companyId, outlet } = this.state;
        const { user, company } = this.props;

        // console.log(user.compa÷nies);

        const shouldAskForOutlet = !outlet_id && (user.role === 'admin' || !user.companies[companyId].outlet_id);

        return (
            shouldAskForOutlet ? <div className="p-10">
                <div className="my-3 max-w-xs w-full">
                    <p className="font-bold mb-1 text-black">Select Outlet</p>
                    <OutletSelector
                        companyId={companyId}
                        outletId={outlet_id}
                        setOutlet={(obj) => {

                            this.setState({ ...obj });

                        }}
                    />
                </div>
            </div> : <Sales
                {...this.props}
                outlet={outlet || user.companies[companyId].outlet}
                outlet_id={outlet_id || user.companies[companyId].outlet_id}
            />
        )

    }

}

export default SaleContainer