import React from 'react';
import {DatePicker, Button, Table} from 'antd';
import moment from 'moment';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';
import {Spinner} from 'evergreen-ui';
import {find, sumBy, flatten, map} from 'lodash';
import {currencyFormat} from 'mout/number';

const dateFormat = 'DD/MM/YYYY';

const OTHERS_QUERY = gql`
    query ($company_id: Int!){
        accounts: ledgers(where: {company_id: {_eq: $company_id}, prefix: {_in: ["current-assets", "non-current-assets", "current-liability", "non-current-liability"]}}) {
            id
            name
            prefix
        }
    }
`;


class BalanceSheet extends React.Component{

    state = {
        companyId: this.props.match.params.companyId,
        to_date: moment().endOf('year').format(dateFormat)
    }

    BALANCE = (accounts) => {

        const {companyId, to_date} = this.state;

        return gql`

            query {
        
                ${accounts.map((account) => {

                    return `
                        balance_${account.id}: ledger_transactions_aggregate(where: {company_id: {_eq: ${companyId}}, accounts: {_contains: [${account.id}]}, date: {
                            _lte: "${moment(to_date, dateFormat).format('YYYY-MM-DD')}"
                        }}) {
                            aggregate {
                                sum {
                                    credit
                                    debit
                                }
                            }
                        }
                    `

                })}

                capital: ledgers(where: {company_id: {_eq: ${companyId}}, prefix: {_eq: "capital"}}) {
                    id
                    name
                    _transactions(limit: 1, order_by: {date: desc}, where: {date: {_lte: "${moment(to_date, dateFormat).format('YYYY-MM-DD')}"}}){
                        date
                        balance
                    }
                }
                drawings: ledgers(where: {company_id: {_eq: ${companyId}}, prefix: {_eq: "drawings"}}) {
                    id
                    name
                    _transactions: running_balance(limit: 1, order_by: {date: desc}, where: {
                        _and: [
                            {date: {
                                _gte: "${moment(to_date, dateFormat).startOf('year').format('YYYY-MM-DD')}"
                            }},
                            {date: {
                                _lte: "${moment(to_date, dateFormat).format('YYYY-MM-DD')}"
                            }}
                        ]
                    }){
                        date
                        balance
                    }
                }

                reserves: ledgers(where: {company_id: {_eq: ${companyId}}, prefix: {_eq: "reserves"}}) {
                    id
                    name
                    _transactions: running_balance(limit: 1, order_by: {date: desc}, where: {
                        _and: [
                            {date: {
                                _gte: "${moment(to_date, dateFormat).startOf('year').format('YYYY-MM-DD')}"
                            }},
                            {date: {
                                _lte: "${moment(to_date, dateFormat).format('YYYY-MM-DD')}"
                            }}
                        ]
                    }){
                        date
                        balance
                    }
                }

                income: ledgers(where: {company_id: {_eq: ${companyId}}, group_id: {_eq: 3}, parent_id: {_is_null: true}}) {
                    id
                    name
                    _transactions: _transactions_aggregate(order_by: {date: desc}, where: {
                        _and: [
                            {date: {
                                _gte: "${moment(to_date, dateFormat).startOf('year').format('YYYY-MM-DD')}"
                            }},
                            {date: {
                                _lte: "${moment(to_date, dateFormat).format('YYYY-MM-DD')}"
                            }}
                        ]
                    }){
                        aggregate {
                            sum {
                                credit
                                debit
                            }
                        }
                    }
                }
                adminExp: ledgers(where: {company_id: {_eq: ${companyId}}, prefix: {_eq: "admin-exp"}}) {
                    id
                    name
                    _transactions: _transactions_aggregate(order_by: {date: desc}, where: {
                        _and: [
                            {date: {
                                _gte: "${moment(to_date, dateFormat).startOf('year').format('YYYY-MM-DD')}"
                            }},
                            {date: {
                                _lte: "${moment(to_date, dateFormat).format('YYYY-MM-DD')}"
                            }}
                        ]
                    }){
                        aggregate {
                            sum {
                                credit
                                debit
                            }
                        }
                    }
                }
                opExp: ledgers(where: {company_id: {_eq: ${companyId}}, prefix: {_eq: "op-exp"}}) {
                    id
                    name
                    _transactions: _transactions_aggregate(order_by: {date: desc}, where: {
                        _and: [
                            {date: {
                                _gte: "${moment(to_date, dateFormat).startOf('year').format('YYYY-MM-DD')}"
                            }},
                            {date: {
                                _lte: "${moment(to_date, dateFormat).format('YYYY-MM-DD')}"
                            }}
                        ]
                    }){
                        aggregate {
                            sum {
                                credit
                                debit
                            }
                        }
                    }
                }
                cos: ledgers(where: {company_id: {_eq: ${companyId}}, prefix: {_eq: "cos"}}) {
                    id
                    name
                    _transactions: _transactions_aggregate(order_by: {date: desc}, where: {
                        _and: [
                            {date: {
                                _gte: "${moment(to_date, dateFormat).startOf('year').format('YYYY-MM-DD')}"
                            }},
                            {date: {
                                _lte: "${moment(to_date, dateFormat).format('YYYY-MM-DD')}"
                            }}
                        ]
                    }){
                        aggregate {
                            sum {
                                credit
                                debit
                            }
                        }
                    }
                }

                reserve_income: ledgers(where: {company_id: {_eq: ${companyId}}, group_id: {_eq: 3}, parent_id: {_is_null: true}}) {
                    id
                    name
                    _transactions: _transactions_aggregate(order_by: {date: desc}, where: {date: {
                        _lte: "${moment(to_date, dateFormat).startOf('year').subtract(1, 'day').format('YYYY-MM-DD')}"
                    }}){
                        aggregate {
                            sum {
                                credit
                                debit
                            }
                        }
                    }
                }
                reserve_adminExp: ledgers(where: {company_id: {_eq: ${companyId}}, prefix: {_eq: "admin-exp"}}) {
                    id
                    name
                    _transactions: _transactions_aggregate(order_by: {date: desc}, where: {date: {
                        _lte: "${moment(to_date, dateFormat).startOf('year').subtract(1, 'day').format('YYYY-MM-DD')}"
                    }}){
                        aggregate {
                            sum {
                                credit
                                debit
                            }
                        }
                    }
                }
                reserve_opExp: ledgers(where: {company_id: {_eq: ${companyId}}, prefix: {_eq: "op-exp"}}) {
                    id
                    name
                    _transactions: _transactions_aggregate(order_by: {date: desc}, where: {date: {
                        _lte: "${moment(to_date, dateFormat).startOf('year').subtract(1, 'day').format('YYYY-MM-DD')}"
                    }}){
                        aggregate {
                            sum {
                                credit
                                debit
                            }
                        }
                    }
                }
                reserve_cos: ledgers(where: {company_id: {_eq: ${companyId}}, prefix: {_eq: "cos"}}) {
                    id
                    name
                    _transactions: _transactions_aggregate(order_by: {date: desc}, where: {date: {
                        _lte: "${moment(to_date, dateFormat).startOf('year').subtract(1, 'day').format('YYYY-MM-DD')}"
                    }}){
                        aggregate {
                            sum {
                                credit
                                debit
                            }
                        }
                    }
                }
        
            }
        
        `;

    }

    calculateProfit = (data) => {

        const income = Math.abs(sumBy(flatten(map(data.income, '_transactions')), (t) => {

            return t.aggregate.sum.debit - t.aggregate.sum.credit;

        }));
        const costOfSales = Math.abs(sumBy(map(data.cos, '_transactions'), (t) => {

            return t.aggregate.sum.debit - t.aggregate.sum.credit;

        }));
        const operatingExpense = Math.abs(sumBy(map(data.opExp, '_transactions'), (t) => {

            return t.aggregate.sum.debit - t.aggregate.sum.credit;

        }));
        const adminExpense = Math.abs(sumBy(map(data.adminExp, '_transactions'), (t) => {

            return t.aggregate.sum.debit - t.aggregate.sum.credit;

        }));
        
        return income - costOfSales - operatingExpense - adminExpense;

    }

    calculateReserve = (data) => {


        const reserves = sumBy(flatten(map(data.reserves, '_transactions')), (t) => {

            return t.balance;

        });

        // console.log('reserves', map(data.reserves, '_transactions'),  reserves);
        
        return reserves;

    }

    getAccountBalance = (data, id) => {

        const balance = data[`balance_${id}`].aggregate.sum;

        return ((balance.debit || 0) - (balance.credit || 0));

    }

    getCurrentAssets = (accounts, data) => {

        const account = find(accounts, {prefix: 'current-assets'});

        if (!account) return 0;

        return this.getAccountBalance(data, account.id);

    } 

    getNonCurrentAssets = (accounts, data) => {

        const account = find(accounts, {prefix: 'non-current-assets'});

        if (!account) return 0;

        return this.getAccountBalance(data, account.id);

    } 

    getCurrentLiability = (accounts, data) => {

        const account = find(accounts, {prefix: 'current-liability'});

        if (!account) return 0;

        return this.getAccountBalance(data, account.id);

    }

    getNonCurrentLiability = (accounts, data) => {

        const account = find(accounts, {prefix: 'non-current-liability'});

        if (!account) return 0;

        return this.getAccountBalance(data, account.id);

    }

    render() {

        const {companyId, to_date} = this.state;

        return (
            <div className="overflow-scroll h-full">
                <div className="flex h-full my-12">
                    <div className="w-full max-w-5xl mx-auto">
                        <div className="text-4xl font-bold text-black mb-4">
                            Balance Sheet
                        </div>
                        <div className="p-6 bg-gray-200 rounded flex items-center justify-between">
                            <div className="flex items-center -mx-2">
                                <p className="font-medium text-black px-2">Date Range</p>
                                <DatePicker
                                    value={moment(to_date, dateFormat)}
                                    format={dateFormat}
                                    onChange={((dates, dateString) => {

                                        this.setState({
                                            to_date: dateString
                                        })

                                    })}
                                />
                            </div>
                            <div className="">
                                <Button type="primary" shape="round" ghost>Update Report</Button>
                            </div>
                        </div>
                        <Query
                            query={OTHERS_QUERY}
                            variables={{
                                company_id: companyId
                            }}
                        >

                            {({data: mainData, error: mainError, loading: mainLoading}) => {

                                return (
                                    !mainLoading && mainData && mainData.accounts ?  <div className="">
                                        <Query
                                            query={this.BALANCE(mainData.accounts.length ? [...mainData.accounts] : [{id: 0}])}
                                        >
                                            {({data, loading, error}) => {

                                                // console.log(mainData, data, loading, error);

                                                if (loading) return <div className="flex h-full items-center justify-center py-10">
                                                    <Spinner />
                                                </div>;

                                                const currentAsset = Math.abs(this.getCurrentAssets(mainData.accounts, data));
                                                const nonCurrentAsset = Math.abs(this.getNonCurrentAssets(mainData.accounts, data));
                                                const currentLiability = Math.abs(this.getCurrentLiability(mainData.accounts, data));
                                                const nonCurrentLiability = Math.abs(this.getNonCurrentLiability(mainData.accounts, data));
                                                const totalAssets = Math.abs(currentAsset + nonCurrentAsset);
                                                const totalLiability = Math.abs(currentLiability + nonCurrentLiability);
                                                const capital = Math.abs(sumBy(data.capital[0]._transactions, 'balance'));
                                                const drawings = Math.abs(sumBy(data.drawings[0]._transactions, 'balance'));

                                                // console.log(currentAsset, nonCurrentAsset, currentLiability, nonCurrentLiability);

                                                return (
                                                    <div className="mb-16">
                                                        <div className="mt-10">
                                                            <div className="px-4 flex items-end justify-between border-b-2 py-4">
                                                                <p className="font-bold text-black">ACCOUNTS</p>
                                                                <div className="font-bold text-black">
                                                                    <p className="">{moment(to_date, dateFormat).format('MMM DD, YYYY')}</p>
                                                                </div>
                                                            </div>
                                                            <div className="px-4 bg-gray-200 text-black flex items-center justify-between border-b py-4">
                                                                <p className="font-bold">Assets</p>
                                                            </div>
                                                            <div className="px-4 text-black flex items-center justify-between border-b py-4">
                                                                <p className="">Total Non-current Assets</p>
                                                                <div className="">
                                                                    GH¢ {currencyFormat(nonCurrentAsset)}
                                                                </div>
                                                            </div>
                                                            <div className="px-4 text-black flex items-center justify-between border-b py-4">
                                                                <p className="">Total Current Assets</p>
                                                                <div className="">
                                                                    GH¢ {currencyFormat(currentAsset)}
                                                                </div>
                                                            </div>
                                                            <div className="px-4 font-bold text-black flex items-center justify-between border-b py-4">
                                                                <p className="">Total Assets</p>
                                                                <div className="">
                                                                    GH¢ {currencyFormat(currentAsset + nonCurrentAsset)}
                                                                </div>
                                                            </div>
                                                            <div className="px-4 bg-gray-200 text-black flex items-center justify-between border-b py-4">
                                                                <p className="font-bold">Liabilities</p>
                                                            </div>
                                                            <div className="px-4 text-black flex items-center justify-between border-b py-4">
                                                                <p className="">Total Non-current Liability</p>
                                                                <div className="">
                                                                    GH¢ {currencyFormat(nonCurrentLiability)}
                                                                </div>
                                                            </div>
                                                            <div className="px-4 text-black flex items-center justify-between border-b py-4">
                                                                <p className="">Total Current Liability</p>
                                                                <div className="">
                                                                    GH¢ {currencyFormat(currentLiability)}
                                                                </div>
                                                            </div>
                                                            <div className="px-4 font-bold text-black flex items-center justify-between border-b py-4">
                                                                <p className="">Total Liabilities</p>
                                                                <div className="">
                                                                    GH¢ {currencyFormat(currentLiability + nonCurrentLiability)}
                                                                </div>
                                                            </div>
                                                            <div className="px-4 py-1 bg-gray-200 text-black flex items-center justify-between border-b">
                                                                <p className="font-bold">Net Assets</p>
                                                                <div className="font-bold">
                                                                    GH¢ {currencyFormat(totalAssets - totalLiability)}
                                                                </div>
                                                            </div>
                                                            <div className="mt-4 px-4 bg-gray-200 text-black flex items-center justify-between border-b py-4">
                                                                <p className="font-bold">Equity</p>
                                                            </div>
                                                            <div className="px-4 text-black flex items-center justify-between border-b py-4">
                                                                <p className="">Stated Capital</p>
                                                                <div className="">
                                                                    GH¢ {currencyFormat(capital)}
                                                                </div>
                                                            </div>
                                                            <div className="px-4 text-black flex items-center justify-between border-b py-4">
                                                                <p className="">Reserve <span className="font-medium">(As of {moment(to_date, dateFormat).startOf('year').subtract(1, 'day').format('MMM DD, YYYY')})</span></p>
                                                                <div className="">
                                                                    GH¢ {currencyFormat(Math.abs(this.calculateReserve(data)))}
                                                                </div> 
                                                            </div>
                                                            <div className="px-4 text-black flex items-center justify-between border-b py-4">
                                                                <p className="">
                                                                    <span className="">Profit between </span>
                                                                    <span className="font-medium">{moment(to_date, dateFormat).startOf('year').format('MMM DD, YYYY')}</span>
                                                                    <span className=""> and </span> 
                                                                    <span className="font-medium">{moment(to_date, dateFormat).format('MMM DD, YYYY')}</span>
                                                                </p>
                                                                <div className="">
                                                                    GH¢ {currencyFormat(this.calculateProfit(data))}
                                                                </div>
                                                            </div>
                                                            <div className="px-4 text-black flex items-center justify-between border-b py-4">
                                                                <p className="">Drawings</p>
                                                                <div className="">
                                                                    GH¢ {currencyFormat(drawings)}
                                                                </div>
                                                            </div>
                                                            <div className="px-4 font-bold text-black flex items-center justify-between border-b py-4">
                                                                <p className="">Total Equity</p>
                                                                <div className="">
                                                                    GH¢ {currencyFormat((capital + (this.calculateProfit(data) + this.calculateReserve(data))) - drawings)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )

                                            }}

                                        </Query>
                                    </div>: null
                                )

                            }}

                        </Query>

                    </div>
                </div>
            </div>
        )

    }

}

export default BalanceSheet;