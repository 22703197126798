import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { split, ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { setContext } from 'apollo-link-context';
import firebase from './firebase';
import { RetryLink } from 'apollo-link-retry';

import QueueLink from 'apollo-link-queue';

import ApolloLinkTimeout from 'apollo-link-timeout';

const offlineLink = new QueueLink();

// Note: remove these listeners when your app is shut down to avoid leaking listeners.
window.addEventListener('offline', () => offlineLink.close());
window.addEventListener('online', () => offlineLink.open());

const timeoutLink = new ApolloLinkTimeout(20000);

const headers = {};

// const GRAPHQL_ENDPOINT = (process.env.NODE_ENV === 'production') ? "graphql.gomerp.com/v1/graphql" : "127.0.0.1:8080/v1/graphql";
const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;
const WSS = (process.env.REACT_APP_GRAPHQL_SECURE === 'true') ? 'wss://': 'ws://';
const HTTP = (process.env.REACT_APP_GRAPHQL_SECURE === 'true') ? 'https://': 'http://';

const _client = new SubscriptionClient(`${WSS}${GRAPHQL_ENDPOINT}`, {
    reconnect: true,
    timeout: 30000,
    connectionParams: async () => {


        const user = firebase.auth().currentUser;

        return {
            headers: user ? {
                Authorization: `Bearer ${await user.getIdToken()}`,
            }: {
                'X-Hasura-Role': 'anonymous'
            }

        }
    }
});

const authLink = setContext(async (_, { headers }) => {

    const user = firebase.auth().currentUser;

    return {
        headers: user ? {
            ...headers,
            Authorization: `Bearer ${await user.getIdToken()}`,
        }: {
            ...headers,
            'X-Hasura-Role': 'anonymous'
        }
    }

});

const httpLink = new HttpLink({
    uri: `${HTTP}${GRAPHQL_ENDPOINT}`
});

const wsLink = new WebSocketLink(_client);

const link = split(
    ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    timeoutLink.concat(authLink.concat(httpLink))
);


export const cache = new InMemoryCache();

const client = new ApolloClient({
    link: ApolloLink.from([
        new RetryLink(),
        offlineLink,
        link
    ]),
    cache,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        }
    }
});

export default client;