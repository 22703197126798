import React from 'react';
import { Button, Table } from 'evergreen-ui';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import {CurrencyFormat} from '../../services/Currency';

const GET_ALL_MATERIALS = gql`
	query($company_id: Int!) {
		raw_materials(where: { company_id: { _eq: $company_id } }) {
			id
			name
			price
			unit
		}
	}
`;

const DELETE_RAW_MATERIAL = gql`
	mutation($company_id: Int!, $id: Int!) {
		delete_raw_materials(where: { company_id: { _eq: $company_id }, id: { _eq: $id } }) {
			affected_rows
		}
	}
`;

class RawMaterials extends React.Component {
	render() {
		const { history, company } = this.props;
		if (company == undefined) return null;
		return (
			<div className="h-full">
				<div className="flex h-full overflow-hidden">
					<div className="overflow-scroll h-full flex-1 p-6">
						<div className="">
							<div className="flex justify-between items-center">
								<h1 className="font-bold text-4xl mb-5">
									Raw Materials
									<span />
								</h1>
								<Button
									appearance={'primary'}
									marginRight={16}
									onClick={() => history.push(`/${company.id}/procument/raw-materials/new`)}
								>
									Add Raw Material
								</Button>
							</div>

							<Query
								query={GET_ALL_MATERIALS}
								variables={{ company_id: company.id }}
								fetchPolicy={'network-only'}
							>
								{({ data, loading }) => {
									if (loading) return null;
									if (data && data.raw_materials.length > 0) {
										return (
											<Table size={'medium'}>
												<Table.Head>
													<Table.TextHeaderCell>Name</Table.TextHeaderCell>
													<Table.TextHeaderCell>Unit</Table.TextHeaderCell>
													<Table.TextHeaderCell>Price</Table.TextHeaderCell>
													<Table.TextHeaderCell />
												</Table.Head>
												<Table.Body>
													{data.raw_materials.map((raw_material) => (
														<Table.Row key={raw_material.id}>
															<Table.TextCell>{raw_material.name}</Table.TextCell>
															<Table.TextCell>{raw_material.unit}</Table.TextCell>
															<Table.TextCell
																isNumber
															>
																<CurrencyFormat amount={raw_material.price} />
															</Table.TextCell>
															<Table.TextCell>
																<Button
																	appearance={'primary'}
																	intent={'warning'}
																	marginRight={16}
																	onClick={() =>
																		history.push(
																			`/${company.id}/procument/raw-materials/${raw_material.id}`
																		)}
																>
																	Edit Material
																</Button>
																<Mutation
																	mutation={DELETE_RAW_MATERIAL}
																	refetchQueries={[
																		{
																			query: GET_ALL_MATERIALS,
																			variables: { company_id: company.id }
																		}
																	]}
																	variables={{
																		company_id: company.id,
																		id: raw_material.id
																	}}
																>
																	{(deleteRawmaterial, { loading }) => (
																		<Button
																			appearance={'primary'}
																			intent={'danger'}
																			marginRight={16}
																			disabled={loading}
																			onClick={() => deleteRawmaterial()}
																		>
																			<span>Delete Material</span>
																		</Button>
																	)}
																</Mutation>
															</Table.TextCell>
														</Table.Row>
													))}
												</Table.Body>
											</Table>
										);
									}
									return null;
								}}
							</Query>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RawMaterials;
