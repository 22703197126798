import React from 'react';
import {Link} from 'react-router-dom';
import {TextInputField, Button} from 'evergreen-ui';
import api from '../services/api';

class PasswordReset extends React.Component{

    constructor(props) {

        super(props);

    }

    state = {
        email: '',
        loading: false
    }

    reset = async (e) => {

        e.preventDefault();

        const {email} = this.state;
        const {account} = this.props;

        this.setState({
            error: null,
            loading: true
        });

        try {

            const res = await api.post('/auth/password/reset', {
                account_id: account.id,
                email
            }, {
                noAuth: true
            });


            this.setState({
                sent: true
            })

        } catch(e) {

            this.setState({
                loading: false,
                error: e.response && e.response.data.message ? e.response.data.message: null
            });


        }


    }
    render() {

        var state = this.state;

        const {account} = this.props;

        return (
            <div className="py-8 flex flex-col justify-center items-center h-full w-full">
                <form onSubmit={this.reset} className="w-full">
                    <div className="mx-auto container max-w-xs">
                        <p className="text-center font-bold text-2xl text-black mb-6">{account.name}</p>
                        <div className="shadow-lg rounded-lg p-8">
                            <p className="text-center font-bold text-lg text-black mb-10">Password reset</p>
                            {(!state.sent) ? <TextInputField
                                label="Email address"
                                required
                                placeholder="Eg. john@example.com"
                                value={state.email}
                                onChange={e => this.setState({email: e.target.value})}
                            />: null}
                            {(this.state.error) ? <p className="text-red-dark text-xs mb-4">{this.state.error}</p> : null}
                            {(!state.sent) ? <div className="flex items-center justify-between">
                                <Button isLoading={this.state.loading} intent="success" width={`100%`} justifyContent="center" alignItems="center" appearance="primary">
                                    {this.state.loading ? 'Loading...' : 'Send reset link'}
                                </Button>
                                {/* <div>
                                    <Link className="text-sm text-grey-darker hover:text-grey-dark no-underline" to="/signin">Sign in</Link>
                                </div> */}
                            </div>: null}
                            {(state.sent) ? <div className="mb-4">
                                If a user record was found with email address <strong>{state.email}</strong>, a password reset link would be sent to it with further instructions.
                            </div>: null }
                            <div className="text-center mt-8">
                                <Link className="text-sm text-grey-darker hover:text-grey-dark no-underline" to="/login">Remember password? Sign in</Link>
                            </div>
                        </div>
                        <div className="text-center my-10">
                            <p className="">Powered by <span className="font-bold">GOMERP</span> &trade;</p>
                        </div>
                    </div>
                </form>
            </div>
        );

    }
}

export default PasswordReset;