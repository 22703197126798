import React from 'react';
import {Input, Table, Select, Button as AntButton, Spin, Checkbox, Modal, InputNumber} from 'antd';
import {Button as EverButton, Spinner} from 'evergreen-ui';
import {values, orderBy, sum, sumBy, every, keyBy, fromPairs, compact} from 'lodash';
import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import {currencyFormat} from 'mout/number';
import apollo from '../../../services/apollo';
import {DeleteOutlined, WarningOutlined} from '@ant-design/icons';
import {CurrencyFormat} from '../../../services/Currency';

import moment from 'moment';

const GET_TRANSFER = gql`

    query(
        $id: Int!
    ) {

        transfer: products_history_by_pk(id: $id) {
            comment
            received
            source_id
            destination_id
            employee{
                id
                display_name
            }
            created_at
            type
            items{
              qty
              price
              variant{
                id
                cost
                name
                price
                product {
                    id
                    name
                    has_variants
                    supplier {
                        name
                    }
                }
              }
            }
            
        }

    } 

`;


const GET_OUTLETS = gql`
    query($company_id: Int!) {

        outlets (where: {company_id: {_eq: $company_id}}) {
            id
            name
            company_id
        }

    }
`;

const GET_VARIANT = gql`
    query (
        $code: String!,
        $q: String!,
        $outlet_id: Int!,
        $dest_id: Int!,
        $company_id: Int!
    ) {
        products_sku(where: {
            _or: [
                {code: {_eq: $code}},
                {variant: {
                    name: {_ilike: $q},
                }},
                {variant: {
                    product: {
                        name: {_ilike: $q}
                        has_variants: {_eq: true}
                    }
                }}
            ],
            variant:{
                product:{
                    company_id: {
                        _eq: $company_id
                    }
                }
            }
        }) {
            id
            variant {
                id
                cost
                name
                price
                product {
                    id
                    name
                    has_variants
                    supplier {
                        name
                    }
                }
                inventory: inventory_aggregate(where: {outlet_id: {_eq: $outlet_id}}) {
                    aggregate {
                        sum {
                            qty
                        }
                    }
                }
                dest_inventory: inventory_aggregate(where: {outlet_id: {_eq: $dest_id}}) {
                    aggregate {
                        count
                    }
                }
            }
        }
    }
  
`;

class ReceiveStock extends React.Component{

    state = {
        companyId: this.props.match.params.companyId,
        productId: this.props.match.params.productId,
        outlet_id: null,
        items: this.props.transfer ? keyBy(this.props.transfer.items, 'id'): {},
        e_qty: this.props.transfer ? fromPairs(this.props.transfer.items.map((i) => [i.id, i.qty])) : {},
        qty: {},
        comment: this.props.transfer ? this.props.transfer.comment: '',
        isReverse: false,
        searchValue: '',
        selectedRowKeys: []
    }

    inputRef = React.createRef()

    columns = compact([{
        title: '',
        key: 'warning',
        render: (v) => {

            const {e_qty, qty} = this.state;

            return (
                qty[v.code] < e_qty[v.code] ? <span className="color-red">
                    <WarningOutlined style={{
                        fontSize: 18,
                        color: 'red'
                    }} />
                </span>: null
            );

        }
    },{
        title: 'Order #',
        dataIndex: 'code',
        key: 'code',
        render: (sku, v, i) => <span className="font-bold text-black">{i + 1}</span>
    },{
        title: 'Product',
        render: (v) => v.variant ? <div className="">
            <p className="font-bold text-black">{v.variant.product.name}</p>
            <p className="text-black font-light">{v.variant.name}</p>
        </div>: null
    }, {
        title: 'Expected Qty',
        render: (v) => this.state.e_qty[v.code]
    },{
        title: 'Quantity',
        render: (v) => (this.state.selectedRowKeys.indexOf(`${v.id}`) > -1) ? this.state.qty[v.code] || 0 :(
            <InputNumber
                value={this.state.qty[v.code] || 0}
                min={0}
                step={1}
                max={this.state.e_qty[v.code]}
                onClick={(e) => e.target.select()}
                // formatter={value => `${value}  ${v.product.unit.toUpperCase()}`}
                // parser={value => value.replace(`  ${v.product.unit.toUpperCase()}`, '')}
                onChange={(_qty) => {

                    this.state.qty[v.code] = parseInt(_qty);

                    this.setState({
                        qty: this.state.qty
                    });

                }}
            />
        )
    },{
        title: 'Retail Price',
        render: (v) => v.variant ? <CurrencyFormat amount={v.variant.price} /> : null
    },{
        title: 'Total',
        render: (v) => v.variant ? <CurrencyFormat amount={(this.state.qty[v.code] || 0) * v.variant.price} /> : null
    }, !this.props.id ? {
        title: '',
        render: (v) => v.loading ? <Spin size="small" /> : <AntButton onClick={() => {

            let {qty, items} = this.state;

            delete items[v.code];
            delete qty[v.code];


            this.setState({
                items: {...items},
                qty: {...qty}
            });

        }} className="flex items-center justify-center" type="danger" size="small" ghost icon={<DeleteOutlined />} shape="circle" />
    }: null]);

    fetchVariant = async (value) => {

        const _item = JSON.parse(value);

        if (_item.variant.product.has_variants && !_item.variant.inventory.aggregate.sum.qty) {

            Modal.error({
                title: 'Out of Stock',
                content: 'This item is either unavailable or out of stock at this location',
            });

            return;
            
        }

        const {items, qty} = this.state;
        const code = _item.variant.id;
        
        const item = items[code];


        if (item) {

            qty[code]++;

            this.setState({
                items,
                qty,
                searchValue: ''
            });

            return;

        } else {

    
            items[code] = {
                code: `${_item.variant.id}`,
                updatedAt: Date.now(),
                ..._item
            };

            qty[code] = 1;

            this.setState({
                items,
                qty,
                searchValue: ''
            });


        }

    }

    returnItems = () => {

        const {e_qty, qty, items} = this.state;

        const _items = values(items).filter((i) => {

            const {code} = i;

            return (e_qty[code] - qty[code]) > 0 || !qty[code];

        }).map((i) => ({
            ...i,
            variance: e_qty[i.code] - (qty[i.code] || 0)
        }));

        // console.log(_items);

        const total = sumBy(_items, (v) => {

            return v.variant ? v.variant.price * (v.variance): 0;

        });

        const total_qty = sumBy(_items, 'variance');

        return _items.length ? `
            ${_items.map((i, index) => {

                // console.log(i);

                const {variance, variant} = i;

                return `
                    returns_u_${index}: update_products_inventory(where: {
                        outlet_id: {_eq: $source_id},
                        variant_id: {_eq: ${parseInt(variant.id)}}
                    }, _inc: {
                        qty: ${variance}
                    }, _set:{
                        product_id: ${parseInt(variant.product.id)}
                    }) {
                        affected_rows
                    }
                `;

            })}
            return_items: insert_products_history(objects: {
                items: {data: [${_items.map((i) => {

                    return `
                        {
                            price: ${i.variant.price},
                            qty: ${i.variance},
                            variant_id: ${i.variant.id}
                        },
                    `;

                })}]},
                company_id: $company_id,
                destination_id: $source_id,
                employee_id: $employee_id,
                source_id: $destination_id,
                total_amount: ${total},
                total_qty: ${total_qty},
                type: "RETURN_STOCK"
            }) {
                affected_rows
            }
        `:  ``;

    }

    get SAVE_MUTATION() {

        const {items, qty} = this.state;
        const {destination_id} = this.props.transfer;
        const {id} = this.props;

        return gql`
            mutation(
                $qty: Int!,
                $total: float8!,
                $company_id: Int!,
                $destination_id: Int,
                $employee_id: Int!,
                $items:  [products_history_items_insert_input!]!,
                $comment: String,
                $source_id: Int,
                $transfer_id: Int
            ){
                ${this.returnItems()}
                update_products_history(where:{
                    id:{_eq: ${parseInt(id)}}
                }, _set:{
                    received: true
                  }){
                    affected_rows
                }
                transfer: insert_products_history(objects: {
                    items: {data: $items},
                    company_id: $company_id,
                    destination_id: $destination_id,
                    employee_id: $employee_id,
                    source_id: $source_id,
                    total_amount: $total,
                    total_qty: $qty,
                    type: "RECEIVE_STOCK",
                    received: true,
                    comment: $comment,
                    transfer_id: $transfer_id
                }) {
                    returning{
                        id
                    }
                }
                ${values(items).map((i, index) => {

                    // console.log(i);

                    const {code, variant} = i;

                    const _qty = qty[code];

                    return `
                        u_${index}: update_products_inventory(where: {
                            outlet_id: {_eq: ${parseInt(destination_id)}},
                            variant_id: {_eq: ${parseInt(variant.id)}}
                        }, _inc: {
                            qty: ${_qty || 0}
                        }, _set:{
                            product_id: ${parseInt(variant.product.id)}
                        }) {
                            affected_rows
                        }
                    `;

                })}
                
            }
        `;

    }

    onSelectChange = (selectedRowKeys) => {

        this.setState({selectedRowKeys});

    };

    render() {

        const {companyId, comment, e_qty, qty, items, searchValue, selectedRowKeys} = this.state;
        const {user, id, transfer} = this.props;
        const {destination_id, source_id} = transfer;
        const itemsCount = Object.keys(items).length;
        const _items = values(items);
        const total_qty = sum(values(qty));
        const total = sumBy(_items, (v) => {

            return v.variant ? v.variant.price * (qty[v.code] || 0): 0;

        });

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        
        return (
            <React.Fragment>
                <div className="overflow-scroll h-full">
                    <div className="max-w-5xl mx-auto w-full px-6">
                        <div className="flex items-center justify-between my-10">
                            <div className="flex items-center">
                                <h1 className="font-bold text-4xl mr-2">
                                    Receive Stock 
                                </h1>
                            </div>
                        </div>
                        <div className="flex w-full -mx-2">
                            <div className="w-1/3 px-2">
                                <p className="font-bold text-black">Source Outlet</p>
                                <div className="my-2">
                                    <Query
                                        query={GET_OUTLETS}
                                        variables={{
                                            company_id: companyId
                                        }}
                                    >
                                        {({loading, data, error, refetch}) => {

                                            return (
                                                loading ? <Select placeholder="Select Outlet" key="loading" value={loading ? "loading":"choose" } style={{width: '100%'}}>
                                                    <Select.Option value="loading" disabled={true}>Loading</Select.Option>
                                                </Select>: <Select disabled={true} value={transfer.source_id} placeholder="Select Outlet" style={{width: '100%'}}>
                                                    {data.outlets.map((outlet) => {

                                                        return <Select.Option key={outlet.id} value={outlet.id}>{outlet.name}</Select.Option>

                                                    })}
                                                </Select>
                                            )

                                        }}
                                    </Query>
                                </div>
                            </div>
                            <div className="w-1/3 px-2">
                                <p className="font-bold text-black">Destination Outlet</p>
                                <div className="my-2">
                                    <Query
                                        query={GET_OUTLETS}
                                        variables={{
                                            company_id: companyId
                                        }}
                                    >
                                        {({loading, data, error, refetch}) => {

                                            return (
                                                loading ? <Select placeholder="Select Outlet" key="loading" value={loading ? "loading":"choose" } style={{width: '100%'}}>
                                                    <Select.Option value="loading" disabled={true}>Loading</Select.Option>
                                                </Select>: <Select disabled={true} value={transfer.destination_id} placeholder="Select Outlet" style={{width: '100%'}}>
                                                    {data.outlets.filter((o) => o.id !== source_id).map((outlet) => {

                                                        return <Select.Option key={outlet.id} value={outlet.id}>{outlet.name}</Select.Option>

                                                    })}
                                                </Select>
                                            )

                                        }}
                                    </Query>
                                </div>
                            </div>
                        </div>
                        {!id ? <div className="my-8">
                            
                            <Query
                                query={GET_VARIANT}
                                variables={{
                                    code: searchValue,
                                    q: `%${searchValue}%`,
                                    outlet_id: source_id,
                                    dest_id: destination_id,
                                    company_id: companyId
                                }}
                                skip={!searchValue}
                                // onCompleted={(data) => {

                                //     if (!data.products_sku || !data.products_sku.length) return; 

                                //     if (data.products_sku.length === 1) {

                                //         const sku = data.products_sku[0];

                                //         if (sku.variant.product.has_variants && !sku.variant.inventory.aggregate.sum.qty) {

                                //             Modal.error({
                                //                 title: 'Out of Stock',
                                //                 content: 'This item is either unavailable or out of stock at this location',
                                //             });

                                //             return;
                                            
                                //         }

                                //         this.fetchVariant(JSON.stringify(sku));

                                //     }

                                // }}
                            >
                                {({data, error, loading, refetch}) => {

                                    return (
                                        <Select
                                            showSearch
                                            loading={loading}
                                            value={searchValue}
                                            placeholder="Scan product barcode here"
                                            style={{width: '100%'}}
                                            disabled={!source_id || !destination_id}
                                            notFoundContent={loading ? <div className="flex items-center justify-center p-4"><Spinner /></div> : null}
                                            showArrow={false}
                                            allowClear
                                            filterOption={false}
                                            onSearch={(searchValue) => this.setState({searchValue})}
                                            onChange={(value) => {

                                                if (value) {

                                                    this.fetchVariant(value);

                                                }


                                            }}
                                            // notFoundContent={null}
                                        >
                                            {!loading && data && data.products_sku.length ? data.products_sku.map(d => d.variant.product ? (
                                                <Select.Option key={d.id} value={JSON.stringify(d)}>
                                                    {d.variant.product.name} - {d.variant.name} - <CurrencyFormat amount={d.variant.price} />
                                                </Select.Option>
                                            ):null): null}
                                        </Select>
                                    )

                                }}
                            </Query>
                            
                        </div>: null}
                        <div className="my-8 shadow rounded">
                            <Table
                                dataSource={orderBy(_items, ['updatedAt'], ['desc'])}
                                columns={this.columns}
                                pagination={false}
                                rowKey={(i) => `${i.id}`}
                                rowSelection={rowSelection}
                            />
                        </div>
                        <div className="flex justify-between my-8 -mx-4">
                            <div className="flex-1 px-4">
                                <div className="mb-4">
                                    {!id ? <Input.TextArea
                                        value={comment}
                                        onChange={(e) => this.setState({comment: e.target.value})}
                                        autosize={{ minRows: 6, maxRows: 6 }}
                                        placeholder="Notes / Comments"
                                        style={{width: '100%'}}
                                    />: <>
                                        <p className="font-bold text-black">Notes / Comments</p>
                                        <p className="text-black">{comment? comment : "No Comment"}</p>
                                    </>}
                                </div>
                                <Mutation
                                    mutation={this.SAVE_MUTATION}
                                    variables={{
                                        transfer_id: parseInt(id),
                                        qty: total_qty,
                                        total,
                                        // comment,
                                        employee_id: user.id,
                                        company_id: companyId,
                                        destination_id,
                                        source_id,
                                        items: _items.map((i) => ({
                                            // id: 10,
                                            e_qty: e_qty[i.code],
                                            price: i.variant.price,
                                            qty: qty[i.code],
                                            variant_id: i.variant.id
                                        }))
                                    }}
                                    onCompleted={(data) => {

                                        this.props.history.push(`/${companyId}/pos/stocks`);

                                    }}
                                >
                                    {(save, {loading}) => {

                                        return (
                                            <EverButton
                                                // disabled={itemsCount < 1 || !every(_items, (v) => v.variant)}
                                                intent="success"
                                                appearance="primary"
                                                isLoading={loading}
                                                onClick={() => {

                                                    save();
                                                    
                                                }}
                                            >
                                                Receive
                                            </EverButton>
                                        );

                                    }}
                                </Mutation>
                            </div>
                            <div className="px-4">
                                <div className="rounded w-64 bg-gray-100 px-6 py-3">
                                    <div className="border-b py-2 my-2 flex items-center justify-between">
                                            <p className="text-black font-bold">Total Units</p>
                                            <p className="font-bold text-black">{total_qty}</p>
                                        </div>
                                        <div className="py-2 my-2 flex items-center justify-between">
                                            <p className="font-bold text-black">Total</p>
                                            <p className="font-bold text-black">
                                                <CurrencyFormat amount={total} />
                                            </p>
                                        </div>
                                    </div>
                                {/* <div className="my-4">
                                    <EverButton intent="success" appearance="primary">Save</EverButton>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )

    }

}

const ReceiveStockContainer = (props) => {

    const {companyId, id, user} = props.match.params;


    return (
        <Query
            query={GET_TRANSFER}
            skip={id ? false: true}
            variables={{
                id
            }}
            onCompleted={(data) => {

                if (data && data.transfer && data.transfer.received) {

                    props.history.push(`/${companyId}/pos/transfers/${id}`);

                }

            }}
        >
            {({loading, data, error, refetch}) => {

                return (
                    <div className="overflow-scroll h-full w-full">
                        {loading ? <div className="flex items-center justify-center py-10">
                            <Spinner />
                        </div> : (!id || (data && data.transfer && data.transfer.type === "TRANSFER_STOCK")) ? <ReceiveStock
                            {...props}
                            id={id}
                            transfer={!id ? null: {
                                ...data.transfer,
                                items: data.transfer.items.map((i) => ({
                                    id: i.variant.id,
                                    qty: i.qty,
                                    code: i.variant.id,
                                    variant:{
                                        id: i.variant.id,
                                        name: i.variant.name,
                                        cost: i.variant.cost,
                                        price: i.price,
                                        product:{
                                            id: i.variant.product.id,
                                            name: i.variant.product.name
                                        }
                                    }
                                }))
                            }}
                        />: <p className="font-bold text-lg p-6 text-black">Stock Transfer not Found</p>}
                    </div>
                );

            }}

        </Query>
    )

}

export default ReceiveStockContainer;