import React from 'react';
import {DatePicker} from 'antd';
import {keyBy, omit, sumBy} from 'lodash';
import {Button, FormField, BackButton, Spinner, toaster} from 'evergreen-ui';
import moment from 'moment';
import PayrollEmployees from './PayrollEmployees';
import {Query, Mutation} from 'react-apollo';
import gql from 'graphql-tag';
const { MonthPicker } = DatePicker;

const monthFormat = 'MMMM, YYYY';
const dateFormat = 'DD/MM/YYYY';


const GET_EMPLOYEES = gql`
    query(
        $company_id: Int!
        $start_date: date!,
        $end_date: date!
    ) {
        ledgers: ledgers(where:{
            company_id: {
                _eq: $company_id
            },
            prefix: {
                _in: [
                    "current-liability",
                    "admin-exp",
                    "deferred-statutory",
                    "deferred-taxes",
                    "deferred-pensions",
                    "deferred-salaries",
                    "deferred-paye",
                    "salaries",
                    "paye",
                    "pensions"
                ]
            }
        }){
            id
            prefix
            parent_id
            name
        }
        employees: employees(where: {company_id: {_eq: $company_id}}) {
            id
            employeeId: id
            name
            bank_name
            bank_branch
            bank_sort_code
            bank_account_number
            designation
            date_joined
            salary_aggregated
            basic_salary
            allowance
            others
            department{
                id
                name
            }
            allowances_activities(where: {
                _or: [
                    { fixed: {_eq: false} },
                    {
                        expiry: {_lte: $end_date},
                        fixed: {_eq: true}
                    }
                ]
            }){
                value
                allowance{
                    id
                    name
                }
            }
            allowances: allowances_activities_aggregate(where: {
                _or: [
                    { fixed: {_eq: false} },
                    {
                        expiry: {_lte: $end_date},
                        fixed: {_eq: true}
                    }
                ]
            }) {
                aggregate {
                    sum {
                        value
                    }
                }
            }
            aggregators_activities(where: {start_date: {_gte: $start_date}, end_date: {_lte: $end_date}}) {
                total
                aggregator{
                    id
                    name
                }
            }
            aggregators: aggregators_activities_aggregate(where: {start_date: {_gte: $start_date}, end_date: {_lte: $end_date}}) {
                aggregate {
                    sum {
                        total
                    }
                }
            }
            deductions_activities(where: {end_date: {_gte: $start_date}}){
                id
                amount_due_monthly
                days_absent
                hours_absent
                deduction{
                    id
                    name
                    type
                }
            }
            deductions: deductions_activities_aggregate(where: {end_date: {_gte: $start_date}}) {
                aggregate {
                    sum {
                        amount_due_monthly
                        days_absent
                        hours_absent
                    }
                }
            }
        }
        company: company_by_pk(id: $company_id) {
            name
            basic_salary_percent
            allowance_percent
            departments {
                id
                name
            }
        }

    }
`;

const CREATE_MUTATION = gql`
    mutation (
        $company_id: Int!,
        $date: date!,
        $title: String!,
        $employees: [payroll_employees_insert_input!]!,
        $transactions: [ledger_transactions_insert_input!]!
    ) {
        insert_payrolls(objects: {
            company_id: $company_id,
            date: $date,
            title: $title,
            employees: {
                data: $employees
            }
        }) {
            returning {
                id
            }
        }
        insert_ledger_transactions(objects: $transactions){
            affected_rows
        }
    }
`;

class GeneratePayroll extends React.Component{

    state = {
        month: moment().format(monthFormat),
        companyId: this.props.match.params.companyId,
        generated: false,
        _employees: [],
    }

    render() {

        const {generated, month, companyId, _employees, preview} = this.state;
        const {company, user} = this.props;

        return (
            !generated ? <div className="max-w-sm w-full mx-auto my-12">
                <BackButton appearance="default" marginBottom={15} onClick={()=>{

                    this.props.history.push(`/${companyId}/hr/payroll`);

                }} children="Back to payroll" />
                <div className="shadow p-6 rounded">
                    <p className="font-bold text-black text-lg">
                        Generate payroll
                    </p>
                    <div className="my-4">
                        <FormField isRequired={true} label="Month" />
                        <MonthPicker
                            disabledDate={(e) => !e.isSame(moment(), 'month')}
                            style={{width: '100%'}}
                            onChange={(month) => this.setState({month: month.format(monthFormat)})}
                            defaultValue={moment(month, monthFormat)} format={monthFormat}
                        />
                    </div>
                    {preview ? null :<Button appearance="primary" intent="success" onClick={() => this.setState({generated: true})}>Generate</Button>}
                </div>
            </div>: <div className="">
                <Query
                    query={GET_EMPLOYEES}
                    variables={{
                        company_id: companyId,
                        start_date: moment(month, monthFormat).startOf('month').format('YYYY-MM-DD'),
                        end_date: moment(month, monthFormat).endOf('month').format('YYYY-MM-DD')
                    }}
                >
                    {({data, error, loading}) => {

                        if (loading) {

                            return (
                                <div className="flex items-center justify-center py-10">
                                    <Spinner />
                                </div>
                            )

                        } else if (data && data.employees && data.employees.length) {

                            // console.log(data);

                            let employees = data.employees.map((e) => {

                                return {
                                    ...e,
                                    sum_allowances: e.allowances.aggregate.sum.value || 0,
                                    sum_aggregations: e.aggregators.aggregate.sum.total || 0,
                                    sum_deductions: e.deductions.aggregate.sum.amount_due_monthly || 0,
                                    hours_absent: e.deductions.aggregate.sum.hours_absent || 0,
                                    days_absent: e.deductions.aggregate.sum.days_absent || 0
                                }

                            });

                            let accounts = keyBy(data.ledgers, 'prefix');

                            // console.log(accounts);

                            return (
                                <div className="">
                                    <div className="print-hidden px-8 mx-auto mt-10">
                                        <BackButton appearance="default" marginBottom={15} onClick={()=>{

                                            this.props.history.push(`/${companyId}/hr/payroll`);

                                        }} children="Back to payroll" />
                                        <div className="text-black font-bold text-3xl">Payroll Preview - {month}</div>
                                        <div className="mt-6">
                                            <Mutation
                                                mutation={CREATE_MUTATION}
                                                onCompleted={() => this.props.history.push(`/${companyId}/hr/payroll`)}
                                                onError={(e) => console.log(e)}
                                                variables={{
                                                    company_id: companyId,
                                                    date: moment(month, monthFormat).endOf('month').format('YYYY-MM-DD'),
                                                    title: month,
                                                    employees: (_employees || []).map((e) => {

                                                        return {
                                                            ...omit(e, [
                                                                '__typename',
                                                                '_income_tax',
                                                                'department',
                                                                'sum_allowances',
                                                                'id',
                                                                'employeeId',
                                                                'designation',
                                                                'date_joined',
                                                                'aggregators',
                                                                'allowances',
                                                                'deductions',
                                                                'allowances_activities',
                                                                'aggregators_activities',
                                                                'deductions_activities'
                                                            ]),
                                                            company_id: companyId,
                                                            department_id: e.department.id,
                                                            department_name: e.department.name,
                                                            employee_id: e.id,
                                                            paye: e._income_tax,
                                                            activities: {
                                                                data: [
                                                                    ...e.deductions_activities.map((d) => ({
                                                                        name: d.deduction.name,
                                                                        value: d.amount_due_monthly,
                                                                        type: `deduction-${d.deduction.type}`
                                                                    })),
                                                                    ...e.aggregators_activities.map((a) => ({
                                                                        name: a.aggregator.name,
                                                                        value: a.total,
                                                                        type: 'aggregator'
                                                                    })),
                                                                    ...e.allowances_activities.map((a) => ({
                                                                        name: a.allowance.name,
                                                                        value: a.value,
                                                                        type: 'allowance'
                                                                    }))
                                                                ]
                                                            }
                                                        }

                                                    }),
                                                    transactions: [{
                                                        date: moment().format('YYYY-MM-DD'),
                                                        cheque_no: '',
                                                        debit: 0,
                                                        credit: sumBy(_employees, (e) => (parseFloat(e._income_tax))),
                                                        description: `Total Employee P.A.Y.E for ${month}`,
                                                        ledger_id: accounts["deferred-paye"].id,
                                                        user_id: user.id,
                                                        company_id: company.id,
                                                        accounts: [
                                                            2,
                                                            accounts["current-liability"].id,
                                                            accounts["deferred-statutory"].id,
                                                            accounts["deferred-taxes"].id,
                                                            accounts["deferred-paye"].id
                                                        ]
                                                    },{
                                                        date: moment().format('YYYY-MM-DD'),
                                                        cheque_no: '',
                                                        debit: 0,
                                                        credit: sumBy(_employees, (e) => (parseFloat(e.ssnit_employee) + parseFloat(e.ssnit_employer))),
                                                        description: `Total Employee Pensions for ${month}`,
                                                        ledger_id: accounts["deferred-pensions"].id,
                                                        user_id: user.id,
                                                        company_id: company.id,
                                                        accounts: [
                                                            2,
                                                            accounts["current-liability"].id,
                                                            accounts["deferred-statutory"].id,
                                                            accounts["deferred-pensions"].id
                                                        ]
                                                    },{
                                                        date: moment().format('YYYY-MM-DD'),
                                                        cheque_no: '',
                                                        debit: 0,
                                                        credit: sumBy(_employees, (e) => (parseFloat(e.net_income))),
                                                        description: `Total Employee Salaries for ${month}`,
                                                        ledger_id: accounts["deferred-salaries"].id,
                                                        user_id: user.id,
                                                        company_id: company.id,
                                                        accounts: [
                                                            2,
                                                            accounts["current-liability"].id,
                                                            accounts["deferred-salaries"].id
                                                        ]
                                                    },{
                                                        date: moment().format('YYYY-MM-DD'),
                                                        cheque_no: '',
                                                        credit: 0,
                                                        debit: sumBy(_employees, (e) => (parseFloat(e._income_tax))),
                                                        description: `Total Employee P.A.Y.E for ${month}`,
                                                        ledger_id: accounts["paye"].id,
                                                        user_id: user.id,
                                                        company_id: company.id,
                                                        accounts: [
                                                            4,
                                                            accounts["paye"].parent_id,
                                                            accounts["paye"].id
                                                        ]
                                                    },{
                                                        date: moment().format('YYYY-MM-DD'),
                                                        cheque_no: '',
                                                        credit: 0,
                                                        debit: sumBy(_employees, (e) => (parseFloat(e.ssnit_employee) + parseFloat(e.ssnit_employer))),
                                                        description: `Total Employee Pensions for ${month}`,
                                                        ledger_id: accounts["pensions"].id,
                                                        user_id: user.id,
                                                        company_id: company.id,
                                                        accounts: [
                                                            4,
                                                            accounts["pensions"].parent_id,
                                                            accounts["pensions"].id
                                                        ]
                                                    },{
                                                        date: moment().format('YYYY-MM-DD'),
                                                        cheque_no: '',
                                                        credit: 0,
                                                        debit: sumBy(_employees, (e) => (parseFloat(e.net_income))),
                                                        description: `Total Employee Salaries for ${month}`,
                                                        ledger_id: accounts["salaries"].id,
                                                        user_id: user.id,
                                                        company_id: company.id,
                                                        accounts: [
                                                            4,
                                                            accounts["salaries"].parent_id,
                                                            accounts["salaries"].id
                                                        ]
                                                    }]
                                                }}
                                            >
                                                {(save, {loading}) => {

                                                    return (
                                                        <Button appearance="primary" intent="success" isLoading={loading} onClick={() => save()}>Save</Button>
                                                    )

                                                }}
                                            </Mutation>
                                        </div>
                                    </div>
                                    <div className="">
                                        <PayrollEmployees
                                            onEmployeesChange={(_employees) => this.setState({_employees})}
                                            isSingle={false}
                                            month={month}
                                            employees={employees}
                                            payrollId={null}
                                            companyId={companyId}
                                            company={{...data.company}}
                                            history={this.props.history}
                                        />
                                    </div>
                                </div>
                            );

                        } else {

                            return null;

                        }

                    }}
                </Query>
            </div>
        )

    }

}

export default GeneratePayroll;