import React, { useState, useEffect } from 'react';
import { Input, Table, Select, Button as AntButton, Spin, Checkbox, InputNumber, message } from 'antd';
import { Button as EverButton, Spinner } from 'evergreen-ui';
import { values, orderBy, sum, sumBy, some, keys, map, pick, get, keyBy } from 'lodash';
import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import { currencyFormat } from 'mout/number';
import { SearchOutlined } from '@ant-design/icons';
import apollo from '../../../services/apollo';
import { CurrencyFormat } from '../../../services/Currency';



const GET_OUTLETS = gql`
    query($company_id: Int!) {

        outlets (where: {company_id: {_eq: $company_id}}) {
            id
            name
            company_id
            raw_materials
        }

    }
`;

const PRODUCT_FRAGMENT = `

    skus{
        id
        code
    }
    id
    cost
    price
    name
    inventory: inventory_aggregate(where: {outlet_id: {_eq: $outlet_id}}) {
        aggregate {
            count
            sum {
                qty
            }
        }
    }
    product {
        id
        name
        supplier {
            name
        }
    }

`;

const GET_VARIANTS = gql`
    query (
        $company_id: Int!,
        $outlet_id: Int!
    ) {
        items: products_variants(where:{
            product:{
                company_id:{_eq: $company_id},
                has_variants:{_eq: true}
            }
        }) {
            ${PRODUCT_FRAGMENT}
        }
    }
  
`;

const GET_VARIANTS_RAW = gql`
    query (
        $company_id: Int!,
        $outlet_id: Int!
    ) {
        items: products_variants(where:{
            product:{
                company_id:{_eq: $company_id},
                category: {
                    raw_materials:{
                        _eq: true
                    }
                }
            }
        }) {
            ${PRODUCT_FRAGMENT}
        }
    }
  
`;

class StockTake extends React.Component {

    state = {
        companyId: this.props.match.params.companyId,
        productId: this.props.match.params.productId,
        outlet_id: null,
        isReverse: false,
        items: {},
        qty: {},
        searchValue: ''
    }

    inputRef = React.createRef()

    render() {

        const { companyId, outlet_id, qty, items, searchValue, isReverse, isRawMaterials } = this.state;
        const { user } = this.props;
        const itemsCount = Object.keys(items).length;

        return (
            <React.Fragment>
                <div className="overflow-scroll h-full">
                    <div className="max-w-6xl mx-auto w-full px-6">
                        <div className="flex items-center justify-between my-10">
                            <div className="flex items-center">
                                <h1 className="font-bold text-4xl mr-2">
                                    Stock Adjustment
                                </h1>
                            </div>
                        </div>
                        <div className="">
                            <div className="max-w-xs">
                                <p className="font-bold text-black">Outlet</p>
                                <div className="my-2">
                                    <Query
                                        query={GET_OUTLETS}
                                        variables={{
                                            company_id: companyId
                                        }}
                                    >
                                        {({ loading, data, error, refetch }) => {

                                            return (
                                                loading ? <Select placeholder="Select Outlet" key="loading" value={loading ? "loading" : "choose"} style={{ width: '100%' }}>
                                                    <Select.Option value="loading" disabled={true}>Loading</Select.Option>
                                                </Select> : <Select
                                                    value={outlet_id}
                                                    disabled={outlet_id != null}
                                                    onChange={(outlet_id) => {

                                                        const _outlets = keyBy(data ? data.outlets : [], 'id');

                                                        this.setState({ outlet_id, isRawMaterials: _outlets[outlet_id].raw_materials });

                                                    }}
                                                    placeholder="Select Outlet"
                                                    style={{ width: '100%' }}
                                                >
                                                    {data.outlets.map((outlet) => {

                                                        return <Select.Option key={outlet.id} value={outlet.id}>{outlet.name}</Select.Option>

                                                    })}
                                                </Select>
                                            )

                                        }}
                                    </Query>
                                </div>
                            </div>
                        </div>
                        <Query
                            query={isRawMaterials ? GET_VARIANTS_RAW : GET_VARIANTS}
                            variables={{
                                company_id: companyId,
                                outlet_id
                            }}
                            skip={!outlet_id}
                        >
                            {({ loading, data, error }) => {

                                if (loading) return (
                                    <Spinner />
                                )

                                const _items = ((data || {}).items || []).map((i) => ({
                                    ...i,
                                    _skus: map(i.skus, 'code'),
                                    updatedAt: Date.now()
                                }));

                                return (
                                    <StockTakeInner
                                        items={_items}
                                        outlet_id={outlet_id}
                                        companyId={companyId}
                                        user={user}
                                        clear={() => this.setState({ outlet_id: null })}
                                    />
                                )

                            }}
                        </Query>
                    </div>
                </div>
            </React.Fragment>
        )

    }

}

export const getColumnSearchProps = ({
    placeholder,
    dataIndex,
    render
}) => {

    let searchInput;

    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {

                        searchInput = node;

                    }}
                    placeholder={placeholder}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => confirm()}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
            </div>
        ),
        filterIcon: filtered => <SearchOutlined className="print-hidden" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => {

            const texts = dataIndex.map((key) => get(record, key)).map((k) => k ? k.toString().toLowerCase() : '');

            return some(texts, (k) => k.includes(value.toLowerCase()));

        },
        onFilterDropdownVisibleChange: visible => {

            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }

        },
        render
    }
};

const StockTakeInner = ({ items, clear, outlet_id, companyId, user }) => {

    const [products, setProducts] = useState(items);
    const [qty, setQty] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [comment, setComment] = useState('');

    useEffect(() => {

        const _qty = localStorage.getItem(`${companyId}-${outlet_id}-${user.id}-stock-adjustment`);

        setQty(JSON.parse(_qty) || {});

        return () => { };


    }, []);

    useEffect(() => {


        localStorage.setItem(`${companyId}-${outlet_id}-${user.id}-stock-adjustment`, JSON.stringify(qty));

        return () => { };


    }, [qty]);

    const qtyOnChange = (v, _qty) => {

        qty[v.id] = parseInt(_qty);

        setQty({
            ...qty
        });


    };

    const onSelectChange = (selectedRowKeys) => {

        setSelectedRowKeys([...selectedRowKeys]);

    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };


    const columns = [{
        title: '#',
        dataIndex: 'code',
        key: 'code',
        render: (sku, v, i) => <span className="font-bold text-black">{i + 1}</span>
    }, {
        title: 'Product',
        ...getColumnSearchProps({
            placeholder: 'Search Products',
            dataIndex: ['name', 'product.name'],
            render: (v) => (
                <div className="">
                    <p className="font-bold text-black">{v.product.name}</p>
                    <p className="text-black font-light">{v.name}</p>
                </div>
            )
        }),
    }, {
        title: 'Stock on Hand',
        render: (v) => v.inventory.aggregate.sum.qty || 0
    }, {
        title: 'Quantity',
        render: (v) => {

            // console.log(selectedRowKeys, v);

            return (
                (selectedRowKeys.indexOf(`${v.id}`) > -1) ? qty[v.id] || 0 : <InputNumber
                    value={qty[v.id] || 0}
                    min={0}
                    // max={v.inventory.aggregate.sum.qty || 0 }
                    onClick={(e) => e.target.select()}
                    // formatter={value => `${value}  ${v.product.unit.toUpperCase()}`}
                    // parser={value => value.replace(`  ${v.product.unit.toUpperCase()}`, '')}
                    onChange={(qty) => qtyOnChange(v, qty)}
                />
            );

        }
    }, {
        title: 'Variance',
        render: (v) => (qty[v.id] || 0) - (v.inventory.aggregate.sum.qty || 0)
    }, {
        title: 'Retail Price',
        render: (v) => <CurrencyFormat amount={v.price} />
    }, {
        title: 'Total',
        render: (v) => <CurrencyFormat amount={(qty[v.id] || 0) * v.price} />
    }];

    const greaterThanZero = some(values(qty), (v) => v > 0);

    const total = sumBy(products, (v) => {

        return v.price * (qty[v.id] || 0);

    });

    const selectedQty = pick(qty, selectedRowKeys);

    // console.log(selectedQty);

    const selectedProducts = products.filter((i) => `${i.id}` in selectedQty);
    const selectedTotal = sumBy(selectedProducts, (v) => {

        return v.price * (selectedQty[v.id] || 0);

    });

    return (
        <React.Fragment>
            {products.length > 0 && keys(qty).length > 0 ? <AntButton onClick={() => {

                clear();

                localStorage.removeItem(`${companyId}-${outlet_id}-${user.id}-stock-adjustment`);

                setProducts([]);
                setQty({});

            }} className="flex items-center justify-center my-2" type="danger" ghost>Clear</AntButton> : null}
            <div className="my-8">
                {/* <Input.Search
                    disabled={!outlet_id}
                    ref={inputRef}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onSearch={(code) => {

                        if (!code) return;

                        code = code.trim();

                        const item = find(products, (i) => {

                            return includes(i._skus, `${code}`);

                        });

                        if (!item) return setSearchValue('');

                        if (qty[item.id]) {

                            if (isReverse) {

                                qty[item.id]--;
                                item.updatedAt = Date.now();

                            } else {

                                qty[item.id]++;
                                item.updatedAt = Date.now();

                            }

                        } else {

                            if (isReverse) {

                                // delete item;
                                delete qty[item.id];

                            } else {

                                qty[item.id] = 1;
                                item.updatedAt = Date.now();


                            }
                        }

                        setQty(qty);
                        setProducts(items);
                        setSearchValue('');

                    }}
                    placeholder="Search Products"
                /> */}
                {/* <div className="mt-3">
                    <Checkbox
                        checked={isReverse}
                        disabled={!outlet_id}
                        onChange={(e) => setIsReserve(e.target.checked, () => inputRef.current.focus()) }
                    >Reverse</Checkbox>
                </div> */}
            </div>
            <div className="my-8 shadow rounded">
                <Table
                    rowSelection={rowSelection}
                    dataSource={orderBy(products, ['updatedAt'], ['desc'])}
                    columns={columns}
                    pagination={false}
                    rowKey={(r) => `${r.id}`}
                />
            </div>
            <div className="flex justify-between my-8 -mx-4">
                <div className="flex-1 px-4">
                    <div className="mb-4">
                        <Input.TextArea
                            autosize={{ minRows: 6, maxRows: 6 }}
                            placeholder="Notes / Comments"
                            style={{ width: '100%' }}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </div>
                    <Mutation
                        mutation={gql`
                            mutation(
                                $qty: Int!,
                                $total: float8!,
                                $company_id: Int!,
                                $employee_id: Int!,
                                $items:  [products_history_items_insert_input!]!,
                                $comment: String
                                $source_id: Int
                            ){
                                insert_products_history(objects: {
                                    items: {data: $items},
                                    company_id: $company_id,
                                    destination_id: null,
                                    employee_id: $employee_id,
                                    source_id: $source_id,
                                    total_amount: $total,
                                    total_qty: $qty,
                                    type: "STOCK_ADJUSTMENT",
                                    comment: $comment
                                }) {
                                    returning{
                                        id
                                    }
                                }
                               ${(selectedProducts).map((i, index) => {

                            const { id, product, inventory: { aggregate } } = i;

                            // console.log(selectedQty[id]);


                            return aggregate.count > 0 ? `
                                        u_${index}: update_products_inventory(where: {
                                            outlet_id: {_eq: ${parseInt(outlet_id)}},
                                            variant_id: {_eq: ${parseInt(id)}}
                                        }, _set:{
                                            qty: ${selectedQty[id] || 0},
                                            product_id: ${parseInt(product.id)},
                                            company_id: $company_id
                                        }) {
                                            affected_rows
                                        }
                                    `: `
                                        i_${index}: insert_products_inventory(objects: {
                                            company_id: $company_id,
                                            outlet_id: ${parseInt(outlet_id)},
                                            qty: ${selectedQty[id] || 0},
                                            variant_id: ${parseInt(id)},
                                            product_id: ${parseInt(product.id)}
                                        }) {
                                            affected_rows
                                        }
                                    `;

                        })}
                            }
                        `}
                        variables={{
                            qty: sum(values(selectedQty)),
                            total: selectedTotal,
                            comment,
                            employee_id: user.id,
                            company_id: companyId,
                            source_id: outlet_id,
                            items: selectedProducts.map((i) => ({
                                e_qty: i.inventory.aggregate.sum.qty || 0,
                                price: i.price,
                                qty: qty[i.id],
                                variant_id: i.id,
                                variance: (qty[i.id] || 0) - (i.inventory.aggregate.sum.qty || 0)
                            }))
                        }}
                        onError={() => message.error('An error occurred while trying to save products.')}
                        onCompleted={() => {

                            setProducts([]);
                            setQty({});
                            clear();

                            message.success('Products saved successfully.');

                        }}
                    >
                        {(save, { loading }) => {

                            return (
                                <EverButton
                                    onClick={() => save()}
                                    isLoading={loading}
                                    disabled={selectedRowKeys.length == 0}
                                    intent="success"
                                    appearance="primary"
                                >
                                    Save
                                </EverButton>
                            )

                        }}
                    </Mutation>
                </div>
                <div className="px-4">
                    <div className="rounded w-64 bg-gray-100 px-6 py-3">
                        <div className="border-b py-2 my-2 flex items-center justify-between">
                            <p className="text-black font-bold">Total Units</p>
                            <p className="font-bold text-black">{sum(values(selectedQty))}</p>
                        </div>
                        <div className="py-2 my-2 flex items-center justify-between">
                            <p className="font-bold text-black">Total</p>
                            <p className="font-bold text-black">
                                <CurrencyFormat amount={total} />
                            </p>
                        </div>
                    </div>
                    {/* <div className="my-4">
                        <EverButton intent="success" appearance="primary">Save</EverButton>
                    </div> */}
                </div>
            </div>
        </React.Fragment>
    )

}

export default StockTake;