import { Input, Select } from 'antd';
import { useState } from 'react';
import { withDatabase } from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from "@nozbe/with-observables";
import { Q } from '@nozbe/watermelondb'
import React from 'react';
import { CurrencyFormat } from '../../../services/Currency';
import { of as $of } from 'rxjs';
import { sumBy } from 'lodash';

export default ({
    companyId,
    outletId,
    onSelect
}) => {

    const [searchValue, setSearchValue] = useState();

    // console.log('companyId:', companyId);

    return (
        <SearchResultsWrapper
            companyId={companyId}
            outletId={outletId}
            onSelect={onSelect}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
        />
    )

};

const SearchResults = ({
    companyId,
    outletId,
    products,
    setSearchValue,
    searchValue,
    onSelect
}) => {

    // console.log('products:', products, outletId);


    return (
        <React.Fragment>
            <Input.Search
                size="large"
                allowClear
                value={searchValue}
                placeholder="Enter product name here"
                style={{ width: '100%' }}
                onChange={(e) => setSearchValue(e.target.value)}
            />
            <div className="bg-white w-full rounded-md shadow-md my-4">
                {products.map((p) => {

                    return (
                        <ProductVariantOption
                            key={p.id}
                            variant={p}
                            companyId={companyId}
                            outletId={outletId}
                            onSelect={onSelect}
                            setSearchValue={setSearchValue}
                        />
                    )

                })}
            </div>
        </React.Fragment>
    )

}

const ProductVariantOption = withDatabase(withObservables(['variant'], ({ companyId, outletId, database, variant }) => ({
    inventory: database.collections.get('products_inventory').query(
        Q.where('company_id', Q.eq(parseInt(companyId))),
        Q.where('outlet_id', Q.eq(parseInt(outletId))),
        Q.where('variant_id', Q.eq(parseInt(variant.id)))
    ).observe(),
    product: database.collections.get('products').findAndObserve(`${variant.productId}`)
}))(({
    variant: p,
    product,
    inventory,
    setSearchValue,
    onSelect
}) => {

    // console.log('variant: ', inventory.length);

    const price = inventory.length ? inventory[0].price : p.price;

    return (
        !inventory.length && product.hasVariants ? null : <div className="border-t p-5 cursor-pointer hover:bg-gray-100" onClick={() => {

            const __qty = sumBy(inventory, 'qty');

            console.log(product);

            const item = {
                variant: {
                    id: parseInt(p.id),
                    cost: p.cost,
                    name: p.name,
                    price: p.price,
                    product: {
                        name: product.name,
                        has_variants: (__qty == 0) ? false : product.hasVariants,
                        raw_material: product.rawMaterial,
                    },
                    prices: inventory.map((p) => ({
                        id: parseInt(p.id),
                        price: p.price,
                        cost: p.cost
                    })),
                    inventory: {
                        aggregate: {
                            sum: {
                                qty: __qty
                            }
                        }
                    }
                }
            }

            setSearchValue('');

            onSelect(item);

        }}>
            <p>
                {p.name} - <span className="font-medium"><CurrencyFormat amount={price} /></span>
            </p>
            {product.hasVariants ? <p className="text-xs">Qty: {sumBy(inventory, 'qty')}</p> : null}
        </div>
        // <Select.Option key={p.id}>
        //     {p.name}
        //     {/* <CurrencyFormat amount={_v.variant.price} /> */}
        // </Select.Option> 
    );

}));

const SearchResultsWrapper = withDatabase(withObservables(['searchValue'], ({ database, searchValue, companyId }) => ({
    products: searchValue ? database.collections.get('products_variants').query(
        Q.where('name', Q.like(`%${Q.sanitizeLikeString(searchValue)}%`)),
        Q.where('company_id', Q.eq(parseInt(companyId)))
    ).observe() : $of([]),
}))(SearchResults))