export const groupedRoles = {

    'payroll': 'Payroll',
    'accounts': 'Accounting',
    'pos': 'Points of Sale',
    'employees': "Employees",
    'customers': "Customers",
    'admin': 'Administration'

};

export const requiresOutlet = [
    'pos-sales-supervisor',
    'pos-inventory-manager'
]

export default {
    'employee': {
        name: 'Employee',
        value: 'employee',
        group: 'employees'
    },
    'customer': {
        name: 'Customer',
        value: 'customer',
        group: 'customers'
    },
    'admin': {
        name: 'Administrator',
        value: 'admin',
        group: 'admin'
    },
    'pos-sales-exec': {
        name: 'Sales Executives',
        value: 'pos-sales-exec',
        group: 'pos'
    },
    'pos-inventory-manager': {
        name: 'Inventory Manager',
        value: 'pos-inventory-manager',
        group: 'pos'
    },
    'pos-inventory-officer': {
        name: 'Inventory Officer',
        value: 'pos-inventory-officer',
        group: 'pos'
    },
    'pos-sales-supervisor': {
        name: 'Sales Supervisor',
        value: 'pos-sales-supervisor',
        group: 'pos'
    },
    'pos-sales-manager': {
        name: 'Sales Manager',
        value: 'pos-sales-manager',
        group: 'pos'
    },
    'pos-auditor': {
        name: 'Auditor',
        value: 'pos-auditor',
        group: 'pos'
    },
    'pos-audit-manager': {
        name: 'Audit Manager',
        value: 'pos-audit-manager',
        group: 'pos'
    },
    'pos-accountant': {
        name: 'Accountant',
        value: 'pos-accountant',
        group: 'pos'
    },
    'pos-finance-manager': {
        name: 'Finance Manager',
        value: 'pos-finance-manager',
        group: 'pos'
    },

    'payroll-pay-officer': {
        name: 'Pay officer',
        value: 'payroll-pay-officer',
        group: 'payroll'
    },
    'payroll-pay-manager': {
        name: 'Pay Manager',
        value: 'payroll-pay-manager',
        group: 'payroll'
    },
    'payroll-hr': {
        name: 'HR',
        value: 'payroll',
        group: 'payroll'
    },
    'payroll-audit-officer': {
        name: 'Audit Officer',
        value: 'payroll-audit-officer',
        group: 'payroll'
    },
    'payroll-audit-manager': {
        name: 'Audit Manager',
        value: 'payroll-audit-manager',
        group: 'payroll'
    },
    'payroll-accountant': {
        name: 'Accountant',
        value: 'payroll-accountant',
        group: 'payroll'
    },
    'payroll-finance-manager': {
        name: 'Finance Manager',
        value: 'payroll-finance-manager',
        group: 'payroll'
    },

    'accounts-officer': {
        name: 'Accounts Officer',
        value: 'accounts-officer',
        group: 'accounts'
    },
    'accounts-accountant': {
        name: 'Accountant ',
        value: 'accounts-accountant',
        group: 'accounts'
    },
    'accounts-procurement-officer': {
        name: 'Procurement officer',
        value: 'accounts-procurement-officer',
        group: 'accounts'
    },
    'accounts-audit-officer': {
        name: 'Audit Officer',
        value: 'accounts-audit-officer',
        group: 'accounts'
    },
    'accounts-audit-manager': {
        name: 'Audit Manager',
        value: 'accounts-audit-manager',
        group: 'accounts'
    },
    'accounts-general-manager': {
        name: 'General Manager',
        value: 'accounts-general-manager',
        group: 'accounts'
    },
};