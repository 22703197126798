import React from 'react';
import { RefreshCw } from 'react-feather';
import { Button } from 'antd';
import { Spinner } from 'evergreen-ui';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

import gql from "graphql-tag";
import { Query } from "react-apollo";

const GET_CATEGORIES = gql`
    query($company_id: Int!) {

        categories (where: {company_id: {_eq: $company_id}}) {
            id
            name
            company_id
            raw_materials
            products_aggregate {
                aggregate {
                    count(columns: category_id)
                }
            }
        }

    }
`;

class Categories extends React.Component {

    state = {
        companyId: this.props.match.params.companyId
    };

    componentDidCatch(error, errorInfo) {

        console.log(error, errorInfo);

    }

    render() {

        const { companyId } = this.state;

        return (
            <div className="py-12">
                <div className="w-full max-w-3xl mx-auto px-4">
                    <Query
                        variables={{
                            company_id: companyId
                        }}
                        fetchPolicy="cache-and-network" errorPolicy="ignore"
                        query={GET_CATEGORIES}
                    >
                        {({ loading, data, error, refetch }) => {

                            // console.log(data);

                            return (
                                <React.Fragment>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <h1 className="font-bold text-4xl mr-2">
                                                Categories
                                            </h1>
                                            {loading ? null : <button className="ml-4 focus:outline-none text-black" onClick={() => refetch()}>
                                                <RefreshCw size={24} />
                                            </button>}
                                        </div>
                                        <div className="">
                                            <Button type="primary" onClick={() => this.props.history.push(`/${companyId}/pos/category/add`)} shape="round" ghost icon={<PlusOutlined />} className="flex items-center justify-center">Add</Button>
                                        </div>
                                    </div>
                                    {loading ? <div className="flex items-center justify-center"><Spinner /></div> : <div className="">
                                        {data && data.categories ? data.categories.map((category) => {

                                            return (
                                                <div key={category.id} className="flex items-center justify-between py-4 border-b">
                                                    <div className="">
                                                        <p className="font-bold text-lg text-black">{category.name}</p>
                                                        <Link to={`/${companyId}/pos/category/${category.id}`}>View</Link>
                                                    </div>
                                                    <div className="">
                                                        <p className="">{category.products_aggregate.aggregate.count} Products</p>
                                                    </div>
                                                </div>
                                            )

                                        }) : null}
                                    </div>}
                                </React.Fragment>
                            )

                        }}
                    </Query>

                </div>
            </div>
        )

    }

}

export default Categories