import React from 'react';
import { ApolloProvider} from "react-apollo";
import parseDomain from 'parse-domain';
import Error from './components/Error';
import {Spinner} from 'evergreen-ui';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import App from './App';

import firebase from './services/firebase';
import apollo from './services/apollo';
import EnterEndpoint from './components/EnterEndpoint';
// import isElectron from 'is-electron';
import { message } from 'antd';

const GET_ACCOUNT = gql`
    query ($slug: String!){
        accounts(where: {slug: {_eq: $slug}}) {
            id
            name
            slug
        }
    }
`;

class Main extends React.Component{

	state = {
		account_slug: localStorage.getItem('gomerp.subdomain') || (parseDomain(window.location.hostname) || {}).subdomain || null
    }

    saveSlug = (slug) => {

        this.setState({
            account_slug: slug
        }, () => {

            localStorage.setItem('gomerp.subdomain', slug);

        })

    }
    
    componentDidMount() {

        // console.log('slug:', this.state.account_slug);

		firebase.auth().onAuthStateChanged( async (user) => {

            if (user) {

                this.setState({
                    inited: true,
                    user,
                    token: await user.getIdToken()
                });

            } else {

                this.setState({
                    inited: true,
                    user: null,
                    token: null
                })

            }

        });

    }

	render() {

        const {account_slug, user, token, inited} = this.state;

        // console.log(account_slug);
        
        // const headers = token ? { Authorization: `Bearer ${token}` }: {
        //     'X-Hasura-Role': 'anonymous'
        // };

		return (
			inited ? <ApolloProvider client={apollo}>
				{!account_slug ? <EnterEndpoint save={this.saveSlug} /> :<Query
					query={GET_ACCOUNT}
                    fetchPolicy="cache-and-network"
					variables={{
						slug: account_slug
					}}
				>
					{({error, data, loading}) => {

                        // console.log(error, data);

                        if (error) {

                            message.error('An error occurred while trying to connect to server.');

                        }

						return (
							loading ? <div className="flex h-full items-center justify-center py-10">
								<Spinner />
							</div> : (data && data.accounts && data.accounts.length)  ? <App clearEndpoint={() => this.setState({account_slug: null})} account={data.accounts[0]} />: <EnterEndpoint save={this.saveSlug} />
						)

					}}
				</Query>}
			</ApolloProvider>: <div className="flex h-full items-center justify-center py-10">
                <Spinner />
            </div>
		);

	}

}

export default Main;