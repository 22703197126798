import { Model } from '@nozbe/watermelondb'
import { field, date, readonly } from '@nozbe/watermelondb/decorators'

export default class Discount extends Model {
    
    static table = 'discounts'

    @field('active') active
    @field('code') code
    @field('company_id') companyId
    @field('created_by') createdBy
    @field('customer_id') customerId
    @field('employee_id') employeeId
    @field('expires_at') expiresAt
    @field('name') name
    @field('one_time') oneTime
    @field('outlet_id') outletId
    @field('type') type
    @field('value') value
    
    @field('remote_id') remoteId
    @readonly @date('created_at') createdAt
    @readonly @date('updated_at') updatedAt
}