import React, {useState} from 'react';

import {Checkbox, Button, InputNumber, Select, Table, message} from 'antd';
import {Spinner} from 'evergreen-ui';
import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import { withRouter } from 'react-router-dom';
import { CurrencyFormat, CurrencyWrapper } from '../../../services/Currency';
import {Button as EverButton} from 'evergreen-ui';
import {uniqBy} from 'lodash';
import {DeleteOutlined} from '@ant-design/icons';

const SEARCH_VARIANT = gql`
    query (
        $q: String!,
        $company_id: Int!
    ) {
        products_sku(where: {
            _or: [
                {variant: {
                    name: {_ilike: $q},
                }},
                {variant: {
                    product: {
                        name: {_ilike: $q}
                    }
                }}
            ],
            variant:{
                product:{
                    archived: {_is_null: true},
                    company_id: {
                        _eq: $company_id
                    }
                }
            }
        }) {
            id
            variant {
                id
                cost
                name
                price
                product {
                    id
                    name
                    has_variants
                }
            }
        }
    }
  
`;

export default withRouter((props) => {

    const [searchValue, setSearchValue] = useState('');
    const [variants, setVariants] = useState([]);

    const companyId = props.match.params.companyId;

    const fetchVariantFromSearch = async (value) => {

        const _item = JSON.parse(value);
        
        variants.unshift(_item.variant);

        setVariants(uniqBy([...variants], 'id'));
        setSearchValue('');

    }

    const columns = [{
        title: '#',
        dataIndex: 'code',
        key: 'code',
        render: (sku, v, i) => <span className="font-bold text-black">{i + 1}</span>
    },{
        title: 'Product',
        render: (v) => (
            <div className="">
                <p className="font-bold text-black">{v.product.name}</p>
                <p className="text-black font-light">{v.name}</p>
            </div>
        )
    },{
        title: 'Supply Price',
        render: (v, n, i) => {

            // console.log(selectedRowKeys, v);

            // console.log(v, i);

            return (
                <CurrencyWrapper
                    component={({symbol}) => (
                        <InputNumber
                            min={0}
                            onClick={(e) => { 
                
                                e.target.select();
                
                            }}
                            value={v.cost}
                            style={{width: '100%'}}
                            onChange={(cost) => {

                                if (variants[i]) {
                    
                                    variants[i].cost = cost;

                                    setVariants([
                                        ...variants
                                    ]);

                                }
                
                            }}
                            parser={value => value.replace(/[^0-9.]/g, '')}
                            formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            // size="small"
                        />
                    )}
                />
            );

        }
    },{
        title: 'Retail Price',
        render: (v, n, i) => {

            // console.log(selectedRowKeys, v);

            return (
                <CurrencyWrapper
                    component={({symbol}) => (
                        <InputNumber
                            min={0}
                            onClick={(e) => { 
                
                                e.target.select();
                
                            }}
                            value={v.price}
                            style={{width: '100%'}}
                            onChange={(price) => {

                                if (variants[i]) {
                    
                                    variants[i].price = price;

                                    setVariants([
                                        ...variants
                                    ]);

                                }
                
                            }}
                            parser={value => value.replace(/[^0-9.]/g, '')}
                            formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            // size="small"
                        />
                    )}
                />
            );

        }
    }, {
        title: '',
        render: (v) => <Button onClick={() => {

            setVariants([
                ...variants.filter((_v) => v.id !== _v.id)
            ]);

        }} className="flex items-center justify-center" type="danger" size="small" ghost icon={<DeleteOutlined />} shape="circle" />
    }];

    return (

        <div className="overflow-scroll h-full">
            <div className="max-w-6xl mx-auto w-full px-6">
                <div className="flex items-center justify-between my-10">
                    <div className="flex items-center">
                        <h1 className="font-bold text-4xl mr-2">
                            Base Price Change
                        </h1>
                    </div>
                </div>
                <div className="">
                    <div className="max-w-xs">
                        <div className="flex-1 print-hidden">
                            <div className="my-8">
                                <p className="font-bold text-black">Search Products</p>
                                <Query
                                    query={SEARCH_VARIANT}
                                    variables={{
                                        q: `%${searchValue}%`,
                                        company_id: companyId
                                    }}
                                    skip={!searchValue}
                                >
                                    {({data, error, loading, refetch}) => {

                                        return (
                                            <Select
                                                showSearch
                                                loading={loading}
                                                // ref={this.inputRef}
                                                value={searchValue}
                                                placeholder="Search for products"
                                                style={{width: '100%'}}
                                                // disabled={!source_id || !destination_id}
                                                notFoundContent={loading ? <div className="flex items-center justify-center p-4"><Spinner /></div> : null}
                                                showArrow={false}
                                                allowClear
                                                filterOption={false}
                                                onSearch={(searchValue) => setSearchValue(searchValue)}
                                                onChange={(value) => {

                                                    if (value) {

                                                        fetchVariantFromSearch(value);

                                                    }


                                                }}
                                                // notFoundContent={null}
                                            >
                                                {!loading && data && data.products_sku.length ? data.products_sku.map(d => d.variant.product ? (
                                                    <Select.Option key={d.id} value={JSON.stringify(d)}>
                                                        {d.variant.product.name} - {d.variant.name} - <CurrencyFormat amount={d.variant.price} />
                                                    </Select.Option>
                                                ):null): null}
                                            </Select>
                                        )

                                    }}
                                </Query>
                            </div>
                        </div>
                    </div>
                    <div className="rounded-lg shadow-lg">
                        <Table
                            dataSource={variants}
                            columns={columns}
                            pagination={false}
                            rowKey={(r) => `${r.id}`}
                        />
                    </div>
                    <div className="my-6">
                        <Mutation
                            mutation={gql`
                                mutation{
                                    ${variants.length ? variants.map((v, i) => {

                                        return `

                                            update_${i}: update_products_variants(_set: {
                                                cost: ${v.cost || 0},
                                                price: ${v.price || 0}
                                            }, where: {
                                                id: {_eq: ${v.id}}
                                            }) {
                                                affected_rows
                                            }

                                        `;

                                    }): `update_products_variants(_set: {}, where: {id: {_eq: 0}}) { affected_rows }`}
                                }
                            `}
                            onError={() => message.error('An error occurred while trying to save products.')}
                            onCompleted={() => {

                                setVariants([]);
                                setSearchValue('');

                                message.success('Base Prices saved successfully.');

                            }}
                        >
                            {(save, {loading}) => {

                                return (
                                    <EverButton
                                        onClick={() => save()}
                                        isLoading={loading}
                                        intent="success"
                                        appearance="primary"
                                    >
                                        Save
                                    </EverButton>
                                )
                                
                            }}
                        </Mutation>
                    </div>
                </div>
            </div>
        </div>

    );

});