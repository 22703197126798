import React from 'react';
import {Link} from 'react-router-dom';
import RestrictView from './misc/restrictView';

class Reports extends React.Component{

    state = {
        companyId: this.props.match.params.companyId
    }

    render() {

        const {company} = this.props;
        const {companyId} = this.state;

        console.log(company);

        return (
            <div className="max-w-4xl mx-auto w-full px-8 py-16">
                <h1 className="text-3xl font-bold text-black">Reports</h1>
                <div className="my-6 -mx-4">
                    <div className="flex flex-wrap">
                        <Link to={`/${companyId}/reports/pos`} className="w-1/2 my-4 px-4">
                            <p className="font-bold text-lg text-black mb-1">Point Of Sales</p>
                            <p className="">
                                Detailed reports on Point of Sales
                            </p>                  
                        </Link>
                        <RestrictView allowed={company.allowed_services} value="accounting">
                            <Link to={`/${companyId}/reports/accounting`} className="w-1/2 my-4 px-4">
                                <p className="font-bold text-lg text-black mb-1">Accounting</p>
                                <p className="">Detailed Reports on Accounting</p>
                            </Link>
                        </RestrictView>
                        {/* <Link to={`/${companyId}/accounting/reports/balances`} className="w-1/2 my-4 px-4">
                            <p className="font-bold text-lg text-black mb-1">Account Balances</p>
                            <p className="">Summary view of balances and activity for all accounts.</p>
                        </Link>
                        <Link to={`/${companyId}/accounting/reports/trial-balance`} className="w-1/2 my-4 px-4">
                            <p className="font-bold text-lg text-black mb-1">Trial Balance</p>
                            <p className="">Balance of all your accounts on a specified date.</p>
                        </Link> */}
                    </div>
                </div>
            </div>
        );

    }

}

export default Reports;