import Loadable from 'react-loadable';
import Loading from './components/Loading';

import Products from './components/POS/Products';
import Product from './components/POS/Product';
import ProductOverview from './components/POS/ProductOverview';
import Outlets from './components/POS/Outlets';
import Outlet from './components/POS/Outlet';
import Suppliers from './components/POS/Suppliers';
import Supplier from './components/POS/Supplier';
import Categories from './components/POS/Categories';
import Category from './components/POS/Category';
import Sales from './components/POS/Sales';
import Inventory from './components/POS/Inventory';
import StockControl from './components/POS/StockControl';
import NewStock from './components/POS/Stocks/NewStock';
import Transfer from './components/POS/Stocks/Transfer';
import StockReturn from './components/POS/Stocks/Return';
import StockTake from './components/POS/Stocks/StockTake';

import PriceChange from './components/POS/Stocks/PriceChange';

import Transactions from './components/Accounting/Transactions';
import Charts from './components/Accounting/Charts';
import AccountsReports from './components/Accounting/Reports';
import BalanceReports from './components/Accounting/Reports/Balances';
import ProfitLossReports from './components/Accounting/Reports/ProfitLoss';
import BalanceSheet from './components/Accounting/Reports/BalanceSheet';
import TrialBalance from './components/Accounting/Reports/TrialBalance';

import Home from './components/Home';
import Profile from './components/Profile';
import Company from './components/Company';
import Employees from './components/Employees';
import Employee from './components/Employee';
import Payroll from './components/Payroll';
import GeneratePayroll from './components/GeneratePayroll';
import PayrollSingle from './components/PayrollSingle';
import Subscription from './components/Subscription';
import Procument from './components/Procument/Home';
// import AggregatorsSettings from './components/AggregatorsSettings';
// import DeductionsSettings from './components/DeductionsSettings';
import Aggregators from './components/Aggregators';
// import Aggregator from './components/Aggregator';
import Deductions from './components/Deductions';
// import Deduction from './components/Deduction';
import Users from './components/Users';
import User from './components/User';

import Customers from './components/POS/Customers';
import Customer from './components/POS/Customer';

import ProductBarcodes from './components/POS/ProductBarcodes';
import RawMaterials from './components/Procument/RawMaterials';
import RawMaterial from './components/Procument/RawMaterial';
import PurchaseOrder from './components/POS/Procument/PurchaseOrder';
import ReceivePurchaseOrder from './components/POS/Procument/ReceivePurchaseOrder';
import POSHome from './components/POS/Home';

import Discount from './components/POS/Discount';
import Discounts from './components/POS/Discounts';

import GoodsReceived from './components/misc/GoodsReceived';

import CompanyHome from './components/CompanyHome';
import ReceiveStockContainer from './components/POS/Stocks/ReceiveStock';
import GenerateReport from './components/POS/GenerateReport';
import Trends from './components/POS/Trends';

import ProductMultiple from './components/POS/ProductMultiple';

import Reports from './components/Reports';
import PriceChangeBase from './components/POS/Stocks/PriceChangeBase';

import Allowances from './components/HR/Allowances';
import SingleSaleContainer from './components/POS/SalesSingle';
import StockTakeMini from './components/POS/Stocks/StockTakeMini';
import StockTakeContainer from './components/POS/Stocks/StockTakeSingle';
import BillOfMaterial from './components/POS/Stocks/BillOfMaterial';

export default [{
    path: '/',
    exact: true,
    component: Home
}, {
    key: "users",
    path: '/users',
    exact: true,
    component: Users,
    roles: ["admin"]
}, {
    key: "users-create",
    path: '/users/create',
    exact: true,
    component: User,
    roles: ["admin"]
}, {
    key: "users-edit",
    path: '/users/:userId',
    exact: true,
    component: User,
    roles: ["admin"]
}, {
    key: "create-company",
    path: '/company/create',
    exact: true,
    component: Company,
    roles: ["admin"]
}, {
    key: "employees",
    path: '/:companyId/hr',
    exact: true,
    component: Employees
}, {
    key: "company-settings",
    path: '/:companyId/settings',
    exact: true,
    component: Company
}, {
    key: "add-employee",
    path: '/:companyId/hr/employee/add',
    exact: true,
    component: Employee
}, {
    key: "edit-employee",
    path: '/:companyId/hr/employee/:employeeId',
    exact: true,
    component: Employee
}, {
    path: '/:companyId/home',
    exact: true,
    component: CompanyHome
}, {
    path: '/:companyId/procument',
    exact: true,
    component: Procument
}, {
    path: '/:companyId/reports',
    exact: true,
    component: Reports
}, {
    path: '/:companyId/pos',
    exact: true,
    component: POSHome
}, {
    path: '/:companyId/pos/outlets',
    exact: true,
    component: Outlets,
    roles: ["admin"]
}, {
    path: '/:companyId/procument/raw-materials/new',
    key: "raw-material-new",
    exact: true,
    component: RawMaterial
}, {
    path: '/:companyId/procument/raw-materials/:materialId',
    key: "raw-material-edit",
    exact: true,
    component: RawMaterial
}, {
    path: '/:companyId/procument/raw-materials',
    key: 'raw-materials',
    exact: true,
    component: RawMaterials
}, {
    path: '/:companyId/pos/purchase-order/new',
    key: "new-purchase-order",
    exact: true,
    component: PurchaseOrder,
    roles: ["admin", "pos-inventory-manager"]
}, {
    path: '/:companyId/pos/purchase-order/:id/receive',
    key: "receive-purchase-order",
    exact: true,
    component: ReceivePurchaseOrder
}, {
    path: '/:companyId/pos/purchase-order/:id',
    key: "edit-purchase-order",
    exact: true,
    component: PurchaseOrder
}, {
    path: '/:companyId/pos/goods-received',
    exact: true,
    component: GoodsReceived,
    roles: ["admin", "pos-inventory-manager", "pos-inventory-officer", "pos-sales-supervisor"]
}, {
    path: '/:companyId/pos/stocks',
    exact: true,
    component: StockControl,
    roles: ["admin", "pos-inventory-officer", "pos-inventory-manager", "pos-audit-manager"]
}, {
    path: '/:companyId/reports/pos',
    exact: true,
    component: GenerateReport
}, {
    path: '/:companyId/pos/stocks/new',
    exact: true,
    component: NewStock,
    roles: ["admin", "pos-inventory-officer", "pos-inventory-manager"]
},
{
    path: '/:companyId/pos/products/add-multiple',
    exact: true,
    component: ProductMultiple,
    roles: ["admin", "pos-inventory-manager", "pos-audit-manager"]
},
{
    path: '/:companyId/pos/transfers/:id/receive',
    exact: true,
    component: ReceiveStockContainer
},
{
    path: '/:companyId/pos/transfers/:id',
    exact: true,
    component: Transfer
}, {
    path: '/:companyId/pos/stock-take/:id',
    exact: true,
    component: StockTakeContainer
},
{
    path: '/:companyId/pos/sales/:id',
    exact: true,
    component: SingleSaleContainer
},
{
    path: '/:companyId/pos/stocks/transfer',
    exact: true,
    component: Transfer,
    roles: ["admin", "pos-inventory-officer", "pos-inventory-manager"]
},
{
    path: '/:companyId/pos/stocks/return',
    exact: true,
    component: StockReturn
},
{
    path: '/:companyId/pos/stocks/take',
    exact: true,
    component: StockTake,
    roles: ["admin", "pos-inventory-officer", "pos-inventory-manager", "pos-audit-manager"]
}, {
    path: '/:companyId/pos/stocks/adjustment',
    exact: true,
    component: StockTakeMini,
    roles: ["admin", "pos-inventory-officer", "pos-inventory-manager", "pos-audit-manager"]
}, {
    path: '/:companyId/pos/stocks/bill-of-material',
    exact: true,
    component: BillOfMaterial,
    roles: ["admin", "pos-inventory-officer", "pos-inventory-manager", "pos-audit-manager"]
}, {
    path: '/:companyId/pos/stocks/price-change',
    exact: true,
    component: PriceChange,
    roles: ["admin", "pos-inventory-officer", "pos-inventory-manager", "pos-audit-manager"]
}, {
    path: '/:companyId/pos/stocks/base-price-change',
    exact: true,
    component: PriceChangeBase,
    roles: ["admin", "pos-inventory-officer", "pos-inventory-manager", "pos-audit-manager"]
},
{
    path: '/:companyId/pos/trends/:type',
    exact: true,
    component: Trends
},
{
    path: '/:companyId/pos/outlets/add',
    exact: true,
    component: Outlet,
    roles: ["admin"]
},
{
    path: '/:companyId/pos/outlets/:outletId',
    exact: true,
    component: Outlet,
    roles: ["admin"]
},
{
    path: '/:companyId/pos/customers',
    exact: true,
    component: Customers,
    roles: ["admin", "pos-sales-supervisor", "pos-sales-manager"]
},
{
    path: '/:companyId/pos/customers/create',
    exact: true,
    component: Customer,
    roles: ["admin", "pos-sales-supervisor", "pos-sales-manager"]
},
{
    path: '/:companyId/pos/customers/:customerId',
    exact: true,
    component: Customer,
    roles: ["admin", "pos-sales-supervisor", "pos-sales-manager"]
},
{
    path: '/:companyId/pos/discounts/create',
    exact: true,
    component: Discount,
    roles: ["admin", "pos-sales-manager"]
},
{
    path: '/:companyId/pos/discounts/:discountId',
    exact: true,
    component: Discount,
    roles: ["admin", "pos-sales-manager"]
},
{
    path: '/:companyId/pos/discounts',
    exact: true,
    component: Discounts,
    roles: ["admin", "pos-sales-manager"]
},
{
    path: '/:companyId/pos/products',
    exact: true,
    component: Products,
    roles: ["admin", "pos-inventory-manager", "pos-audit-manager"]
},
{
    path: '/:companyId/pos/product/add',
    exact: true,
    component: Product,
    roles: ["admin", "pos-inventory-manager", "pos-audit-manager"]
},
{
    path: '/:companyId/pos/product/:productId/edit',
    exact: true,
    component: Product
}, {
    path: '/:companyId/pos/barcodes',
    exact: true,
    component: ProductBarcodes,
    roles: ["admin", "pos-inventory-manager", "pos-inventory-officer"]
},
{
    path: '/:companyId/pos/product/:productId',
    roles: ["admin", "pos-inventory-manager"],
    exact: true,
    component: ProductOverview
},
{
    path: '/:companyId/procument/suppliers',
    exact: true,
    component: Suppliers,
    roles: ["admin", "pos-inventory-manager"]
},
{
    path: '/:companyId/procument/supplier/add',
    exact: true,
    component: Supplier,
    roles: ["admin", "pos-inventory-manager"]
},
{
    path: '/:companyId/procument/supplier/:supplierId',
    exact: true,
    component: Supplier,
    roles: ["admin", "pos-inventory-manager"]
},

{
    path: '/:companyId/pos/categories',
    exact: true,
    component: Categories,
    roles: ['admin', 'pos-inventory-manager', "pos-audit-manager"]
},
{
    path: '/:companyId/pos/category/add',
    exact: true,
    component: Category
},
{
    path: '/:companyId/pos/category/:categoryId',
    exact: true,
    component: Category
},

{
    path: '/:companyId/pos/inventory',
    exact: true,
    component: Inventory
},
{
    path: '/:companyId/pos/sales',
    exact: true,
    component: Sales,
    roles: ['admin', 'pos-sales-supervisor', "pos-inventory-manager", "pos-inventory-officer"]
},

{
    path: '/:companyId/accounting',
    exact: true,
    component: Transactions
},
{
    path: '/:companyId/accounting/charts',
    exact: true,
    component: Charts
},
{
    path: '/:companyId/reports/accounting',
    exact: true,
    component: AccountsReports
}, {
    path: '/:companyId/reports/accounting/balances',
    exact: true,
    component: BalanceReports
}, {
    path: '/:companyId/reports/accounting/trial-balance',
    exact: true,
    component: TrialBalance
}, {
    path: '/:companyId/reports/accounting/profit-loss',
    exact: true,
    component: ProfitLossReports
}, {
    path: '/:companyId/reports/accounting/balance-sheet',
    exact: true,
    component: BalanceSheet
},
{
    path: '/:companyId/hr/payroll',
    exact: true,
    component: Payroll
},
{
    path: '/:companyId/hr/aggregators',
    exact: true,
    component: Aggregators
},
{
    path: '/:companyId/hr/allowances',
    exact: true,
    component: Allowances
},
{
    path: '/:companyId/hr/deductions',
    exact: true,
    component: Deductions
},
// {
//     path: '/:companyId/hr/aggregators/settings',
//     exact: true,
//     component: AggregatorsSettings
// },
// {
//     path: '/:companyId/hr/deductions/settings',
//     exact: true,
//     component: DeductionsSettings
// },
// {
//     path: '/:companyId/hr/deductions/add',
//     exact: true,
//     component: Deduction
// },
// {
//     path: '/:companyId/hr/deductions/:deductionId',
//     exact: true,
//     component: Deduction
// },
{
    path: '/:companyId/hr/logs',
    exact: true,
    component: Aggregators
},
// {
//     path: '/:companyId/hr/aggregators/add',
//     exact: true,
//     component: Aggregator
// },
// {
//     path: '/:companyId/hr/aggregators/:aggregatorId',
//     exact: true,
//     component: Aggregator
// },
{
    path: '/:companyId/hr/payroll/generate',
    exact: true,
    component: GeneratePayroll
},
{
    path: '/:companyId/hr/payroll/:payrollId',
    exact: true,
    component: PayrollSingle
},
{
    path: '/profile',
    exact: true,
    component: Profile
},
{
    path: '/subscription',
    exact: true,
    component: Subscription,
    roles: ['admin']
},
]
