import React, { useState } from 'react';
import Chart from "react-apexcharts";
import {Spinner} from 'evergreen-ui';
import {capitalize} from 'lodash';
import {DatePicker, Button as AntButton} from 'antd';
import moment from 'moment';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';
import abbreviate from 'mout/number/abbreviate';
import currencyFormat from 'mout/number/currencyFormat';
import { CurrencyFormat, CurrencyWrapper } from '../../services/Currency';

const {RangePicker} = DatePicker;

const dateFormat = "YYYY-MM-DD[T]HH:mm";

const queries = {
    sales: `
        payload: orders(where:{
            created_at:{
                _gte: $fromDate,
                _lte: $toDate
            }
        }, order_by:{
            created_at: desc
        }){
            created_at
            total_amt
        }
    `
};


export default ({match}) => {

    // console.log(props);

    const {params: {type}} = match;


    const [options, setOptions] = useState({
        chart: {
            id: 'area-datetime',
            type: 'area',
            height: 150,
            zoom: {
              autoScaleYaxis: true
            }
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
            style: 'hollow',
        },
        yaxis: {
            labels: {
                formatter: function (value) {

                    return <CurrencyWrapper
                        component={({symbol}) => (
                            `${symbol} ${abbreviate(value, 1)}`
                        )}
                    />;
                }
            }
        },
        xaxis: {
            type: 'datetime',
            // min: new Date('01 Mar 2012').getTime(),
            tickAmount: 6,
        },
        tooltip: {
            x: {
                format: 'dd MMM yyyy HH:mm TT',
            },
            y: {
                formatter: function (value) {
                    return <CurrencyFormat
                        amount={value}
                    />;
                }
            }
        },
        // fill: {
        //     type: 'gradient',
        //     gradient: {
        //       shadeIntensity: 1,
        //       opacityFrom: 0.7,
        //       opacityTo: 0.9,
        //       stops: [0, 100]
        //     }
        // },
    });

    // const [series, setSeries] = useState([
    //     {
    //       name: "series-1",
    //       data: [30, 40, 45, 50, 49, 60, 70, 91]
    //     }
    // ]);

    const [fromDate, setFromDate] = useState(moment().subtract(1, 'week').format(dateFormat));
    const [toDate, setToDate] = useState(moment().format(dateFormat));

    return (
        <div className="max-w-4xl mx-auto py-12">
            <p className="text-2xl font-bold text-black mb-4">{capitalize(type)} Trends</p>
            <div className="">
                <RangePicker
                    value={[moment(fromDate, dateFormat), moment(toDate, dateFormat)]}
                    showTime
                    format={dateFormat}
                    size="large"
                    onChange={(dates, dateStrings) => {

                        setFromDate(dateStrings[0]);
                        setToDate(dateStrings[1]);
                    }}
                />
            </div>
            <Query
                query={gql`
                    query(
                        $fromDate: timestamptz,
                        $toDate: timestamptz,
                    ){
                        ${queries[type] || ''}
                        products(where:{
                            id: {_eq: 0}
                        }){
                            id
                        }
                    }
                `}
                variables={{
                    fromDate,
                    toDate
                }}
                skip={!queries[type]}
            >
                {({data, error, loading, refetch}) => {

                    if (loading) {

                        return (
                            <div className="flex h-full items-center justify-center">
                                <Spinner />
                            </div>
                        )

                    }

                    if (error || !loading && (!data || !data.payload)) {

                        return (
                            <div className="flex h-full items-center justify-center">
                                <div className="">
                                    <AntButton onClick={() => refetch()}>
                                        Retry
                                    </AntButton>
                                </div>
                            </div>
                        )

                    }

                    // console.log(
                    //     data.payload.map((r) => [moment(r.created_at).valueOf(), r.total_amt])
                    // )

                    return (
                        <Chart
                            options={options}
                            series={[{
                                name: 'Total Sales',
                                data: data.payload.map((r) => [moment(r.created_at).valueOf(), r.total_amt])
                            }]}
                            width="100%"
                        />
                    );

                }}
            </Query>
        </div>
    )

}