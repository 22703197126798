import React from 'react';
import {Input, Table, Select, Button as AntButton, Spin, Checkbox, InputNumber, message} from 'antd';
import {Button as EverButton, Spinner} from 'evergreen-ui';
import {DeleteOutlined, WarningOutlined} from '@ant-design/icons';
import {values, orderBy, sum, sumBy, every, keyBy, fromPairs, find, some} from 'lodash';
import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import {currencyFormat} from 'mout/number';
import AddStock from '../Stocks/Modals/AddStock';
import apollo from '../../../services/apollo';
import { CurrencyFormat, CurrencyWrapper } from '../../../services/Currency';

const GET_LPO = gql`
    query($id: Int!) {

        lpo: purchase_order_by_pk(id: $id) {
            comment
            id
            qty
            total
            received
            comment
            supplier_id
            created_at
            employee{
                id
                display_name
            }
            supplier{
                id
                name
            }
            items {
              id
              cost: price
              qty
              variant{
                  id
                  name
                  price
                  product{
                      id
                      name
                  }
                  stock_balance{
                    outlet_id
                    qty
                  }
              }
            }
        }

    }
`;

const GET_OUTLETS = gql`
    query($company_id: Int!) {

        outlets (where: {
            company_id: {_eq: $company_id},
            warehouse:{
                _eq: true
            }
        }) {
            id
            name
            company_id
        }

    }
`;

const GET_VARIANT = gql`
    query (
        $code: String!,
        $outlet_id: Int!,
        $company_id: Int!
    ) {
        products_sku(where: {
            code: {_eq: $code},
            variant:{
                product:{
                    company_id: {
                        _eq: $company_id
                    }
                }
            }
        }) {
            code
            id
            variant {
              id
              cost
              name
              product {
                id
                name
                supplier {
                  name
                }
              }
              exists: inventory_aggregate(where: {outlet_id: {_eq: $outlet_id}}, distinct_on: outlet_id) {
                    aggregate{
                        count(columns: outlet_id)
                    }
              }
              inventory: inventory_aggregate(where: {outlet_id: {_eq: $outlet_id}}) {
                aggregate {
                  sum {
                    qty
                  }
                }
              }
            }
        }
    }
  
`;

class ReceivePurchaseOrder extends React.Component{

    state = {
        companyId: this.props.match.params.companyId,
        productId: this.props.match.params.productId,
        outlet_id: null,
        isReverse: false,
        items: keyBy(this.props.lpo.items, 'id'),
        e_qty: fromPairs(this.props.lpo.items.map((i) => [i.id, i.qty])),
        qty: {},
        comment: '',
        searchValue: ''
    }

    inputRef = React.createRef()

    columns = [{
        title: '',
        key: 'warning',
        render: (v) => {

            const {e_qty, qty} = this.state;

            return (
                qty[v.code] < e_qty[v.code] ? <span className="color-red">
                    <WarningOutlined style={{
                        fontSize: 18,
                        color: 'red'
                    }} />
                </span>: null
            );

        }
    },{
        title: 'Order #',
        dataIndex: 'code',
        key: 'code',
        render: (sku, v, i) => <span className="font-bold text-black">{i + 1}</span>
    },{
        title: 'Product',
        render: (v) => v.variant ? <div className="">
            <p className="font-bold text-black">{v.variant.product.name}</p>
            <p className="text-black font-light">{v.variant.name}</p>
        </div>: null
    },{
        title: 'Supply Price',
        render: (v) => v.variant ? <CurrencyFormat amount={v.variant.cost} /> : null
    },{
        title: 'Expected Quantity',
        render: (v) => {

            const {e_qty} = this.state;

            return e_qty[v.code];

        }
    },{
        title: 'Quantity',
        render: (v) => {

            const {qty} = this.state;

            // return qty[v.code] || 0;

            return (
                <InputNumber
                    value={qty[v.code] || 0}
                    min={0}
                    onClick={(e) => e.target.select()}
                    // formatter={value => `${value}  ${v.product.unit.toUpperCase()}`}
                    // parser={value => value.replace(`  ${v.product.unit.toUpperCase()}`, '')}
                    onChange={(_qty) => {

                        qty[v.code] = parseInt(_qty);

                        this.setState({qty});

                    }}
                />
            )

        }
    },{
        title: 'Retail Price',
        render: (v) => <CurrencyWrapper
            component={({symbol}) => (
                <InputNumber
                    min={v.variant.default_price}
                    onClick={(e) => { 

                        e.target.select();

                    }}
                    value={v.variant.price}
                    style={{width: '100%'}}
                    onChange={(price) => {

                        this.state.items[v.code].variant.price = price;

                        this.setState({
                            items: this.state.items
                        });

                    }}
                    parser={value => value.replace(/[^0-9.]/g, '')}
                    formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    size="small"
                />
            )}
        />
    },{
        title: 'Total',
        render: (v) => v.variant ? <CurrencyFormat amount={(this.state.qty[v.code] || 0) * v.variant.cost} /> : null
    }
    // {
    //     title: '',
    //     render: (v) => v.loading ? <Spin size="small" /> : <AntButton onClick={() => {

    //         let {qty, items} = this.state;

    //         delete items[v.code];
    //         delete qty[v.code];


    //         this.setState({
    //             items: {...items},
    //             qty: {...qty}
    //         });

    //     }} className="flex items-center justify-center" type="danger" size="small" ghost icon={<DeleteOutlined />} shape="circle" />
    // }
    ];

    fetchVariant = async (code, _qty) => {

        const {outlet_id, qty, e_qty, companyId, isReverse} = this.state;

        const {data} = await apollo.query({
            query: GET_VARIANT,
            variables:{
                code,
                outlet_id,
                company_id: companyId
            }
        });

        // console.log(data);

        if (data.products_sku.length) {

            const _code = data.products_sku[0].variant.id;

            if (isReverse) {

                if (qty[_code] > 0) {

                    qty[_code]--;

                }


            } else {

                if (qty[_code] === e_qty[_code]) return;

                if (qty[_code]) {

                    if (_qty) {

                        qty[_code] += _qty;

                    } else {
                    
                        qty[_code]++;

                    }

                } else {
                    
                    qty[_code] = _qty || 1;

                }

            }

            this.setState({
                qty,
                isReverse: false
            });


        } else {

            this.setState({
                barcode: code,
                loading: false,
                showModal: true
            });

            // alert('Show Modal');

        }

    }

    returnItems = () => {

        const {e_qty, qty, items} = this.state;

        const _items = values(items).filter((i) => {

            const {code} = i;

            return (e_qty[code] - qty[code]) > 0 || !qty[code];

        }).map((i) => ({
            ...i,
            variance: e_qty[i.code] - (qty[i.code] || 0)
        }));

        // console.log(_items);

        const total = sumBy(_items, (v) => {

            return v.variant ? v.variant.cost * (v.variance): 0;

        });

        const total_qty = sumBy(_items, 'variance');

        return _items.length ? `
            return_items: insert_products_history(objects: {
                items: {data: [${_items.map((i) => {

                    return `
                        {
                            price: ${i.variant.price},
                            qty: ${i.variance},
                            variant_id: ${i.variant.id}
                        },
                    `;

                })}]},
                company_id: $company_id,
                destination_id: null,
                employee_id: $employee_id,
                source_id: null,
                total_amount: ${total},
                total_qty: ${total_qty},
                type: "RETURN_PURCHASE_ORDER",
                supplier_id: $supplier_id
            }) {
                affected_rows
            }
        `:  ``;

    }

    get SAVE_MUTATION() {

        const {items, qty, outlet_id} = this.state;
        const {id} = this.props;

        return gql`
            mutation(
                $total_qty: Int!,
                $total_amount: float8!,
                $company_id: Int!,
                $destination_id: Int,
                $employee_id: Int!,
                $items:  [products_history_items_insert_input!]!,
                $comment: String,
                $supplier_id: Int
            ){
                ${this.returnItems()}
                update_purchase_order(where:{
                    id:{_eq: ${parseInt(id)}}
                }, _set:{
                    received: true
                  }){
                    affected_rows
                }
                insert_products_history(objects: {
                    items: {data: $items},
                    company_id: $company_id,
                    destination_id: $destination_id,
                    employee_id: $employee_id,
                    source_id: null,
                    total_amount: $total_amount,
                    total_qty: $total_qty,
                    type: "PURCHASE_ORDER"
                    comment: $comment,
                    supplier_id: $supplier_id
                }) {
                    affected_rows
                }
                ${values(items).map((i, index) => {

                    return `
                        update_pricing_${index}: update_products_variants(where:{
                            id:{_eq:${parseInt(i.variant.id)}}
                        }, _set:{
                            cost: ${i.variant.cost},
                            price: ${i.variant.price}
                        }){
                            affected_rows
                        }
                    `

                })}
                ${values(items).map((i, index) => {

                    // console.log(i);

                    const {code, variant} = i;
                    const exists = find(variant.stock_balance, {outlet_id});
                    const _qty = qty[code];

                    return !exists ? `

                        i_${index}: insert_products_inventory(objects: {
                            company_id: $company_id,
                            outlet_id: ${parseInt(outlet_id)},
                            qty: ${_qty || 0},
                            variant_id: ${parseInt(variant.id)},
                            product_id: ${parseInt(variant.product.id)}
                        }) {
                            affected_rows
                        }
                                        
                    ` :`
                    
                        u_${index}: update_products_inventory(where: {
                            outlet_id: {_eq: ${parseInt(outlet_id)}},
                            variant_id: {_eq: ${parseInt(variant.id)}}
                        }, _inc: {
                            qty: ${_qty || 0}
                        }, _set:{
                            company_id: $company_id,
                            product_id: ${parseInt(variant.product.id)}
                        }) {
                            affected_rows
                        }

                    `;

                })}
            }
        `;

    }

    fetchExists = () => {



    }

    clear = () => {

        this.setState({
            barcode: '',
            searchValue: '',
            outlet_id: null
        }, () => this.fetchExists());

    }

    render() {

        const {
            companyId,
            outlet_id,
            qty,
            e_qty,
            items,
            searchValue,
            comment,
            isReverse,
            loading,
            barcode,
            showModal
        } = this.state;
        const itemsCount = Object.keys(items).length;
        const _items = values(items);
        const {user, lpo, id} = this.props;
        const {supplier} = lpo;

        const total = sumBy(_items, (v) => {

            return v.variant ? v.variant.cost * (qty[v.code] || 0): 0;

        });

        return (
            <React.Fragment>
                <div className="overflow-scroll h-full">
                    <div className="max-w-5xl mx-auto w-full px-6">
                        <div className="flex items-center justify-between my-10">
                            <div className="flex items-center">
                                <h1 className="font-bold text-4xl mr-2">
                                    Purchase Order # {id}
                                </h1>
                            </div>
                        </div>
                        <div className="flex items-end my-2">
                            <div className="max-w-xs w-full">
                                <p className="font-bold text-black">Destination Outlet</p>
                                <div className="py-2">
                                    <Query
                                        query={GET_OUTLETS}
                                        variables={{
                                            company_id: companyId
                                        }}
                                    >
                                        {({loading, data, error, refetch}) => {

                                            return (
                                                loading ? <Select placeholder="Select Outlet" key="loading" value={loading ? "loading":"choose" } style={{width: '100%'}}>
                                                    <Select.Option value="loading" disabled={true}>Loading</Select.Option>
                                                </Select>: <Select 
                                                    value={outlet_id}
                                                    onChange={(outlet_id) => {
                                                        
                                                        this.setState({outlet_id}, () => this.fetchExists());
                                                    
                                                    }}
                                                    placeholder="Select Outlet"
                                                    style={{width: '100%'}}
                                                >
                                                    {data.outlets.map((outlet) => {

                                                        return <Select.Option key={outlet.id} value={outlet.id}>{outlet.name}</Select.Option>

                                                    })}
                                                </Select>
                                            )

                                        }}
                                    </Query>
                                </div>
                            </div>
                            {/* {outlet_id ? <AntButton onClick={() => {

                                this.clear();

                            }} className="flex items-center justify-center my-2 mx-2" type="danger" ghost>Clear</AntButton>: null} */}
                        </div>
                        {/* <div className="my-8">
                            <Input.Search
                                loading={loading}
                                disabled={!outlet_id}
                                ref={this.inputRef}
                                value={searchValue}
                                onChange={(e) => this.setState({searchValue: e.target.value})} onSearch={(code) => {

                                    if (!code) return;

                                    code = code.trim();

                                    this.setState({loading: true});

                                    if (!qty[code]) {

                                        setTimeout(async () => {

                                            this.fetchVariant(code);

                                        }, 1);

                                    } else {

                                        this.state.qty[code] = this.state.qty[code]++;
                                        
                                    }


                                    this.setState({
                                        loading: false,
                                        searchValue: '',
                                        qty: this.state.qty
                                    });

                                }}
                                placeholder="Search Products"
                            />
                            <div className="mt-3">
                                <Checkbox
                                    checked={isReverse}
                                    disabled={!outlet_id}
                                    onChange={(e) => this.setState({isReverse: e.target.checked}, () => this.inputRef.current.focus())}
                                >Reverse</Checkbox>
                            </div>
                        </div> */}
                        <div className="my-8 shadow rounded">
                            <Table
                                dataSource={orderBy(_items, ['updatedAt'], ['desc'])}
                                columns={this.columns}
                                pagination={false}
                            />
                        </div>
                        <div className="flex justify-between my-8 -mx-4">
                            <div className="flex-1 px-4">
                                <div className="mb-4">
                                    <Input.TextArea
                                        onChange={(e) => this.setState({comment: e.target.value})}
                                        autosize={{ minRows: 6, maxRows: 6 }}
                                        placeholder="Notes / Comments"
                                        style={{width: '100%'}}
                                    />
                                </div>
                                {outlet_id && _items.length ? <Mutation
                                    mutation={this.SAVE_MUTATION}
                                    onCompleted={() => {
                                        
                                        this.props.history.push(`/${companyId}/pos/stocks`)

                                    }}
                                    variables={{
                                        comment,
                                        supplier_id: supplier.id,
                                        destination_id: outlet_id,
                                        employee_id: user.id,
                                        total_amount: total,
                                        company_id: companyId,
                                        total_qty: sum(values(qty)),
                                        items: _items.map((i) => ({
                                            e_qty: e_qty[i.code],
                                            price: i.variant.price,
                                            qty: qty[i.code] || 0,
                                            variant_id: i.variant.id
                                        })),
                                    }}
                                >
                                    {(save, {loading}) => {

                                        return (
                                            <EverButton
                                                isLoading={loading}
                                                onClick={() => {

                                                    // if (some(_items, (i) => {

                                                    //     return qty[i.code] === 0;

                                                    // })){

                                                    //     return message.error('Some quantities do not match expected quantities');

                                                    // }
                                                    
                                                    save()
                                                    
                                                }}
                                                disabled={itemsCount < 1 || !every(_items, (v) => v.variant)}
                                                intent="success"
                                                appearance="primary"
                                            >
                                                Save
                                            </EverButton>
                                        )

                                    }}
                                </Mutation>:null}
                            </div>
                            <div className="px-4">
                                <div className="rounded w-64 bg-gray-100 px-6 py-3">
                                    <div className="border-b py-2 my-2 flex items-center justify-between">
                                            <p className="text-black font-bold">Total Units</p>
                                            <p className="font-bold text-black">{sum(values(qty))}</p>
                                        </div>
                                        <div className="py-2 my-2 flex items-center justify-between">
                                            <p className="font-bold text-black">Total</p>
                                            <p className="font-bold text-black">
                                                <CurrencyFormat amount={total} />
                                            </p>
                                        </div>
                                    </div>
                                {/* <div className="my-4">
                                    <EverButton intent="success" appearance="primary">Save</EverButton>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {showModal ? <AddStock
                        value={barcode}
                        companyId={companyId}
                        onOk={(sku, qty) => {


                            this.fetchVariant(sku, qty);

                            this.setState({showModal: false});

                        }}
                        onCancel={() => this.setState({showModal: false})}
                    /> : null}
                </div>
            </React.Fragment>
        )

    }

}

class ReceivePurchaseOrderContainer extends React.Component {

    state = {
        companyId: this.props.match.params.companyId,
        id: this.props.match.params.id
    }

    render() {

        const {companyId, id} = this.state;

        return (

            <Query
                query={GET_LPO}
                skip={id ? false: true}
                variables={{
                    id
                }}
                onCompleted={(data) => {

                    if (data && data.lpo && data.lpo.received) {

                        this.props.history.push(`/${companyId}/pos/purchase-order/${id}`);

                    }

                }}
            >
                {({loading, data, error, refetch}) => {

                    return (
                        <div className="overflow-scroll h-full w-full">
                            {loading ? <div className="flex items-center justify-center py-10">
                                <Spinner />
                            </div> : (!id || (data && data.lpo)) ? <ReceivePurchaseOrder
                                history={this.props.history}
                                companyId={companyId}
                                id={id}
                                {...this.props}
                                lpo={!id ? null: {
                                    ...data.lpo,
                                    items: data.lpo.items.map((i) => ({
                                        id: i.variant.id,
                                        qty: i.qty,
                                        code: i.variant.id,
                                        variant:{
                                            id: i.variant.id,
                                            name: i.variant.name,
                                            cost: i.cost,
                                            price: i.variant.price,
                                            default_price: i.variant.price,
                                            stock_balance: i.variant.stock_balance,
                                            product:{
                                                id: i.variant.product.id,
                                                name: i.variant.product.name
                                            }
                                        }
                                    }))
                                }}
                            />: <p className="font-bold text-lg p-6 text-black">No Purchase Order Found</p>}

                        </div>

                    );

                }}
                
            </Query>
            
        )

    }

}

export default ReceivePurchaseOrderContainer;