import React, {createContext} from 'react';
import {includes, groupBy, map, flatten} from 'lodash';
import RestrictView from '../components/misc/restrictView';
import roles from './roles';

const groups = groupBy(roles, (r) => r.group);

const spreadGroups = (allowed) => flatten(allowed.map((a) => map(groups[a], 'value')))

export const context = createContext({});

export const PermissionView = ({children, allowed, allowedGroups}) => {

    return (
        <context.Consumer>
            {({role}) => {

                return (
                    role ? <RestrictView
                        children={children}
                        value={role}
                        allowed={allowedGroups ? spreadGroups(allowedGroups)  :allowed}
                    />: null
                )

            }}
        </context.Consumer>
    )

}

export default (path, user, company) => {

    if (includes([
        '/users',
        '/users/create',
        '/users/:userId',
        '/company/create'
    ], path)) {

        // console.log('matches path', path, user);

        return user.role === 'admin';

    } else if(includes([
        '/:companyId/settings'
    ], path)) {

        if (company && user.companies[company.id]) {


            return user.companies[company.id].role === 'admin';


        } else {

            return false;

        }

    } else {

        return true;

    }

};