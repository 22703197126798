import React from 'react';
import { InputNumber, Modal } from 'antd';
import { TextInputField, Spinner, Button } from 'evergreen-ui';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { CurrencyWrapper } from '../../services/Currency';

const GET_MATERIAL = gql`
	query($id: Int!) {
		material: raw_materials_by_pk(id: $id) {
			id
			name
			unit
			price
			company_id
		}
	}
`;

const SAVE_MUTATION = gql`
	mutation save($id: Int!, $name: String!, $unit: String!, $price: float8!, $company_id: String) {
		update_raw_materials(where: { id: { _eq: $id } }, _set: { name: $name, unit: $unit, price: $price }) {
			affected_rows
		}
	}
`;
const ADD_MUTATION = gql`
	mutation add($id: Int, $name: String!, $company_id: Int, $unit: String!, $price: float8!) {
		insert_raw_materials(objects: { name: $name, unit: $unit, price: $price, company_id: $company_id }) {
			returning {
				id
			}
		}
	}
`;

class RawMaterial extends React.Component {
	state = {
		name: this.props.material ? this.props.material.name : '',
		price: this.props.material ? this.props.material.price : '',
		unit: this.props.material ? this.props.material.unit : ''
	};

	render() {
		const { name, price, unit } = this.state;
		const { companyId, materialId } = this.props;

		return (
			<div className="max-w-lg w-full mx-auto my-12">
				<p className="text-black text-lg font-bold">{`${materialId ? 'Edit' : 'Create'} Raw Material`}</p>
				<div className="my-2">
					<TextInputField
						label="Name"
						value={name}
						onChange={(e) => {
							this.setState({
								name: e.target.value
							});
						}}
						placeholder="Name of raw material"
					/>
				</div>
				<div className="my-2">
					<TextInputField
						label="Unit"
						value={unit}
						onChange={(e) => {
							this.setState({
								unit: e.target.value
							});
						}}
						placeholder="Unit of raw material eg cm, pcs, kg"
					/>
				</div>
				<div className="my-2">
					<p className="font-medium text-black mb-2">Unit Price</p>
					<CurrencyWrapper
						component={({symbol}) => (
							<InputNumber
								onClick={(e) => {
									e.target.select();
								}}
								value={price}
								style={{ width: '100%' }}
								onChange={(price) => {
									this.setState({
										price
									});
								}}
								parser={(value) => value.replace(/[^0-9.]/g, '')}
								formatter={(value) => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
							/>
						)}
					/>
				</div>
				<div className="py-2">
					<Mutation
						onCompleted={(data) => {
							// console.log(data);

							Modal.success({
								title: 'Raw Material saved!',
								onCancel: () => this.props.history.push(`/${companyId}/procument/raw-materials/new`),
								onOk: () => this.props.history.push(`/${companyId}/procument/raw-materials`),
								content: `You have successfully saved ${name}`
							});
						}}
						mutation={materialId ? SAVE_MUTATION : ADD_MUTATION}
						variables={{
							company_id: companyId,
							id: materialId,
							unit,
							price,
							name
						}}
					>
						{(add, { loading }) => {
							return (
								<Button appearance="primary" intent="success" isLoading={loading} onClick={() => add()}>
									Save
								</Button>
							);
						}}
					</Mutation>
				</div>
			</div>
		);
	}
}

class RawMaterialContainer extends React.Component {
	state = {
		companyId: this.props.match.params.companyId,
		materialId: this.props.match.params.materialId
	};

	render() {
		const { companyId, materialId } = this.state;

		return (
			<Query
				query={GET_MATERIAL}
				skip={!materialId ? true : false}
				variables={{
					id: materialId
				}}
			>
				{({ loading, data, error, refetch }) => {
					console.log({ data });
					return (
						<div className="py-8 overflow-scroll h-full w-full">
							{loading ? (
								<div className="flex items-center justify-center py-10">
									<Spinner />
								</div>
							) : (
								<RawMaterial
									history={this.props.history}
									companyId={companyId}
									materialId={materialId}
									material={!materialId ? null : data.material}
								/>
							)}
						</div>
					);
				}}
			</Query>
		);
	}
}

export default RawMaterialContainer;
