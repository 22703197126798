import React from 'react';
import {Button, DatePicker} from 'antd';
import moment from 'moment';
import {currencyFormat} from 'mout/number';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import {Spinner} from 'evergreen-ui';
import {sumBy, sum} from 'lodash';

const dateFormat = 'YYYY-MM-DD';

const GET_TRANSACTIONS = gql`

    query($company_id: Int!, $to_date: date!) {

        currentAssets: ledgers(where: {
            company_id: {_eq: $company_id},
            prefix: {_in: ["current-assets", "non-current-assets"]}
        }) {
            id
            name
            children{
                id
                name
                _transactions: running_balance(limit: 1, order_by: {date: desc}, where: {date: {_lte: $to_date}}){
                    date
                    debit
                    credit
                    balance
                }
            }
        },
        currentLiabilities: ledgers(where: {
            company_id: {_eq: $company_id},
            prefix: {_in: ["current-liability", "non-current-liability"]}
        }) {
            id
            name
            children{
                id
                name
                _transactions: running_balance(limit: 1, order_by: {date: desc}, where: {date: {_lte: $to_date}}){
                    date
                    debit
                    credit
                    balance
                }
            }
        }
        equity: ledgers(where: {company_id: {_eq: $company_id}, group_id: {_eq: 5}, parent_id: {_is_null: true}}) {
            id
            name
            _transactions: running_balance(limit: 1, order_by: {date: desc}, where: {date: {_lte: $to_date}}){
                date
                debit
                credit
                balance
            }
        }
        income: ledgers(where: {company_id: {_eq: $company_id}, group_id: {_eq: 3}, parent_id: {_is_null: true}}) {
            id
            name
            _transactions: running_balance(limit: 1, order_by: {date: desc}, where: {date: {_lte: $to_date}}){
                date
                debit
                credit
                balance
            }
        },
        expenses: ledgers(where: {company_id: {_eq: $company_id}, group_id: {_eq: 4}, parent_id: {_is_null: true}}) {
            id
            name
            _transactions: running_balance(limit: 1, order_by: {date: desc}, where: {date: {_lte: $to_date}}){
                date
                debit
                credit
                balance
            }
        }

    }

`;

class TrialBalance extends React.Component{

    state = {
        companyId: this.props.match.params.companyId,
        to_date: moment().endOf('year').format(dateFormat)
    }

    render() {

        const {
            to_date,
            companyId
        } = this.state;

        return (
            <div className="overflow-scroll h-full">
                <div className="flex h-full my-12">
                    <div className="w-full max-w-5xl mx-auto">
                        <div className="text-4xl font-bold text-black mb-4">
                            Trial Balance
                        </div>
                        <div className="p-6 bg-gray-200 rounded flex items-center justify-between">
                            <div className="flex items-center -mx-2">
                                <p className="font-medium text-black px-2">As Of</p>
                                <DatePicker
                                    value={moment(to_date, dateFormat)}
                                    format={dateFormat}
                                    onChange={((date, dateString) => {

                                        this.setState({
                                            to_date: dateString
                                        })

                                    })}
                                />
                            </div>
                            <div className="">
                                <Button type="primary" shape="round" ghost>Update Report</Button>
                            </div>
                        </div>
                        <Query
                            query={GET_TRANSACTIONS}
                            variables={{
                                company_id: companyId,
                                to_date
                            }}
                        >
                            {({data, error, loading}) => {

                                // if (!data && !lo) return;

                                const totalAssetsDebit =  sumBy(data ? data.currentAssets : [], (a) => {

                                    return sumBy(a.children, (_a) => sumBy(_a._transactions, (t) => t.balance > 0 ? t.balance: 0))

                                });
                                const totalAssetsCredit =  sumBy(data ? data.currentAssets : [], (a) => {

                                    return sumBy(a.children, (_a) => sumBy(_a._transactions, (t) => t.balance < 0 ? t.balance: 0))

                                });

                                const totalLiabilityDebit =  sumBy(data ? data.currentLiabilities : [], (a) => {

                                    return sumBy(a.children, (_a) => sumBy(_a._transactions, (t) => t.balance > 0 ? t.balance: 0))

                                });
                                const totalLiabilityCredit =  sumBy(data ? data.currentLiabilities : [], (a) => {

                                    return sumBy(a.children, (_a) => sumBy(_a._transactions, (t) => t.balance < 0 ? t.balance: 0))

                                });

                                const totalEquityDebit =  sumBy(data ? data.equity : [], (a) => {

                                    return sumBy(a._transactions, (t) => t.balance > 0 ? t.balance: 0);

                                });
                                const totalEquityCredit =  sumBy(data ? data.equity : [], (a) => {

                                    return sumBy(a._transactions, (t) => t.balance < 0 ? t.balance: 0);

                                });

                                const totalIncomeDebit =  sumBy(data ? data.income : [], (a) => {

                                    return sumBy(a._transactions, (t) => t.balance > 0 ? t.balance: 0);

                                });
                                const totalIncomeCredit =  sumBy(data ? data.income : [], (a) => {

                                    return sumBy(a._transactions, (t) => t.balance < 0 ? t.balance: 0);

                                });

                                const totalExpenseDebit =  sumBy(data ? data.expenses : [], (a) => {

                                    return sumBy(a._transactions, (t) => t.balance > 0 ? t.balance: 0);

                                });
                                const totalExpenseCredit =  sumBy(data ? data.expenses : [], (a) => {

                                    return sumBy(a._transactions, (t) => t.balance < 0 ? t.balance: 0);

                                });


                                return (
                                    !loading && data ?  <div className="mt-10 mb-16">
                                        <div className="px-4 flex items-end justify-between border-b-2 py-4">
                                            <p className="font-bold text-black flex-1">ACCOUNTS</p>
                                            <p className="font-bold text-black text-right w-64">DEBIT</p>
                                            <p className="font-bold text-black text-right w-64">CREDIT</p>
                                        </div>
                                        <div className="px-4 bg-gray-200 text-black flex items-end justify-between border-b py-4">
                                            <div className="">
                                                <p className="font-bold">Assets</p>
                                            </div>
                                        </div>
                                        {data.currentAssets.map((a) => {
                                            
                                            return (
                                                <React.Fragment key={a.id}>
                                                    {a.children.map((_a) => {

                                                        const bal = sumBy(_a._transactions, 'balance');

                                                        return (
                                                            <div key={_a.id} className="px-4 text-black flex items-end justify-between border-b py-4">
                                                                <p className="flex-1">{_a.name}</p>
                                                                <p className="text-black text-right w-64">
                                                                    GH¢ {currencyFormat(Math.abs(bal > 0 ? bal: 0))}
                                                                </p>
                                                                <p className="text-black text-right w-64">
                                                                    GH¢ {currencyFormat(Math.abs(bal < 0 ? bal: 0))}
                                                                </p>
                                                            </div>
                                                        )

                                                    })}
                                                </React.Fragment>
                                            );

                                        })}
                                        <div className="px-4 font-bold text-black flex items-end justify-between border-b py-4">
                                            <p className="flex-1">Total Assets</p>
                                            <p className="text-black text-right w-64">
                                                GH¢ {currencyFormat(Math.abs(totalAssetsDebit))}
                                            </p>
                                            <p className="text-black text-right w-64">
                                                GH¢ {currencyFormat(Math.abs(totalAssetsCredit))}

                                            </p>
                                        </div>
            
                                        <div className="mt-6 px-4 bg-gray-200 text-black flex items-end justify-between border-b py-4">
                                            <div className="">
                                                <p className="font-bold">Liabilities</p>
                                            </div>
                                        </div>
                                        {data.currentLiabilities.map((a) => {
                                            
                                            return (
                                                <React.Fragment key={a.id}>
                                                    {a.children.map((_a) => {

                                                        const bal = sumBy(_a._transactions, 'balance');

                                                        return (
                                                            <div key={_a.id} className="px-4 text-black flex items-end justify-between border-b py-4">
                                                                <p className="flex-1">{_a.name}</p>
                                                                <p className="text-black text-right w-64">
                                                                    GH¢ {currencyFormat(Math.abs(bal > 0 ? bal: 0))}
                                                                </p>
                                                                <p className="text-black text-right w-64">
                                                                    GH¢ {currencyFormat(Math.abs(bal < 0 ? bal: 0))}
                                                                </p>
                                                            </div>
                                                        )

                                                    })}
                                                </React.Fragment>
                                            );

                                        })}
                                        <div className="px-4 font-bold text-black flex items-end justify-between border-b py-4">
                                            <p className="flex-1">Total Liabilities</p>
                                            <p className="text-black text-right w-64">
                                                GH¢ {currencyFormat(Math.abs(totalLiabilityDebit))}
                                            </p>
                                            <p className="text-black text-right w-64">
                                                GH¢ {currencyFormat(Math.abs(totalLiabilityCredit))}
                                            </p>
                                        </div>
            
                                        <div className="mt-6 px-4 bg-gray-200 text-black flex items-end justify-between border-b py-4">
                                            <div className="">
                                                <p className="font-bold">Equity</p>
                                            </div>
                                        </div>
                                        {data.equity.map((a) => {

                                            const bal = sumBy(a._transactions, 'balance');
                                            
                                            return (
                                                <div key={a.id} className="px-4 text-black flex items-end justify-between border-b py-4">
                                                    <p className="flex-1">{a.name}</p>
                                                    <p className="text-black text-right w-64">
                                                        GH¢ {currencyFormat(Math.abs(bal > 0 ? bal: 0))}
                                                    </p>
                                                    <p className="text-black text-right w-64">
                                                        GH¢ {currencyFormat(Math.abs(bal < 0 ? bal: 0))}
                                                    </p>
                                                </div>
                                                
                                            );

                                        })}
                                        <div className="px-4 font-bold text-black flex items-end justify-between border-b py-4">
                                            <p className="flex-1">Total Equity</p>
                                            <p className="text-black text-right w-64">
                                                GH¢ {currencyFormat(Math.abs(totalEquityDebit))}
                                            </p>
                                            <p className="text-black text-right w-64">
                                                GH¢ {currencyFormat(Math.abs(totalEquityCredit))}
                                            </p>
                                        </div>
            
                                        <div className="mt-6 px-4 bg-gray-200 text-black flex items-end justify-between border-b py-4">
                                            <div className="">
                                                <p className="font-bold">Income</p>
                                            </div>
                                        </div>
                                        {data.income.map((a) => {

                                            const bal = sumBy(a._transactions, 'balance');
                                            
                                            return (
                                                <div key={a.id} className="px-4 text-black flex items-end justify-between border-b py-4">
                                                    <p className="flex-1">{a.name}</p>
                                                    <p className="text-black text-right w-64">
                                                        GH¢ {currencyFormat(Math.abs(bal > 0 ? bal : 0))}
                                                    </p>
                                                    <p className="text-black text-right w-64">
                                                        GH¢ {currencyFormat(Math.abs(bal < 0 ? bal : 0))}
                                                    </p>
                                                </div>
                                                
                                            );

                                        })}
                                        <div className="px-4 font-bold text-black flex items-end justify-between border-b py-4">
                                            <p className="flex-1">Total Income</p>
                                            <p className="text-black text-right w-64">
                                                GH¢ {currencyFormat(Math.abs(totalIncomeDebit))}
                                            </p>
                                            <p className="text-black text-right w-64">
                                                GH¢ {currencyFormat(Math.abs(totalIncomeCredit))}
                                            </p>
                                        </div>
            
                                        <div className="mt-6 px-4 bg-gray-200 text-black flex items-end justify-between border-b py-4">
                                            <div className="">
                                                <p className="font-bold">Expenses</p>
                                            </div>
                                        </div>
                                        {data.expenses.map((a) => {
                                            
                                            const bal = sumBy(a._transactions, 'balance');

                                            return (
                                                <div key={a.id} className="px-4 text-black flex items-end justify-between border-b py-4">
                                                    <p className="flex-1">{a.name}</p>
                                                    <p className="text-black text-right w-64">
                                                        GH¢ {currencyFormat(Math.abs(bal > 0 ? bal : 0))}
                                                    </p>
                                                    <p className="text-black text-right w-64">
                                                        GH¢ {currencyFormat(Math.abs(bal < 0 ? bal : 0))}
                                                    </p>
                                                </div>
                                                
                                            );

                                        })}
                                        <div className="px-4 font-bold text-black flex items-end justify-between border-b py-4">
                                            <p className="flex-1">Total Expenses</p>
                                            <p className="text-black text-right w-64">
                                                GH¢ {currencyFormat(Math.abs(totalExpenseDebit))}
                                            </p>
                                            <p className="text-black text-right w-64">
                                                GH¢ {currencyFormat(Math.abs(totalExpenseCredit))}
                                            </p>
                                        </div>
            
                                        <div className="mt-10 border-t px-4 font-bold text-black flex items-end justify-between border-b py-4">
                                            <p className="flex-1">Total for all accounts</p>
                                            <p className="text-black text-right w-64">
                                                GH¢ {currencyFormat(Math.abs(sum([
                                                    totalAssetsDebit,
                                                    totalLiabilityDebit,
                                                    totalEquityDebit,
                                                    totalIncomeDebit,
                                                    totalExpenseDebit
                                                ])))}
                                            </p>
                                            <p className="text-black text-right w-64">
                                                GH¢ {currencyFormat(Math.abs(sum([
                                                    totalAssetsCredit,
                                                    totalLiabilityCredit,
                                                    totalEquityCredit,
                                                    totalIncomeCredit,
                                                    totalExpenseCredit
                                                ])))}
                                            </p>
                                        </div>
            
                                    </div> : <div className="flex h-full items-center justify-center py-10">
                                        <Spinner />
                                    </div>
                                )

                            }}
                        </Query>
                       
                    </div>
                </div>
            </div>
        )

    }

}

export default TrialBalance;