import React from 'react';
import { TextInputField, Button, Spinner } from 'evergreen-ui';
import { InputNumber, Select } from 'antd';

import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';

const GET_ALLOWANCE = gql`
	query($company_id: Int!, $allowance_id: Int!) {
		allowances: employee_allowances(where: { company_id: { _eq: $company_id }, id: { _eq: $allowance_id } }) {
			id
			name
		}
	}
`;

const CREATE_MUTATION = gql`
	mutation($allowance_id: Int, $company_id: Int!, $name: String!) {
		insert_employee_allowances(
			objects: { company_id: $company_id, name: $name}
		) {
			returning {
				id
			}
		}
	}
`;

const UPDATE_MUTATION = gql`
	mutation($allowance_id: Int, $company_id: Int!, $name: String!) {
		update_employee_allowances(
			where: { id: { _eq: $allowance_id }, company_id: { _eq: $company_id } }
			_set: { name: $name }
		) {
			affected_rows
		}
	}
`;

class Allowance extends React.Component {
	state = {
		companyId: this.props.companyId,
		allowanceId: this.props.allowanceId,
		name: this.props.allowance.name || '',
	};

	render() {
		const { name, allowanceId, loading, companyId } = this.state;

		return (
			<div className="max-w-lg w-full mx-auto my-12">
				{/* <BackButton appearance="default" marginBottom={15} onClick={()=>{

                    this.props.history.push(`/${companyId}/hr/allowances/settings`);

                }} children="Back to allowances" /> */}
                <div className="p-8">
                    {loading ? <div className="flex items-center justify-center py-10">
                        <Spinner />
                    </div> : <div className="">
                        <p className="text-black text-lg font-bold">{allowanceId ? 'Edit' : 'Create'} Allowance</p>
                        <div className="mt-4">
                            <TextInputField label="Allowance Name" defaultValue={name} onChange={(e) => {

                                this.setState({
                                    name: e.target.value
                                });

                            }} placeholder="Allowance Name" />
                        </div>
                        <Mutation
                            mutation={allowanceId ? UPDATE_MUTATION : CREATE_MUTATION}
                            onCompleted={() => this.props.close()}
                            variables={{
                                allowance_id: allowanceId,
                                company_id: companyId,
                                name
                            }}
                        >
                            {(save, {loading}) => {

                                return (
                                    <Button appearance="primary" intent="success" isLoading={loading} onClick={() => save()}>Save</Button>
                                )

                            }}
                        </Mutation>
                    </div>}
                </div>
            </div>

        );

    }

}

class AllowanceWrapper extends React.Component {
	state = {
		companyId: this.props.companyId,
		allowanceId: this.props.allowanceId
	};

	render() {
		const { companyId, allowanceId } = this.state;

		return (
			<Query
				query={GET_ALLOWANCE}
				variables={{
					company_id: companyId,
					allowance_id: allowanceId
				}}
				skip={!allowanceId}
			>
				{({ data, error, loading }) => {
					// console.log(data);

					return !loading ? (
						<Allowance
							{...this.props}
							allowanceId={allowanceId}
							allowance={allowanceId && data ? data.allowances[0] : {}}
						/>
					) : (
						<div className="flex items-center justify-center py-10">
							<Spinner />
						</div>
					);
				}}
			</Query>
		);
	}
}

export default AllowanceWrapper;
