import React, { useState } from 'react';
import gql from "graphql-tag";
import {Query} from 'react-apollo';
import {Button, Spinner} from 'evergreen-ui'
import {Select} from 'antd';
import Invoice from '../../services/components/Invoice';
import moment from 'moment';
import Receipt from '../../services/components/Receipt';

const GET_SALES = gql`
    query(
        $id: Int!
    ) {
        sale: orders_by_pk(id: $id){
            id
            subtotal
            total_amt
            total_qty
            tax_amt
            discount_amt
            created_at
            is_wholesale
            outlet {
                id
                phone
                name
            }
            customer{
                id
                name
            }
            salesperson {
                display_name
            }
            supervisor {
                display_name
            }
            discount{
                id
                value,
                type
            }
            items {
                price
                qty
                subtotal
                tax_amt
                total
                discount_amt
                variant {
                name
                product {
                    name
                }
                }
            }
            payments {
                exchange
                currency
                amount
                title
                type
            }
        }
    }
`

const SingleSaleContainer = (props) => {

    const {company} = props;

    const {companyId, id} = props.match.params;

    const [printLayout, setPrintLayout] = useState('invoice');

    return (
        <Query
            query={GET_SALES}
            skip={id ? false: true}
            variables={{
                id
            }}
        >
            {({loading, data, error, refetch}) => {

                const PrintOut = printLayout === 'invoice' ? Invoice : Receipt;

                return (
                    <div className="overflow-scroll h-full w-full py-10">
                        {loading ? <div className="flex items-center justify-center py-10">
                            <Spinner />
                        </div> : (!id || (data && data.sale)) ? <div className="max-w-4xl mx-auto w-full">
                            <div className="print-hidden my-4 flex items-center justify-between">
                                <div className="">
                                    <p className="font-bold text-black">Print Layout</p>
                                    <Select placeholder="Print Layout" value={printLayout} onChange={(printLayout) => setPrintLayout(printLayout)}>
                                        <Select.Option value="receipt">Receipt</Select.Option>
                                        <Select.Option value="invoice">Invoice</Select.Option>
                                    </Select>
                                </div>
                                <Button appearance="primary" intent="success" onClick={() => setTimeout(() => window.print(), 150)}>
                                    Print
                                </Button>
                            </div>
                            <PrintOut
                                {...{
                                    id: data.sale.id,
                                    companyName: company.name,
                                    companyPhone: company.phone,
                                    companyLogo: company.logo,
                                    companyEmail: company.email,
                                    date: moment(data.sale.created_at).format('DD/MM/YYYY [a]t HH:mm A'),
                                    shop: data.sale.outlet.name,
                                    phone: data.sale.outlet.phone,
                                    reg_no: company.file_number,
                                    is_wholesale: data.sale.is_wholesale,
                                    salesperson: data.sale.salesperson.display_name,
                                    supervisor: data.sale.supervisor.display_name,
                                    items: data.sale.items.map((i) => ({
                                        name: `${i.variant.product.name}`,
                                        price: i.price,
                                        qty: i.qty
                                    })),
                                    customer: data.sale.customer,
                                    total: data.sale.total_amt,
                                    discount_amt: data.sale.discount_amt,
                                    discount: data.sale.discount,
                                    amt_due: data.sale.total_amt,
                                    tax: data.sale.tax_amt,
                                    paid: data.sale.total_amt,
                                    change: 0.00,
                                    payments: data.sale.payments.map(({title, currency, amount}) => ({
                                        title,
                                        currency,
                                        amount
                                    }))
                                }}
                            />

                        </div>: <p className="font-bold text-lg p-6 text-black">Sales ID not Found</p>}
                    </div>
                );

            }}

        </Query>
    )

}

export default SingleSaleContainer;