import React from 'react';
import { Button, TextInputField, FormField, BackButton, Switch, TagInput, toaster, Spinner } from 'evergreen-ui';
import { Button as AntButton, Modal, Select, InputNumber, message } from 'antd';
import { Camera } from 'react-feather';
import firebase from '../services/firebase';
import Promise from 'bluebird';
import { toPairs, groupBy, values, map, includes } from 'lodash';
import { randHex } from 'mout/random';
import ProfilePicture from './ProfilePicture';
import { withRouter } from 'react-router-dom';
import { Mutation, Subscription, Query } from 'react-apollo';
import gql from 'graphql-tag';
import UserSettings from './misc/UserSettings'
import TaxSettings from './misc/taxSettings';
import AggregatorSettings from './AggregatorsSettings';
import DeductionSettings from './DeductionsSettings';
import EmployeeDepartments from './misc/employeeDept';
// import CompanySummary from './misc/companySummary'

import {warning} from './Home';
import EmployeeAllowanceSettings from './HR/EmployeeAllowanceSettings';

const SAVE_TAX = gql`
    mutation(
        $id: Int!,
        $tax_type: String,
        $tax_option: String,
        $vat: float8,
        $nhil: float8,
        $tourism_levy: float8,
        $flat_rate: Boolean
    ){

        update_company(where: {id: {_eq: $id}}, _set: {
            tax_type: $tax_type
            tax_option: $tax_option
            vat: $vat
            nhil: $nhil
            tourism_levy: $tourism_levy
            flat_rate: $flat_rate
        }) {
            returning {
              id
            }
        }

    }
`;

const GET_COMPANY_TAX = gql`

    query ($id: Int!) {
        company: company_by_pk(id: $id) {
            id
            tax_type
            tax_option
            vat
            nhil
            tourism_levy
            flat_rate
        }
    }

`;


const GET_COMPANY = gql`

    query ($id: Int!) {
        company: company_by_pk(id: $id) {
            id
            created_by
            allowance_percent
            basic_salary_percent
            email
            file_number
            logo
            name
            phone
            tax_id
            discount_before_tax
            country_id
            currency_id
            users {
                user {
                    display_name
                    email
                    id
                    employee{
                        id
                        name
                        designation
                    }
                }
                outlet_id
                outlet{
                    name
                }
                role
            }
        }
    }

`;

const UPDATE_ROLE_MUTATION = gql`

    mutation (
        $account_id: Int!,
        $company_id: Int!,
        $role: String!,
        $user_id: Int!,
        $outlet_id: Int
    ) {
        update_company_users(where: {
            company_id: {_eq: $company_id}, user_id: {_eq: $user_id}, account_id: {_eq: $account_id}
        }, _set: {
            role: $role,
            outlet_id: $outlet_id
        }) {
            affected_rows
        }
    }

`;

const DELETE_ROLE_MUTATION = gql`

    mutation (
        $account_id: Int!,
        $company_id: Int!,
        $user_id: Int!
    ) {
        delete_company_users(where: {
            company_id: {_eq: $company_id}, user_id: {_eq: $user_id}, account_id: {_eq: $account_id}
        }) {
            affected_rows
        }
    }

`;

const CREATE_ROLE_MUTATION = gql`
    mutation (
        $account_id: Int!,
        $company_id: Int!,
        $role: String!,
        $user_id: Int!,
        $outlet_id: Int
    ) {
        insert_company_users(objects: {
            account_id: $account_id,
            company_id: $company_id,
            role: $role,
            outlet_id: $outlet_id,
            user_id: $user_id
        }) {
            affected_rows
        }
    }
`;

const SAVE_MUTATION = gql`
    mutation (
        $id: Int!,
        $account_id: Int,
        $user_id: Int,
        $name: String!,
        $logo: String!,
        $email: String!,
        $phone: String!,
        $file_number: String!,
        $tax_id: String!,
        $basic_salary_percent: float8!,
        $allowance_percent: float8!
        $country_id: Int!,
        $currency_id: Int!,
        $discount_before_tax: Boolean,
        $transactions: [ledger_transactions_insert_input!]
    ) {

        company: update_company(where: {id: {_eq: $id}}, _set: {
            allowance_percent: $allowance_percent,
            basic_salary_percent: $basic_salary_percent,
            email: $email,
            file_number: $file_number,
            logo: $logo,
            name: $name,
            phone: $phone,
            tax_id: $tax_id,
            country_id: $country_id,
            currency_id: $currency_id,
            discount_before_tax: $discount_before_tax
        }) {
            returning {
              id
            }
        }

    }
`;

const CREATE_MUTATION = gql`
    mutation (
        $id: Int,
        $account_id: Int!,
        $user_id: Int!,
        $email: String!,
        $phone: String!,
        $name: String!
        $logo: String!,
        $file_number: String!,
        $tax_id: String!,
        $basic_salary_percent: float8!,
        $allowance_percent: float8!
        $country_id: Int!,
        $currency_id: Int!,
        $transactions: [ledger_transactions_insert_input!]!,
        $discount_before_tax: Boolean
    ) {
        company: insert_company(objects: {
            account_id: $account_id,
            allowance_percent: $allowance_percent,
            basic_salary_percent: $basic_salary_percent,
            created_by: $user_id,
            email: $email,
            file_number: $file_number,
            logo: $logo,
            name: $name,
            phone: $phone,
            country_id: $country_id,
            currency_id: $currency_id,
            tax_id: $tax_id,
            outlets: {
                data: [{
                    name: "Main Warehouse",
                    warehouse: true
                },{
                    name: "Finished Goods Warehouse",
                    warehouse: true
                },{
                    name: "Raw Materials Warehouse",
                    raw_materials: true,
                    warehouse: true
                }]
            },
            users: {
                data: [{
                    account_id: $account_id,
                    role: "admin",
                    user_id: $user_id
                }]
            },
            employee_deductions: {
                data: [{
                    name: "Attendance",
                    is_default: true,
                    type: "attendance"
                }, {
                    name: "Hire Purchase",
                    is_default: true,
                    type: "hire-purchase"
                }, {
                    name: "Staff Loans",
                    is_default: true,
                    type: "loans"
                }, {
                    name: "Surcharges",
                    is_default: true,
                    type: "surcharge"
                }, {
                    name: "Welfare",
                    is_default: true,
                    type: "welfare"
                }]
            }
            ledgers: {
                data: [{
                    archived: false,
                    currency: "GHS",
                    description: "",
                    is_default: true,
                    name: "Current Assets",
                    parent_id: null,
                    prefix: "current-assets",
                    group_id: 1,
                    transactions: {
                        data: $transactions
                    },
                    children: {
                        data: [{
                            archived: false,
                            currency: "GHS",
                            description: "",
                            is_default: true,
                            name: "Trade Receivables",
                            group_id: 1,
                            prefix: "trade-receivables",
                            transactions: {
                                data: $transactions
                            },
                        },{
                            archived: false,
                            currency: "GHS",
                            description: "",
                            is_default: true,
                            name: "Cash & Bank",
                            group_id: 1,
                            prefix: "cash-and-bank",
                            transactions: {
                                data: $transactions
                            },
                            children: {
                                data: [{
                                    archived: false,
                                    currency: "GHS",
                                    description: "",
                                    is_default: true,
                                    name: "Cash",
                                    prefix: "cash",
                                    group_id: 1,
                                    transactions: {
                                        data: $transactions
                                    }
                                },{
                                    archived: false,
                                    currency: "GHS",
                                    description: "",
                                    is_default: true,
                                    name: "Mobile Money",
                                    prefix: "momo",
                                    group_id: 1,
                                    transactions: {
                                        data: $transactions
                                    }
                                },{
                                    archived: false,
                                    currency: "GHS",
                                    description: "",
                                    is_default: true,
                                    name: "Card Payments",
                                    prefix: "card",
                                    group_id: 1,
                                    transactions: {
                                        data: $transactions
                                    }
                                }]
                            }
                        }]
                    }
                },{
                    archived: false,
                    currency: "GHS",
                    description: "",
                    is_default: true,
                    name: "Non-Current Assets",
                    prefix: "non-current-assets",
                    parent_id: null,
                    group_id: 1,
                    transactions: {
                        data: $transactions
                    }
                },{
                    archived: false,
                    currency: "GHS",
                    description: "",
                    is_default: true,
                    name: "Current Liability",
                    prefix: "current-liability",
                    parent_id: null,
                    group_id: 2,
                    transactions: {
                        data: $transactions
                    },
                    children: {
                        data: [{
                            archived: false,
                            currency: "GHS",
                            group_id: 2,
                            description: "",
                            is_default: true,
                            name: "Trade Payables",
                            prefix: "suppliers",
                            transactions: {
                                data: $transactions
                            }
                        },{
                            archived: false,
                            currency: "GHS",
                            group_id: 2,
                            description: "",
                            is_default: true,
                            name: "Other Payables",
                            prefix: "other-suppliers",
                            transactions: {
                                data: $transactions
                            }
                        },{
                            archived: false,
                            currency: "GHS",
                            group_id: 2,
                            description: "",
                            is_default: true,
                            name: "Deferred Salaries",
                            prefix: "deferred-salaries",
                            transactions: {
                                data: $transactions
                            }
                        },{
                            archived: false,
                            currency: "GHS",
                            group_id: 2,
                            description: "",
                            is_default: true,
                            name: "Deferred Statutory",
                            prefix: "deferred-statutory",
                            transactions: {
                                data: $transactions
                            },
                            children: {
                                data: [{
                                    archived: false,
                                    currency: "GHS",
                                    group_id: 2,
                                    description: "",
                                    is_default: true,
                                    name: "Deferred Taxes",
                                    prefix: "deferred-taxes",
                                    transactions: {
                                        data: $transactions
                                    },
                                    children: {
                                        data: [{
                                            archived: false,
                                            currency: "GHS",
                                            group_id: 2,
                                            description: "",
                                            is_default: true,
                                            name: "Deferred VAT",
                                            prefix: "deferred-vat",
                                            transactions: {
                                                data: $transactions
                                            }
                                        },{
                                            archived: false,
                                            currency: "GHS",
                                            group_id: 2,
                                            description: "",
                                            is_default: true,
                                            name: "Deferred Tourism Levy",
                                            prefix: "deferred-tourism-levy",
                                            transactions: {
                                                data: $transactions
                                            }
                                        },{
                                            archived: false,
                                            currency: "GHS",
                                            group_id: 2,
                                            description: "",
                                            is_default: true,
                                            name: "Deferred P.A.Y.E",
                                            prefix: "deferred-paye",
                                            transactions: {
                                                data: $transactions
                                            }
                                        }]
                                    }
                                },{
                                    archived: false,
                                    currency: "GHS",
                                    group_id: 2,
                                    description: "",
                                    is_default: true,
                                    name: "Deferred Pensions",
                                    prefix: "deferred-pensions",
                                    transactions: {
                                        data: $transactions
                                    }
                                }]
                            }
                        }]
                    }
                },{
                    archived: false,
                    currency: "GHS",
                    description: "",
                    is_default: true,
                    name: "Non-Current Liability",
                    prefix: "non-current-liability",
                    parent_id: null,
                    group_id: 2,
                    transactions: {
                        data: $transactions
                    }
                },{
                    archived: false,
                    currency: "GHS",
                    description: "",
                    is_default: true,
                    name: "Sales",
                    prefix: "sales",
                    parent_id: null,
                    group_id: 3,
                    transactions: {
                        data: $transactions
                    }
                },{
                    archived: false,
                    currency: "GHS",
                    description: "",
                    is_default: true,
                    name: "Other Income",
                    prefix: "other-income",
                    parent_id: null,
                    group_id: 3,
                    transactions: {
                        data: $transactions
                    }
                },{
                    archived: false,
                    currency: "GHS",
                    description: "",
                    is_default: true,
                    name: "Operating Expense",
                    prefix: "op-exp",
                    parent_id: null,
                    group_id: 4,
                    transactions: {
                        data: $transactions
                    }
                },{
                    archived: false,
                    currency: "GHS",
                    description: "",
                    is_default: true,
                    name: "General & Admin Expense",
                    prefix: "admin-exp",
                    parent_id: null,
                    group_id: 4,
                    transactions: {
                        data: $transactions
                    },
                    children: {
                        data: [{
                            archived: false,
                            currency: "GHS",
                            group_id: 4,
                            description: "",
                            is_default: true,
                            name: "Salaries",
                            prefix: "salaries",
                            transactions: {
                                data: $transactions
                            }
                        },{
                            archived: false,
                            currency: "GHS",
                            group_id: 4,
                            description: "",
                            is_default: true,
                            name: "Pensions",
                            prefix: "pensions",
                            transactions: {
                                data: $transactions
                            }
                        },{
                            archived: false,
                            currency: "GHS",
                            group_id: 4,
                            description: "",
                            is_default: true,
                            name: "P.A.Y.E",
                            prefix: "paye",
                            transactions: {
                                data: $transactions
                            }
                        }]
                    }
                },{
                    archived: false,
                    currency: "GHS",
                    description: "",
                    is_default: true,
                    name: "Cost of Sales",
                    prefix: "cos",
                    parent_id: null,
                    group_id: 4,
                    transactions: {
                        data: $transactions
                    },
                    children: {
                        data: [{
                            archived: false,
                            currency: "GHS",
                            group_id: 4,
                            description: "",
                            is_default: true,
                            name: "Discounts",
                            prefix: "discounts",
                            transactions: {
                                data: $transactions
                            }
                        },{
                            archived: false,
                            currency: "GHS",
                            group_id: 4,
                            description: "",
                            is_default: true,
                            name: "Purchases",
                            prefix: "purchases",
                            transactions: {
                                data: $transactions
                            }
                        }]
                    }
                },{
                    archived: false,
                    currency: "GHS",
                    description: "",
                    is_default: true,
                    name: "Stated Capital",
                    prefix: "capital",
                    parent_id: null,
                    group_id: 5,
                    transactions: {
                        data: $transactions
                    }
                },{
                    archived: false,
                    currency: "GHS",
                    description: "",
                    is_default: true,
                    name: "Drawings",
                    prefix: "drawings",
                    parent_id: null,
                    group_id: 5,
                    transactions: {
                        data: $transactions
                    }
                },{
                    archived: false,
                    currency: "GHS",
                    description: "",
                    is_default: true,
                    name: "Reserves",
                    prefix: "reserves",
                    parent_id: null,
                    group_id: 5,
                    transactions: {
                        data: $transactions
                    }
                }]
            }
        }) {
            returning {
              id
            }
        }

    }
`;


const GET_OUTLETS = gql`

    query ($company_id: Int!) {
        outlets(where: {
            company_id:{_eq: $company_id},
            warehouse: {_eq: false}
        }) {
            id
            name
        }
    }

`;


const apiUrl = 'https://us-central1-payhive-84e5b.cloudfunctions.net/api';

class Company extends React.Component {

    state = {
        activeTab: 'overview',
        companyId: this.props.companyId,
        loading: false,
        showAddUser: false,
        disable_user: false,
        selected_user: null,
        selected_role: null,
        selected_outlet: null,
        country: this.props.company.country_id || null,
        currency: this.props.company.currency_id || null,
        discount_before_tax: this.props.company.discount_before_tax || false,
        name: this.props.company.name || '',
        logo: this.props.company.logo || '',
        email: this.props.company.email || '',
        phone: this.props.company.phone || '',
        fileNumber: this.props.company.file_number || '',
        tin: this.props.company.tax_id || '',
        basic_salary_percent: this.props.company.basic_salary_percent || 70,
        allowance_percent: this.props.company.allowance_percent || 30,
        departments: {},
        requestRole: 'admin',
        requestEmail: '',
        requests: [],
        users: this.props.company.users || []
    }

    componentDidUpdate(prevProps) {

        if (this.props.company.users && this.state.users !== this.props.company.users) {

            this.setState({
                users: this.props.company.users || []
            })

        }

    }


    getActiveClass = (tab) => {

        const { activeTab } = this.state;

        return [
            "px-4",
            "py-2",
            "hover:bg-gray-100",
            "cursor-pointer",
            "border-b",
            activeTab == tab ? "border-r-2" : "",
            activeTab == tab ? "bg-gray-200" : ""
        ].join(' ');

    }

    saveTaxSettings = (state) => {
        //this.setState({state})
        console.log(state)
    }

    render() {

        const {
            users,
            showAddUser,
            disable_user,
            selected_user,
            selected_role,
            selected_outlet,
            loading,
            name,
            email,
            logo,
            companyId,
            fileNumber,
            tin,
            basic_salary_percent,
            allowance_percent,
            activeTab,
            country,
            currency,
            phone,
            discount_before_tax
        } = this.state;

        const { account, user } = this.props;

        const selectedUsers = map(users, (c) => c.user.id);
        const possibleUsers = account.users.filter((c) => !includes(selectedUsers, c.id));


        // const _roles = values(roles);

        return (
            <div className="flex h-full">
                {companyId ? <div className="w-64 h-full border-r overflow-scroll">
                    <div
                        onClick={() => this.setState({ activeTab: 'overview' })}
                        className={this.getActiveClass('overview')}
                    >
                        Overview
                    </div>
                    <div
                        onClick={() => this.setState({ activeTab: 'departments' })}
                        className={this.getActiveClass('departments')}
                    >
                        Employee Departments
                    </div>
                    <div
                        onClick={() => this.setState({ activeTab: 'taxes' })}
                        className={this.getActiveClass('taxes')}
                    >
                        Tax Settings
                    </div>
                    <div
                        onClick={() => this.setState({ activeTab: 'users' })}
                        className={this.getActiveClass('users')}
                    >
                        Users
                    </div>
                    <div
                        onClick={() => this.setState({ activeTab: 'deduction_settings' })}
                        className={this.getActiveClass('deduction_settings')}
                    >
                        Deduction Settings
                    </div>
                    <div
                        onClick={() => this.setState({ activeTab: 'aggregator_settings' })}
                        className={this.getActiveClass('aggregator_settings')}
                    >
                        Aggregator Settings
                    </div>
                    <div
                        onClick={() => this.setState({ activeTab: 'employee_allowance' })}
                        className={this.getActiveClass('employee_allowance')}
                    >
                        Employee Allowance
                    </div>
                    {/* <div
                    onClick={()=> this.setState({activeTab: 'discount_settings'})}
                    className={this.getActiveClass('discount_settings')}
                    >
                        Discount Settings
                    </div> */}
                    {/* <div
                    onClick={()=> this.setState({activeTab: 'company_summary'})}
                    className={this.getActiveClass('company_summary')}>
                    Company Summary
                    </div> */}
                </div> : null}
                <div className="overflow-scroll h-full w-full">
                    {activeTab === 'overview' ? <div className="w-full max-w-2xl mx-auto px-4 sm:px-8 mt-16 sm:my-16">
                        {!companyId ? <BackButton appearance="default" marginBottom={15} onClick={() => {

                            this.props.history.push('/');

                        }} children="Back to companies" /> : null}
                        <div className="flex items-center justify-center">
                            <h1 className="text-3xl font-bold mr-4">
                                {companyId ? 'Settings' : 'Create a new company'}
                            </h1>
                        </div>
                        {loading ? <div className="flex items-center justify-center py-10">
                            <Spinner />
                        </div> : <div>
                                <div className="mt-8">
                                    <ProfilePicture url={logo} width={48} height={48} onUploadComplete={(logo) => this.setState({ logo })} />
                                    <div className="">
                                        <TextInputField label="Company Name" defaultValue={name} onChange={(e) => {

                                            this.setState({
                                                name: e.target.value
                                            });

                                        }} placeholder="Enter the name of your company" />
                                        <TextInputField label="Company Email" defaultValue={email} onChange={(e) => {

                                            this.setState({
                                                email: e.target.value
                                            });

                                        }} placeholder="Enter the email address of your company" />
                                        <TextInputField label="Company Phone Number" defaultValue={phone} onChange={(e) => {

                                            this.setState({
                                                phone: e.target.value
                                            });

                                        }} placeholder="Enter the phone number of your company" />
                                        <TextInputField label="Company File Number" defaultValue={fileNumber} onChange={(e) => {

                                            this.setState({
                                                fileNumber: e.target.value
                                            });

                                        }} placeholder="Enter the social security file number of your company" />
                                        <TextInputField label="Tax Identification Number" defaultValue={tin} onChange={(e) => {

                                            this.setState({
                                                tin: e.target.value
                                            });

                                        }} placeholder="Enter the company's tax identification number" />
                                        <div className="flex items-center -mx-2 mb-4">
                                            <div className="flex-1 px-2">
                                                <FormField label="Aggregation Basic Salary %" />
                                                <InputNumber
                                                    style={{ width: '100%' }}
                                                    value={basic_salary_percent}
                                                    min={0}
                                                    max={100}
                                                    formatter={value => `${value}%`}
                                                    parser={value => value.replace('%', '')}
                                                    onChange={(basic_salary_percent) => {

                                                        const _val = parseFloat(basic_salary_percent);

                                                        this.setState({
                                                            basic_salary_percent: _val,
                                                            allowance_percent: parseFloat(parseFloat(100 - _val).toFixed(2))
                                                        })

                                                    }}
                                                />
                                            </div>
                                            <div className="flex-1 px-2">
                                                <FormField label="Aggregation Allowance %" />
                                                <InputNumber
                                                    style={{ width: '100%' }}
                                                    value={allowance_percent}
                                                    min={0}
                                                    max={100}
                                                    formatter={value => `${value}%`}
                                                    parser={value => value.replace('%', '')}
                                                    onChange={(allowance_percent) => {

                                                        const _val = parseFloat(allowance_percent);

                                                        this.setState({
                                                            allowance_percent: _val,
                                                            basic_salary_percent: parseFloat(parseFloat(100 - _val).toFixed(2))
                                                        });

                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="my-3">
                                            <p className="text-black font-medium">Country</p>
                                            <Query
                                                query={gql`
                                                    query{
                                                        countries{
                                                            id
                                                            name
                                                            code
                                                        }
                                                    }
                                                `}
                                            >
                                                {({data, error, loading}) => {

                                                    return (
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Select country"
                                                            optionFilterProp="children"
                                                            value={country}
                                                            disabled={country && companyId ? true: false}
                                                            onChange={(country) => this.setState({country})}
                                                            filterOption={(input, option) =>
                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {(!loading && data && data.countries ? data.countries: []).map((c) => {

                                                                return (
                                                                    <Select.Option value={c.id} key={c.id}>{c.name}</Select.Option>
                                                                )

                                                            })}
                                                        </Select>
                                                    )

                                                }}
                                            </Query>
                                        </div>
                                        <div className="my-3">
                                            <p className="text-black font-medium">Currency</p>
                                            <Query
                                                query={gql`
                                                    query{
                                                        currencies{
                                                            id
                                                            name
                                                            code
                                                        }
                                                    }
                                                `}
                                            >
                                                {({data, error, loading}) => {

                                                    return (
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Select currency"
                                                            optionFilterProp="children"
                                                            value={currency}
                                                            disabled={currency && companyId ? true: false}
                                                            onChange={(currency) => this.setState({currency})}
                                                            filterOption={(input, option) =>
                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {(!loading && data && data.currencies ? data.currencies: []).map((c) => {

                                                                return (
                                                                    <Select.Option value={c.id} key={c.id}>{c.code}</Select.Option>
                                                                )

                                                            })}
                                                        </Select>
                                                    )

                                                }}
                                            </Query>
                                        </div>
                                    </div>
                                    {companyId ? <div className="mb-3">
                                        <p className="font-medium">Apply discounts before taxes</p>
                                        <Switch height={24} checked={discount_before_tax} onChange={e => this.setState({ discount_before_tax: e.target.checked })} />
                                    </div>: null}
                                    <Mutation
                                        mutation={companyId ? SAVE_MUTATION : CREATE_MUTATION}
                                        onCompleted={(data) => companyId ? null : window.location = `/${data.company.returning[0].id}/settings`}
                                        variables={{
                                            id: companyId || null,
                                            discount_before_tax,
                                            account_id: account.id,
                                            user_id: user.id,
                                            country_id: country,
                                            currency_id: currency,
                                            name,
                                            phone,
                                            logo,
                                            email,
                                            file_number: fileNumber,
                                            tax_id: tin,
                                            basic_salary_percent,
                                            allowance_percent,
                                            transactions: [{
                                                description: "INITIAL BALANCE",
                                                initial: true,
                                                user_id: user.id
                                            }]
                                        }}
                                    >
                                        {(mutate, { loading }) => <Button isLoading={loading} appearance="primary" intent="success" onClick={() => {

                                            if (!country || !currency) {

                                                message.error('Country & Currency are required and cannot be changed after creating company.');

                                                return;

                                            }
                                            
                                            mutate();
                                            
                                            
                                        }}>Save</Button>}
                                    </Mutation>
                                </div>

                            </div>}
                    </div> : null}
                    {activeTab === 'users' && <UserSettings
                        users={users}
                        possibleUsers={possibleUsers}
                        companyId={companyId}
                        account={account}
                        company={this.props.company}
                        showAddUser = {showAddUser}
                        disable_user = {disable_user}
                        selected_user = {selected_user}
                        selected_role = {selected_role}
                        selected_outlet = {selected_outlet}
                    />}
                    {activeTab === 'taxes' && <Query
                        query={GET_COMPANY_TAX}
                        variables={{
                            id: parseInt(companyId)
                        }}
                    >
                        {({data, loading, refetch, error}) => {

                            if (loading) {

                                return (

                                    <Spinner />

                                );

                            }

                            if (error) {

                                return (
                                    <Button
                                        appearance="primary"
                                        onClick={() => {

                                            return refetch();

                                        }}
                                    >
                                        Retry
                                    </Button>
                                );

                            }

                            return (
                                <TaxSettings
                                    data={{
                                        taxOption: data.company.tax_type,
                                        option: data.company.tax_option,
                                        VAT: data.company.vat,
                                        NHIL: data.company.nhil,
                                        Tourism: data.company.tourism_levy,
                                        flat_rate: data.company.flat_rate
                                    }}
                                    renderSaveButton={(_state) => (
                                        <Mutation
                                            mutation={SAVE_TAX}
                                            variables={{
                                                id: parseInt(companyId),
                                                tax_type: _state.taxOption,
                                                tax_option: _state.option,
                                                vat: _state.VAT,
                                                nhil: _state.NHIL,
                                                tourism_levy: _state.Tourism,
                                                flat_rate: _state.flat_rate
                                            }}
                                            onCompleted={() => {}}
                                        >
                                            {(save, {loading}) => {

                                                return (
                                                    <Button
                                                        isLoading={loading}
                                                        appearance="primary"
                                                        intent="success"
                                                        onClick={() => {

                                                            return save();

                                                        }}
                                                    >
                                                        Save
                                                    </Button>
                                                )

                                            }}
                                        </Mutation>
                                    )}
                                    saveTaxSettings={this.saveTaxSettings}
                                />
                            )

                        }}
                    </Query>}
                    {activeTab === 'deduction_settings' ? <DeductionSettings companyId={companyId} /> : null}
                    {activeTab === 'aggregator_settings' ? <AggregatorSettings companyId={companyId} /> : null}

                    {activeTab === 'departments' ? <EmployeeDepartments companyId={companyId} user={user} /> : null}
                    {activeTab === 'employee_allowance' ? <EmployeeAllowanceSettings companyId={companyId} user={user} /> : null}
                    {/* {activeTab === 'discount_settings' ? <Discount /> : null} */}
                    {/* {activeTab === 'company_summary' ? <CompanySummary /> : null} */}
                    {/* {activeTab === 'taxes' ? <EmployeeDepartments /> : null} */}
                </div>
            </div>
        )

    }

}

const CompanyWrapper = withRouter(Company);

class CompanyContainer extends React.Component {

    errorIsShown = false

    state = {
        companyId: this.props.match.params.companyId
    }

    render() {

        const { companyId } = this.state;
        const { account, user } = this.props;

        if (!this.errorIsShown && account.companies.length >= account.max_companies && !companyId) {

            warning();

            this.errorIsShown = true;

            return null;


        }

        if (account.companies.length >= account.max_companies && !companyId) return null;


        return (

            companyId ? <Query
                query={GET_COMPANY}
                variables={{
                    id: companyId
                }}
            >
                {({ error, loading, data }) => {

                    return (
                        !companyId || !loading && data && data.company ? <CompanyWrapper account={account} user={user} company={data ? data.company : {}} companyId={companyId} /> : null
                    )

                }}
            </Query> : <CompanyWrapper account={account} user={user} company={{}} companyId={companyId} />

        )

    }


}

export default CompanyContainer;