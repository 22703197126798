import React from 'react';
import { RefreshCw } from 'react-feather';
import { Button, Input, Select } from 'antd';
import { Spinner } from 'evergreen-ui';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { currencyFormat } from 'mout/number';
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { CurrencyWrapper } from '../../services/Currency';

const GET_PRODUCTS = gql`
    query(
        $company_id: Int!
        $name: String,
        $category_id: Int
    ) {

        products (where: {
            name: {_ilike: $name},
            category_id: {_eq: $category_id},
            company_id: {
                _eq: $company_id
            },
            archived: {_is_null: true}
        }, order_by:{
            created_at: desc
        }) {
            id
            name
            company_id
            has_variants
            category{
                id
                raw_materials
            }
            variants_aggregate{
                aggregate{
                    count
                    min{
                        price
                    }
                    max{
                        price
                    }
                }
            }
            inventory_aggregate: products_inventory_aggregate {
                aggregate {
                    sum {
                        qty
                    }
                }
            }
        }

    }
`;

const GET_CATEGORIES = gql`
    query($company_id: Int!) {

        categories (where: {company_id: {_eq: $company_id}}) {
            id
            name
        }

    }
`;

class Products extends React.Component {

    state = {
        companyId: this.props.match.params.companyId,
        searchValue: '',
        category: 'all'
    };

    render() {

        const { searchValue, category, companyId } = this.state;

        return (
            <div className="py-12 overflow-scroll h-full w-full">
                <div className="w-full max-w-3xl mx-auto px-4">
                    <Query
                        variables={{
                            company_id: companyId,
                            name: searchValue ? `%${searchValue}%` : null,
                            category_id: category !== 'all' ? parseInt(category) : null
                        }}
                        fetchPolicy="cache-and-network"
                        errorPolicy="ignore"
                        query={GET_PRODUCTS}
                    >
                        {({ loading, data, error, refetch }) => {

                            // console.log(data);

                            return (
                                <React.Fragment>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <h1 className="font-bold text-4xl mr-2">
                                                Products
                                            </h1>
                                            {loading ? null : <button className="ml-4 focus:outline-none text-black" onClick={() => refetch()}>
                                                <RefreshCw size={24} />
                                            </button>}
                                        </div>
                                        <div className="">
                                            <Button type="primary" onClick={() => this.props.history.push(`/${companyId}/pos/product/add`)} shape="round" ghost icon={<PlusOutlined />} className="flex items-center justify-center">Add</Button>
                                        </div>
                                    </div>
                                    <div className="flex items justify-between mt-8">
                                        <div className="flex-1 pr-4">
                                            <Input.Search
                                                defaultValue={searchValue}
                                                placeholder="Search products"
                                                onSearch={searchValue => this.setState({ searchValue })}
                                            // style={{ width: 200 }}
                                            />
                                        </div>
                                        <div className="">
                                            <Query
                                                query={GET_CATEGORIES}
                                                variables={{
                                                    company_id: companyId
                                                }}
                                            >
                                                {({ data, error, loading }) => {

                                                    return (
                                                        !loading && data ? <Select style={{ width: 240 }} onChange={(category) => this.setState({ category })} defaultValue={category || ''} placeholder="">
                                                            <Select.Option value="all">All Categories</Select.Option>
                                                            {data.categories.map((category) => {

                                                                return <Select.Option value={category.id} key={category.id}>{category.name}</Select.Option>

                                                            })}
                                                        </Select> : null
                                                    )

                                                }}
                                            </Query>
                                        </div>
                                    </div>
                                    {loading ? <div className="flex items-center justify-center"><Spinner /></div> : <div className="mt-3">
                                        {data && data.products ? data.products.map((product) => {

                                            const minPrice = product.variants_aggregate.aggregate.min.price || 0;
                                            const maxPrice = product.variants_aggregate.aggregate.max.price || 0;
                                            const isService = !product.has_variants;
                                            const isRawMaterial = product.category.raw_materials;

                                            return (
                                                <Link to={`/${companyId}/pos/product/${product.id}`}>
                                                    <div key={product.id} className="py-4 border-b flex items-center justify-between">
                                                        <div className="">
                                                            <p className="font-bold text-lg text-black">{product.name}</p>
                                                            {isRawMaterial ? <p>
                                                                Raw Material
                                                            </p> : isService ? <p>
                                                                Service
                                                            </p> : <p className="">
                                                                {product.variants_aggregate.aggregate.count} Variants
                                                            </p>}
                                                        </div>
                                                        <CurrencyWrapper
                                                            component={({ symbol }) => (
                                                                <div className="">
                                                                    {symbol} {isService || (minPrice == maxPrice) ? currencyFormat(maxPrice) : `${currencyFormat(minPrice)} - ${currencyFormat(maxPrice)}`}
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </Link>
                                            )

                                        }) : null}
                                    </div>}
                                </React.Fragment>
                            )

                        }}
                    </Query>
                </div>
            </div>
        )

    }

}

export default Products