import React from 'react';
import { Input, Table, Select, Button as AntButton, Spin, Checkbox, Modal, InputNumber } from 'antd';
import { Button as EverButton, Spinner } from 'evergreen-ui';
import { values, orderBy, sum, sumBy, every, keyBy, fromPairs, compact } from 'lodash';
import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import { currencyFormat } from 'mout/number';
import apollo from '../../../services/apollo';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { CurrencyFormat } from '../../../services/Currency';
import moment from 'moment';

const GET_TRANSFER = gql`

    query(
        $id: Int!
    ) {

        transfer: products_history_by_pk(id: $id) {
            received
            source{
              id
              name
            }
            destination{
              id
              name
            }
            comment
            
            employee{
                id
                display_name
            }
            created_at
            type
            items{
              qty
              price
              variant{
                id
                cost
                name
                price
                product {
                    id
                    name
                    has_variants
                    supplier {
                        name
                    }
                }
              }
            }
            
        }

    } 

`;


const GET_OUTLETS = gql`
    query($company_id: Int!) {

        outlets (where: {
            company_id: {_eq: $company_id},
            _or: [
                {raw_materials: {
                    _is_null: true,
                }},
                {raw_materials: {
                    _eq: false
                }}
            ],
        }) {
            id
            name
            company_id
        }

    }
`;

const GET_VARIANT = gql`
    query (
        $code: String!,
        $q: String!,
        $outlet_id: Int!,
        $dest_id: Int!,
        $company_id: Int!
    ) {
        products_sku(where: {
            _or: [
                {code: {_eq: $code}},
                {variant: {
                    name: {_ilike: $q},
                }},
                {variant: {
                    product: {
                        name: {_ilike: $q}
                    }
                }}
            ],
            variant:{
                product:{
                    archived: {_is_null: true},
                    has_variants: {_eq: true},
                    company_id: {
                        _eq: $company_id
                    }
                }
            }
        }) {
            id
            variant {
                id
                cost
                name
                price
                product {
                    id
                    name
                    has_variants
                    supplier {
                        name
                    }
                }
                inventory: inventory_aggregate(where: {outlet_id: {_eq: $outlet_id}}) {
                    aggregate {
                        sum {
                            qty
                        }
                    }
                }
                dest_inventory_i: inventory(where: {outlet_id: {_eq: $dest_id}}) {
                    id
                    price
                    cost
                }
                dest_inventory: inventory_aggregate(where: {outlet_id: {_eq: $dest_id}}) {
                    aggregate {
                        count
                    }
                }
            }
        }
    }
  
`;

class StockTransfer extends React.Component {

    state = {
        companyId: this.props.match.params.companyId,
        productId: this.props.match.params.productId,
        outlet_id: null,
        items: this.props.transfer ? keyBy(this.props.transfer.items, 'id') : {},
        qty: this.props.transfer ? fromPairs(this.props.transfer.items.map((i) => [i.id, i.qty])) : {},
        comment: this.props.transfer ? this.props.transfer.comment : '',
        isReverse: false,
        searchValue: ''
    }

    inputRef = React.createRef()

    columns = compact([{
        title: 'Order #',
        dataIndex: 'code',
        key: 'code',
        render: (sku, v, i) => <span className="font-bold text-black">{i + 1}</span>
    }, {
        title: 'Product',
        render: (v) => v.variant ? <div className="">
            <p className="font-bold text-black">{v.variant.product.name}</p>
            <p className="text-black font-light">{v.variant.name}</p>
        </div> : null
    }, this.props.id ? null : {
        title: 'Stock on Hand',
        render: (v) => v.variant ? v.variant.inventory.aggregate.sum.qty || 0 : null
    }, {
        title: 'Quantity',
        render: (v) => (
            !this.props.id ? <InputNumber
                value={this.state.qty[v.code]}
                min={1}
                max={v.variant.inventory.aggregate.sum.qty || 0}
                onClick={(e) => e.target.select()}
                // formatter={value => `${value}  ${v.product.unit.toUpperCase()}`}
                // parser={value => value.replace(`  ${v.product.unit.toUpperCase()}`, '')}
                onChange={(_qty) => {

                    this.state.qty[v.code] = parseInt(_qty);

                    this.setState({
                        qty: this.state.qty
                    });

                }}
            /> : this.state.qty[v.code]
        )
    }, {
        title: 'Retail Price',
        render: (v) => v.variant ? <CurrencyFormat amount={v.variant.price} /> : null
    }, {
        title: 'Total',
        render: (v) => v.variant ? <CurrencyFormat amount={this.state.qty[v.code] * v.variant.price} /> : null
    }, !this.props.id ? {
        title: '',
        render: (v) => v.loading ? <Spin size="small" /> : <AntButton onClick={() => {

            let { qty, items } = this.state;

            delete items[v.code];
            delete qty[v.code];


            this.setState({
                items: { ...items },
                qty: { ...qty }
            });

        }} className="flex items-center justify-center" type="danger" size="small" ghost icon={<DeleteOutlined />} shape="circle" />
    } : null]);

    fetchVariant = async (value) => {

        const _item = JSON.parse(value);

        if (_item.variant.product.has_variants && !_item.variant.inventory.aggregate.sum.qty) {

            Modal.error({
                title: 'Out of Stock',
                content: 'This item is either unavailable or out of stock at this location',
            });

            return;

        }

        const { items, qty } = this.state;
        const code = _item.variant.id;

        const item = items[code];


        if (item) {

            qty[code]++;

            this.setState({
                items,
                qty,
                searchValue: ''
            });

            return;

        } else {


            items[code] = {
                code: `${_item.variant.id}`,
                updatedAt: Date.now(),
                ..._item
            };

            qty[code] = 1;

            this.setState({
                items,
                qty,
                searchValue: ''
            });


        }

    }

    get SAVE_MUTATION() {

        const { items, qty, source_id, destination_id } = this.state;

        return gql`
            mutation(
                $qty: Int!,
                $total: float8!,
                $company_id: Int!,
                $destination_id: Int,
                $employee_id: Int!,
                $items:  [products_history_items_insert_input!]!,
                $comment: String
                $source_id: Int
            ){
                transfer: insert_products_history(objects: {
                    items: {data: $items},
                    company_id: $company_id,
                    destination_id: $destination_id,
                    employee_id: $employee_id,
                    source_id: $source_id,
                    total_amount: $total,
                    total_qty: $qty,
                    type: "TRANSFER_STOCK",
                    comment: $comment
                }) {
                    returning{
                        id
                    }
                }
                ${values(items).map((i, index) => {

            // console.log(i);

            const { variant } = i;
            const notExist = variant.dest_inventory.aggregate.count == 0;
            const exist = variant.dest_inventory_i;

            // console.log(variant.dest_inventory.aggregate);

            return notExist ? `

                        i_${index}: insert_products_inventory(objects: {
                            company_id: $company_id,
                            outlet_id: ${parseInt(destination_id)},
                            qty: 0,
                            variant_id: ${parseInt(variant.id)},
                            product_id: ${parseInt(variant.product.id)},
                            price: ${parseFloat(variant.price)},
                            cost: ${parseFloat(variant.cost)}
                        }) {
                            affected_rows
                        }
                                        
                    ` : `
                        u_dest_${index}: update_products_inventory(where: {
                            outlet_id: {_eq: ${parseInt(destination_id)}},
                            variant_id: {_eq: ${parseInt(variant.id)}}
                        }, _set:{
                            ${exist.length ? `
                                ${!exist[0].price ? `price: ${parseFloat(variant.price)},` : ''}
                                ${!exist[0].cost ? `cost: ${parseFloat(variant.cost)}` : ''}
                            `: ''}
                        }) {
                            affected_rows
                        }
                    `;

        })}
                ${values(items).map((i, index) => {

            // console.log(i);

            const { code, variant } = i;

            const _qty = qty[code];

            return `
                        u_${index}: update_products_inventory(where: {
                            outlet_id: {_eq: ${parseInt(source_id)}},
                            variant_id: {_eq: ${parseInt(variant.id)}}
                        }, _inc: {
                            qty: -${_qty || 0}
                        }, _set:{
                            company_id: $company_id,
                            product_id: ${parseInt(variant.product.id)}
                        }) {
                            affected_rows
                        }
                    `;

        })}
                
            }
        `;

    }

    render() {

        const { companyId, comment, qty, items, searchValue, isReverse, source_id, destination_id } = this.state;
        const { user, id, transfer } = this.props;
        const itemsCount = Object.keys(items).length;
        const _items = values(items);
        const total_qty = sum(values(qty));
        const total = sumBy(_items, (v) => {

            return v.variant ? v.variant.price * qty[v.code] : 0;

        });

        return (
            <React.Fragment>
                <div className="overflow-scroll h-full">
                    <div className="max-w-4xl mx-auto w-full px-6">
                        <div className="flex items-center justify-between my-10">
                            <div className="flex items-center">
                                <h1 className="font-bold text-4xl mr-2">
                                    Transfer Stock {id ? `#${id}` : ''}
                                </h1>
                            </div>
                        </div>
                        {id ? <div className="mb-3">
                            <p className="font-bold text-black">Date Created</p>
                            <p className="font-bold text-black text-lg my-1">
                                {moment(transfer.created_at).format('DD/MM/YYYY')}
                            </p>
                        </div> : null}
                        {id && transfer.employee ? <div className="mb-3">
                            <p className="font-bold text-black">Created By</p>
                            <p className="font-bold text-black text-lg my-1">
                                {transfer.employee.display_name}
                            </p>
                        </div> : null}
                        <div className="flex w-full -mx-2">
                            <div className="w-1/3 px-2">
                                <p className="font-bold text-black">Source Outlet</p>
                                <div className="my-2">
                                    {!id ? <Query
                                        query={GET_OUTLETS}
                                        variables={{
                                            company_id: companyId
                                        }}
                                    >
                                        {({ loading, data, error, refetch }) => {

                                            return (
                                                loading ? <Select placeholder="Select Outlet" key="loading" value={loading ? "loading" : "choose"} style={{ width: '100%' }}>
                                                    <Select.Option value="loading" disabled={true}>Loading</Select.Option>
                                                </Select> : <Select disabled={itemsCount > 0} value={source_id} onChange={(source_id) => this.setState({
                                                    source_id,
                                                    destination_id: null
                                                })} placeholder="Select Outlet" style={{ width: '100%' }}>
                                                    {data.outlets.map((outlet) => {

                                                        return <Select.Option key={outlet.id} value={outlet.id}>{outlet.name}</Select.Option>

                                                    })}
                                                </Select>
                                            )

                                        }}
                                    </Query> : <p className="font-bold text-lg text-black">{transfer.source.name}</p>}
                                </div>
                            </div>
                            <div className="w-1/3 px-2">
                                <p className="font-bold text-black">Destination Outlet</p>
                                <div className="my-2">
                                    {!id ? <Query
                                        query={GET_OUTLETS}
                                        variables={{
                                            company_id: companyId
                                        }}
                                    >
                                        {({ loading, data, error, refetch }) => {

                                            return (
                                                loading ? <Select placeholder="Select Outlet" key="loading" value={loading ? "loading" : "choose"} style={{ width: '100%' }}>
                                                    <Select.Option value="loading" disabled={true}>Loading</Select.Option>
                                                </Select> : <Select disabled={!source_id || itemsCount > 0} value={destination_id} onChange={(destination_id) => this.setState({ destination_id })} placeholder="Select Outlet" style={{ width: '100%' }}>
                                                    {data.outlets.filter((o) => o.id !== source_id).map((outlet) => {

                                                        return <Select.Option key={outlet.id} value={outlet.id}>{outlet.name}</Select.Option>

                                                    })}
                                                </Select>
                                            )

                                        }}
                                    </Query> : <p className="font-bold text-lg text-black">{transfer.destination.name}</p>}
                                </div>
                            </div>
                        </div>
                        {!id ? <div className="my-8">

                            <Query
                                query={GET_VARIANT}
                                variables={{
                                    code: searchValue,
                                    q: `%${searchValue}%`,
                                    outlet_id: source_id,
                                    dest_id: destination_id,
                                    company_id: companyId
                                }}
                                skip={!searchValue}
                            >
                                {({ data, error, loading, refetch }) => {

                                    return (
                                        <Select
                                            showSearch
                                            loading={loading}
                                            value={searchValue}
                                            placeholder="Scan product barcode here"
                                            style={{ width: '100%' }}
                                            disabled={!source_id || !destination_id}
                                            notFoundContent={loading ? <div className="flex items-center justify-center p-4"><Spinner /></div> : null}
                                            showArrow={false}
                                            allowClear
                                            filterOption={false}
                                            onSearch={(searchValue) => this.setState({ searchValue })}
                                            onChange={(value) => {

                                                if (value) {

                                                    this.fetchVariant(value);

                                                }


                                            }}
                                        // notFoundContent={null}
                                        >
                                            {!loading && data && data.products_sku.length ? data.products_sku.map(d => d.variant.product ? (
                                                <Select.Option key={d.id} value={JSON.stringify(d)}>
                                                    {d.variant.product.name} - {d.variant.name} - <CurrencyFormat amount={d.variant.price} />
                                                </Select.Option>
                                            ) : null) : null}
                                        </Select>
                                    )

                                }}
                            </Query>

                        </div> : null}
                        <div className="my-8 shadow rounded">
                            <Table
                                dataSource={orderBy(_items, ['updatedAt'], ['desc'])}
                                columns={this.columns}
                                pagination={false}
                                rowKey={(i) => `${i.code}`}
                            />
                        </div>
                        <div className="flex justify-between my-8 -mx-4">
                            <div className="flex-1 px-4">
                                <div className="mb-4">
                                    {!id ? <Input.TextArea
                                        value={comment}
                                        onChange={(e) => this.setState({ comment: e.target.value })}
                                        autosize={{ minRows: 6, maxRows: 6 }}
                                        placeholder="Notes / Comments"
                                        style={{ width: '100%' }}
                                    /> : <>
                                        <p className="font-bold text-black">Notes / Comments</p>
                                        <p className="text-black">{comment ? comment : "No Comment"}</p>
                                    </>}
                                </div>
                                {!id ? <Mutation
                                    mutation={this.SAVE_MUTATION}
                                    variables={{
                                        qty: total_qty,
                                        total,
                                        comment,
                                        employee_id: user.id,
                                        company_id: companyId,
                                        destination_id,
                                        source_id,
                                        items: _items.map((i) => ({
                                            // id: 10,
                                            price: i.variant.price,
                                            qty: qty[i.code],
                                            variant_id: i.variant.id
                                        }))
                                    }}
                                    onCompleted={(data) => {

                                        this.props.history.push(`/${companyId}/pos/transfers/${data.transfer.returning[0].id}`)

                                    }}
                                >
                                    {(save, { loading }) => {

                                        return (
                                            <EverButton
                                                disabled={itemsCount < 1 || !every(_items, (v) => v.variant)}
                                                intent="success"
                                                appearance="primary"
                                                isLoading={loading}
                                                onClick={() => {

                                                    save();

                                                }}
                                            >
                                                Save
                                            </EverButton>
                                        );

                                    }}
                                </Mutation> : null}
                            </div>
                            <div className="px-4">
                                <div className="rounded w-64 bg-gray-100 px-6 py-3">
                                    <div className="border-b py-2 my-2 flex items-center justify-between">
                                        <p className="text-black font-bold">Total Units</p>
                                        <p className="font-bold text-black">{total_qty}</p>
                                    </div>
                                    <div className="py-2 my-2 flex items-center justify-between">
                                        <p className="font-bold text-black">Total</p>
                                        <p className="font-bold text-black">
                                            <CurrencyFormat amount={total} />
                                        </p>
                                    </div>
                                </div>
                                {/* <div className="my-4">
                                    <EverButton intent="success" appearance="primary">Save</EverButton>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )

    }

}

const StockTransferContainer = (props) => {

    const { companyId, id } = props.match.params;

    return (
        <Query
            query={GET_TRANSFER}
            skip={id ? false : true}
            variables={{
                id
            }}
            onCompleted={(data) => {

                if (data && data.transfer && data.transfer.received) {

                    props.history.push(`/${companyId}/pos/transfers/${id}`);

                }

            }}
        >
            {({ loading, data, error, refetch }) => {

                return (
                    <div className="overflow-scroll h-full w-full">
                        {loading ? <div className="flex items-center justify-center py-10">
                            <Spinner />
                        </div> : (!id || (data && data.transfer && data.transfer.type == "TRANSFER_STOCK")) ? <StockTransfer
                            {...props}
                            id={id}
                            transfer={!id ? null : {
                                ...data.transfer,
                                items: data.transfer.items.map((i) => ({
                                    id: i.variant.id,
                                    qty: i.qty,
                                    code: i.variant.id,
                                    variant: {
                                        id: i.variant.id,
                                        name: i.variant.name,
                                        cost: i.variant.cost,
                                        price: i.price,
                                        product: {
                                            id: i.variant.product.id,
                                            name: i.variant.product.name
                                        }
                                    }
                                }))
                            }}
                        /> : <p className="font-bold text-lg p-6 text-black">Stock Transfer not Found</p>}
                    </div>
                );

            }}

        </Query>
    )

}

export default StockTransferContainer;