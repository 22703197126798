import React from 'react';
import CompanySummary from "./misc/companySummary";

export default ({company}) => {

    return company ? (
        <CompanySummary
            company={company}
        />
    ): null;

}