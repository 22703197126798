import React from 'react';
import {Link} from 'react-router-dom';
import {BackButton} from 'evergreen-ui';

class Reports extends React.Component{

    state = {
        companyId: this.props.match.params.companyId
    }

    render() {

        const {companyId} = this.state;

        return (
            <div className="max-w-4xl mx-auto w-full px-8 py-16">
                <BackButton
                    appearance="default"
                    marginBottom={15}
                    onClick={()=>{

                        this.props.history.push(`/${companyId}/reports/`);

                    }}
                    children="Back to reports"
                />
                <h1 className="text-3xl font-bold text-black">Accounting Reports</h1>
                <div className="my-6 -mx-4">
                    <div className="flex flex-wrap">
                        <Link to={`/${companyId}/reports/accounting/balance-sheet`} className="w-1/2 my-4 px-4">
                            <p className="font-bold text-lg text-black mb-1">Balance Sheet</p>
                            <p className="">Snapshot of what your business owns or is due to receive from others (assets), what it owes to others (liabilities), and what you've invested or retained in your company (equity).</p>                  
                        </Link>
                        <Link to={`/${companyId}/reports/accounting/profit-loss`} className="w-1/2 my-4 px-4">
                            <p className="font-bold text-lg text-black mb-1">Profit & Loss (Income Statement)</p>
                            <p className="">Summary of your revenue and expenses that determine the profit you made.</p>
                        </Link>
                        <Link to={`/${companyId}/reports/accounting/balances`} className="w-1/2 my-4 px-4">
                            <p className="font-bold text-lg text-black mb-1">Account Balances</p>
                            <p className="">Summary view of balances and activity for all accounts.</p>
                        </Link>
                        <Link to={`/${companyId}/reports/accounting/trial-balance`} className="w-1/2 my-4 px-4">
                            <p className="font-bold text-lg text-black mb-1">Trial Balance</p>
                            <p className="">Balance of all your accounts on a specified date.</p>
                        </Link>
                    </div>
                </div>
            </div>
        );

    }

}

export default Reports;