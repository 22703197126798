import React from 'react';
import Pusher from 'pusher-js';
import { Switch } from 'evergreen-ui';

const pusher = new Pusher('85a1ab66499df71f8a72', {
    authEndpoint: `${process.env.REACT_APP_API_ENDPOINT}/pusher/auth`,
    cluster: 'eu'
});


class RemoteScanner extends React.Component{

    state = {
        enabled: false,
        ready: false
    }

    componentDidMount(){

        const {accountId} = this.props;
        const _this = this;

        const channel = pusher.subscribe(`private-account-${accountId}`);

        channel.bind('pusher:subscription_succeeded', () => {
            
            this.setState({
                ready: true
            });

        });

        channel.bind('client-barcode', function ({code}) {

            const {enabled} = _this.state;

        
            if (enabled) {

                const input = document.activeElement;

                input.focus();

                input.value = code + String.fromCharCode(13);

                input.dispatchEvent(new Event('change'));

                var ev = new KeyboardEvent('keydown', {altKey:false,
                    bubbles: true,
                    cancelBubble: false, 
                    cancelable: true,
                    charCode: 0,
                    code: "Enter",
                    composed: true,
                    ctrlKey: false,
                    currentTarget: null,
                    defaultPrevented: true,
                    detail: 0,
                    eventPhase: 0,
                    isComposing: false,
                    isTrusted: true,
                    key: "Enter",
                    keyCode: 13,
                    location: 0,
                    metaKey: false,
                    repeat: false,
                    returnValue: false,
                    shiftKey: false,
                    type: "keydown",
                    which: 13});
                  
                  input.dispatchEvent(ev);


            }

        });
        
    }

    render(){

        const {enabled, ready} = this.state;
        const {hideText} = this.props;

        return (

            ready ? <div className={[
                "flex",
                "items-center",
                hideText ? "justify-center" : "justify-between"
            ].join(' ')}>
                {!hideText ? <p className="📦color_425A70 📦fnt-fam_b77syt 📦fnt-sze_14px font-bold">
                    Remote Scan
                </p>: null}
                <Switch
                    checked={enabled}
                    onChange={(e) => this.setState({enabled: e.target.checked})}
                />
            </div>: null

        );

    }

}

export default RemoteScanner;