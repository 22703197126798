import React, {createContext} from 'react';
import currencyFormat from 'mout/number/currencyFormat';


export const CurrencyContext = createContext({});

export const CurrencyFormat = ({amount}) => {

    return (
        <CurrencyContext.Consumer>
            {({currency}) => {

                const { symbol } = currency;

                const money = currencyFormat(amount, currency.precision, currency.decimal_separator, currency.thousand_separator);

                return (
                    `${symbol} ${money}`
                )

            }}
        </CurrencyContext.Consumer>
    )

}

export const CurrencyWrapper = ({component}) => {

    return (
        <CurrencyContext.Consumer>
            {({currency}) => {
                
                return (
                    component(currency)
                )

            }}
        </CurrencyContext.Consumer>
    );
}

// export const CurrenyRegex = (symbol) => new RegExp(`\${symbol}\s?|(,*)`, "g")

export const CurrencySymbol = () => {

    return (
        <CurrencyContext.Consumer>
            {({currency}) => {

                const { symbol } = currency;

                return (
                    `${symbol}`
                )

            }}
        </CurrencyContext.Consumer>
    )

}
