import { Model } from '@nozbe/watermelondb'
import { field, date, readonly } from '@nozbe/watermelondb/decorators'

export default class Outlet extends Model {
    
    static table = 'outlets'

    @field('commission') commission
    @field('company_id') companyId
    @field('discount_limit') discountLimit
    @field('name') name
    @field('phone') phone
    @field('raw_materials') rawMaterials
    @field('salesperson_percent') salespersonPercent
    @field('supervisor_percent') supervisorPercent
    @field('warehouse') warehouse
    @field('wholesale') wholesale
    
    @field('remote_id') remoteId
    @readonly @date('created_at') createdAt
    @readonly @date('updated_at') updatedAt
}