import { Model } from '@nozbe/watermelondb'
import { field, date, readonly } from '@nozbe/watermelondb/decorators'

export default class ProductSku extends Model {
    
    static table = 'products_sku'

    @field('code') code
    @field('company_id') companyId
    @field('variant_id') variantId
    
    @field('remote_id') remoteId
    @readonly @date('created_at') createdAt
    @readonly @date('updated_at') updatedAt
}