import React, { useState, useEffect } from 'react';
import { Input, Table, Select, Button as AntButton, Spin, Checkbox, InputNumber, message } from 'antd';
import { Button as EverButton, Spinner } from 'evergreen-ui';
import { values, orderBy, sum, sumBy, some, keys, map, pick, get, fromPairs } from 'lodash';
import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import { currencyFormat } from 'mout/number';
import { SearchOutlined } from '@ant-design/icons';
import apollo from '../../../services/apollo';
import { CurrencyFormat, CurrencyWrapper } from '../../../services/Currency';



const GET_OUTLETS = gql`
    query($company_id: Int!) {

        outlets (where: {
            company_id: {_eq: $company_id},
            _or: [
                {raw_materials: {
                    _is_null: true,
                }},
                {raw_materials: {
                    _eq: false
                }}
            ],
        }) {
            id
            name
            company_id
        }

    }
`;

const GET_VARIANTS = gql`
    query (
        $company_id: Int!,
        $outlet_id: Int!
    ) {
        items: products_variants(where:{
            product:{
                archived: {_is_null: true},
                company_id:{_eq: $company_id},
                has_variants:{_eq: true}
            }
        }) {
            skus{
                id
                code
            }
            id
            cost
            price
            name
            prices: inventory(where: {outlet_id: {_eq: $outlet_id}}){
                id
                price
                cost
            }
            inventory: inventory_aggregate(where: {outlet_id: {_eq: $outlet_id}}) {
                aggregate {
                    count
                    sum {
                        qty
                    }
                }
            }
            product {
                id
                name
                supplier {
                    name
                }
            }
        }
    }
  
`;

class PriceChange extends React.Component {

    state = {
        companyId: this.props.match.params.companyId,
        productId: this.props.match.params.productId,
        outlet_id: null,
        isReverse: false,
        items: {},
        qty: {},
        searchValue: ''
    }

    inputRef = React.createRef()

    render() {

        const { companyId, outlet_id, qty, items, searchValue, isReverse } = this.state;
        const { user } = this.props;
        const itemsCount = Object.keys(items).length;

        return (
            <React.Fragment>
                <div className="overflow-scroll h-full">
                    <div className="max-w-6xl mx-auto w-full px-6">
                        <div className="flex items-center justify-between my-10">
                            <div className="flex items-center">
                                <h1 className="font-bold text-4xl mr-2">
                                    Price Change
                                </h1>
                            </div>
                        </div>
                        <div className="">
                            <div className="max-w-xs">
                                <p className="font-bold text-black">Outlet</p>
                                <div className="my-2">
                                    <Query
                                        query={GET_OUTLETS}
                                        variables={{
                                            company_id: companyId
                                        }}
                                    >
                                        {({ loading, data, error, refetch }) => {

                                            return (
                                                loading ? <Select placeholder="Select Outlet" key="loading" value={loading ? "loading" : "choose"} style={{ width: '100%' }}>
                                                    <Select.Option value="loading" disabled={true}>Loading</Select.Option>
                                                </Select> : <Select
                                                    value={outlet_id}
                                                    disabled={outlet_id != null}
                                                    onChange={(outlet_id) => this.setState({ outlet_id })}
                                                    placeholder="Select Outlet"
                                                    style={{ width: '100%' }}
                                                >
                                                    {data.outlets.map((outlet) => {

                                                        return <Select.Option key={outlet.id} value={outlet.id}>{outlet.name}</Select.Option>

                                                    })}
                                                </Select>
                                            )

                                        }}
                                    </Query>
                                </div>
                            </div>
                        </div>
                        <Query
                            query={GET_VARIANTS}
                            variables={{
                                company_id: companyId,
                                outlet_id
                            }}
                            skip={!outlet_id}
                        >
                            {({ loading, data, error }) => {

                                if (loading) return (
                                    <Spinner />
                                )

                                const _items = ((data || {}).items || []).map((i) => ({
                                    ...i,
                                    _skus: map(i.skus, 'code'),
                                    updatedAt: Date.now()
                                }));

                                return (
                                    <PriceChangeInner
                                        items={_items}
                                        outlet_id={outlet_id}
                                        companyId={companyId}
                                        user={user}
                                        clear={() => this.setState({ outlet_id: null })}
                                    />
                                )

                            }}
                        </Query>
                    </div>
                </div>
            </React.Fragment>
        )

    }

}

export const getColumnSearchProps = ({
    placeholder,
    dataIndex,
    render
}) => {

    let searchInput;

    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {

                        searchInput = node;

                    }}
                    placeholder={placeholder}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => confirm()}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
            </div>
        ),
        filterIcon: filtered => <SearchOutlined className="print-hidden" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => {

            const texts = dataIndex.map((key) => get(record, key)).map((k) => k ? k.toString().toLowerCase() : '');

            return some(texts, (k) => k.includes(value.toLowerCase()));

        },
        onFilterDropdownVisibleChange: visible => {

            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }

        },
        render
    }
};

const PriceChangeInner = ({ items, clear, outlet_id, companyId, user }) => {

    const defaultQty = fromPairs(items.map((i) => [i.id, {
        cost: i.prices.length ? i.prices[0].cost || i.cost : i.cost,
        price: i.prices.length ? i.prices[0].price || i.price : i.price
    }]));

    const [products, setProducts] = useState(items);
    const [qty, setQty] = useState(defaultQty);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [comment, setComment] = useState('');

    useEffect(() => {

        const _qty = localStorage.getItem(`${companyId}-${outlet_id}-${user.id}-price-change`);
        const parsd = JSON.parse(_qty);

        setQty(parsd && keys(parsd).length ? parsd : defaultQty);

        return () => { };


    }, []);

    useEffect(() => {


        localStorage.setItem(`${companyId}-${outlet_id}-${user.id}-price-change`, JSON.stringify(qty));

        return () => { };


    }, [qty]);

    const priceOnChange = (v, price) => {

        if (price == 0) return;

        if (`${v.id}` in qty) {

            qty[v.id].price = price;

        } else {

            qty[v.id] = {
                price
            };

        }

        setQty({
            ...qty
        });


    };

    const costOnChange = (v, cost) => {

        if (cost == 0) return;

        if (`${v.id}` in qty) {

            qty[v.id].cost = cost;

        } else {

            qty[v.id] = {
                cost
            };

        }

        setQty({
            ...qty
        });


    };

    const onSelectChange = (selectedRowKeys) => {

        setSelectedRowKeys([...selectedRowKeys]);

    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };


    const columns = [{
        title: '#',
        dataIndex: 'code',
        key: 'code',
        render: (sku, v, i) => <span className="font-bold text-black">{i + 1}</span>
    }, {
        title: 'Product',
        ...getColumnSearchProps({
            placeholder: 'Search Products',
            dataIndex: ['name', 'product.name'],
            render: (v) => (
                <div className="">
                    <p className="font-bold text-black">{v.product.name}</p>
                    <p className="text-black font-light">{v.name}</p>
                </div>
            )
        }),
    }, {
        title: 'Stock on Hand',
        render: (v) => v.inventory.aggregate.sum.qty || 0
    }, {
        title: 'Supply Price',
        render: (v) => {

            // console.log(selectedRowKeys, v);

            // console.log(v.id, qty);

            return (
                (selectedRowKeys.indexOf(`${v.id}`) > -1) ? <CurrencyFormat amount={qty[v.id].cost || 0} /> : <CurrencyWrapper
                    component={({ symbol }) => (
                        <InputNumber
                            min={0}
                            onClick={(e) => {

                                e.target.select();

                            }}
                            value={qty[v.id] ? qty[v.id].cost || 0 : 0}
                            style={{ width: '100%' }}
                            onChange={(cost) => {

                                costOnChange(v, cost)

                            }}
                            parser={value => value.replace(/[^0-9.]/g, '')}
                            formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            size="small"
                        />
                    )}
                />
            );

        }
    }, {
        title: 'Retail Price',
        render: (v) => {

            // console.log(selectedRowKeys, v);

            return (
                (selectedRowKeys.indexOf(`${v.id}`) > -1) ? <CurrencyFormat amount={qty[v.id].price || 0} /> : <CurrencyWrapper
                    component={({ symbol }) => (
                        <InputNumber
                            min={0}
                            onClick={(e) => {

                                e.target.select();

                            }}
                            value={qty[v.id] ? qty[v.id].price || 0 : 0}
                            style={{ width: '100%' }}
                            onChange={(price) => {

                                priceOnChange(v, price)

                            }}
                            parser={value => value.replace(/[^0-9.]/g, '')}
                            formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            size="small"
                        />
                    )}
                />
            );

        }
    }];


    const total = sumBy(products, (v) => {

        const _qty = v.inventory.aggregate.sum.qty

        return (qty[v.id] ? qty[v.id].price : 0) * _qty;

    });

    const selectedQty = pick(qty, selectedRowKeys);

    // console.log(selectedQty);

    const selectedProducts = products.filter((i) => `${i.id}` in selectedQty);
    const selectedTotal = sumBy(selectedProducts, (v) => {

        const _qty = v.inventory.aggregate.sum.qty;

        return (selectedQty[v.id] ? selectedQty[v.id].price : 0) * _qty;

    });

    // console.log(selectedProducts);

    return (
        <React.Fragment>
            {products.length > 0 && keys(qty).length > 0 ? <AntButton onClick={() => {

                clear();

                setProducts([]);
                setQty({});
                setSelectedRowKeys([]);

            }} className="flex items-center justify-center my-2" type="danger" ghost>Clear</AntButton> : null}
            <div className="my-8">
                {/* <Input.Search
                    disabled={!outlet_id}
                    ref={inputRef}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onSearch={(code) => {

                        if (!code) return;

                        code = code.trim();

                        const item = find(products, (i) => {

                            return includes(i._skus, `${code}`);

                        });

                        if (!item) return setSearchValue('');

                        if (qty[item.id]) {

                            if (isReverse) {

                                qty[item.id]--;
                                item.updatedAt = Date.now();

                            } else {

                                qty[item.id]++;
                                item.updatedAt = Date.now();

                            }

                        } else {

                            if (isReverse) {

                                // delete item;
                                delete qty[item.id];

                            } else {

                                qty[item.id] = 1;
                                item.updatedAt = Date.now();


                            }
                        }

                        setQty(qty);
                        setProducts(items);
                        setSearchValue('');

                    }}
                    placeholder="Search Products"
                /> */}
                {/* <div className="mt-3">
                    <Checkbox
                        checked={isReverse}
                        disabled={!outlet_id}
                        onChange={(e) => setIsReserve(e.target.checked, () => inputRef.current.focus()) }
                    >Reverse</Checkbox>
                </div> */}
            </div>
            <div className="my-8 shadow rounded">
                <Table
                    rowSelection={rowSelection}
                    dataSource={orderBy(products, ['updatedAt'], ['desc'])}
                    columns={columns}
                    pagination={false}
                    rowKey={(r) => `${r.id}`}
                />
            </div>
            <div className="flex justify-between my-8 -mx-4">
                <div className="flex-1 px-4">
                    <div className="mb-4">
                        <Input.TextArea
                            autosize={{ minRows: 6, maxRows: 6 }}
                            placeholder="Notes / Comments"
                            style={{ width: '100%' }}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </div>
                    <Mutation
                        mutation={gql`
                            mutation(
                                $qty: Int!,
                                $total: float8!,
                                $company_id: Int!,
                                $employee_id: Int!,
                                $items:  [products_history_items_insert_input!]!,
                                $comment: String
                                $source_id: Int
                            ){
                                insert_products_history(objects: {
                                    items: {data: $items},
                                    company_id: $company_id,
                                    destination_id: null,
                                    employee_id: $employee_id,
                                    source_id: $source_id,
                                    total_amount: $total,
                                    total_qty: $qty,
                                    type: "PRICE_CHANGE",
                                    comment: $comment
                                }) {
                                    returning{
                                        id
                                    }
                                }
                               ${(selectedProducts).map((i, index) => {

                            const { id, product, inventory: { aggregate } } = i;

                            return aggregate.count > 0 ? `
                                        u_${index}: update_products_inventory(where: {
                                            outlet_id: {_eq: ${parseInt(outlet_id)}},
                                            variant_id: {_eq: ${parseInt(id)}}
                                        }, _set:{
                                            price: ${selectedQty[id].price || 0},
                                            cost: ${selectedQty[id].cost || 0},
                                            product_id: ${parseInt(product.id)},
                                            company_id: $company_id
                                        }) {
                                            affected_rows
                                        }
                                    `: `
                                        i_${index}: insert_products_inventory(objects: {
                                            company_id: $company_id,
                                            outlet_id: ${parseInt(outlet_id)},
                                            cost: ${selectedQty[id].cost || 0},
                                            price: ${selectedQty[id].price || 0},
                                            qty: 0,
                                            variant_id: ${parseInt(id)},
                                            product_id: ${parseInt(product.id)}
                                        }) {
                                            affected_rows
                                        }
                                    `;

                        })}
                            }
                        `}
                        variables={{
                            qty: keys(selectedQty).length,
                            total: selectedTotal,
                            comment,
                            employee_id: user.id,
                            company_id: companyId,
                            source_id: outlet_id,
                            items: selectedProducts.map((i) => ({
                                e_qty: i.inventory.aggregate.sum.qty || 0,
                                price: qty[i.id].price,
                                cost: qty[i.id].cost,
                                qty: i.inventory.aggregate.sum.qty || 0,
                                variant_id: i.id
                            }))
                        }}
                        onError={() => message.error('An error occurred while trying to save products.')}
                        onCompleted={() => {

                            setProducts([]);
                            setQty({});
                            setSelectedRowKeys([]);
                            clear();

                            message.success('Products saved successfully.');

                        }}
                    >
                        {(save, { loading }) => {

                            return (
                                <EverButton
                                    onClick={() => save()}
                                    isLoading={loading}
                                    disabled={selectedRowKeys.length == 0}
                                    intent="success"
                                    appearance="primary"
                                >
                                    Save
                                </EverButton>
                            )

                        }}
                    </Mutation>
                </div>
                <div className="px-4">
                    <div className="rounded w-64 bg-gray-100 px-6 py-3">
                        <div className="border-b py-2 my-2 flex items-center justify-between">
                            <p className="text-black font-bold">Total Units</p>
                            <p className="font-bold text-black">{keys(selectedQty).length}</p>
                        </div>
                        <div className="py-2 my-2 flex items-center justify-between">
                            <p className="font-bold text-black">Total</p>
                            <p className="font-bold text-black">
                                <CurrencyFormat amount={total} />
                            </p>
                        </div>
                    </div>
                    {/* <div className="my-4">
                        <EverButton intent="success" appearance="primary">Save</EverButton>
                    </div> */}
                </div>
            </div>
        </React.Fragment>
    )

}

export default PriceChange;