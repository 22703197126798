import { Database } from '@nozbe/watermelondb'
import LokiJSAdapter from '@nozbe/watermelondb/adapters/lokijs'

import schema from './schema'
import Discount from './Discount';
import Customer from './Customer';
import User from './User';
import Outlet from './Outlet';
// import LedgerTransaction from './LedgerTransaction';
// import Order from './Order';
// import OrderItem from './OrderItem';
// import OrderPayment from './OrderPayment';
import Product from './Product';
// import ProductHistory from './ProductHistory';
// import ProductHistoryItem from './ProductHistoryItem';
import ProductInventory from './ProductInventory';
import ProductSku from './ProductSku';
import ProductVariant from './ProductVariant';

import migrations from './migrations';



const adapter = new LokiJSAdapter({
    schema,
    useWebWorker: false,
    useIncrementalIndexedDB: true,
    migrations,
    onIndexedDBVersionChange: () => {
        // if (checkIfUserIsLoggedIn()) {
        //   window.location.reload()
        // }
    },
})

export const database = new Database({
    adapter,
    modelClasses: [
        Customer,
        Outlet,
        User,
        Discount,
        // LedgerTransaction,
        // Order,
        // OrderItem,
        // OrderPayment,
        Product,
        // ProductHistory,
        // ProductHistoryItem,
        ProductInventory,
        ProductSku,
        ProductVariant
    ],
    actionsEnabled: true,
})