import React from 'react';
import { Popover, Position, Menu } from 'evergreen-ui';
import { MoreHorizontal } from 'react-feather';
import { withRouter, Link } from 'react-router-dom';

class ProcumentMenu extends React.Component {
	render() {
		const { currentCompany, company } = this.props;

		return (
			<ul className="list-reset flex items-center -mx-4">
				<li>
					<Link to={`/${currentCompany}/procument/suppliers`} className="px-4 font-medium">
						Suppliers
					</Link>
				</li>
				<li>
					<Link to={`/${currentCompany}/procument/raw-materials`} className="px-4 font-medium">
						Raw Materials
					</Link>
				</li>
			</ul>
		);
	}
}

export default withRouter(ProcumentMenu);
