import React from 'react';
import {RefreshCw, Plus} from 'react-feather';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import {withRouter} from 'react-router-dom';
import {Modal} from 'antd';
import {context as PermissionContext} from '../services/Permission';

const GET_COMPANIES = gql`

    query ($account_id: Int!) {
        companies: company(where: {account_id: {_eq: $account_id}}) {
            id
            logo
            name
            employees_aggregate{
                aggregate{
                    count
                }
            }
        }
    }

`;

export function warning() {
    
    Modal.warning({
        title: 'Upgrade Required',
        content: 'You have reached the maximum number of companies you can create, please contact 0206835553 or info@defpal.com for assistance.',
    });

}

class Home extends React.Component{

    state = {
        companies: this.props.companies,
        refreshing: false
    }

    render() {

        const {refreshing, companies} = this.state;
        const {user, account, isAdmin} = this.props;

        return (
            <div className="py-12 overflow-scroll h-full">
                <div className="container mx-auto px-12">
                    <div className="flex items-center">
                        <h1 className="font-bold text-4xl mr-2">
                            Companies 
                        </h1>
                        {/* <button className="ml-4 focus:outline-none" onClick={this.refresh}>
                            <RefreshCw className={(refreshing) ? "rotating": null} size={24} />
                        </button> */}
                    </div>
                </div>
                <div className="my-8 container mx-auto px-8">
                    <div className="flex flex-wrap my-8">
                        {user.role === 'admin' ? <div className="p-4 w-full sm:w-2/4 lg:w-1/3">
                            <button className="shadow h-64 w-full bg-white rounded" onClick={()=>{

                                // alert(account.max_companies);

                                if (account.companies.length < account.max_companies) {

                                    this.props.history.push('/company/create');

                                } else {

                                    warning();

                                }


                            }}>
                                <div className="p-8 flex flex-col justify-between">
                                    <div className="text-grey-darker mb-4">
                                        <Plus className="inline" size={48} />
                                    </div>
                                    <div>
                                        Create a new company
                                    </div>
                                </div>
                            </button>
                        </div>: null}
                        {companies.map((company) => {

                            return (
                                <div key={company.id} className="p-4 w-full sm:w-2/4 lg:w-1/3">
                                    <div onClick={() => {

                                        this.props.history.push(`/${company.id}/home`);

                                    }} className="w-full cursor-pointer no-underline shadow h-64 w-full bg-white flex rounded">

                                        <div className="flex flex-col justify-between w-full">
                                            <div style={{
                                                backgroundImage: `url(${company.logo})`,
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'contain'
                                            }} className="flex-1 bg-gray-100 w-full" />
                                            <div className="p-4">
                                                <div className="text-lg font-bold">
                                                    {company.name}
                                                </div>
                                                {isAdmin ? <p className="">{company.employees_aggregate.aggregate.count || 0} Employees</p>: null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )

                        })}
                    </div>
                </div>
            </div>
        )

    }

}

const HomeWrapper = withRouter(Home);

class HomeContainer extends React.Component{

    render() {

        return (
            <Query
                query={GET_COMPANIES}
                variables={{
                    account_id: this.props.account.id
                }}
            >
                {({error, loading, data}) => {

                    return (
                        !loading && data && data.companies ? <PermissionContext.Consumer>
                            {({allowed_companies, isAdmin}) => {

                                // console.log(allowed_companies);

                                return (
                                    <HomeWrapper
                                        isAdmin={isAdmin}
                                        account={this.props.account}
                                        user={this.props.user}
                                        companies={data.companies.filter((c) => isAdmin ? c: allowed_companies.indexOf(`${c.id}`) > -1)}
                                    />
                                )

                            }}
                        </PermissionContext.Consumer> : null
                    )

                }}
            </Query>
        )

    }
 
}

export default HomeContainer;