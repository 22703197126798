import React from 'react';
import {Modal, Input} from 'antd';
import SearchProduct from './SearchProduct';

class AddStock extends React.Component{

    render() {

        const {value, onCancel, onOk, companyId} = this.props;

        return (
            <Modal
                title="Add Variant"
                visible={true}
                okText="Save"
                footer={null}
                onCancel={onCancel}
            >
                <div>
                    <div className="my-2">
                        <Input disabled={true} value={value} size="large" />
                    </div>
                    <SearchProduct companyId={companyId} code={value} onOk={onOk} />
                </div>
            </Modal>
        )

    }


}

export default AddStock;