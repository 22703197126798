import React from 'react';
import {Input, Table} from 'antd';

class ReturnStock extends React.Component{

    render() {

        return (
            <React.Fragment>
                <div className="flex items-center justify-between my-10">
                    <div className="flex items-center">
                        <h1 className="font-bold text-4xl mr-2">
                            Return Stock 
                        </h1>
                    </div>
                </div>
            </React.Fragment>
        )

    }

}

export default ReturnStock;