import React from 'react'
import { Mutation, Subscription, Query } from 'react-apollo';
import { Button as AntButton, Modal, Select, InputNumber } from 'antd';
import gql from 'graphql-tag';
import { toPairs, groupBy, values, map, includes } from 'lodash';
import roles, { groupedRoles, requiresOutlet } from '../../services/roles';

// console.log(roles, groupedRoles);

const UPDATE_ROLE_MUTATION = gql`

    mutation (
        $account_id: Int!,
        $company_id: Int!,
        $role: String!,
        $user_id: Int!,
        $outlet_id: Int,
        $employee_id: Int,
        $old_employee: Int
    ) {
        update_company_users(where: {
            company_id: {_eq: $company_id}, user_id: {_eq: $user_id}, account_id: {_eq: $account_id}
        }, _set: {
            role: $role,
            outlet_id: $outlet_id
        }) {
            affected_rows
        }
    }

`;

const UPDATE_ROLE_MUTATION_WITH_EMPLOYEE = gql`

    mutation (
        $account_id: Int!,
        $company_id: Int!,
        $role: String!,
        $user_id: Int!,
        $outlet_id: Int,
        $employee_id: Int!,
        $old_employee: Int!
    ) {
        update_company_users(where: {
            company_id: {_eq: $company_id}, user_id: {_eq: $user_id}, account_id: {_eq: $account_id}
        }, _set: {
            role: $role,
            outlet_id: $outlet_id
        }) {
            affected_rows
        }
        update_employees(where:{id:{_eq: $employee_id}}, _set:{
            credential_id: $user_id
        }){
            affected_rows
        }
        old_employee: update_employees(where:{id:{_eq: $old_employee}}, _set:{
            credential_id: null
        }){
            affected_rows
        }
    }

`;

const GET_OUTLETS = gql`

    query ($company_id: Int!) {
        outlets(where: {
            company_id:{_eq: $company_id}
        }) {
            id
            name
        }
    }

`;

const GET_EMPLOYEES_WITH_CURRENT = gql`

    query ($company_id: Int!, $id: Int) {
        employees(where: {
            _or: [
                {
                    company_id:{_eq: $company_id},
                    credential_id: {_is_null: true},
                },
                {
                    company_id:{_eq: $company_id},
                    id: {_eq: $id},
                }
            ]
        }) {
            id
            name
            designation
        }
    }

`;

const GET_EMPLOYEES = gql`

    query ($company_id: Int!, $id: Int) {
        employees(where: {
            company_id:{_eq: $company_id},
            credential_id: {_is_null: true}
        }) {
            id
            name
            designation
        }
    }

`;

const DELETE_ROLE_MUTATION = gql`

    mutation (
        $account_id: Int!,
        $company_id: Int!,
        $user_id: Int!
    ) {
        delete_company_users(where: {
            company_id: {_eq: $company_id}, user_id: {_eq: $user_id}, account_id: {_eq: $account_id}
        }) {
            affected_rows
        }
    }

`;

const CREATE_ROLE_MUTATION = gql`
    mutation (
        $account_id: Int!,
        $company_id: Int!,
        $role: String!,
        $user_id: Int!,
        $outlet_id: Int,
        $employee_id: Int,
        $old_employee: Int
    ) {
        insert_company_users(objects: {
            account_id: $account_id,
            company_id: $company_id,
            role: $role,
            outlet_id: $outlet_id,
            user_id: $user_id
        }) {
            affected_rows
        }
    }
`;

const CREATE_ROLE_MUTATION_WITH_EMPLOYEE = gql`
    mutation (
        $account_id: Int!,
        $company_id: Int!,
        $role: String!,
        $user_id: Int!,
        $outlet_id: Int,
        $employee_id: Int!,
        $old_employee: Int!
    ) {
        insert_company_users(objects: {
            account_id: $account_id,
            company_id: $company_id,
            role: $role,
            outlet_id: $outlet_id,
            user_id: $user_id
        }) {
            affected_rows
        }
        update_employees(where:{id:{_eq: $employee_id}}, _set:{
            credential_id: $user_id
        }){
            affected_rows
        }
        old_employee: update_employees(where:{id:{_eq: $old_employee}}, _set:{
            credential_id: null
        }){
            affected_rows
        }
    }
`;

export default class UserSettings extends React.Component {

    state = {
        disable_user: false,
        showAddUser: false,
        selected_outlet: null,
        selected_user: null,
        selected_role: null
    }
    render() {
        const {
            users,
            companyId,
            account,
            company,
            possibleUsers,
        } = this.props

        const {
            disable_user,
            selected_user,
            showAddUser,
            selected_role,
            selected_outlet,
            selected_employee,
            old_employee
        } = this.state
        return (
            <div className="px-8">

                <div className="my-8">
                    <div className="border-b py-3 my-4 flex items-center">
                        <p className="font-light text-black text-xl">Users</p>
                        {!this.props.possibleUsers.length ? null : <AntButton className="mx-2" type="primary" size="small" ghost onClick={() => this.setState({ showAddUser: true })}>add</AntButton>}
                    </div>
                    <div className="my-4">
                        {users.map((user) => {

                            console.log(user.user);

                            return (
                                <div key={user.user.id} className="flex items-center justify-between py-4 border-b">
                                    <div className="">
                                        <p className="text-black text-xl">{user.user.display_name}</p>
                                        <p className="font-light text-black">{roles[user.role].name}</p>
                                        {user.outlet ? <p className="font-light text-black">{user.outlet.name}</p> : null}
                                    </div>
                                    <div className="flex items-center -mx-2">
                                        {company.created_by !== user.user.id ? <AntButton className="mx-2" type="primary" size="small" ghost onClick={() => this.setState({
                                            showAddUser: true,
                                            disable_user: true,
                                            selected_user: user.user.id,
                                            selected_outlet: user.outlet_id,
                                            selected_role: user.role,
                                            old_employee: user.user.employee.length ? user.user.employee[0].id: null,
                                            selected_employee: user.user.employee.length ? user.user.employee[0].id: null
                                        })}>edit</AntButton>: null}
                                        {company.created_by !== user.user.id ? <Mutation
                                            mutation={DELETE_ROLE_MUTATION}
                                            variables={{
                                                company_id: companyId,
                                                account_id: account.id,
                                                user_id: user.user.id
                                            }}
                                        >
                                            {(mutate, { loading }) => <AntButton className="mx-2" type="danger" loading={loading} size="small" ghost onClick={() => mutate()}>delete</AntButton>}
                                        </Mutation>: null}
                                    </div>
                                </div>
                            )

                        })}
                    </div>
                </div>

                <Modal
                    title={disable_user ? "Edit Role" : "Add user"}
                    visible={showAddUser}
                    okText="Save"
                    footer={false}
                    onCancel={() => this.setState({
                        disable_user: false,
                        showAddUser: false,
                        selected_outlet: null,
                        selected_user: null,
                        selected_role: null,
                        selected_employee: null,
                        old_employee: null
                    })}>

                    <div className="mb-3">
                        <p className="font-bold mb-1">User</p>
                        <Select disabled={disable_user} value={selected_user} onChange={(selected_user) => this.setState({ selected_user })} style={{ width: '100%' }}>
                            {(disable_user ? account.users : possibleUsers).map((user) => {

                                return <Select.Option key={user.id} value={user.id}>{user.display_name}</Select.Option>

                            })}
                        </Select>
                    </div>

                    <div className="my-3">
                        <p className="font-bold mb-1">Role</p>
                        <Select value={selected_role} onChange={(selected_role) => {

                            this.setState({
                                selected_role,
                                selected_outlet: null
                            });


                        }} style={{ width: '100%' }}>
                            {toPairs(groupBy(values(roles), 'group')).map((group) => {

                                return <Select.OptGroup label={groupedRoles[group[0]]} key={group[0]}>
                                    {group[1].map((role) => {

                                        return (
                                            <Select.Option key={role.value} value={role.value}>{role.name}</Select.Option>
                                        );

                                    })}
                                </Select.OptGroup>

                            })}
                        </Select>
                    </div>
                    {selected_role && includes(requiresOutlet, selected_role) ? <div className="my-3">
                        <p className="font-bold mb-1">Outlet</p>
                        <Query
                            query={GET_OUTLETS}
                            variables={{
                                company_id: `${companyId}`
                            }}
                        >
                            {({ loading, refetch, error, data }) => {

                                return (
                                    <Select value={selected_outlet} onChange={(selected_outlet) => this.setState({ selected_outlet })} style={{ width: '100%' }}>
                                        {!loading && data.outlets ? data.outlets.map((o) => {

                                            return (
                                                <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                                            );

                                        }) : null}
                                    </Select>
                                )

                            }}
                        </Query>
                    </div> : null}
                    <div className="my-3">
                        <p className="font-bold mb-1">Attach to Employee</p>
                        <Query
                            query={selected_employee ? GET_EMPLOYEES_WITH_CURRENT : GET_EMPLOYEES}
                            variables={{
                                company_id: `${companyId}`,
                                id: selected_employee
                            }}
                        >
                            {({ loading, refetch, error, data }) => {

                                return (
                                    <Select value={selected_employee} onChange={(selected_employee) => this.setState({ selected_employee })} style={{ width: '100%' }}>
                                        {!loading && data && data.employees ? data.employees.map((e) => {

                                            return (
                                                <Select.Option key={e.id} value={e.id}>{e.name} ({e.designation})</Select.Option>
                                            );

                                        }) : null}
                                    </Select>
                                )

                            }}
                        </Query>
                    </div>
                    <div className="my-3">
                        <Mutation
                            onCompleted={() => {

                                this.setState({
                                    disable_user: false,
                                    showAddUser: false,
                                    selected_outlet: null,
                                    selected_user: null,
                                    selected_role: null,
                                    selected_employee: null,
                                    old_employee: null
                                });

                            }}
                            mutation={disable_user ? selected_employee ? UPDATE_ROLE_MUTATION_WITH_EMPLOYEE : UPDATE_ROLE_MUTATION : selected_employee ? CREATE_ROLE_MUTATION_WITH_EMPLOYEE : CREATE_ROLE_MUTATION}
                            variables={{
                                company_id: companyId,
                                account_id: account.id,
                                user_id: selected_user,
                                role: selected_role,
                                outlet_id: selected_outlet,
                                employee_id: selected_employee,
                                old_employee
                            }}
                        >
                            {(mutate, { loading }) => <AntButton ghost type="primary" loading={loading} onClick={() => mutate()}>Save</AntButton>}
                        </Mutation>
                    </div>

                </Modal>

            </div>

        )
    }
}