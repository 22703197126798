import React from 'react';
import {DatePicker, Button, Table} from 'antd';
import {Spinner} from 'evergreen-ui';
import moment from 'moment';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';
import {find, sumBy, sum, flatten, map} from 'lodash';
import {currencyFormat} from 'mout/number';

const OTHERS_QUERY = gql`
    query ($company_id: Int!){
        accounts: ledgers(where: {company_id: {_eq: $company_id}, prefix: {_in: ["cos", "op-exp", "admin-exp"]}}) {
            id
            name
            prefix
        }
    }
`;


const dateFormat = 'DD/MM/YYYY';


class ProfitLossReports extends React.Component{

    state = {
        companyId: this.props.match.params.companyId,
        from_date: moment().startOf('year').format(dateFormat),
        to_date: moment().endOf('year').format(dateFormat)
    }

    BALANCE = () => {

        const {companyId, from_date, to_date} = this.state;

        return gql`

            query {

                income: ledgers(where: {company_id: {_eq: ${companyId}}, group_id: {_eq: 3}, parent_id: {_is_null: true}}) {
                    id
                    name
                    _transactions: _transactions_aggregate(order_by: {date: desc}, where: {
                        _and: [
                            {date: {
                                _gte: "${moment(from_date, dateFormat).format('YYYY-MM-DD')}"
                            }},
                            {date: {
                                _lte: "${moment(to_date, dateFormat).format('YYYY-MM-DD')}"
                            }}
                        ]
                    }){
                       aggregate {
                            sum {
                                credit
                                debit
                            }
                        } 
                    }
                }
                adminExp: ledgers(where: {company_id: {_eq: ${companyId}}, prefix: {_eq: "admin-exp"}}) {
                    id
                    name
                    _transactions: _transactions_aggregate(order_by: {date: desc}, where: {
                        _and: [
                            {date: {
                                _gte: "${moment(from_date, dateFormat).format('YYYY-MM-DD')}"
                            }},
                            {date: {
                                _lte: "${moment(to_date, dateFormat).format('YYYY-MM-DD')}"
                            }}
                        ]
                    }){
                       aggregate {
                            sum {
                                credit
                                debit
                            }
                        } 
                    }
                }
                opExp: ledgers(where: {company_id: {_eq: ${companyId}}, prefix: {_eq: "op-exp"}}) {
                    id
                    name
                    _transactions: _transactions_aggregate(order_by: {date: desc}, where: {
                        _and: [
                            {date: {
                                _gte: "${moment(from_date, dateFormat).format('YYYY-MM-DD')}"
                            }},
                            {date: {
                                _lte: "${moment(to_date, dateFormat).format('YYYY-MM-DD')}"
                            }}
                        ]
                    }){
                       aggregate {
                            sum {
                                credit
                                debit
                            }
                        } 
                    }
                }
                cos: ledgers(where: {company_id: {_eq: ${companyId}}, prefix: {_eq: "cos"}}) {
                    id
                    name
                    _transactions: _transactions_aggregate(order_by: {date: desc}, where: {
                        _and: [
                            {date: {
                                _gte: "${moment(from_date, dateFormat).format('YYYY-MM-DD')}"
                            }},
                            {date: {
                                _lte: "${moment(to_date, dateFormat).format('YYYY-MM-DD')}"
                            }}
                        ]
                    }){
                       aggregate {
                            sum {
                                credit
                                debit
                            }
                        } 
                    }
                }
        
            }
        
        `;

    }

    render() {

        const {companyId, from_date, to_date} = this.state;

        return (
            <div className="overflow-scroll h-full">
                <div className="flex h-full my-12">
                    <div className="w-full max-w-5xl mx-auto">
                        <div className="text-4xl font-bold text-black mb-4">
                            Profit & Loss
                        </div>
                        <div className="p-6 bg-gray-200 rounded flex items-center justify-between">
                            <div className="flex items-center -mx-2">
                                <p className="font-medium text-black px-2">Date Range</p>
                                <DatePicker.RangePicker
                                    value={[moment(from_date, dateFormat), moment(to_date, dateFormat)]}
                                    format={dateFormat}
                                    onChange={((dates, dateString) => {

                                        this.setState({
                                            from_date: dateString[0],
                                            to_date: dateString[1]
                                        })

                                    })}
                                />
                            </div>
                            <div className="">
                                <Button type="primary" shape="round" ghost>Update Report</Button>
                            </div>
                        </div>
                        <Query
                            query={this.BALANCE()}
                        >
                            {({data, loading, error}) => {

                                // console.log(data, loading, error);

                                if (loading) return <div className="flex h-full items-center justify-center py-10">
                                    <Spinner />
                                </div>;

                                const income = Math.abs(sumBy(flatten(map(data.income, '_transactions')), (t) => {

                                    return t.aggregate.sum.debit - t.aggregate.sum.credit;

                                }));
                                const costOfSales = Math.abs(sumBy(map(data.cos, '_transactions'), (t) => {

                                    return t.aggregate.sum.debit - t.aggregate.sum.credit;

                                }));
                                const operatingExpense = Math.abs(sumBy(map(data.opExp, '_transactions'), (t) => {

                                    return t.aggregate.sum.debit - t.aggregate.sum.credit;

                                }));
                                const adminExpense = Math.abs(sumBy(map(data.adminExp, '_transactions'), (t) => {

                                    return t.aggregate.sum.debit - t.aggregate.sum.credit;

                                }));
                                const gross_profit = income - costOfSales;
                                const profit = income - costOfSales - operatingExpense - adminExpense;

                                return (
                                    <div className="mb-16">
                                        <div className="flex items-center justify-center border-b py-10">
                                            <div className="text-center flex flex-col items-center justify-center w-1/5 px-2">
                                                <p className="font-medium">Income</p>
                                                <p className="font-bold text-2xl">GH¢ {currencyFormat(income)}</p>
                                            </div>
                                            <div className="">
                                                <p className="font-bold text-xl">-</p>
                                            </div>
                                            <div className="text-center flex flex-col items-center justify-center w-1/5 px-2">
                                                <p className="font-medium">Cost of Goods Sold</p>
                                                <p className="font-bold text-2xl">GH¢ {currencyFormat(costOfSales)}</p>
                                            </div>
                                            <div className="">
                                                <p className="font-bold text-xl">-</p>
                                            </div>
                                            <div className="text-center flex flex-col items-center justify-center w-1/5 px-2">
                                                <p className="font-medium">Operating Expenses</p>
                                                <p className="font-bold text-2xl">GH¢ {currencyFormat(operatingExpense)}</p>
                                            </div>
                                            <div className="">
                                                <p className="font-bold text-xl">-</p>
                                            </div>
                                            <div className="text-center flex flex-col items-center justify-center w-1/5 px-2">
                                                <p className="font-medium">Admin Expenses</p>
                                                <p className="font-bold text-2xl">GH¢ {currencyFormat(adminExpense)}</p>
                                            </div>
                                            <div className="">
                                                <p className="font-bold text-xl">=</p>
                                            </div>
                                            <div className="text-center flex flex-col items-center justify-center w-1/5 px-2">
                                                <p className="font-medium">Net Profit</p>
                                                <p className={[
                                                    "font-bold",
                                                    "text-2xl",
                                                    profit > 0 ? "text-green-500": "text-red-500"
                                                ].join(' ')}>GH¢ {currencyFormat(Math.abs(profit))}</p>
                                            </div>
                                        </div>
                                        <div className="mt-10">
                                            <div className="px-4 flex items-end justify-between border-b-2 py-4">
                                                <p className="font-bold text-black">ACCOUNTS</p>
                                                <div className="font-bold text-black">
                                                    <p className="">{moment(from_date, dateFormat).format('MMM DD, YYYY')}</p>
                                                    <p className="">to {moment(to_date, dateFormat).format('MMM DD, YYYY')}</p>
                                                </div>
                                            </div>
                                            <div className="px-4 text-lg text-black flex items-center justify-between border-b py-4">
                                                <p className="">Income</p>
                                                <div className="">
                                                    GH¢ {currencyFormat(income)}
                                                </div>
                                            </div>
                                            <div className="px-4 text-lg text-black flex items-center justify-between border-b py-4">
                                                <p className="">Cost of Goods Sold</p>
                                                <div className="">
                                                    GH¢ {currencyFormat(costOfSales)}
                                                </div>
                                            </div>
                                            <div className="px-4 bg-gray-200 text-lg text-black flex items-center justify-between border-b py-4">
                                                <div className="">
                                                    <p className="font-bold">Gross Profit</p>
                                                    <p className="text-xs">As a percentage of Total Income</p>
                                                </div>
                                                <div className="text-right">
                                                    <p className="">
                                                        GH¢ {currencyFormat(gross_profit)}
                                                    </p>
                                                    <p className="text-xs">{(((gross_profit || 0) / (income || 0)) * 100).toFixed(2)}%</p>
                                                </div>
                                            </div>
                                            <div className="px-4 text-lg text-black flex items-center justify-between border-b py-4">
                                                <p className="">Operating Expenses</p>
                                                <div className="">
                                                    GH¢ {currencyFormat(operatingExpense)}
                                                </div>
                                            </div>
                                            <div className="px-4 text-lg text-black flex items-center justify-between border-b py-4">
                                                <p className="">Administration Expenses</p>
                                                <div className="">
                                                    GH¢ {currencyFormat(adminExpense)}
                                                </div>
                                            </div>
                                            <div className="px-4 bg-gray-200 text-lg text-black flex items-center justify-between border-b py-4">
                                                <div className="">
                                                    <p className="font-bold">Net Profit</p>
                                                    <p className="text-xs">As a percentage of Total Income</p>
                                                </div>
                                                <div className="text-right">
                                                    <p className="">
                                                        GH¢ {currencyFormat(profit)}
                                                    </p>
                                                    <p className="text-xs">{(((profit || 0) / (income || 0)) * 100).toFixed(2)}%</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )

                            }}
                        </Query>
                    </div>
                </div>
            </div>
        )

    }

}

export default ProfitLossReports;