import firebase from './firebase';
import {merge} from 'lodash';
import {toaster} from 'evergreen-ui';

var async = require('async');
var axios = require('axios');
var Promise = require('bluebird');

// const apiUrl = (process.env.NODE_ENV === 'production') ? 'https://api.gomerp.com': 'http://localhost:5001';
const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export default {
    get: function(url, options){

        return new Promise(function(resolve, reject){

            async.waterfall([
                function(cb) {

                    if (options && options.noAuth) return cb(null, null);
        
                    firebase.auth().currentUser.getIdToken().then(function(token){
        
                        cb(null, token);
                        
                        return null;
        
                    }).catch(function(err){
        
                        cb(err);
        
                        return null;
        
                    });
        
                },
                function(token, cb) {
        
                    axios.get(`${apiUrl}${url}`, merge({
                        headers:{
                            Authorization: (token) ? `Bearer ${token}`: null
                        }
                    }, options || {})).then(function(res){

                        cb(null, res);

                        return null;

                    }).catch(function(err){

                        cb(err);

                        return null;

                    })
        
                }
            ], function(err, res){
        
                if (err) {

                    toaster.danger(
                        'Something went wrong.',
                        {
                            id: 'api-error',
                            description: `An error occurred while connecting to our servers.`,
                            duration: 80
                        }
                    );

                    return reject(err);

                }

                return resolve(res);
        
        
            });

        });
    
    },
    post: function(url, data, options){

        return new Promise(function(resolve, reject){

            async.waterfall([
                function(cb) {

                    if (options && options.noAuth) return cb(null, null);
        
                    firebase.auth().currentUser.getIdToken().then(function(token){
        
                        cb(null, token);
                        
                        return null;
        
                    }).catch(function(err){
        
                        cb(err);
        
                        return null;
        
                    });
        
                },
                function(token, cb) {
        
                    axios.post(`${apiUrl}${url}`, data, merge({
                        headers:{
                            Authorization: (token) ? `Bearer ${token}`: null
                        }
                    }, options || {})).then(function(res){

                        cb(null, res);

                        return null;

                    }).catch(function(err){

                        cb(err);

                        return null;

                    })
        
                }
            ], function(err, res){
        
                if (err) {

                    if (!options.noError) {

                        toaster.danger(
                            'Something went wrong.',
                            {
                                id: 'api-error',
                                description: `An error occurred while connecting to our servers.`,
                                duration: 80
                            }
                        );

                    }

                    return reject(err);

                }

                return resolve(res);
        
        
            });

        });
    
    },
    delete: function(url){

        return new Promise(function(resolve, reject){

            async.waterfall([
                function(cb) {
        
                    firebase.auth().currentUser.getIdToken().then(function(token){
        
                        cb(null, token);
                        
                        return null;
        
                    }).catch(function(err){
        
                        cb(err);
        
                        return null;
        
                    });
        
                },
                function(token, cb) {
        
                    axios.delete(`${apiUrl}${url}`, {
                        headers:{
                            Authorization: `Bearer ${token}`
                        }
                    }).then(function(res){

                        cb(null, res);

                        return null;

                    }).catch(function(err){

                        cb(err);

                        return null;

                    })
        
                }
            ], function(err, res){
        
                if (err) {

                    toaster.danger(
                        'Something went wrong.',
                        {
                            id: 'api-error',
                            description: `An error occurred while connecting to our servers.`,
                            duration: 80
                        }
                    );

                    return reject(err);

                }

                return resolve(res);
        
        
            });

        });
    
    }
};