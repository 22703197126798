import { Model } from '@nozbe/watermelondb'
import { field, date, readonly } from '@nozbe/watermelondb/decorators'

export default class Customer extends Model {
    
    static table = 'customers'

    @field('company_name') companyName
    @field('company_id') companyId
    @field('contact_name') contactName
    @field('first_name') firstName
    @field('is_wholesale') isWholesale
    @field('last_name') lastName
    @field('name') name
    @field('phone') phone
    @field('website') website
    @field('ledger_id') ledgerId
    
    @field('remote_id') remoteId
    @readonly @date('created_at') createdAt
    @readonly @date('updated_at') updatedAt
}