import React from 'react';
import { TextInputField, FormField, Button, Spinner, BackButton, Switch } from 'evergreen-ui';
import { InputNumber, Select } from 'antd';

import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import { useState } from 'react';

const GET_CATEGORY = gql`
    query($id: Int!) {

        categories_by_pk(id: $id) {
            id
            name
            company_id
            raw_materials
        }

    }
`;

const SAVE_MUTATION = gql`

    mutation add($id: Int!, $name: String!, $company_id: Int, $raw_materials: Boolean) {
        update_categories(where: {id: {_eq: $id}}, _set: {name: $name, raw_materials: $raw_materials}) {
            affected_rows
        }
    }  

`;

const ADD_MUTATION = gql`

    mutation add($id: Int, $name: String!, $company_id: Int, $raw_materials: Boolean){
        insert_categories(objects: {name: $name, company_id: $company_id, raw_materials: $raw_materials}) {
            affected_rows
        }
    }
  

`;


class Category extends React.Component {

    state = {
        companyId: this.props.match.params.companyId,
        categoryId: this.props.match.params.categoryId,
        name: '',
        rawMaterials: false
    }

    render() {

        const { name, companyId, categoryId, rawMaterials } = this.state;

        // console.log(categoryId);

        return (
            <div className="max-w-lg w-full mx-auto my-12">
                <BackButton
                    appearance="default"
                    marginBottom={15}
                    onClick={() => {

                        this.props.history.push(`/${companyId}/pos/categories`);

                    }}
                    children="Back to categories"
                />
                <Query
                    query={GET_CATEGORY}
                    skip={(!categoryId) ? true : false}
                    variables={{
                        id: categoryId
                    }}
                >
                    {({ loading, data, error, refetch }) => {

                        return (
                            <div className="p-8 rounded-lg shadow-lg">
                                {loading ? <div className="flex items-center justify-center py-10">
                                    <Spinner />
                                </div> : <div className="">
                                    <p className="text-black text-lg font-bold">Create Category</p>
                                    <CategoryInner
                                        category={data ? data.categories_by_pk : null}
                                        categoryId={categoryId}
                                        companyId={companyId}
                                        history={this.props.history}
                                    />
                                </div>}
                            </div>
                        )

                    }}
                </Query>
            </div>

        );

    }

}

const CategoryInner = ({
    category,
    history,
    companyId,
    categoryId
}) => {

    const [name, setName] = useState(category ? category.name : '');
    const [rawMaterials, setRawMaterials] = useState(category ? category.raw_materials : false);

    return (
        <>
            <div className="mt-4">
                <TextInputField
                    label="Name" defaultValue={name || ''}
                    onChange={(e) => {

                        setName(e.target.value)

                    }}
                    placeholder="Name"
                />
            </div>
            <p className="📦color_425A70 📦fnt-fam_b77syt 📦fnt-sze_14px 📦f-wght_500 📦ln-ht_20px 📦ltr-spc_-0-05px 📦dspl_block 📦mb_4px 📦box-szg_border-box">Raw Materials?</p>
            <Switch
                marginBottom={16}
                checked={rawMaterials}
                onChange={(e) => setRawMaterials(e.target.checked)}
            />
            <Mutation
                onCompleted={() => {

                    history.push(`/${companyId}/pos/categories`);

                }}
                mutation={categoryId ? SAVE_MUTATION : ADD_MUTATION}
                variables={{
                    company_id: companyId,
                    id: categoryId,
                    name,
                    raw_materials: rawMaterials
                }}
            >
                {(add, { loading }) => {

                    return (
                        <Button appearance="primary" intent="success" isLoading={loading} onClick={() => add()}>Save</Button>
                    )

                }}
            </Mutation>
        </>
    )

}

export default Category;