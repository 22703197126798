import React from 'react';
import { Button, TextInputField, FormField, BackButton, toaster, Spinner, FormFieldLabel } from 'evergreen-ui';
import { InputNumber, DatePicker, Divider, Select, Checkbox, Input, message } from 'antd';
import { values, keys, fromPairs } from 'lodash';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import firebase from '../services/firebase';
import { createKeywords } from '../services/keywords';
import { CurrencyWrapper } from '../services/Currency';
import api from '../services/api';


const MINIMUM_WAGE = 319;

const dateFormat = 'YYYY/MM/DD';

const GET_DEPARTMENTS = gql`
    query(
        $company_id: Int!
    ) {

        departments: employee_department(where: {company_id: {_eq: $company_id}}) {
            id
            name
        }

    }
`;

const GET_EMPLOYEE = gql`
    query(
        $employee_id: Int!,
        $company_id: Int!
    ) {

        employees(where: {company_id: {_eq: $company_id}, id: {_eq: $employee_id}}) {
            name
            ssnit_number
            tin
            bank_name
            bank_branch
            bank_account_number
            bank_sort_code
            basic_salary
            allowance
            others
            dob
            designation
            department_id
            salary_aggregated
            date_joined
            credential{
                id
                email
            }
        }

    }
`;

const CREATE_EMPLOYEE = gql`
    mutation (
        $employee_id: Int,
        $company_id: Int!,
        $name: String!,
        $ssnit_number: String!,
        $tin: String!,
        $bank_name: String!,
        $bank_branch: String!,
        $bank_account_number: String!,
        $bank_sort_code: String!,
        $basic_salary: float8!,
        $allowance: float8!,
        $others: float8!,
        $dob: date!,
        $designation: String!,
        $department_id: Int!,
        $salary_aggregated: Boolean!,
        $date_joined: date!,
    ) {
        insert_employees(objects: {
            company_id: $company_id,
            name: $name,
            ssnit_number: $ssnit_number,
            tin: $tin,
            bank_name: $bank_name,
            bank_branch: $bank_branch,
            bank_account_number: $bank_account_number,
            bank_sort_code: $bank_sort_code,
            basic_salary: $basic_salary,
            allowance: $allowance,
            others: $others,
            dob: $dob,
            designation: $designation,
            department_id: $department_id,
            salary_aggregated: $salary_aggregated,
            date_joined: $date_joined
        }) {
            returning {
              id
            }
        }
    }
`;

const UPDATE_EMPLOYEE = gql`

    mutation (
        $employee_id: Int,
        $company_id: Int!,
        $name: String!,
        $ssnit_number: String!,
        $tin: String!,
        $bank_name: String!,
        $bank_branch: String!,
        $bank_account_number: String!,
        $bank_sort_code: String!,
        $basic_salary: float8!,
        $allowance: float8!,
        $others: float8!,
        $dob: date!,
        $designation: String!,
        $department_id: Int!,
        $salary_aggregated: Boolean!,
        $date_joined: date!
    ) {
        update_employees(where: {id: {_eq: $employee_id}, company_id: {_eq: $company_id}}, _set: {
            name: $name,
            ssnit_number: $ssnit_number,
            tin: $tin,
            bank_name: $bank_name,
            bank_branch: $bank_branch,
            bank_account_number: $bank_account_number,
            bank_sort_code: $bank_sort_code,
            basic_salary: $basic_salary,
            allowance: $allowance,
            others: $others,
            dob: $dob,
            designation: $designation,
            department_id: $department_id,
            salary_aggregated: $salary_aggregated,
            date_joined: $date_joined
        }) {
            affected_rows
        }
    }

`;

class Employee extends React.Component {

    state = {
        company: null,
        loading: false,
        companyId: this.props.match.params.companyId,
        employeeId: this.props.match.params.employeeId,
        role: null,
        credential: this.props.employee.credential || {
            email: '',
            password: '',
            repeat_password: ''
        },
        name: this.props.employee.name || '',
        ssnit_number: this.props.employee.ssnit_number || '',
        tin: this.props.employee.tin || '',
        bankName: this.props.employee.bank_name || '',
        bankBranch: this.props.employee.bank_branch || '',
        bankAccountNumber: this.props.employee.bank_account_number || '',
        bankSortCode: this.props.employee.bank_sort_code || '',
        basic_salary: this.props.employee.basic_salary || MINIMUM_WAGE,
        allowance: this.props.employee.allowance || 0,
        salary_aggregated: this.props.employee.salary_aggregated || false,
        others: this.props.employee.others || 0,
        dob: this.props.employee.dob ? moment(this.props.employee.dob, 'YYYY-MM-DD').format(dateFormat) : moment().format(dateFormat),
        designation: this.props.employee.designation || '',
        department: this.props.employee.department_id || null,
        date_joined: this.props.employee.date_joined ? moment(this.props.employee.date_joined, 'YYYY-MM-DD').format(dateFormat) : moment().format(dateFormat),
        companyAggregators: [],
        aggregators: {}
    }

    create = async () => {

        const {
            name,
            employeeId,
            companyId,
            credential: {
                email,
                repeat_password,
                password,
            }
        } = this.state;

        const { account } = this.props;

        if (password.length < 8) {

            return message.error('Password must be 8 characters or more.');

        }

        if (password !== repeat_password) {

            return message.error('Passwords do no match');

        }

        this.setState({ isCreating: true });

        try {

            const res = await api.post(`/auth/credentials/create`, {
                display_name: name,
                email,
                password,
                account_id: account.id,
                employee_id: employeeId,
                company_id: companyId
            });

            this.setState({
                isCreating: false,
                credential: {
                    ...res.data,
                    password: '',
                    repeat_password: ''
                }
            });

            // this.props.history.push(`/users/${res.data.id}`);

        } catch (e) {

            if (e.response && e.response.data.message) {

                message.error(e.response.data.message);

            }

            this.setState({ isCreating: false });

        }


    }

    changePassword = async () => {

        const {
            credential
        } = this.state;

        const {
            id,
            repeat_password,
            password
        } = credential;

        const { account } = this.props;

        if (password.length < 8) {

            return message.error('Password must be 8 characters or more.');

        }

        if (password !== repeat_password) {

            return message.error('Passwords do no match');

        }

        this.setState({ isChangingPassword: true });


        try {

            const res = await api.post(`/auth/password/change`, {
                user_id: id,
                account_id: account.id,
                password
            });

            this.setState({
                isChangingPassword: false,
                changePasswordMode: false,
                credential: {
                    ...credential,
                    repeat_password: '',
                    password: '',
                }
            });

            // this.props.history.push(`/users/${res.data.id}`);

        } catch (e) {

            if (e.response && e.response.data.message) {

                message.error(e.response.data.message);

            }

            this.setState({ isChangingPassword: false });

        }


    }


    render() {

        const {
            employeeId,
            name,
            ssnit_number,
            tin,
            bankName,
            bankBranch,
            bankAccountNumber,
            bankSortCode,
            basic_salary,
            allowance,
            others,
            dob,
            designation,
            department,
            salary_aggregated,
            date_joined,
            credential,
            changePasswordMode,
            // companyAggregators,
            // aggregators,
            companyId,
            isChangingPassword,
            isCreating
        } = this.state;

        return (
            <div className="h-full w-full overflow-scroll">
                <div className="max-w-2xl mx-auto px-4 sm:px-8 mt-16 sm:my-16">
                    <BackButton appearance="default" marginBottom={15} onClick={() => {

                        this.props.history.push(`/${companyId}/hr`);

                    }} children="Back to employees" />
                    <div className="flex items-center">
                        <h1 className="text-3xl font-bold mr-4 my-4">
                            {employeeId ? 'Edit' : 'Add'} Employee
                        </h1>
                    </div>
                    <div className="flex flex-wrap -mx-2 my-4">
                        <div className="w-1/2 px-2">
                            <TextInputField required label="Employee Name" defaultValue={name} onChange={(e) => {

                                this.setState({
                                    name: e.target.value
                                });

                            }} placeholder="Enter the employee's fullname" />
                        </div>
                        <div className="w-1/2 px-2">
                            <TextInputField required label="Position/Role/Designation" defaultValue={designation} onChange={(e) => {

                                this.setState({
                                    designation: e.target.value
                                });

                            }} placeholder="" />
                        </div>
                        <div className="w-1/2 px-2">
                            <TextInputField required label="Social Security Number" defaultValue={ssnit_number} onChange={(e) => {

                                this.setState({
                                    ssnit_number: e.target.value
                                });

                            }} placeholder="" />
                        </div>
                        <div className="w-1/2 px-2">
                            <TextInputField required label="Tax Identification Number (TIN)" defaultValue={tin} onChange={(e) => {

                                this.setState({
                                    tin: e.target.value
                                });

                            }} placeholder="" />
                        </div>
                        <div className="w-1/2 px-2">
                            <TextInputField required label="Bank Name" defaultValue={bankName} onChange={(e) => {

                                this.setState({
                                    bankName: e.target.value
                                });

                            }} placeholder="" />
                        </div>
                        <div className="w-1/2 px-2">
                            <TextInputField required label="Bank Branch" defaultValue={bankBranch} onChange={(e) => {

                                this.setState({
                                    bankBranch: e.target.value
                                });

                            }} placeholder="" />
                        </div>
                        <div className="w-1/2 px-2">
                            <TextInputField required label="Bank Sort Code" description="The employees bank sort code" defaultValue={bankSortCode} onChange={(e) => {

                                this.setState({
                                    bankSortCode: e.target.value
                                });

                            }} placeholder="" />
                        </div>
                        <div className="w-1/2 px-2">
                            <TextInputField required label="Bank Account Number" description="The employees bank account number" defaultValue={bankAccountNumber} onChange={(e) => {

                                this.setState({
                                    bankAccountNumber: e.target.value
                                });

                            }} />
                        </div>
                        <div className="w-1/2 px-2">
                            <FormField isRequired label="Department" description="The department the employee belongs to" />
                            <Query
                                query={GET_DEPARTMENTS}
                                variables={{
                                    company_id: companyId
                                }}
                            >
                                {({ data, error, loading }) => {

                                    return (
                                        !loading && data ? <Select
                                            style={{ width: '100%' }}
                                            onChange={(department) => this.setState({ department })}
                                            defaultValue={department || ''}
                                            placeholder=""
                                        >
                                            {data.departments.map((department) => {

                                                return <Select.Option value={department.id} key={department.id}>{department.name}</Select.Option>

                                            })}
                                        </Select> : null
                                    )

                                }}
                            </Query>
                        </div>
                        <div className="w-1/2 px-2 mb-4">
                            <FormField isRequired label="Date of Birth" description="The date the employee was born" />
                            <DatePicker style={{ width: '100%' }} onChange={(date, dob) => this.setState({ dob })} defaultValue={moment(dob, dateFormat)} format={dateFormat} />
                        </div>
                        <div className="w-1/2 px-2 mb-4">
                            <FormField isRequired label="Date Joined" description="The date the employee joined the company" />
                            <DatePicker style={{ width: '100%' }} onChange={(date, date_joined) => this.setState({ date_joined })} defaultValue={moment(date_joined, dateFormat)} format={dateFormat} />
                        </div>
                        <div className="w-1/2 px-2 mb-4">
                            <FormField isRequired label="Casual Employee Settings" description="" />

                            <div className="">
                                <Checkbox
                                    className=""
                                >
                                    Casual Employee
                                </Checkbox>
                            </div>
                            <div className="">
                                <Checkbox
                                    className=""
                                >
                                    Enable Social Security
                                </Checkbox>
                            </div>


                        </div>
                    </div>
                    <div className="">
                        <p className="font-bold text-black py-4 border-b mb-4">Basic Salary & Allowances</p>
                        <Checkbox
                            className="mb-4"
                            // disabled={!('basic_salary_percent' in company) || !('allowance_percent' in company)}
                            onChange={(e) => {

                                this.setState({
                                    basic_salary: e.target.checked ? 0 : MINIMUM_WAGE,
                                    allowance: 0,
                                    salary_aggregated: e.target.checked
                                });

                            }}
                            checked={salary_aggregated}
                        >
                            Aggregate Salary
                        </Checkbox>
                        <div className="flex flex-wrap -mx-2">
                            {salary_aggregated ? null : <div className="w-1/2 px-2 mb-4">
                                <FormField isRequired label="Employee Salary" description="Enter the employees's basic salary" />
                                <CurrencyWrapper
                                    component={({ symbol }) => (
                                        <InputNumber
                                            style={{
                                                width: '100%'
                                            }}
                                            // disabled={true}
                                            min={employeeId ? false : MINIMUM_WAGE}
                                            defaultValue={basic_salary}
                                            formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/[^0-9.]/g, '')}
                                            onChange={(basic_salary) => this.setState({ basic_salary })}
                                        />
                                    )}
                                />
                            </div>}
                            {salary_aggregated ? null : <div className="w-1/2 px-2 mb-4">
                                <FormField isRequired label="Employee Allowance" description="Employee Allowance" />
                                <CurrencyWrapper
                                    component={({ symbol }) => (
                                        <InputNumber
                                            style={{
                                                width: '100%'
                                            }}
                                            defaultValue={allowance}
                                            formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/[^0-9.]/g, '')}
                                            onChange={(allowance) => this.setState({ allowance })}
                                        />
                                    )}
                                />
                            </div>}
                            {/* <div className="w-1/2 px-2 mb-4">
                                <FormField isRequired label="Others" description="Other allowances" />
                                <CurrencyWrapper
                                    component={({symbol}) => (
                                        <InputNumber
                                            style={{
                                                width: '100%'
                                            }}
                                            defaultValue={others}
                                            formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/[^0-9.]/g, '')}
                                            onChange={(others) => this.setState({others})}
                                        />
                                    )}
                                />
                            </div> */}
                        </div>
                    </div>
                    <Mutation
                        mutation={employeeId ? UPDATE_EMPLOYEE : CREATE_EMPLOYEE}
                        variables={{
                            employee_id: employeeId,
                            company_id: companyId,
                            name,
                            ssnit_number,
                            tin,
                            bank_name: bankName,
                            bank_branch: bankBranch,
                            bank_account_number: bankAccountNumber,
                            bank_sort_code: bankSortCode,
                            basic_salary,
                            allowance,
                            others: 0,
                            dob: moment(dob, dateFormat).format('YYYY-MM-DD'),
                            designation,
                            department_id: parseInt(department),
                            salary_aggregated,
                            date_joined: moment(date_joined, dateFormat).format('YYYY-MM-DD')
                        }}
                        onCompleted={(data) => {

                            this.props.history.push(`/${companyId}/hr`);

                        }}
                    >
                        {(create, { loading }) => {

                            return (
                                <Button appearance="primary" isLoading={loading} intent="success" onClick={() => {

                                    if (
                                        name &&
                                        // ssnit_number &&
                                        // tin &&
                                        // bankName &&
                                        // bankBranch &&
                                        // bankAccountNumber &&
                                        // bankSortCode &&
                                        // (salary_aggregated || basic_salary >= MINIMUM_WAGE) &&
                                        // dob &&
                                        date_joined &&
                                        designation
                                        // department
                                    ) {

                                        create();

                                    } else {

                                        toaster.danger('All fields are required');

                                    }


                                }}>Save</Button>
                            )

                        }}
                    </Mutation>
                    {employeeId ? <div className="my-10">
                        <p className="font-bold text-black py-4 border-b mb-4">
                            Login Credentials
                        </p>
                        <div className="">
                            <TextInputField
                                disabled={credential.id}
                                label="Email"
                                defaultValue={credential.email}
                                onChange={(e) => {

                                    this.setState({
                                        credential: {
                                            ...credential,
                                            email: e.target.value
                                        }
                                    });

                                }}
                                placeholder="Example johndoe@example.com"
                            />
                            {!credential.id || changePasswordMode ? <>
                                <div className="my-4">
                                    <FormFieldLabel>Password</FormFieldLabel>
                                    <Input.Password
                                        onChange={(e) => this.setState({ credential: { ...credential, password: e.target.value } })}
                                        placeholder="Enter Password"
                                    />
                                </div>
                                <div className="my-4">
                                    <FormFieldLabel>Repeat Password</FormFieldLabel>
                                    <Input.Password
                                        onChange={(e) => this.setState({ credential: { ...credential, repeat_password: e.target.value } })}
                                        placeholder="Repeat Password"
                                    />
                                </div>
                            </> : null}
                        </div>
                        {!credential.id ? <Button appearance="primary" isLoading={isCreating} intent="success" onClick={() => {

                            this.create();


                        }}>
                            {isCreating ? 'creating' : 'Create login credentials'}
                        </Button> :
                            <Button
                                ghost
                                appearance="primary"
                                intent="success"
                                isLoading={isChangingPassword}
                                onClick={() => {

                                    if (!changePasswordMode) {

                                        this.setState({ changePasswordMode: true })

                                    } else {

                                        this.changePassword();

                                    }

                                }}
                            >
                                Change Password
                            </Button>}
                    </div> : null}

                </div>
            </div>
        );

    }

}

const EmployeeContainer = withRouter(Employee);

class EmployeeWrapper extends React.Component {

    state = {
        companyId: this.props.match.params.companyId,
        employeeId: this.props.match.params.employeeId
    }

    render() {

        const { employeeId, companyId } = this.state;

        return (
            <Query
                query={GET_EMPLOYEE}
                skip={!employeeId}
                variables={{
                    company_id: companyId,
                    employee_id: employeeId
                }}
            >
                {({ data, error, loading }) => {

                    // console.log(data, loading);

                    return (
                        !loading ? <EmployeeContainer employee={((data || { employees: [] }).employees)[0] || {}} {...this.props} /> : <div className="flex items-center justify-center py-10">
                            <Spinner />
                        </div>
                    );

                }}
            </Query>
        )

    }

}

export default EmployeeWrapper;