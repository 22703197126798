import React from 'react';
import {RefreshCw} from 'react-feather';
import {Label, Spinner} from 'evergreen-ui';
import {keys, findIndex} from 'lodash';
import {Button, Modal, Input, Select, InputNumber, Radio} from 'antd';
import currencies from "currency-codes/data";
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import {withRouter} from 'react-router-dom';
import { convert, buildTree } from 'react-sortly';
import apollo from '../../services/apollo';

import {PlusOutlined, EditOutlined} from '@ant-design/icons';
import { CurrencyWrapper } from '../../services/Currency';


const groups = {
    1: {
        name: 'Assets'
    },
    2: {
        name: 'Liabilities'
    },
    3: {
        name: 'Income'
    },
    4: {
        name: 'Expenses'
    },
    5: {
        name: 'Equity'
    }
}

const GET_ACCOUNTS = gql`
    query ($company_id: Int!) {
        accounts: ledgers(where: {company_id: {_eq: $company_id}}) {
            archived
            company_id
            currency
            description
            id
            is_default
            group_id
            name
            prefix
            parentId: parent_id
            transactions(where: {initial: {_eq: true}}) {
                id
                credit
                debit
            }
        }
    }  
`;

const SAVE_MUTATION = gql`

    mutation (
        $company_id: Int!,
        $currency: String!,
        $description: String!,
        $name: String!,
        $parent_id: Int,
        $group_id: Int,
        $transactions: [ledger_transactions_insert_input!]!,
        $account_id: Int,
        $transaction_id: Int,
        $accounts: jsonb,
        $user_id: Int,
        $debit: float8,
        $credit: float8
    ) {
        insert_ledgers(objects: {
            archived: false,
            company_id: $company_id,
            currency: $currency,
            description: $description,
            is_default: true,
            name: $name,
            parent_id: $parent_id,
            group_id: $group_id,
            user_id: $user_id,
            transactions: {
                data: $transactions
            }
        }) {
            returning{
                archived
                company_id
                currency
                description
                id
                is_default
                group_id
                name
                prefix
                parentId: parent_id
                transactions(where: {initial: {_eq: true}}) {
                    id
                    credit
                    debit
                }
            }
        }
    }
  

`;

const UPDATE_MUTATION = gql`

    mutation (
        $company_id: Int,
        $currency: String,
        $description: String,
        $name: String,
        $parent_id: Int,
        $group_id: Int,
        $user_id: Int,
        $transactions: [ledger_transactions_insert_input!],
        $account_id: Int!,
        $transaction_id: Int!,
        $accounts: jsonb!,
        $debit: float8!,
        $credit: float8!
    ) {
        update_ledger_transactions(where: {id: {_eq: $transaction_id}}, _set: {accounts: $accounts, credit: $credit, debit: $debit}) {
            affected_rows
        }
        update_ledgers(where: {id: {_eq: $account_id}}, _set: {description: $description}) {
            returning{
                archived
                company_id
                currency
                description
                id
                is_default
                group_id
                name
                prefix
                parentId: parent_id
                transactions(where: {initial: {_eq: true}}) {
                    id
                    credit
                    debit
                }
            }
        }
    }

`;

class AccountItem extends React.Component{

    state = {
        showModal: false,
        companyId: this.props.companyId,
        entry: {
            name: '',
            currency: 'GHS',
            description: '',
            balance_type: 'credit',
            balance: 0.00
        },
        accounts: this.props.accounts,
        showModal: false
    }

    save = async () => {

        const {account, user, parents, addAccount, updateAccount} = this.props;
        const {entry, companyId} = this.state;
        const {
            currency,
            description,
            name,
            edit,
            balance_type,
            amount
        } = entry;

        // console.log(user,account);

        if (!name) return;

        this.setState({isSaving: true});

        try {

            const {data} = await apollo.mutate({
                mutation: edit ? UPDATE_MUTATION : SAVE_MUTATION,
                variables: {
                    company_id: companyId,
                    currency,
                    description,
                    name,
                    user_id: user.id,
                    group_id: account.group_id,
                    // group_id: null,
                    parent_id: account.id,
                    account_id: account.id,
                    transaction_id: (account.transactions[0] || {}).id,
                    accounts: [...parents, account.id],
                    debit: balance_type == 'debit' ? amount: 0.00,
                    credit: balance_type == 'credit' ? amount : 0.00,
                    transactions: [{
                        description: "INITIAL BALANCE",
                        initial: true,
                        user_id: user.id
                    }]
                }
            });

            if (edit) {

                updateAccount(data.returning[0]);

            } else {

                addAccount(data.returning[0]);

            }

            this.setState({
                isSaving: false,
                entry: {...entry, name: '', description: '', amount: 0.00 , balance_type: 'credit'},
                showModal: false
            });

        } catch(e) {

            console.log(e);

            this.setState({isSaving: false});

        }


    }

    render() {

        const {account, parent, parents, user, updateAccount, addAccount} = this.props;
        const {entry, showModal, companyId, isSaving} = this.state;

        const {
            currency,
            description,
            name,
            edit,
            balance_type,
            amount
        } = entry;

        // console.log(account, parents);

        return <div>
            <div key={account.id} className="">
                <div className={`rounded my-6 ${!parent ? 'bg-gray-200 p-4' : 'bg-white px-4 py-2'} shadow flex items-center justify-between mb-3`}>
                    <div className="flex items-center">
                        <div className="font-bold">
                            {account.name}
                        </div>
                        {(account.prefix === 'suppliers' || (parent && parent.prefix === 'suppliers'))  || (parents.length >= 4) ? null: <div className="mx-2">
                            <Button
                                onClick={() => this.setState({showModal: true})}
                                type="primary"
                                shape="round"
                                size="small"
                                ghost
                                icon={<PlusOutlined />}
                                className="flex items-center justify-center"
                            >
                                Add
                            </Button>
                        </div>}
                    </div>
                    <div className="">
                        <Button onClick={() => this.setState({
                            showModal: true,
                            entry: {
                                ...entry,
                                edit: true,
                                name: account.name,
                                description: account.description,
                                amount: account.transactions[0].credit || account.transactions[0].debit,
                                balance_type: account.transactions[0].debit ? 'debit' : 'credit'
                            }
                        })} className="flex items-center justify-center" shape="circle" icon={<EditOutlined />} type="primary" ghost />
                    </div>
                </div>
            </div>
            {account.children ? <div className="pl-4">
                {account.children.map((a) => <AccountItem
                    parent={account}
                    parents={[...parents, account.id]}
                    user={user}
                    companyId={companyId}
                    key={a.id}
                    account={a}
                    updateAccount={updateAccount}
                    addAccount={addAccount}
                />)}
            </div>: null}
            <Mutation
                mutation={edit ? UPDATE_MUTATION : SAVE_MUTATION}
                variables={{
                    company_id: companyId,
                    currency,
                    description,
                    name,
                    user_id: user.id,
                    group_id: account.group_id,
                    // group_id: null,
                    parent_id: account.id,
                    account_id: account.id,
                    transaction_id: (account.transactions[0] || {}).id,
                    accounts: [...parents, account.id],
                    debit: balance_type == 'debit' ? amount: 0.00,
                    credit: balance_type == 'credit' ? amount : 0.00,
                    transactions: [{
                        description: "INITIAL BALANCE",
                        initial: true,
                        user_id: user.id
                    }]
                }}
                onCompleted={(data) => {

                    if (edit) {

                        updateAccount(data.update_ledgers.returning[0]);
        
                    } else {
        
                        addAccount(data.insert_ledgers.returning[0]);
        
                    }

                    this.setState({
                        entry: {...entry, name: '', description: '', amount: 0.00 , balance_type: 'credit'},
                        showModal: false
                    })

                }}
            >
                {(save, {loading}) => {

                    return (
                        <Modal
                            title={entry.edit ? "Edit Account" : "Add Account"}
                            visible={showModal}
                            onOk={() => {

                                if (!name) return;

                                save();

                            }}
                            confirmLoading={loading}
                            onCancel={() => this.setState({entry: {...entry, name: '', description: '', edit: false, balance_type: 'credit', balance: 0.00}, showModal: false})}
                            okText="Save"
                        >
                            <div className="mb-2">
                                <Label>Account Name</Label>
                                <Input disabled={entry.edit} value={entry.name} onChange={(e) => this.setState({entry: {...entry, name: e.target.value}})} placeholder="Enter Account Name" />
                            </div>
                            {!entry.edit ? <div className="my-2">
                                <Label>Parent Account</Label>
                                <Input disabled={true} value={account.name}  style={{width: '100%'}} />
                            </div>: null}
                            <div className="my-2">
                                <Label>Account Currency</Label>
                                <Select
                                    defaultValue={entry.currency}
                                    showSearch
                                    disabled={true}
                                    style={{
                                        width: "100%"
                                    }}
                                    onChange={(currency) => this.setState({entry: {...entry, currency}})}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {

                                        // console.log(input, option);

                                        return option.props.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0;

                                    }}
                                >
                                    {currencies.map((currency) => {

                                        return <Select.Option key={currency.code} value={currency.code}>
                                            {currency.currency} ({currency.code})
                                        </Select.Option>

                                    })}
                                </Select>
                            </div>
                            {entry.edit ? <div className="my-2">
                                <Label>Inital Balance</Label>
                                <CurrencyWrapper
                                    component={({symbol}) => (
                                        <InputNumber
                                            onClick={(e) => e.target.select()}
                                            style={{width: '100%'}}
                                            placeholder=""
                                            value={entry.amount || 0.00}
                                            onChange={(amount) => {

                                                this.setState({entry: {...entry, amount}});

                                            }}
                                            parser={value => value.replace(/[^0-9.]/g, '')}
                                            formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        />
                                    )}
                                />
                            </div>: null}
                            {entry.edit ? <div className="my-2">
                                <Radio.Group onChange={(e) => {

                                    this.setState({entry: {...entry, balance_type: e.target.value}});

                                }} value={entry.balance_type}>
                                    <Radio value="debit">DEBIT</Radio>
                                    <Radio value="credit">CREDIT</Radio>
                                </Radio.Group>
                            </div>: null}
                            <div className="my-2">
                                <Label>Description</Label>
                                <Input.TextArea value={entry.description} onChange={(e) => this.setState({entry: {...entry, description: e.target.value}})} placeholder="Write an account description" />
                            </div>
                        </Modal>
                    )

                }}
            </Mutation>
        </div>

    }

}

class Charts extends React.Component{

    state = {
        companyId: this.props.companyId,
        loading: false,
        activeGroup: 1,
        account: {
            name: '',
            currency: 'GHS',
            description: '',
            balance_type: 'debit',
            amount: 0.00
        },
        accounts: this.props.accounts,
        showModal: false
    };

    // componentDidUpdate() {

    //     if (this.state.accounts !== this.props.accounts) {

    //         this.setState({
    //             accounts: this.props.accounts
    //         })

    //     }

    // } 

    updateAccount = (account) => {

        let {accounts} = this.state;

        const found = findIndex(accounts, {id: account.id})

        if (found > -1) {

            accounts[found] = account;

        }

        this.setState({accounts});


    };

    addAccount = (account) => {

        let {accounts} = this.state;


        this.setState({
            accounts: [...accounts, account]
        });

    }

    save = async () => {

        const {account, companyId, accounts} = this.state;
        const {user} = this.props;
        const {
            currency,
            description,
            name,
            group_id,
            balance_type,
            amount
        } = account;

        if (!name || !group_id) return;

        this.setState({isSaving: true});

        try {

            const {data} = await apollo.mutate({
                mutation: SAVE_MUTATION,
                variables: {
                    company_id: companyId,
                    currency,
                    description,
                    name,
                    group_id,
                    parent_id: null,
                    user_id: user.id,
                    transactions: [{
                        description: "INITIAL BALANCE",
                        initial: true,
                        user_id: user.id
                    }]
                }
            });

            this.setState({
                isSaving: false,
                account: {...account, name: '', description: '', amount: 0.00},
                showModal: false
            });

        } catch(e) {

            console.log(e);

            this.setState({isSaving: false});

        }


    }

    render(){

        const {loading, companyId, account, accounts, showModal, isSaving, activeGroup} = this.state;
        const {user, refetch} = this.props;
        const _groups = keys(groups);
        const _accounts = buildTree(convert(accounts.filter((a) => parseInt(a.group_id) === parseInt(activeGroup))));

        const {
            currency,
            description,
            name,
            group_id,
            balance_type,
            amount
        } = account;

        return (
            <div className="overflow-scroll w-full h-full">
                <div className="max-w-4xl w-full mx-auto px-10 py-12">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <h1 className="font-bold text-4xl mr-2">
                                Chart of Accounts
                            </h1>
                            {loading ? null : <button className="ml-4 focus:outline-none text-black"
                                onClick={() => refetch()}
                            >
                                <RefreshCw size={24} />
                            </button>}
                        </div>
                        <div className="">
                            <Button 
                                type="primary"
                                onClick={() => this.setState({showModal: true})}
                                shape="round"
                                ghost
                                icon={<PlusOutlined />}
                                className="flex items-center justify-center"
                            >
                                Add
                            </Button>
                        </div>
                    </div>
                    <div className="">
                        <ul className="list-reset list flex items-center border-b my-4">
                            {_groups.map((key, i) => {

                                const group = groups[key];
                                const isActive = activeGroup == key;

                                return (
                                    <li key={key}><a onClick={() => this.setState({activeGroup: key})} className={`py-3 px-4 block ${isActive ? 'font-bold border-blue-500 border-b-4' : 'font-medium'}`}>{group.name}</a></li>
                                )

                            })}
                        </ul>
                        {_accounts.map((account) => {

                            return (
                                <AccountItem
                                    updateAccount={this.updateAccount}
                                    addAccount={this.addAccount}
                                    companyId={companyId}
                                    user={user}
                                    key={account.id}
                                    parents={[parseInt(activeGroup)]}
                                    account={account}
                                />
                            )

                        })}
                    </div>

                </div>
                <Mutation
                    mutation={SAVE_MUTATION}
                    variables={{
                        company_id: companyId,
                        currency,
                        description,
                        name,
                        group_id,
                        parent_id: null,
                        user_id: user.id,
                        transactions: [{
                            description: "INITIAL BALANCE",
                            initial: true,
                            user_id: user.id
                        }]
                    }}
                    onCompleted={(data) => {

            
                        this.addAccount(data.insert_ledgers.returning[0]);
            
                        this.setState({
                            account: {...account, name: '', description: '', amount: 0.00},
                            showModal: false
                        });

                    }}
                >
                    {(save, {loading}) => {

                        return (
                            <Modal
                                title="Add Account"
                                visible={showModal}
                                onOk={() => {

                                    if (!name || !group_id) return;

                                    save();

                                }}
                                confirmLoading={loading}
                                onCancel={() => this.setState({account: {...account, name: '', description: ''}, showModal: false})}
                                okText="Save"
                            >
                                <div className="mb-2">
                                    <Label>Account Name</Label>
                                    <Input value={account.name} onChange={(e) => this.setState({account: {...account, name: e.target.value}})} placeholder="Enter Account Name" />
                                </div>
                                <div className="my-2">
                                    <Label>Account Group</Label>
                                    <Select value={account.group_id} onChange={(group_id) => this.setState({account: {...account, group_id}})}  style={{width: '100%'}}>
                                        {keys(groups).map((key) => {

                                            return <Select.Option key={key} value={key}>{groups[key].name}</Select.Option>

                                        })}
                                    </Select>
                                </div>
                                <div className="my-2">
                                    <Label>Account Currency</Label>
                                    <Select
                                        value={account.currency}
                                        showSearch
                                        style={{
                                            width: "100%"
                                        }}
                                        disabled={true}
                                        onChange={(currency) => this.setState({account: {...account, currency}})}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => {

                                            // console.log(input, option);

                                            return option.props.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0;

                                        }}
                                    >
                                        {currencies.map((currency) => {

                                            return <Select.Option key={currency.code} value={currency.code}>
                                                {currency.currency} ({currency.code})
                                            </Select.Option>

                                        })}
                                    </Select>
                                </div>
                                <div className="my-2">
                                    <Label>Description</Label>
                                    <Input.TextArea value={account.description} onChange={(e) => this.setState({account: {...account, description: e.target.value}})} placeholder="Write an account description" />
                                </div>
                            </Modal>
                        )

                    }}
                </Mutation>
            </div>
        )

    }

}

const ChartsWrapper = withRouter(Charts);

class ChartsContainer extends React.Component {

    state = {
        companyId: this.props.match.params.companyId
    }

    render() {

        const {companyId} = this.state;
        const {user} = this.props;

        return (
            <Query
                query={GET_ACCOUNTS}
                variables={{
                    company_id: companyId
                }}
                notifyOnNetworkStatusChange={true}
            >
                {({error, refetch, loading, data}) => {

                    // console.log(error, loading, data);

                    return (!loading && data && data.accounts) ? <ChartsWrapper
                        user={user}
                        companyId={companyId}
                        accounts={data.accounts}
                        refetch={refetch}
                    />: <div className="flex items-center justify-center py-10">
                        <Spinner />
                    </div>

                }}
            </Query>
        )
        
    }

}

export default ChartsContainer