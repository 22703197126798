import React from 'react';
import {Table, InputNumber, Spin, Input, Select, Button, Checkbox, DatePicker} from 'antd';
import {Badge, FormField, Button as EverButton, Spinner} from 'evergreen-ui';
import moment from 'moment';
import {withRouter} from 'react-router-dom';
import {flatten, keyBy, keys, toPairs} from 'lodash';
import {Query, Mutation} from 'react-apollo';
import gql from 'graphql-tag';
import {DeleteOutlined} from '@ant-design/icons';

const SEARCH_EMPLOYEES = gql`
        query(
            $company_id: Int!,
            $name: String!
        ) {
            employees(where: {company_id: {_eq: $company_id}, name: {_ilike: $name}}) {
                id
                employeeId: id
                name
                designation
                date_joined
                department{
                    id
                    name
                }
            }

        }
`;

const GET_ALLOWANCES = gql`
    query($company_id: Int!) {

        allowances: employee_allowances(where: {company_id: {_eq: $company_id}}) {
            allowanceId: id
            id
            name

        }

    }
`;

const CREATE_MUTATIONS = gql`
    mutation($allowances: [employee_allowances_activity_insert_input!]!) {
        insert_employee_allowances_activity(objects: $allowances) {
            affected_rows
        }
    }
`;

const dateFormat = 'YYYY/MM/DD';


class Allowances extends React.Component{

    state = {
        companyId: this.props.match.params.companyId,
        searchValue: '',
        allowances: {},
        employeesHidden: {},
        employees: [],
        employeesAuto: [],
        companyAllowances: this.props.companyAllowances,
        companyAllowancesIndex: keyBy(this.props.companyAllowances, 'id')
    }

    columns = [{
        title: 'Employee Name',
        dataIndex: 'name',
        key: 'name',
        render: (name, record) => {

            return (
                <div className="">
                    <p className="font-medium">{name}</p>
                    <p className=""><Badge color="teal">{record.department.name}</Badge></p>
                </div>
            )

        }
    }, {
        title: 'Allowances',
        render: (n, employee) => {

            let {companyAllowances, allowances, activeAgg} = this.state;
            const {employeeId} = employee;

            const selectedAllowance = allowances[employeeId][activeAgg];

            return (
                <div className="flex flex-col">
                    <div className="mr-2">
                        <Select style={{width: 240}} onChange={(activeAgg) => this.setState({activeAgg})} showSearch defaultActiveFirstOption={true}>
                            {companyAllowances.map((a) => {

                                const _a = a;

                                return (
                                    <Select.Option key={a.allowanceId} value={a.allowanceId}>{_a.name}</Select.Option>
                                )

                            })}
                        </Select>
                        {activeAgg ? <InputNumber
                            className="mx-3"
                            onChange={(value) => {

                                allowances = {
                                    ...allowances,
                                    [employeeId]: {
                                        ...allowances[employeeId],
                                        [activeAgg]: {
                                            ...allowances[employeeId][activeAgg],
                                            value
                                        }
                                    }
                                };

                                console.log(allowances);

                                this.setState({
                                    allowances
                                });

                            }}
                            min={0}
                            value={allowances[employeeId][activeAgg] ? allowances[employeeId][activeAgg].value || 0: 0}
                        />: null}
                    </div>
                    {activeAgg ? <div className="my-2">
                        {selectedAllowance && selectedAllowance.fixed ? <DatePicker
                            placeholder="Select end date"
                            onChange={(date, dateString) => {
                                
                                // console.log(date, dateString);

                                allowances = {
                                    ...allowances,
                                    [employeeId]: {
                                        ...allowances[employeeId],
                                        [activeAgg]: {
                                            ...allowances[employeeId][activeAgg],
                                            expiry: dateString
                                        }
                                    }
                                };

                                this.setState({
                                    allowances
                                });

                            }}
                        /> : null}
                        <div className="">
                            {selectedAllowance ? <Checkbox
                                checked={selectedAllowance.fixed}
                                onChange={(e) => {

                                    allowances = {
                                        ...allowances,
                                        [employeeId]: {
                                            ...allowances[employeeId],
                                            [activeAgg]: {
                                                ...allowances[employeeId][activeAgg],
                                                fixed: e.target.checked
                                            }
                                        }
                                    };
    
                                    // console.log(allowances);
    
                                    this.setState({
                                        allowances
                                    });

                                }}
                            >
                                Recurring Allowance
                            </Checkbox>: null}
                        </div>
                    </div>: null}
                </div>
            )

        }
    }, {
        title: 'Review',
        dataIndex: 'review',
        key: 'review',
        render: (e, employee) => {

            const {activeAgg, allowances, companyAllowancesIndex} = this.state;
            const {employeeId} = employee;

            // console.log(allowances[employeeId]);

            return (
                activeAgg ? <div>
                    {keys(allowances[employeeId]).map((a) => {

                        const _a = companyAllowancesIndex[a];
                        const __a = allowances[employeeId][a];

                        return (
                            <div className="font-bold mb-2" key={a} value={a}>{_a.name} - {__a.value}</div>
                        )

                    })}
                </div>: null
            )

        }
    },{
        title: '',
        dataIndex: 'remove',
        key: 'remove',
        render: (e, employee) => {

            const {employees, allowances} = this.state;
            const {employeeId} = employee;

            return (
                <div>
                    <Button onClick={() => {

                        delete allowances[employeeId];

                        this.setState({
                            allowances,
                            employees: employees.filter((e) => e.employeeId !== employeeId)
                        });

                    }} type="danger" className="flex items-center justify-center" shape="circle" ghost icon={<DeleteOutlined />} />
                </div>
            )

        }
    }];


    clear = () => this.setState({employees: [], allowances:{}, searchValue: '', activeAgg: null, isSaving: false})


    load = async () => {}

    render() {

        const {employees, companyId, searchValue, allowances, companyAllowancesIndex} = this.state;

        // const options = employeesAuto.map(d => <Select.Option key={d.value}>{d.text}</Select.Option>);


        return (

            <div className="overflow-scroll h-full w-full my-12">
                <div className="max-w-6xl mx-auto w-full">
                    <div className="flex items-center">
                        <div className="flex items-center">
                            <h1 className="text-4xl font-bold mr-4 my-4">
                                Allowances
                            </h1>
                            {employees.length ? <Button type="danger" size="small" shape="round" ghost onClick={() => this.clear()}>Clear</Button>: null}
                        </div>
                    </div>
                    <div className="">
                        <div className="my-3">
                            <FormField label="Search Employees" />
                            <div className="">

                                <Query
                                    query={SEARCH_EMPLOYEES}
                                    variables={{
                                        name: `%${searchValue}%`,
                                        company_id: companyId
                                    }}
                                    skip={!searchValue}
                                >
                                    {({data, loading}) => {

                                        const employeesIndexed = keyBy(data && data.employees && data.employees.length ? data.employees: [], 'id');

                                        return (
                                            <Select
                                                size="large"
                                                showSearch
                                                loading={loading}
                                                value={searchValue}
                                                placeholder="Filter Employee"
                                                style={{width: '100%'}}
                                                notFoundContent={loading ? <div className="flex items-center justify-center p-4"><Spin size="small" /></div> : null}
                                                // defaultActiveFirstOption={false}
                                                // showArrow={false}
                                                // allowClear
                                                filterOption={false}
                                                onSearch={(searchValue) => this.setState({searchValue})}
                                                onChange={(e) => {

                                                    this.setState({
                                                        searchValue: '',
                                                        allowances: {
                                                            ...allowances,
                                                            [e]: {}
                                                        },
                                                        employees: [
                                                            {
                                                                ...employeesIndexed[e]
                                                            },
                                                            ...this.state.employees
                                                        ]
                                                    });

                                                }}
                                                // notFoundContent={null}
                                            >
                                                {!loading && data && data.employees.length ? data.employees.map(d => <Select.Option key={d.id}>{d.name}</Select.Option>): null}
                                            </Select>
                                        );

                                    }}
                                </Query>
                            </div>
                        </div>
                        {employees.length ? <div className="">
                            <div className="my-10">
                                <Table rowKey={(row) => row.employeeId} size="middle" pagination={false} columns={this.columns} dataSource={employees} />
                            </div>
                            <div className="my-3">
                                <Mutation
                                    mutation={CREATE_MUTATIONS}
                                    onCompleted={() => this.clear()}
                                    variables={{
                                        allowances: flatten(keys(allowances).map((id) => {

                                            const agg = allowances[id];

                                            return keys(agg).map((_aa) => {

                                                const __a = companyAllowancesIndex[_aa];

                                                // console.log(_aa, __a, agg);

                                                return {
                                                    allowance_id: parseInt(_aa),
                                                    company_id: parseInt(companyId),
                                                    value: agg[_aa].value,
                                                    fixed: agg[_aa].fixed || false,
                                                    employee_id: parseInt(id),
                                                    expiry: agg[_aa].expiry || null
                                                }

                                            })

                                        }))
                                    }}
                                >
                                    {(save, {loading}) => {

                                        return (
                                            <EverButton isLoading={loading} appearance="primary" intent="success" onClick={() => save()}>Save</EverButton>
                                        )

                                    }}
                                </Mutation>
                            </div>
                        </div>: null}
                    </div>
                </div>
            </div>

        );

    }

}

const AllowancesContainer = withRouter(Allowances);

class AllowancesWrapper extends React.Component{

    render() {

        return (

            <Query
                query={GET_ALLOWANCES}
                variables={{
                    company_id: this.props.match.params.companyId
                }}
            >
                {({data, loading, error}) => {

                    return (
                        !loading && data ? <AllowancesContainer companyAllowances={data.allowances} /> : <div className="flex items-center justify-center py-10">
                            <Spinner />
                        </div>
                    )

                }}
            </Query>

        )

    }

}

export default AllowancesWrapper;