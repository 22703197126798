import React from 'react';
import firebase from '../services/firebase';
import {Table, InputNumber, Spin, Select, Input, Button, DatePicker} from 'antd';
import {Badge, FormField, Button as EverButton, Spinner} from 'evergreen-ui';
import moment from 'moment';
import {flatten, keys, keyBy, toPairs, includes} from 'lodash';
import {withRouter} from 'react-router-dom';
import {Query, Mutation} from 'react-apollo';
import gql from 'graphql-tag';
import { CurrencyWrapper } from '../services/Currency';
import {DeleteOutlined} from '@ant-design/icons';


const dateFormat = 'YYYY/MM/DD';
const monthFormat = 'MMMM, YYYY';

const GET_DEDUCTIONS = gql`
    query($company_id: Int!) {

        deductions: employee_deductions(where: {company_id: {_eq: $company_id}}) {
            deductionId: id
            title: name
            id
            name
            type
        }

    }
`;

const SEARCH_EMPLOYEES = gql`
        query(
            $company_id: Int!,
            $name: String!
        ) {
            employees(where: {company_id: {_eq: $company_id}, name: {_ilike: $name}}) {
                id
                employeeId: id
                name
                designation
                date_joined
                department{
                    id
                    name
                }
            }

        }
`;

const CREATE_MUTATIONS = gql`
    mutation($deductions: [employee_deductions_activity_insert_input!]!) {
        insert_employee_deductions_activity(objects: $deductions) {
            affected_rows
        }
    }
`;


class Deductions extends React.Component{

    state = {
        loading: false,
        companyId: this.props.match.params.companyId,
        searchValue: '',
        companyDeductions: this.props.companyDeductions || [],
        companyDeductionsIndex: keyBy(this.props.companyDeductions, 'id'),
        active: null,
        deductions: {},
        employees: []
    }

    columns = [{
        title: 'Employee Name',
        dataIndex: 'name',
        key: 'name',
        render: (name, record) => {

            return (
                <div className="">
                    <p className="font-medium">{name}</p>
                    <p className=""><Badge color="teal">{record.department.name}</Badge></p>
                </div>
            )

        }
    }, {
        title: 'Deductions',
        dataIndex: 'deductions',
        key: 'deductions',
        render: (n, employee) => {

            const {companyDeductions, companyDeductionsIndex, active, deductions} = this.state;
            const deduction = companyDeductionsIndex[active];
            const {employeeId} = employee;

            const _deduction = deductions[employeeId] || {};

            return (
                <div className="flex items-center">
                    {(deduction.type == 'attendance') ? <div className="">
                        <div className="">
                            <InputNumber
                                style={{
                                    // width: '100%'
                                }}
                                min={0}
                                value={_deduction.days_absent || 0}
                                formatter={value => `${value} days`}
                                parser={value => value.replace(' days', '')}
                                onChange={(days_absent) => {

                                    deductions[employeeId] = {
                                        ...deductions[employeeId],
                                        days_absent
                                    }

                                    this.setState({
                                        employees: this.state.employees
                                    });

                                }}
                            />
                            <InputNumber
                                className="ml-2"
                                style={{
                                    // width: '100%'
                                }}
                                min={0}
                                formatter={value => `${value} mins`}
                                parser={value => value.replace(' mins', '')}
                                value={_deduction.hours_absent || 0}
                                onChange={(hours_absent) => {

                                    deductions[employeeId] = {
                                        ...deductions[employeeId],
                                        hours_absent
                                    }

                                    this.setState({
                                        employees: this.state.employees
                                    })

                                }}
                            />
                        </div>
                    </div> : <div className="">
                        <div className="flex -mx-2">
                            {(includes(['surcharge', 'hire-purchase'], deduction.type)) ? <div className="px-2">
                                <FormField isRequired={true} label="Description" />
                                <Input
                                    style={{width: '100%'}}
                                    value={_deduction.description || ''}
                                    onChange={(e) => {

                                        deductions[employeeId] = {
                                            ...deductions[employeeId],
                                            description: e.target.value
                                        }
    
                                        this.setState({
                                            employees: this.state.employees
                                        })

                                    }}
                                />
                            </div>: null}
                            <div className="px-2">
                                <FormField isRequired={true} label="Principal Amount" />
                                <CurrencyWrapper
                                    component={({symbol}) => (
                                        <InputNumber style={{width: '100%'}}
                                            min={1}
                                            value={_deduction.principal_amount || 1}
                                            formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/[^0-9.]/g, '')}
                                            onChange={(principal_amount) => {

                                                deductions[employeeId] = {
                                                    ...deductions[employeeId],
                                                    principal_amount,
                                                    amount_due_monthly: parseFloat(principal_amount / (_deduction.duration || 0)).toFixed(2)
                                                }
            
                                                this.setState({
                                                    employees: this.state.employees
                                                })

                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="px-2">
                                <div className="">
                                    <FormField isRequired={true} label="Duration" />
                                    <InputNumber style={{width: '100%'}}
                                        min={1}
                                        formatter={value => `${value} mo`}
                                        parser={value => value.replace(' mo', '')}
                                        value={_deduction.duration || 1}
                                        onChange={(duration) => {

                                            deductions[employeeId] = {
                                                ...deductions[employeeId],
                                                duration,
                                                amount_due_monthly: parseFloat((_deduction.principal_amount || 0) / duration).toFixed(2)
                                            }
        
                                            this.setState({
                                                employees: this.state.employees
                                            })

                                        }}
                                    />
                                </div>
                            </div>
                            <div className="px-2">
                                <FormField isRequired={true} label="Amount Due Monthly" />
                                <CurrencyWrapper
                                    component={({symbol}) => (
                                        <InputNumber style={{width: '100%'}}
                                            min={1}
                                            value={_deduction.amount_due_monthly || 1}
                                            formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/[^0-9.]/g, '')}
                                            onChange={(amount_due_monthly) => {
                                                
                                                deductions[employeeId] = {
                                                    ...deductions[employeeId],
                                                    amount_due_monthly
                                                }
            
                                                this.setState({
                                                    employees: this.state.employees
                                                })

                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="px-2">
                                <FormField isRequired={true} label="Start Month" />
                                <DatePicker.MonthPicker
                                    disabledDate={(e) => e.isBefore(moment())}
                                    onChange={(e) => {

                                        // console.log(e.startOf('month').format('YYYY-MM-DD'), e.endOf('month').format('YYYY-MM-DD'))

                                        deductions[employeeId] = {
                                            ...deductions[employeeId],
                                            start_date: e.format('YYYY-MM')
                                        }
    
                                        this.setState({
                                            employees: this.state.employees
                                        })

                                    }} value={_deduction.start_date? moment(_deduction.start_date, 'YYYY-MM') : moment()}
                                    format={monthFormat}
                                />
                            </div>
                            <div className="px-2">
                                <FormField label="End Month" />
                                {_deduction.duration && _deduction.start_date ? <div className="h-8 flex flex-col justify-center">
                                    <p className="text-black font-medium">
                                        {moment(_deduction.start_date, 'YYYY-MM').add(_deduction.duration, 'months').format(monthFormat)}
                                    </p>
                                </div>: null}
                            </div>
                        </div>
                    </div>}
                </div>
            )

        }
    },{
        title: '',
        dataIndex: 'review',
        key: 'review',
        render: (e, employee) => {

            const {employees, deductions} = this.state;
            const {employeeId} = employee;

            return (
                <div>
                    <Button onClick={() => {

                        delete deductions[employeeId];

                        this.setState({
                            deductions,
                            employees: employees.filter((e) => e.employeeId !== e.employeeId)
                        });

                    }} type="danger" className="flex items-center justify-center" shape="circle" ghost icon={<DeleteOutlined />} />
                </div>
            )

        }
    }];

    load = async () => {}

    save = async () => {

        const {deductions, companyId, active, companyDeductionsIndex, employees} = this.state;

        if (!employees.length) return;

        const _deductions = toPairs(deductions);
        const deduction = companyDeductionsIndex[active];

        this.setState({isSaving: true});

        const batch = firebase.firestore().batch();
        const employeesRef = firebase.firestore().collection('companies').doc(companyId).collection('employees');

        _deductions.forEach((d) => {

            const start_date = deduction.type == 'attendance' ? moment().startOf('month').format('YYYY-MM-DD') : moment(d[1].start_date, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
            const end_date = deduction.type == 'attendance' ? moment().endOf('month').format('YYYY-MM-DD') : moment(d[1].start_date, 'YYYY-MM').add(d[1].duration, 'months').endOf('month').format('YYYY-MM-DD');

            batch.set(employeesRef.doc(d[0]).collection('activity').doc(), {
                ...d[1],
                amount_due_monthly: parseFloat(d[1].amount_due_monthly),
                type: 'deduction',
                hours_absent: parseInt(d[1].hours_absent) / 60,
                deduction,
                companyId,
                employeeId: d[0],
                type_id: active,
                // date_range: `${end_date}-${start_date}`,
                start_date,
                end_date
            });

        });

        await batch.commit();

        this.clear();

    }

    clear = () => this.setState({employees: [], deductions:{}, searchValue: '', active: null, isSaving: false})

    render() {

        const {
            employees,
            searchValue,
            loading,
            companyId,
            deductions,
            companyDeductions,
            companyDeductionsIndex,
            active,
            isSaving
        } = this.state;


        return (

            <div className="overflow-scroll h-full w-full my-12">
                <div className="max-w-6xl mx-auto w-full">
                    <div className="flex items-center">
                        <div className="flex items-center">
                            <h1 className="text-4xl font-bold mr-4 my-4">
                                Deductions
                            </h1>
                            {employees.length ? <Button type="danger" size="small" shape="round" ghost onClick={() => this.clear()}>Clear</Button>: null}
                        </div>
                    </div>
                    {loading ? <div className="flex items-center justify-center py-10">
                        <Spinner />
                    </div> : <div className="">
                        <div className="-mx-2">
                            {(employees.length && active ? companyDeductions.filter((d) => d.deductionId == active) : companyDeductions).map((deduction) => {

                                return (
                                    <Button disabled={employees.length && active} className="mx-2" onClick={() => {

                                        this.setState({
                                            active: active == deduction.deductionId? null: deduction.deductionId
                                        });

                                    }} key={deduction.deductionId} shape="round" ghost={(active == deduction.deductionId) ? false: true} type="primary">{deduction.title}</Button>
                                )

                            })}
                        </div>
                        <div className="my-3">
                            {/* <AutoComplete
                                dataSource={employeesAuto}
                                style={{ width: '100%' }}
                                onSelect={(e) => console.log(e)}
                                // optionLabelProp="label"
                                onSearch={this.handleSearch}
                                placeholder="Filter Employee"
                            /> */}
                            <FormField label="Search Employees" />
                            <div className="">

                                <Query
                                    query={SEARCH_EMPLOYEES}
                                    variables={{
                                        name: `%${searchValue}%`,
                                        company_id: companyId
                                    }}
                                    skip={!searchValue}
                                >
                                    {({data, loading}) => {

                                        const employeesIndexed = keyBy(data && data.employees && data.employees.length ? data.employees: [], 'id');

                                        return (
                                            <Select
                                                size="large"
                                                showSearch
                                                loading={loading}
                                                value={searchValue}
                                                placeholder="Filter Employee"
                                                style={{width: '100%'}}
                                                disabled={!active}
                                                notFoundContent={loading ? <div className="flex items-center justify-center p-4"><Spin size="small" /></div>: null}
                                                // defaultActiveFirstOption={false}
                                                // showArrow={false}
                                                filterOption={false}
                                                onSearch={(searchValue) => this.setState({searchValue})}
                                                onChange={(e) => {

                                                    const _emp = employeesIndexed[e];
                                                    const _deduction = companyDeductionsIndex[active];

                                                    // console.log(e, _emp, _deduction, companyDeductionsIndex);

                                                    this.setState({
                                                        deductions: {
                                                            ...deductions,
                                                            [e]: (_deduction.type === 'attendance') ? {
                                                                days_absent: 0,
                                                                hours_absent: 0
                                                            } : {
                                                                principal_amount: 1,
                                                                duration: 1,
                                                                amount_due_monthly: 1,
                                                                start_date: moment().format('YYYY-MM')
                                                            }
                                                        },
                                                        searchValue: '',
                                                        employees: [
                                                            {
                                                                ..._emp
                                                            },
                                                            ...this.state.employees
                                                        ]
                                                    });

                                                }}
                                                // notFoundContent={null}
                                            >
                                                {!loading && data && data.employees.length ? data.employees.map(d => <Select.Option key={d.id}>{d.name}</Select.Option>): null}                                                
                                            </Select>

                                        )
                                    }}
                                </Query>
                            </div>
                        </div>
                    </div>}
                    {employees.length ? <div className="">
                        <Table rowKey={(row) => row.employeeId} size="middle" loading={loading} pagination={false} columns={this.columns} dataSource={employees} />
                        <div className="my-3">
                            <Mutation
                                    mutation={CREATE_MUTATIONS}
                                    onCompleted={() => this.clear()}
                                    variables={{
                                        deductions: flatten(keys(deductions).map((id) => {

                                            const deduction = deductions[id];
                                            const _deduction = companyDeductionsIndex[active];

                                            const start_date = _deduction.type == 'attendance' ? moment().startOf('month').format('YYYY-MM-DD') : moment(deduction.start_date, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
                                            const end_date = _deduction.type == 'attendance' ? moment().endOf('month').format('YYYY-MM-DD') : moment(deduction.start_date, 'YYYY-MM').add(deduction.duration, 'months').endOf('month').format('YYYY-MM-DD');

                                            return {
                                                deduction_id: parseInt(active),
                                                company_id: parseInt(companyId),
                                                employee_id: parseInt(id),
                                                start_date,
                                                end_date,
                                                amount_due_monthly: deduction.amount_due_monthly,
                                                principal_amount: deduction.principal_amount,
                                                duration: deduction.duration,
                                                hours_absent: parseInt(deduction.hours_absent) / 60,
                                                days_absent: deduction.days_absent
                                            }

                                        }))
                                    }}
                                >
                                    {(save, {loading}) => {

                                        return (
                                            <EverButton isLoading={loading} appearance="primary" intent="success" onClick={() => save()}>Save</EverButton>
                                        )

                                    }}
                                </Mutation>
                        </div>
                    </div>: null}
                </div>
            </div>

        );

    }

}

const DeductionsContainer = withRouter(Deductions);

class DeductionsWrapper extends React.Component{

    render() {

        return (

            <Query
                query={GET_DEDUCTIONS}
                variables={{
                    company_id: this.props.match.params.companyId
                }}
            >
                {({data, loading, error}) => {

                    return (
                        !loading && data ? <DeductionsContainer companyDeductions={data.deductions} /> : <div className="flex items-center justify-center py-10">
                            <Spinner />
                        </div>
                    )

                }}
            </Query>

        )

    }

}

export default DeductionsWrapper;