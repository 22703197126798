import {keyBy, flattenDepth, omit} from 'lodash';

var arrayToTree = require('array-to-tree');

export const buildAccount = (account, parents) => {

    const key = [...parents, account.id];

    return ({
        ...account,
        accounts: key,
        children: account.children && account.children.length ? account.children.map((account) => buildAccount(account, key)): []
    })

}

export const buildArray = (account) => {

    return [omit(account, ['children', '__typename']), ...account.children.map(buildArray)];

}

export const fetchAccounts = (ledgers) => {

    const _accounts = arrayToTree(ledgers).map((account) => buildAccount(account, account.parent_id ? [account.group_id, account.parent_id] : [account.group_id]));

    return keyBy(flattenDepth(_accounts.map(buildArray), 5), 'prefix')

}