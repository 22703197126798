import React from 'react';
import {TextInputField, Button} from 'evergreen-ui';
import {decode} from 'mout/queryString';
import {Link} from 'react-router-dom';
import firebase from '../services/firebase';
import api from '../services/api';


class Login extends React.Component{

    constructor(props) {

        super(props);

        this.query = decode(window.location.search);

    }

    state = {
        email: '',
        password: '',
        loading: false,
        account: this.props.account
    }

    login = async (e) => {

        e.preventDefault();

        const {email, password, account} = this.state;

        this.setState({
            error: null,
            loading: true
        });

        try {

            const res = await api.post('/auth/login', {
                account_id: account.id,
                email,
                password
            }, {
                noAuth: true
            });



            if (res.data.token) {

                await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

                await firebase.auth().signInWithCustomToken(res.data.token);

                // this.setState({
                //     loading: false
                // });

                this.props.history.push('/');

            } else {

                this.setState({
                    loading: false,
                    error: 'An error occurred, please try again'
                });

            }

        } catch(e) {

            this.setState({
                loading: false,
                error: e.response && e.response.data.message ? e.response.data.message: null
            });


        }

        // firebase.auth().signInWithEmailAndPassword(
        //     email,
        //     password
        // ).then((data) => {

        //     // this.props.history.push('/');

        // }).catch((err) => {

        //     this.setState({
        //         loading: false,
        //         error: err.message
        //     });

        // })

    }

    render() {

        const {error, loading, email, password, account} = this.state;

        return (
            <div className="py-8 flex flex-col justify-center items-center h-full w-full">
                <form className="w-full" onSubmit={this.login}>
                    <div className="mx-auto container max-w-xs">
                        <img src="/icon.png" className="w-24 h-auto mx-auto" alt="GomERP Logo"/>
                        <p className="text-center font-bold text-2xl text-black mb-6">{account.name}</p>
                        <div className="rounded-lg shadow-lg p-8">
                            <TextInputField
                                label="Email address"
                                required
                                placeholder="Eg. john@example.com"
                                value={email}
                                onChange={e => this.setState({email: e.target.value})}
                            />
                            <TextInputField
                                label="Password"
                                required
                                type="password"
                                placeholder="At least 10 characters"
                                value={password}
                                onChange={e => this.setState({password: e.target.value})}
                            />
                            {(error) ? <p className="text-red-500 text-xs font-medium mb-4">{error}</p> : null}
                            <div className="flex flex-col items-center">
                                <Button  width={`100%`} justifyContent="center" alignItems="center" isLoading={loading} appearance="primary" intent="success">
                                    {loading ? 'Loading...' : 'Sign in to your account'}
                                </Button>
                                <div className="mt-6">
                                    <a href="#" onClick={() => this.props.clearEndpoint()} className="text-sm text-grey-darker hover:text-grey-dark">Change endpoint</a>
                                </div>
                            </div>
                        </div>
                        <div className="text-center my-10">
                            <p className="">Powered by <span className="font-bold">GOMERP</span> &trade;</p>
                        </div>
                    </div>
                </form>
            </div>
        )

    }

}

export default Login;