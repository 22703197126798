import React from 'react'
import { TextInputField, Button, Switch } from 'evergreen-ui'
import { DatePicker } from 'antd'
import {fetchAccounts} from '../../utils/accounts';
import apollo from '../../services/apollo';


import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import moment from 'moment';

const UPDATE_WHOLESALE_MUTATION = gql`

    mutation add(
        $id: Int!,
        $is_wholesale: Boolean,
        $ledger_id: Int!
    ) {
        customer: update_customers(where: {id: {_eq: $id}}, _set: {
            is_wholesale: $is_wholesale,
            ledger_id: $ledger_id
        }) {
            returning{
                id
            }
        }
    }  

`;

const CREATE_LEDGER_MUTATION = gql`

    mutation add(
        $company_id: Int,
        $parentId: Int,
        $name: String!,
        $transactions: [ledger_transactions_insert_input!]!,
        $ledger_id: Int
    ) {
        ledger: insert_ledgers(objects: {
            archived: false,
            currency: "GHS",
            group_id: 1,
            description: "",
            is_default: false,
            name: $name,
            company_id: $company_id,
            prefix: null,
            parent_id: $parentId,
            transactions: {
                data: $transactions
            }
        }) {
            returning{
                id
            }
        }
    }  

`;

const SAVE_MUTATION = gql`

    mutation add(
        $id: Int!,
        $name: String!,
        $first_name: String!,
        $last_name: String!,
        $phone: String,
        $email: String,
        $date_of_birth: date,
        $company_id: Int,
        $ledger_id: Int,
        $parentId: Int,
        $is_wholesale: Boolean,
        $transactions: [ledger_transactions_insert_input!]!
    ) {
        customer: update_customers(where: {id: {_eq: $id}}, _set: {
            contact_name: $name,
            name: $name,
            first_name: $first_name,
            last_name: $last_name,
            phone: $phone,
            email: $email,
            date_of_birth: $date_of_birth
        }) {
            returning{
                id
            }
        }
        update_ledgers(where: {id: {_eq: $ledger_id}}, _set: {name: $name}){
            affected_rows
        }
    }  

`;

const ADD_MUTATION = gql`

    mutation add(
        $id: Int,
        $name: String!,
        $first_name: String!,
        $last_name: String!,
        $phone: String,
        $email: String,
        $date_of_birth: date,
        $company_id: Int,
        $parentId: Int,
        $ledger_id: Int,
        $is_wholesale: Boolean,
        $transactions: [ledger_transactions_insert_input!]!
    ){
        customer: insert_customers(objects: {
            contact_name: $name,
            name: $name,
            first_name: $first_name,
            last_name: $last_name,
            phone: $phone,
            email: $email,
            date_of_birth: $date_of_birth
            company_id: $company_id,
            ledger: {
                data: {
                    archived: false,
                    currency: "GHS",
                    group_id: 1,
                    description: "",
                    is_default: false,
                    name: $name,
                    company_id: $company_id,
                    prefix: null,
                    parent_id: $parentId,
                    transactions: {
                        data: $transactions
                    }
                }
            }
        }) {
            returning{
                id
            }
        }
    }
  

`;

const dateFormat = 'YYYY-MM-DD';


export default class CustomerForm extends React.Component {
    state = {
        firstName: this.props.data.firstName || '',
        lastName: this.props.data.lastName || '',
        phone: this.props.data.phone || '',
        email: this.props.data.email || '',
        is_wholesale: this.props.data.is_wholesale || false,
        ledger_id: this.props.data.ledger_id || null,
        DOB: this.props.data.DOB || moment().format(dateFormat),
        inValid: false,
        inValidEmail: false,
        emailErrorMsg: null,
        phoneErrorMsg: null,
    }
    //phone number validation
    handleNumbers = e => {
        if (/^\d+$/.test(e.target.value)) {
            console.log("is working")
            if (e.target.value.length <= 10) {
                this.setState({
                    phone: e.target.value,
                    inValid: false,
                    phoneErrorMsg: null,
                });
            }
            else {
                this.setState({
                    inValid: true,
                    phoneErrorMsg: 'Phone number is not up to 10'
                })
            }

        }
        else {
            if (e.target.value === '') {
                this.setState({
                    phone: '',
                    inValid: false,
                    phoneErrorMsg: null
                })
            }
            else {
                this.setState({
                    inValid: true,
                    phoneErrorMsg: "Phone number invalid"
                })
            }


        }
    }

    //email validation
    handleEmails = e => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
            this.setState({
                email: e.target.value,
                inValidEmail: false,
                emailErrorMessage: null
            });
        }
        else {
            this.setState({
                inValidEmail: true,
                emailErrorMessage: "Enter a valid Email"
            })
        }
    }
    render() {

        const {
            firstName,
            lastName,
            phone,
            email,
            DOB,
            is_wholesale,
            ledger_id,
            inValid,
            inValidEmail,
            emailErrorMsg,
            phoneErrorMsg,
            savingLedger
        } = this.state;

        const {customerId, onSave, companyId, user, company} = this.props;

        const accounts = fetchAccounts(company.ledgers);


        return (
            <div>
                <div className="mt-4">
                    <TextInputField
                        label="First Name"
                        defaultValue={firstName}
                        onChange={(e) => {

                            this.setState({
                                firstName: e.target.value
                            });

                        }}
                        placeholder="First Name"
                        required
                    />
                </div>

                {/* Supplier's last name */}
                <div className="mt-4">
                    <TextInputField
                        label="Last Name"
                        defaultValue={lastName}
                        onChange={(e) => {

                            this.setState({
                                lastName: e.target.value
                            });

                        }}
                        placeholder="Last Name"
                        required
                    />
                </div>

                {/* Supplier's phone number */}
                <div className="mt-4">
                    <TextInputField
                        label="Phone number"
                        defaultValue={phone}
                        onChange={this.handleNumbers}
                        placeholder="Phone"
                        isInvalid={inValid}
                        validationMessage={phoneErrorMsg}
                        required
                    />
                </div>

                {/* Supplier's email address */}
                <div className="mt-4">
                    <TextInputField
                        label="Email"
                        defaultValue={email}
                        onChange={this.handleEmails}
                        placeholder="Eg. John@example.com"
                        isInvalid={inValidEmail}
                        validationMessage={emailErrorMsg}
                        required
                    />
                </div>
                <div className="mt-4">

                <p className="font-medium">Date of Birth</p>
                    <DatePicker
                        style={{
                            width: '100%'
                        }}
                        defaultValue={moment(DOB, dateFormat)}
                        format={dateFormat}
                        onChange={(date) => {

                            this.setState({ DOB: date.format(dateFormat) })

                        }}
                    />
                </div>
                <div className="my-5">
                    <p className="📦color_425A70 📦fnt-fam_b77syt 📦fnt-sze_14px 📦f-wght_500 📦ln-ht_20px 📦ltr-spc_-0-05px 📦dspl_block 📦mb_4px 📦box-szg_border-box">Wholesale customer?</p>
                    <Switch
                        marginBottom={16}
                        checked={is_wholesale}
                        disabled={savingLedger}
                        onChange={async (e) => {

                            e.persist();

                            const checked = e.target.checked;

                            if (checked && !ledger_id) {

                                this.setState({
                                    savingLedger: true
                                });

                                const {data} = await apollo.mutate({
                                    mutation: CREATE_LEDGER_MUTATION,
                                    variables: {
                                        company_id: company.id,
                                        name: `${firstName} ${lastName}`,
                                        parentId: accounts['trade-receivables'].id,
                                        transactions: [{
                                            description: "INITIAL BALANCE",
                                            initial: true,
                                            user_id: user.id
                                        }]
                                    }
                                });

                                this.setState({
                                    ledger_id: data.ledger.returning[0].id
                                });

                                await apollo.mutate({
                                    mutation: UPDATE_WHOLESALE_MUTATION,
                                    variables: {
                                        id: customerId,
                                        ledger_id: data.ledger.returning[0].id,
                                        is_wholesale: checked
                                    }
                                });

                                this.setState({
                                    savingLedger: false
                                });

                            } else {

                                this.setState({
                                    savingLedger: true
                                });

                                await apollo.mutate({
                                    mutation: UPDATE_WHOLESALE_MUTATION,
                                    variables: {
                                        id: customerId,
                                        ledger_id,
                                        is_wholesale: checked
                                    }
                                });

                            }

                            this.setState({is_wholesale: checked, savingLedger: false});


                        }}
                    />
                </div>
                <div className="mt-3">
                    
                    <Mutation
                        onCompleted={(data) => {

                            onSave({
                                id: data.customer.returning[0].id,
                                first_name: firstName,
                                last_name: lastName,
                                phone,
                                email,
                                date_of_birth: DOB,
                            });

                        }}
                        mutation={customerId ? SAVE_MUTATION : ADD_MUTATION}
                        variables={{
                            company_id: companyId,
                            id: customerId,
                            name: `${firstName} ${lastName}`,
                            first_name: firstName,
                            last_name: lastName,
                            phone,
                            email,
                            date_of_birth: DOB,
                            is_wholesale,
                            parentId: accounts['trade-receivables'].id,
                            ledger_id: ledger_id ? ledger_id: null,
                            transactions: [{
                                description: "INITIAL BALANCE",
                                initial: true,
                                user_id: user.id
                            }]
                        }}
                    >
                        {(add, { loading }) => {

                            return (
                                <Button appearance="primary" intent="success" isLoading={loading} onClick={() => add()}>
                                    Save
                                </Button>
                            )

                        }}
                    </Mutation>
                </div>
            </div>
        )
    }
}