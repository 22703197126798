import React from 'react';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import {Button} from 'antd';
import {Spinner} from 'evergreen-ui';
import {withRouter} from 'react-router-dom';
import roles from '../services/roles';

const GET_USERS = gql`

    query($account_id: Int!) {
        users(where: {account_id: {_eq: $account_id}}) {
            id
            display_name
            email
            companies: companies_aggregate {
                aggregate {
                    count(columns: account_id)
                }
            }
        }

    }

`;

class Users extends React.Component{

    render() {

        const {account, users} = this.props;

        return (
            <div className="py-12 overflow-scroll h-full">
                <div className="max-w-2xl w-full mx-auto px-4">
                    <div className="">
                        <p className="font-light text-xl">Account Name</p>
                        <h1 className="font-bold text-4xl">
                            {account.name} 
                        </h1>
                    </div>
                    <div className="my-10">
                        <div className="border-b pb-4 flex items-center">
                            <h1 className="font-light text-black text-2xl">Users</h1>
                            <Button size="small" type="primary" className="mx-2" ghost onClick={() => this.props.history.push('/users/create')}>add</Button>
                        </div>

                        <div className="">
                            {users.map((user) => {

                                return (
                                    <div key={user.id} className="py-6 border-b">
                                        <div className="flex items-center justify-between">
                                            <div className="">
                                                <p className="text-xl font-bold text-black">{user.display_name}</p>
                                                <div className="">
                                                    {user.companies.aggregate.count ? <p className="font-light text-sm">{user.companies.aggregate.count } Companies</p> :  <p className="italic font-light text-sm">No Companies</p>}
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="text-black">
                                                    <Button size="small" type="primary" ghost onClick={() => this.props.history.push(`/users/${user.id}`)}>Edit</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )

                            })}
                        </div>
                        
                    </div>
                </div>
            </div>
        )

    }

}

const UsersWrapper = withRouter(Users);

class UsersContainer extends React.Component {

    render() {

        const {account, user} = this.props;

        // console.log(account, user);

        return (
            <Query
                query={GET_USERS}
                variables={{
                    account_id: account.id
                }}
            >
                {({data, error, loading}) => {

                    return (
                        !loading && data && data.users ? <UsersWrapper account={account} user={user} users={data.users} />: <div className="flex h-full items-center justify-center py-10">
                            <Spinner />
                        </div>
                    )

                }}
            </Query>
        )

    }

}


export default UsersContainer;