import { Model } from '@nozbe/watermelondb'
import { field, date, readonly } from '@nozbe/watermelondb/decorators'

export default class User extends Model {
    
    static table = 'users'

    @field('display_name') displayName
    @field('role') role
    @field('outlet_id') outletId
    @field('company_id') companyId
    @field('user_id') userId
    
    @field('remote_id') remoteId
    @readonly @date('created_at') createdAt
    @readonly @date('updated_at') updatedAt
}