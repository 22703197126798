import moment from 'moment';
import { sumBy } from 'lodash';

const MOVEMENT_FRAGMENT = `
    id
    price
    qty
    created_at
    variance
    e_qty
    variant {
        id
        name
        product{
            id
            name
        }
    }
    history {
        id
        type
        destination {
            id
            name
        }
        source {
            id
            name
        }
        employee {
            id
            display_name
        }
        supplier{
            id
            name
        }
        transfer{
            id
            employee{
              display_name
            }
        }
    }
`;

const mapAttrs = (i) => {

    const _date = moment(i.created_at);

    return {
        id: i.variant.id,
        description: i.variant.product.name,
        specs: i.variant.name,
        outlet: '',
        employee: i.history.employee.display_name,
        supplier: i.history.supplier ? i.history.supplier.name : '',
        transfer_by: i.history.transfer ? i.history.transfer.employee.display_name : '',
        date: _date.format('DD/MM/YYYY HH:mm A'),
        date_raw: _date,
        unit_price: i.price,
        qty: i.qty,
        variance: i.variance || (i.qty - i.e_qty) || 0,
        total: i.price * i.qty
    }

};

const query = ({
    source,
    destination,
    employee,
}, type) => {

    return `
        payload: products_history_items(where:{
            created_at: {
                _gte: $fromDate,
                _lte: $toDate
            }
            history:{
                type: ${type},
                company_id:{_eq: $company_id},
                ${source && source != 'all' ? `source: {
                    id: {_eq: ${source}}
                },`: ''}
                ${destination && destination != 'all' ? `destination: {
                    id: {_eq: ${destination}}
                },`: ''}
                ${employee && employee != 'all' ? `employee: {
                    id: {_eq: $employee}
                },`: ''}
            }
        }, order_by:{
            created_at: desc
        }) {
            ${MOVEMENT_FRAGMENT}
        }
    `;

}

export default {
    PAYMENT_MODES: {
        columns: [
            "description",
            // "unit_price",
            // "qty",
            "total"
        ],
        query: ({
            outlet
        }) => {

            return `
                card: order_payments_aggregate(where:{
                    type:{_eq: "card"},
                    company_id: {_eq: $company_id},
                    created_at: {
                        _gte: $fromDate,
                        _lte: $toDate
                    },
                    ${outlet && outlet != 'all' ? `outlet_id:{_eq: ${parseInt(outlet)}}` : ''}
                }){
                    aggregate{
                        sum{
                            value
                        }
                    }
                },
                momo: order_payments_aggregate(where:{
                    type:{_eq: "momo"},
                    company_id: {_eq: $company_id},
                    created_at: {
                        _gte: $fromDate,
                        _lte: $toDate
                    },
                    ${outlet && outlet != 'all' ? `outlet_id:{_eq: ${parseInt(outlet)}}` : ''}
                }){
                    aggregate{
                        sum{
                            value
                        }
                    }
                },
                cash: order_payments_aggregate(where:{
                    type:{_eq: "cash"},
                    company_id: {_eq: $company_id},
                    created_at: {
                        _gte: $fromDate,
                        _lte: $toDate
                    },
                    ${outlet && outlet != 'all' ? `outlet_id:{_eq: ${parseInt(outlet)}}` : ''}
                }){
                    aggregate{
                        sum{
                            value
                        }
                    }
                }
            `

        }
    },
    STOCK_BALANCE: {
        columns: [
            "id",
            "description",
            "specs",
            "opening",
            "unit_price",
            "min_qty",
            "closing",
            "total"
        ],
        query: ({
            outlet
        }) => {

            return `
                payload: products_variants(where:{
                    product:{
                        archived: {_is_null: true},
                        company_id:{_eq: $company_id}
                    }
                  }){
                    id
                    name
                    price
                    min_qty
                    product{
                        id
                        name
                    }
                    inventory_aggregate(where:{
                        ${outlet && outlet != 'all' ? `
                            outlet_id: {_eq: ${outlet}}
                        `: ''}
                    }){
                        aggregate{
                          sum{
                            qty
                          }
                        }
                    }
                    opening:inventory_history(where:{
                        date:{_eq: $fromDate}
                        ${outlet && outlet != 'all' ? `
                            outlet_id: {_eq: ${outlet}}
                        `: ''}
                    }){
                      qty
                    }
                    closing:inventory_history(where:{
                        date:{_eq: $toDate}
                        ${outlet && outlet != 'all' ? `
                            outlet_id: {_eq: ${outlet}}
                        `: ''}
                    }){
                      qty
                    }
                }
            `;

        },
        mapAttrs: (i) => {

            const _qty = i.inventory_aggregate.aggregate.sum.qty || 0;

            const closing = i.closing.length ? sumBy(i.closing, 'qty') : _qty;
            const opening = i.opening.length ? sumBy(i.opening, 'qty') : _qty;



            return {
                id: i.id,
                description: i.product.name,
                specs: i.name,
                min_qty: i.min_qty,
                opening,
                unit_price: i.price,
                closing,
                total: i.price * closing
            }

        }
    },
    TRANSFERS: {
        columns: [
            "transfer_id",
            "id",
            "description",
            "specs",
            "transfer_by",
            "date",
            "source",
            "destination",
            "unit_price",
            "qty",
            "total"
        ],
        query: (variables) => {

            return query(variables, `{_eq: "TRANSFER_STOCK"}`);

        },
        mapAttrs: (i) => ({
            ...mapAttrs(i),
            transfer_id: i.history.id,
            transfer_by: i.history.employee.display_name,
            source: i.history.source.name,
            destination: i.history.destination.name
        })
    },
    RECEIVINGS: {
        columns: [
            "id",
            "description",
            "specs",
            "outlet",
            "transfer_by",
            "employee",
            "date",
            "unit_price",
            "qty",
            "total"
        ],
        query: (variables) => {

            return query({
                ...variables,
                destination: variables.outlet
            }, `{_eq: "RECEIVE_STOCK"}`);

        },
        mapAttrs: (i) => ({
            ...mapAttrs(i),
            outlet: i.history.destination.name
        })
    },
    SALE: {
        columns: [
            "id",
            "description",
            "specs",
            "outlet",
            "employee",
            "date",
            "unit_price",
            "qty",
            "discount_amt",
            "subtotal",
            "total"
        ],
        query: ({
            outlet,
            employee
        }) => {

            return `
                payload: order_items(where:{
                    created_at: {
                        _gte: $fromDate,
                        _lte: $toDate
                    },
                    ${employee && employee != 'all' ? `
                        employee_id: {_eq: $employee},
                    `: ''}
                    order:{
                        ${outlet && outlet != 'all' ? `
                            outlet_id: {_eq: ${outlet}}
                        `: ''}
                        company_id:{_eq: $company_id}
                    }
                  }){
                    id
                    price
                    qty
                    subtotal
                    total
                    discount_amt
                    created_at
                    employee{
                        id
                        display_name
                    }
                    order{
                        id
                        outlet{
                            id
                            name
                        }
                    }
                    variant{
                        id
                        name
                        product{
                            id
                            name
                        }
                    }

                }

            `

        },
        mapAttrs: (i) => {

            const _date = moment(i.created_at);

            return {
                id: i.order.id,
                description: i.variant.product.name,
                specs: i.variant.name,
                outlet: i.order.outlet.name,
                employee: i.employee ? i.employee.display_name : '-',
                date: _date.format('DD/MM/YYYY HH:mm A'),
                date_raw: _date,
                unit_price: i.price,
                qty: i.qty,
                discount_amt: i.discount_amt,
                subtotal: i.subtotal,
                total: i.total
            }

        }
    },
    RETURN_SALES: {
        columns: [
            "id",
            "description",
            "specs",
            "outlet",
            "employee",
            "date",
            "unit_price",
            "qty",
            "total"
        ],
        query: ({
            outlet,
            employee
        }) => {

            return `
                payload: order_items(where:{
                    qty: {_lt: 0},
                    created_at: {
                        _gte: $fromDate,
                        _lte: $toDate
                    },
                    ${employee && employee != 'all' ? `
                        employee_id: {_eq: $employee},
                    `: ''}
                    order:{
                        ${outlet && outlet != 'all' ? `
                            outlet_id: {_eq: ${outlet}}
                        `: ''}
                        company_id:{_eq: $company_id}
                    }
                  }){
                    id
                    price
                    qty
                    subtotal
                    created_at
                    employee{
                        id
                        display_name
                    }
                    order{
                        outlet{
                            id
                            name
                        }
                    }
                    variant{
                        id
                        name
                        product{
                            id
                            name
                        }
                    }

                }

            `

        },
        mapAttrs: (i) => {

            const _date = moment(i.created_at);

            return {
                id: i.variant.id,
                description: i.variant.product.name,
                specs: i.variant.name,
                outlet: i.order.outlet.name,
                employee: i.employee ? i.employee.display_name : '-',
                date: _date.format('DD/MM/YYYY HH:mm A'),
                date_raw: _date,
                unit_price: Math.abs(i.price),
                qty: Math.abs(i.qty),
                total: Math.abs(i.subtotal)
            }

        }
    },
    RETURN_STOCK: {
        columns: [
            "id",
            "description",
            "specs",
            "outlet",
            "employee",
            "date",
            "unit_price",
            "qty",
            "total"
        ],
        query: (variables) => {

            return query({
                ...variables,
                destination: variables.outlet
            }, `{_eq: "RETURN_STOCK"}`);

        },
        mapAttrs: (i) => ({
            ...mapAttrs(i),
            outlet: i.history.destination.name
        })
    },
    RETURN_PURCHASE_ORDER: {
        columns: [
            "id",
            "description",
            "specs",
            // "outlet",
            "supplier",
            "employee",
            "date",
            "unit_price",
            "qty",
            "total"
        ],
        query: (variables) => {

            return query(variables, `{_eq: "RETURN_PURCHASE_ORDER"}`);

        },
        mapAttrs: mapAttrs
    },
    STOCK_TAKE: {
        columns: [
            "id",
            "outlet",
            "employee",
            "date",
            "stock_qty",
            "stock_value"
        ],
        query: ({
            type,
            outlet,
            employee
        }) => {

            return `
                payload: products_history(where:{
                    created_at: {
                        _gte: $fromDate,
                        _lte: $toDate
                    },
                    type: {_eq: "${type}"},
                    company_id:{_eq: $company_id},
                    ${outlet && outlet != 'all' ? `source: {
                        id: {_eq: ${outlet}}
                    },`: ''}
                    ${employee && employee != 'all' ? `employee: {
                        id: {_eq: $employee}
                    },`: ''}
                }, order_by:{
                    created_at: desc
                }) {
                    id
                    qty: total_qty
                    total: total_amount
                    employee{
                        id
                        display_name
                    }
                    outlet: source{
                        id
                        name
                    }
                    created_at
                }
            `

        },
        mapAttrs: (i) => {

            const _date = moment(i.created_at);

            return {
                id: i.id,
                outlet: i.outlet.name,
                employee: i.employee ? i.employee.display_name : '-',
                date: _date.format('DD/MM/YYYY HH:mm A'),
                date_raw: _date,
                stock_qty: i.qty,
                stock_value: i.total,
                total: i.total
            }

        }
    },
    STOCK_ADJUSTMENT: {
        columns: [
            "id",
            "description",
            "specs",
            "outlet",
            "employee",
            "date",
            "unit_price",
            "qty",
            "variance",
            "total"
        ],
        query: (variables) => {

            return query({
                ...variables,
                source: variables.outlet
            }, `{_eq: "STOCK_ADJUSTMENT"}`);

        },
        mapAttrs: (i) => ({
            ...mapAttrs(i),
            outlet: i.history.source.name
        })
    },
    BILL_OF_MATERIAL: {
        columns: [
            "id",
            "description",
            "specs",
            "outlet",
            "employee",
            "date",
            "unit_price",
            "qty",
            "total"
        ],
        query: (variables) => {

            return query({
                ...variables,
                source: variables.outlet
            }, `{_eq: "BILL_OF_MATERIAL"}`);

        },
        mapAttrs: (i) => ({
            ...mapAttrs(i),
            outlet: i.history.source.name
        })
    },
    NEW_STOCK: {
        columns: [
            "id",
            "description",
            "specs",
            "outlet",
            "employee",
            "date",
            "unit_price",
            "qty",
            "total"
        ],
        query: (variables) => {

            return query({
                ...variables,
                destination: variables.outlet
            }, `{_eq: "NEW_STOCK"}`);

        },
        mapAttrs: (i) => ({
            ...mapAttrs(i),
            outlet: i.history.destination.name
        })
    },
    PURCHASE_ORDERS: {
        columns: [
            "id",
            "description",
            "specs",
            "outlet",
            "supplier",
            "employee",
            "date",
            "unit_price",
            "qty",
            "total"
        ],
        query: (variables) => {

            return query({
                ...variables,
                destination: variables.outlet
            }, `{_eq: "PURCHASE_ORDER"}`);

        },
        mapAttrs: (i) => ({
            ...mapAttrs(i),
            outlet: i.history.destination.name
        })
    }
}