import React from 'react';
import {Button} from 'evergreen-ui';
import {debounce} from 'lodash';
import {Spinner, Badge} from 'evergreen-ui';
import {RefreshCw, MoreHorizontal} from 'react-feather';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';
import {Modal} from 'antd';
import Allowance from './Allowance';

const GET_ALLOWANCES = gql`
    query($company_id: Int!) {

        allowances: employee_allowances(where: {company_id: {_eq: $company_id}}) {
            id
            name

        }

    }
`;

class EmployeeAllowanceSettings extends React.Component{

    state = {
        showModal: false,
        companyId: this.props.companyId,
        allowanceId: null
    }

    render() {

        const {showModal, companyId, allowanceId} = this.state;


        return (
            <div className="overflow-scroll w-full h-full py-12">

                <Query
                    query={GET_ALLOWANCES}
                    variables={{
                        company_id: companyId
                    }}
                >
                    {({data, loading, refetch, error}) => {

                        return (
                            <React.Fragment>
                                <div className="w-full max-w-3xl mx-auto px-4">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <h1 className="font-bold text-4xl mr-2">
                                                Allowances 
                                            </h1>
                                            <button disabled={loading} className="ml-4 focus:outline-none text-black" onClick={() => refetch()}>
                                                <RefreshCw className={(loading) ? "rotating": null} size={24} />
                                            </button>
                                        </div>
                                        <div className="">
                                            <Button appearance="primary" intent="success" onClick={() => {

                                                this.setState({
                                                    showModal: true
                                                });

                                            }} className="flex items-center justify-center">Add Allowance</Button>
                                        </div>
                                    </div>
                                    {loading ? <div className="flex items-center justify-center py-10">
                                        <Spinner />
                                    </div> : data.allowances.length ? <div className="my-4">
                                        {data.allowances.map((allowance) => {

                                            const {name} = allowance;

                                            return (
                                                <div className="border-b my-4 py-4" key={allowance.id}>
                                                    <div className="">
                                                        <div onClick={(e) => {

                                                            e.stopPropagation();

                                                            // this.props.history.push(`/${companyId}/hr/allowances/${allowance.id}`);

                                                            this.setState({
                                                                showModal: true,
                                                                allowanceId: allowance.id
                                                            });

                                                        }} className="cursor-pointer flex-1">
                                                            <div className="font-bold text-lg text-black">
                                                                {name}
                                                            </div>
                                                            {/* <p className="">{qty} {unit.toUpperCase()} / <CurrencyFormat amount={rate} /></p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            );

                                        })}
                                    </div>: <p className="text-center py-10 text-black">No Allowances found.</p>}
                                </div>
                                <Modal
                                    visible={showModal}
                                    footer={false}
                                    onCancel={() => this.setState({showModal: false})}
                                >
                                    <Allowance
                                        companyId={companyId}
                                        allowanceId={allowanceId}
                                        close={() => this.setState({showModal: false})}
                                    />
                                </Modal>
                            </React.Fragment>
                        );

                    }}
                </Query>
            </div>
        );

    }

}

export default withRouter(EmployeeAllowanceSettings);