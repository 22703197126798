import React from 'react';
import { RefreshCw } from 'react-feather';
import { withRouter } from 'react-router-dom';
import { Button, Input, InputNumber, Select, Breadcrumb, TreeSelect, Table, DatePicker } from 'antd';
import { Textarea, Label, Button as EverButton, Spinner } from 'evergreen-ui';
import { keys, keyBy, last, sumBy, includes } from 'lodash';
import { currencyFormat } from 'mout/number';
import gql from 'graphql-tag';
import moment from 'moment';
import { convert, buildTree } from 'react-sortly';

import {Query, Mutation, Subscription} from 'react-apollo';
import { CurrencyFormat, CurrencyWrapper } from '../../services/Currency';



const dateFormat = 'DD/MM/YYYY';

const dateFormatList = [ 'DD/MM/YYYY', 'DD/MM/YY' ];

const groups = {
	1: {
		name: 'Assets'
	},
	2: {
		name: 'Liabilities'
	},
	3: {
		name: 'Income'
	},
	4: {
		name: 'Expenses'
	},
	5: {
		name: 'Equity'
	}
};

const GET_ACCOUNTS = gql`
	query($company_id: Int!) {
		accounts: ledgers(
			where: {
				company_id: { _eq: $company_id }
				_or: [
					{
						prefix: {
							_in: ["cash-and-bank", "sales", "cos", "other-income", "trade-receivables", "suppliers", "other-suppliers"]
						}
					}
					{ _and: [{ group_id: { _eq: 4 } }, { parent_id: { _is_null: true } }] }
				]
			}
		) {
			id
			name
			group_id
			prefix
			parentId: parent_id
			children {
				id
				name
				group_id
				prefix
				parentId: parent_id
				children {
					id
					name
					group_id
					prefix
					parentId: parent_id
					children {
						id
						name
						group_id
						prefix
						parentId: parent_id
						children {
							id
							name
							group_id
							prefix
							parentId: parent_id
						}
					}
				}
			}
		}
		_accounts: ledgers(where: { company_id: { _eq: $company_id } }) {
			archived
			company_id
			currency
			description
			id
			is_default
			group_id
			name
			prefix
			parentId: parent_id
		}
	}
`;

const GET_TRANSACTION = gql`
    query fetch(
        $company_id: Int!,
        $user_id: Int,
        $account_id: Int,
        $from_date: date,
        $to_date: date,
        $text: String
    ) {

        transactions: ledger_transactions(where: {
            company_id: {_eq: $company_id},
            ledger_id: {_eq: $account_id},
            description: {_ilike: $text},
            date: {
                _gte: $from_date,
                _lte: $to_date
            }
        }, order_by: {created_at: desc}) {
            id
            date
            debit
            credit
            cheque_no
            accounts
            meta
            ledger_id
            description
        }

    }
`;

const ADD_MUTATION = gql`
	mutation add(
		$payee: String!
		$date: date!
		$user_id: Int!
		$company_id: Int!
		$transactions: [ledger_transactions_insert_input!]!
	) {
		insert_ledger_journals(
			objects: {
				payee: $payee
				date: $date
				user_id: $user_id
				company_id: $company_id
				transactions: { data: $transactions }
			}
		) {
			returning {
				id
			}
		}
	}
`;



class Transactions extends React.Component{

    state = {
        companyId: this.props.companyId,
        accounts: this.props.accounts,
        _accounts: keyBy(this.props._accounts, 'id'),
        loading: false,
        showAdd: false,
        filter: {
            from_date: moment().format('YYYY-MM-DD'),
            to_date: moment().format('YYYY-MM-DD')
        },
        journal: {
            payee: '',
            date: moment().format(dateFormat),
            cheque: '',
            credit: {
                account: '',
                description: '',
                amount: 0.00
            },
            debit: [{
                account: '',
                amount: '',
                description: ''
            }]
        }
    };

    columns = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
          render: (date) => <span className="font-bold">{moment(date, 'YYYY-MM-DD').format('MMM DD, YYYY')}</span>
        },
        {
            title: 'Account',
            render: (t) => {

                const a = this.state._accounts[t.ledger_id];

                return (
                    a ? a.name: null
                )

            }
        },
        {
          title: 'Type',
          render: (t) => <span className="font-bold">{(t.credit) ? "CREDIT": "DEBIT"}</span>
        },
        {
            title: 'Description',
            render: (t) => <div>
                {t.description.split('\n').map((_p, index) => {

                    return (
                        <p key={index}>{_p}</p>
                    )

                })}
            </div>
        },
        {
          title: 'Amount',
          render: (t) => <span className="font-bold">
              <CurrencyFormat
                amount={(t.credit) ? t.credit: t.debit}
              />
          </span>
        }
    ];

    buildAccount = (account, parents) => {

        const key = [...parents, account.id];

        return ({
            ...account,
            title: account.name,
            key: account.id,
            value: account.id,
            key: key.join('-'),
            value: key.join('-'),
            disabled: account.children.length ? true: false,
            children: account.children.length ? account.children.map((account) => this.buildAccount(account, key)): []
        })

    }

    componentDidMount() {

        const _groups = keys(groups);
        const _accounts = this.props.accounts.map((a) => ({...a, title: a.name, key: a.id, value: a.id}));

        // const accounts = _groups.map((key) => {

        //     return {
        //         title: groups[key].name,
        //         id: key,
        //         key,
        //         value: key,
        //         disabled: true,
        //         children: _accounts.filter((a) => parseInt(a.group_id) === parseInt(key)).map((account) => this.buildAccount(account, [key]))
        //     }

        // });

        // console.log(_accounts.map((account) => this.buildAccount(account, [account.group_id])));

        this.setState({
            accounts: _accounts.map((account) => this.buildAccount(account, account.parentId ? [account.group_id, account.parentId] : [account.group_id]))
        });

    }

    organizeAccounts = () => {

        const {journal} = this.state;

        const _groups = keys(groups);
        const _accounts = buildTree(convert(this.props.accounts.map((a) => ({...a, title: a.name, key: a.id, value: a.id}))));

        const accounts = _groups.map((key) => {

            return {
                title: groups[key].name,
                id: key,
                key,
                value: key,
                disabled: true,
                children: _accounts.filter((a) => parseInt(a.group_id) === parseInt(key)).map((account) => this.buildAccount(account, [key]))
            }

        });

        this.setState({
            accounts
        });

    }


    filterCreditAccounts = (a) => {

        let {journal} = this.state;
        const voucher_type = journal.voucher_type;

        if (voucher_type === 'payments') {

            return a.prefix === 'cash-and-bank';
            
        } else if (voucher_type === 'cash-receipt') {

            return includes(['suppliers', 'trade-receivables'], a.prefix);

        } else if (voucher_type === 'transfers') {

            return a.prefix === 'cash-and-bank';

        } else if (voucher_type === 'suppliers') {

            return includes(["suppliers", "other-suppliers"], a.prefix);

        } else if (voucher_type === 'sales') {

            return includes(["sales", "other-income"], a.prefix);

        } else {

            return a.id;

        }

    } 

    filterDebitAccount = (a) => {

        let {journal} = this.state;
        const voucher_type = journal.voucher_type;

        if (voucher_type === 'payments') {

            return a.prefix !== 'cash-and-bank';
            
        } else if (voucher_type === 'cash-receipt') {

            return a.prefix === 'cash-and-bank';

        } else if (voucher_type === 'transfers') {

            return a.prefix === 'cash-and-bank';

        } else if (voucher_type === 'suppliers') {

            return a.group_id === 4;

        } else if (voucher_type === 'sales') {

            return includes(["trade-receivables", 'cash-and-bank'], a.prefix);

        } else {

            return a.id;

        }

    }


    render(){

        let {loading, accounts, journal, filter} = this.state;
        const {company, user} = this.props;

        return (
            <div className="h-full">
                <div className="flex h-full justify-between overflow-hidden">
                <Query
                    query={GET_TRANSACTION}
                    fetchPolicy="network-only"
                    variables={{
                        company_id: company.id,
                        user_id: user.id,
                        from_date: filter.from_date,
                        to_date: filter.to_date,
                        text: filter.text ? filter.text :null,
                        account_id: filter.account_id ? filter.account_id: null
                    }}
                >
                    {({loading, data, error, refetch}) => {

                        return (
                            <div className="overflow-scroll h-full flex-1 p-6">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <h1 className="font-bold text-4xl mr-2">
                                            Transactions
                                        </h1>
                                        {loading ? null :<button className="ml-4 focus:outline-none text-black" onClick={() => refetch()}>
                                            <RefreshCw size={24} />
                                        </button>}
                                    </div>
                                    {/* {!showAdd ? <div className="">
                                        <Button type="primary" onClick={() => this.setState({showAdd: !showAdd})} shape="round" ghost icon="plus" className="flex items-center justify-center">Add</Button>
                                    </div>: null} */}
                                </div>

                                <div className="flex items-center my-4 -mx-2">
                                    <div className="px-2 flex-1">
                                        <Input.Search
                                            placeholder="Search Transaction"
                                            onChange={(e) => this.setState({
                                                filter: {...filter, text: e.target.value}
                                            })}
                                        />
                                    </div>
                                    <div className="px-2 w-64">
                                        <TreeSelect
                                            showSearch
                                            allowClear
                                            treeNodeFilterProp="title"
                                            style={{ width: '100%' }}
                                            // value={item.menuId}
                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            treeData={accounts}
                                            placeholder="Filter by account"
                                            treeDefaultExpandAll
                                            onChange={(account) => {

                                                if (!account) {
                                                    
                                                    return this.setState({
                                                        filter: {...filter, account_id: null}
                                                    });

                                                }

                                                const splif = account.split('-');

                                                const account_id = splif[splif.length - 1];

                                                this.setState({
                                                    filter: {...filter, account_id}
                                                });

                                            }}
                                        />
                                    </div>
                                    <div className="px-2">
                                        <DatePicker.RangePicker
                                            defaultValue={[moment(filter.from_date, 'YYYY-MM-DD'), moment(filter.to_date, 'YYYY-MM-DD')]}
                                            format={dateFormat}
                                            onChange={(date) => {

                                                // console.log(date, dateString);
                
                                                this.setState({
                                                    filter: {
                                                        ...filter,
                                                        from_date: date[0].format('YYYY-MM-DD'),
                                                        to_date: date[1].format('YYYY-MM-DD')
                                                    }
                                                });
                
                                            }}
                                        />
                                    </div>
                                </div>

                                {data && !loading ? <Table
                                    size="medium"
                                    dataSource={data.transactions}
                                    columns={this.columns}
                                    rowKey={(r) => r.id}
                                    pagination={false}
                                />: <div className="flex h-full items-center justify-center py-10">
                                    <Spinner />
                                </div>}
                                
                        
                            </div>
                            )

                        }}
                    </Query>
                    <div className="flex flex-col justify-between bg-gray-100 h-full max-w-sm w-full overflow-hidden">
                        <div className=" border-b py-6 px-6 bg-gray-200">
                            <Label>Voucher Type</Label>
                            <Select style={{
                                width: '100%'
                            }} value={journal.voucher_type} onChange={(voucher_type) => this.setState({journal: {...journal, voucher_type}})}>
                                <Select.Option value="cash-receipt">Cash Receipt Voucher</Select.Option>
                                <Select.Option value="payments">Payments</Select.Option>
                                <Select.Option value="transfers">Transfers</Select.Option>
                                <Select.Option value="suppliers">Supplier Vouchers</Select.Option>
                                <Select.Option value="sales">Sales Vouchers</Select.Option>
                            </Select>
                            {!journal.voucher_type ? <p className="">Kindly select a voucher type.</p>: null}
                        </div>
                        <div className="overflow-scroll flex-1 px-6 py-6">
                            {/* <Button type="primary" onClick={() => this.setState({showAdd: false})} shape="round" ghost icon="close" className="mx-auto flex items-center justify-center">Close</Button> */}                            
                            {!journal.voucher_type ? null : <React.Fragment>
                                <div className="my-4">
                                    <Label>Payee</Label>
                                    <div className="">
                                        <Input value={journal.payee} style={{width: '100%'}} onChange={(e) => this.setState({
                                            journal: {...journal, payee: e.target.value}
                                        })} placeholder="Enter Payee" />
                                    </div>
                                </div>
                                <div className="flex -mx-2 my-4">
                                    <div className="flex-1 px-2">
                                        <Label>{journal.voucher_type == 'suppliers' ? 'Invoice No,' : 'Cheque/Transaction No.'}</Label>
                                        <div className="">
                                            <Input value={journal.cheque} style={{width: '100%'}} onChange={(e) => this.setState({
                                                journal: {...journal, cheque: e.target.value}
                                            })} placeholder={journal.voucher_type == 'suppliers' ? "Enter Invoice No." : "Enter Cheque or Transaction No."} />
                                        </div>
                                    </div>
                                    <div className="flex-1 px-2">
                                        <Label>Date</Label>
                                        <div className="">
                                            <DatePicker onChange={(date, dateString) => this.setState({
                                                journal: {
                                                    ...journal,
                                                    date: dateString
                                                }
                                            })} value={moment(journal.date, dateFormat)} format={dateFormat} style={{width: '100%'}} />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-4 mt-8">
                                    <p className="font-bold text-black text-lg my-4">Debit accounts</p>
                                    {journal.debit.map((d, i) => {

                                        return (
                                            <div className="border-b pb-4" key={i}>
                                                <div className="flex items-center justify-between">
                                                    <p className="font-bold text-black my-2">
                                                        Debit #{i + 1}
                                                    </p>
                                                    {journal.debit.length > 1 ? <Button onClick={() => {

                                                        journal.debit.splice(i, 1);

                                                        this.setState({
                                                            journal: {...journal}
                                                        });

                                                    }} className="flex items-center justify-center" type="danger" size="small" ghost icon="delete" shape="circle" />: null}
                                                </div>
                                                <div className="flex items-center -mx-2">
                                                    <div className="px-2 w-1/2">
                                                        <Label>Debit Account</Label>
                                                        <TreeSelect
                                                            showSearch
                                                            allowClear
                                                            treeNodeFilterProp="title"
                                                            style={{ width: '100%' }}
                                                            value={journal.debit[i].account || null}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            treeData={accounts.filter(this.filterDebitAccount)}
                                                            placeholder="Select Debit Account"
                                                            treeDefaultExpandAll
                                                            onChange={(account) => {

                                                                journal.debit[i].account = account;

                                                                this.setState({journal});

                                                            }}
                                                        />
                                                    </div>
                                                    <div className="px-2 w-1/2">
                                                        <Label>Amount</Label>
                                                        <CurrencyWrapper
                                                            component={({symbol}) => (
                                                                <InputNumber
                                                                    onClick={(e) => e.target.select()}
                                                                    style={{width: '100%'}}
                                                                    placeholder=""
                                                                    value={journal.debit[i].amount || 0.00}
                                                                    onChange={(amount) => {

                                                                        journal.debit[i].amount = amount;

                                                                        this.setState({journal});

                                                                    }}
                                                                    parser={value => value.replace(/[^0-9.]/g, '')}
                                                                    formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="my-2">
                                                    <Label
                                                        marginBottom={4}
                                                        display="block"
                                                    >
                                                        Description
                                                    </Label>
                                                    <Input.TextArea
                                                        size="small"
                                                        placeholder="Enter Description"
                                                        value={journal.debit[i].description || ""}
                                                        autosize={{ minRows: 2, maxRows: 6 }}
                                                        onChange={(e) => {

                                                            journal.debit[i].description = e.target.value;

                                                            this.setState({journal});

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )

                                    })}
                                    <div className="my-4 mb-12">
                                        <Button block ghost type="primary" onClick={() => this.setState({
                                            journal: {
                                                ...journal,
                                                debit: [...journal.debit, {account: '', amount: 0.00, description: ''}]
                                            }
                                        })}>Add</Button>
                                    </div>
                                </div>
                                <div className="my-4">
                                    <Label>Credit Account</Label>
                                    <div className="">
                                        <TreeSelect
                                            showSearch
                                            allowClear
                                            treeNodeFilterProp="title"
                                            style={{ width: '100%' }}
                                            value={journal.credit.account}
                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            treeData={accounts.filter(this.filterCreditAccounts)}
                                            placeholder="Select Credit Account"
                                            treeDefaultExpandAll
                                            onChange={(account) => {

                                                journal.credit.account = account;

                                                this.setState({journal});

                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className="py-2">
                                        <Label>Credit Amount</Label>
                                        <CurrencyWrapper
                                            component={({symbol}) => (
                                                <InputNumber
                                                    onClick={(e) => e.target.select()}
                                                    size="large"
                                                    style={{width: '100%'}}
                                                    placeholder=""
                                                    value={journal.credit.amount}
                                                    parser={value => value.replace(/[^0-9.]/g, '')}
                                                    formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    onChange={(amount) => {

                                                        journal.credit.amount = amount;

                                                        this.setState({journal});

                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>}
                        </div>
                        <div className="p-4">
                            <Mutation
                                mutation={ADD_MUTATION}
                                onCompleted={(d) => {
                                    
                                    this.setState({
                                        journal: {
                                            payee: '',
                                            date: moment().format(dateFormat),
                                            cheque: '',
                                            credit: {
                                                account: '',
                                                description: '',
                                                amount: 0.00
                                            },
                                            debit: [{
                                                account: '',
                                                amount: '',
                                                description: ''
                                            }]
                                        }
                                    });

                                }}
                                variables={{
                                    payee: journal.payee,
                                    date: moment(journal.date, dateFormat).format('YYYY-MM-DD'),
                                    user_id: user.id,
                                    company_id: company.id,
                                    transactions: [{
                                        date: moment(journal.date, dateFormat).format('YYYY-MM-DD'),
                                        cheque_no: journal.cheque,
                                        credit: journal.credit.amount,
                                        description: journal.debit.map((d, i) => {

                                            return `${i + 1}. ${d.description} - ${company.currency.symbol} ${currencyFormat(d.amount)}`

                                        }).join('\n'),
                                        ledger_id: parseInt(last(journal.credit.account.split('-'))),
                                        user_id: user.id,
                                        company_id: company.id,
                                        accounts: journal.credit.account.split('-').map((i) => (parseInt(i)))
                                    }, ...journal.debit.map((d) => {

                                        return {
                                            date: moment(journal.date, dateFormat).format('YYYY-MM-DD'),
                                            cheque_no: journal.cheque,
                                            debit: d.amount,
                                            description: d.description,
                                            ledger_id: parseInt(last(d.account.split('-'))),
                                            user_id: user.id,
                                            company_id: company.id,
                                            accounts: d.account.split('-').map((i) => (parseInt(i)))
                                        };

                                    })]
                                }}
                            >
                                    {(add, {loading}) => {

                                        return (
                                            <EverButton
                                                isLoading={loading}
                                                disabled={!journal.voucher_type || journal.credit.amount === 0 || journal.credit.amount !== sumBy(journal.debit, (d) => d.amount)}
                                                onClick={() => add()}
                                                height={45} width="100%"
                                                justifyContent="center"
                                                alignItems="center"
                                                appearance="primary"
                                                intent="success"
                                            >
                                                Save
                                            </EverButton>
                                        )

                                    }}
                            </Mutation>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

}

const TransactionsWrapper = withRouter(Transactions);

class TransactionsContainer extends React.Component {
	state = {
		companyId: this.props.match.params.companyId
	};

	render() {
		const { companyId } = this.state;

		return (
			<Query
				query={GET_ACCOUNTS}
				variables={{
					company_id: companyId
				}}
			>
				{({ loading, data, error }) => {
					return !loading && data && data.accounts ? (
						<TransactionsWrapper
							companyId={companyId}
							user={this.props.user}
							company={this.props.company}
							accounts={data.accounts}
							_accounts={data._accounts}
						/>
					) : (
						<div className="flex h-full items-center justify-center py-10">
							<Spinner />
						</div>
					);
				}}
			</Query>
		);
	}
}

export default TransactionsContainer;
