import { schemaMigrations, unsafeExecuteSql, addColumns } from '@nozbe/watermelondb/Schema/migrations';

export default schemaMigrations({
    migrations: [
        {
            toVersion: 2,
            steps: [
                addColumns({
                    table: 'products',
                    columns: [
                        { name: 'raw_material', type: 'boolean' }
                    ]
                })
            ]
        }
    ]
});