import React from 'react';
import { Popover, Position, Menu } from 'evergreen-ui';
import { MoreHorizontal } from 'react-feather';
import { withRouter, Link } from 'react-router-dom';
import { PermissionView } from '../../services/Permission';


class POS extends React.Component {

    render() {

        const { currentCompany, company } = this.props;

        // console.log(this.props);

        return (

            <ul className="list-reset flex items-center -mx-4">
                <PermissionView allowed={["admin", "pos-inventory-manager", "pos-inventory-officer", "pos-audit-manager"]}>
                    <li>
                        <Popover
                            position={Position.BOTTOM_LEFT}
                            content={
                                ({ close }) => {

                                    return <Menu>
                                        <PermissionView allowed={["admin", "pos-inventory-manager", "pos-audit-manager"]}>
                                            <Menu.Item
                                                onSelect={() => {

                                                    close();

                                                    this.props.history.push(`/${currentCompany}/pos/categories`);

                                                }}
                                            >
                                                Categories
                                            </Menu.Item>
                                        </PermissionView>
                                        <PermissionView allowed={["admin", "pos-inventory-manager", "pos-inventory-officer", "pos-audit-manager"]}>
                                            <Menu.Divider />
                                            <Menu.Item
                                                onSelect={() => {

                                                    close();

                                                    this.props.history.push(`/${currentCompany}/pos/stocks`);

                                                }}
                                            >
                                                Stock Control
                                            </Menu.Item>
                                        </PermissionView>
                                        <PermissionView allowed={["admin", "pos-inventory-manager", "pos-audit-manager"]}>
                                            <Menu.Divider />
                                            <Menu.Item
                                                onSelect={() => {

                                                    close();

                                                    this.props.history.push(`/${currentCompany}/pos/products`);

                                                }}
                                            >
                                                Products
                                            </Menu.Item>
                                        </PermissionView>
                                        <PermissionView allowed={["admin", "pos-inventory-manager", "pos-audit-manager"]}>
                                            <Menu.Divider />
                                            <Menu.Item
                                                onSelect={() => {

                                                    close();

                                                    this.props.history.push(`/${currentCompany}/pos/products/add-multiple`);

                                                }}
                                            >
                                                Add Multiple Products
                                            </Menu.Item>
                                        </PermissionView>
                                        {/* <Menu.Divider />
                                        <Menu.Item
                                            onSelect={() => {

                                                close();

                                                this.props.history.push(`/${currentCompany}/pos/raw-materials`);

                                            }}
                                        >
                                            Raw Materials
                                        </Menu.Item> */}
                                    </Menu>
                                }
                            }
                        >
                            {({ getRef, toggle, isShown }) => (

                                <button ref={getRef} onClick={toggle} className="font-medium px-4 flex flex-col items-center active:outline-none focus:outline-none">
                                    Inventory
                                </button>

                            )}
                        </Popover>
                    </li>
                </PermissionView>
                <PermissionView allowed={["admin", "pos-inventory-manager"]}>
                    <li>
                        <Popover
                            position={Position.BOTTOM_RIGHT}
                            content={
                                ({ close }) => {

                                    return <Menu>
                                        {/* <Menu.Item
                                            onSelect={() => {

                                                close();

                                                this.props.history.push(`/${currentCompany}/pos/raw-material/new`);

                                            }}
                                        >
                                            New Raw Material
                                        </Menu.Item>
                                        <Menu.Divider /> */}
                                        <Menu.Item
                                            onSelect={() => {

                                                close();

                                                this.props.history.push(`/${currentCompany}/pos/purchase-order/new`);

                                            }}
                                        >
                                            New Purchase Order
                                        </Menu.Item>
                                        {/* <Menu.Item
                                            onSelect={() => {

                                                close();

                                                this.props.history.push(`/${currentCompany}/pos/purchase-orders`);

                                            }}
                                        >
                                            Purchase Orders
                                        </Menu.Item> */}
                                    </Menu>
                                }
                            }
                        >
                            {({ getRef, toggle, isShown }) => (

                                <button ref={getRef} onClick={toggle} className="font-medium px-4 flex flex-col items-center active:outline-none focus:outline-none">
                                    Procument
                                </button>

                            )}
                        </Popover>
                    </li>
                </PermissionView>
                <PermissionView allowed={["admin", "pos-inventory-manager", "pos-inventory-officer", "pos-sales-supervisor"]}>
                    <li>
                        <Link to={`/${currentCompany}/pos/goods-received`} className="px-4 font-medium">Goods Received Note</Link>
                    </li>
                </PermissionView>
                {/* <li>
                    <Link to={`/${currentCompany}/pos/reports/generate`} className="px-4 font-medium">Reports</Link>
                </li> */}
                {/* <li>
                    <Popover
                        position={Position.BOTTOM_RIGHT}
                        content={
                            ({close}) => {

                                return <Menu>
                                    <Menu.Item
                                        onSelect={() => {

                                            close();

                                            this.props.history.push(`/${currentCompany}/pos/trends/products`);

                                        }}
                                    >
                                        Product Trends
                                    </Menu.Item>
                                    <Menu.Item
                                        onSelect={() => {

                                            close();

                                            this.props.history.push(`/${currentCompany}/pos/trends/sales`);

                                        }}
                                    >
                                        Sales Trends
                                    </Menu.Item>
                                    <Menu.Item
                                        onSelect={() => {

                                            close();

                                            this.props.history.push(`/${currentCompany}/pos/trends/supplier`);

                                        }}
                                    >
                                        Supplier Trends
                                    </Menu.Item>
                                    <Menu.Item
                                        onSelect={() => {

                                            close();

                                            this.props.history.push(`/${currentCompany}/pos/trends/purchases`);

                                        }}
                                    >
                                        Purchase Trends
                                    </Menu.Item>
                                    <Menu.Item
                                        onSelect={() => {

                                            close();

                                            this.props.history.push(`/${currentCompany}/pos/trends/payments`);

                                        }}
                                    >
                                        Payments Trends
                                    </Menu.Item>
                                </Menu>
                            }
                        }
                    >
                        {({getRef, toggle, isShown}) => (

                            <button ref={getRef} onClick={toggle} className="font-medium px-4 flex flex-col items-center active:outline-none focus:outline-none">
                                Trends
                            </button>

                        )}
                    </Popover>
                </li> */}
            </ul>
        )

    }

}

export default withRouter(POS);