import React from 'react';
import { TextInputField, FormField, Button, Spinner, BackButton } from 'evergreen-ui';
import { InputNumber, Select } from 'antd';

import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { CurrencyFormat, CurrencyWrapper } from '../services/Currency';

const GET_AGGREGATOR = gql`
	query($company_id: Int!, $aggregator_id: Int!) {
		aggregators: employee_aggregators(where: { company_id: { _eq: $company_id }, id: { _eq: $aggregator_id } }) {
			id
			name
			unit
			rate
			qty
		}
	}
`;

const CREATE_MUTATION = gql`
	mutation($aggregator_id: Int, $company_id: Int!, $name: String!, $unit: String!, $rate: float8!, $qty: Int!) {
		insert_employee_aggregators(
			objects: { company_id: $company_id, name: $name, unit: $unit, rate: $rate, qty: $qty }
		) {
			returning {
				id
			}
		}
	}
`;

const UPDATE_MUTATION = gql`
	mutation($aggregator_id: Int, $company_id: Int!, $name: String!, $unit: String!, $rate: float8!, $qty: Int!) {
		update_employee_aggregators(
			where: { id: { _eq: $aggregator_id }, company_id: { _eq: $company_id } }
			_set: { name: $name, unit: $unit, rate: $rate, qty: $qty }
		) {
			affected_rows
		}
	}
`;

class Aggregator extends React.Component {
	state = {
		companyId: this.props.companyId,
		aggregatorId: this.props.aggregatorId,
		name: this.props.aggregator.name || '',
		unit: this.props.aggregator.unit || 'pcs',
		rate: this.props.aggregator.rate || 1.0,
		qty: this.props.aggregator.qty || 1
	};

	render() {
		const { name, unit, rate, qty, aggregatorId, loading, companyId } = this.state;

		return (
			<div className="max-w-lg w-full mx-auto my-12">
				{/* <BackButton appearance="default" marginBottom={15} onClick={()=>{

                    this.props.history.push(`/${companyId}/hr/aggregators/settings`);

                }} children="Back to aggregators" /> */}
                <div className="p-8">
                    {loading ? <div className="flex items-center justify-center py-10">
                        <Spinner />
                    </div> : <div className="">
                        <p className="text-black text-lg font-bold">{aggregatorId ? 'Edit' : 'Create'} Aggregator</p>
                        <div className="mt-4">
                            <TextInputField label="Aggregator Name" defaultValue={name} onChange={(e) => {

                                this.setState({
                                    name: e.target.value
                                });

                            }} placeholder="Aggregator Name" />
                        </div>
                        <div className="flex my-4 -mx-4">
                            <div className="flex-1 px-4">
                                <FormField isRequired={true} label="Rate" />
                                <CurrencyWrapper component={({symbol}) => (
                                    <InputNumber style={{width: '100%'}}
                                        min={1}
                                        defaultValue={rate}
                                        formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/[^0-9.]/g, '')}
                                        onChange={(rate) => this.setState({rate})}
                                    />
                                )} />
                                
                            </div>
                            <div className="flex-1 px-4">
                                <FormField isRequired={true} label="Unit" />
                                <div className="flex items-center">
                                    <InputNumber className="mr-2" style={{width: '100%'}}
                                        min={1}
                                        defaultValue={qty}
                                        onChange={(qty) => this.setState({qty})}
                                    />
                                    <Select className="" style={{width: '100%'}} defaultValue={unit} onChange={(unit) => this.setState({unit})}>
                                        <Select.Option value="cm">cm</Select.Option>
                                        <Select.Option value="m">m</Select.Option>
                                        <Select.Option value="pcs">pcs</Select.Option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <p className="font-bold text-black mb-4">{qty} {unit.toUpperCase()} / <CurrencyFormat amount={rate} /></p>
                        <Mutation
                            mutation={aggregatorId ? UPDATE_MUTATION : CREATE_MUTATION}
                            onCompleted={() => this.props.close()}
                            variables={{
                                aggregator_id: aggregatorId,
                                company_id: companyId,
                                name,
                                unit,
                                rate,
                                qty
                            }}
                        >
                            {(save, {loading}) => {

                                return (
                                    <Button appearance="primary" intent="success" isLoading={loading} onClick={() => save()}>Save</Button>
                                )

                            }}
                        </Mutation>
                    </div>}
                </div>
            </div>

        );

    }

}

class AggregatorWrapper extends React.Component {
	state = {
		companyId: this.props.companyId,
		aggregatorId: this.props.aggregatorId
	};

	render() {
		const { companyId, aggregatorId } = this.state;

		return (
			<Query
				query={GET_AGGREGATOR}
				variables={{
					company_id: companyId,
					aggregator_id: aggregatorId
				}}
				skip={!aggregatorId}
			>
				{({ data, error, loading }) => {
					// console.log(data);

					return !loading ? (
						<Aggregator
							{...this.props}
							aggregatorId={aggregatorId}
							aggregator={aggregatorId && data ? data.aggregators[0] : {}}
						/>
					) : (
						<div className="flex items-center justify-center py-10">
							<Spinner />
						</div>
					);
				}}
			</Query>
		);
	}
}

export default AggregatorWrapper;
