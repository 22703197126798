import React, { useEffect } from 'react';
import {Select} from 'antd';
import {keyBy, map} from 'lodash';
import { withDatabase } from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from "@nozbe/with-observables";
import { Q } from '@nozbe/watermelondb'
import {of as $of} from 'rxjs';

export default withDatabase(withObservables(['customerSearch'], ({ companyId, customerSearch, database }) => ({
    customers: customerSearch ? database.collections.get('customers').query(
        Q.where('company_id', Q.eq(parseInt(companyId))),
        Q.where('name', Q.like(`%${customerSearch}%`))
    ).observe(): $of([])
}))(({
    customerName,
    customers,
    customerSearch,
    setState
}) => {

    return (
        <Select
            showSearch
            value={customerSearch}
            placeholder="Search for customer"
            style={{width: '100%'}}
            showArrow={false}
            allowClear
            filterOption={false}
            onSearch={(customerSearch) => setState({customerSearch})}
            value={customerName}
            onChange={(value) => {

                if (!value) {

                    return setState({
                        customer_name: null,
                        customer_id: null,
                        customer_ledger: null
                    });

                }

                const c = JSON.parse(value);

                setState({
                    customer_name: c.name,
                    customer_id: c.id,
                    customer_ledger: c.ledger_id
                });

                // if (value) {

                //     this.fetchVariant(value);

                // }


            }}
            // notFoundContent={null}
        >
            {map(customers, '_raw').map(d => {


                return (
                    <Select.Option key={d.id} value={JSON.stringify(d)}>
                        {d.name}
                    </Select.Option>
                )
                
            })}
        </Select>
    );

}));