import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { DatePicker } from 'antd';
import { map } from 'lodash';
import { Spinner, Button, Badge } from 'evergreen-ui';
import moment from 'moment';
import { currencyFormat } from 'mout/number';
import Trend from 'react-trend';
import {CurrencyFormat} from '../../services/Currency';

import Chart from "react-apexcharts";

const dateFormat = 'YYYY-MM-DD';

const GET_OUTLETS = gql`
    query($company_id: Int!) {

        outlets (where: {
            company_id: {_eq: $company_id}
        }, order_by: {orders_aggregate: {sum: {total_amt: desc}}}) {
            id
            name
            warehouse
            company_id

            stock_balance: stock_balance_aggregate {
                aggregate {
                  sum {
                    qty
                    total
                  }
                }
            }
        }

    }
`;

const GET_SALES = gql`
    query(
        $outlet_id: Int!,
        $from_date: timestamptz,
        $to_date: timestamptz
    ) {
        aggregate: order_payments_aggregate(where: {
            outlet_id: {_eq: $outlet_id},
            created_at: {
                _gte: $from_date,
                _lte: $to_date
            }
        }) {
            aggregate {
                sum {
                    value
                }
            }
        }
        momo :order_payments_aggregate(where:{
            type:{_eq: "momo"},
            created_at: {
                _gte: $from_date,
                _lte: $to_date
            }
            outlet_id: {_eq: $outlet_id}
        }){
            aggregate{
                sum{
                    value
                }
            }
        }
        cash :order_payments_aggregate(where:{
            type:{_eq: "cash"},
            created_at: {
                _gte: $from_date,
                _lte: $to_date
            }
            outlet_id: {_eq: $outlet_id}
        }){
            aggregate{
                sum{
                    value
                }
            }
        }
        card :order_payments_aggregate(where:{
            type:{_eq: "card"},
            created_at: {
                _gte: $from_date,
                _lte: $to_date
            }
            outlet_id: {_eq: $outlet_id}
        }){
            aggregate{
                sum{
                    value
                }
            }
        }
        outlet: outlets_by_pk(id: $outlet_id) {
            hourly_sales(where:{
                created_at: {
                    _gte: $from_date,
                    _lte: $to_date
                }
            }) {
                created_at
                total
            }
        }
    }  
`;

const GET_SALES_ALL = gql`
    query(
        $company_id: Int!,
        $from_date: timestamptz,
        $to_date: timestamptz
    ) {
        aggregate: order_payments_aggregate(where: {
            company_id: {_eq: $company_id},
            created_at: {
                _gte: $from_date,
                _lte: $to_date
            }
        }) {
            aggregate {
                sum {
                    value
                }
            }
        }
        momo :order_payments_aggregate(where:{
            type:{_eq: "momo"},
            created_at: {
                _gte: $from_date,
                _lte: $to_date
            }
            company_id: {_eq: $company_id}
        }){
            aggregate{
                sum{
                    value
                }
            }
        }
        cash :order_payments_aggregate(where:{
            type:{_eq: "cash"},
            created_at: {
                _gte: $from_date,
                _lte: $to_date
            }
            company_id: {_eq: $company_id}
        }){
            aggregate{
                sum{
                    value
                }
            }
        }
        card :order_payments_aggregate(where:{
            type:{_eq: "card"},
            created_at: {
                _gte: $from_date,
                _lte: $to_date
            }
            company_id: {_eq: $company_id}
        }){
            aggregate{
                sum{
                    value
                }
            }
        }
        company: company_by_pk(id: $company_id) {
            hourly_sales(where:{
                created_at: {
                    _gte: $from_date,
                    _lte: $to_date
                }
            }) {
                created_at
                total
            }
        }
    }  
`;

class Outlet extends React.Component {


    render() {

        const {outlet, from_date, to_date} = this.props;

        return (
            <div className="bg-white rounded-lg shadow">
                <div className="flex flex-col mt-2 realtive p-4">
                    {outlet.warehouse ? <div className="absolute" style={{top: -15}}>
                        <Badge color="blue">WAREHOUSE</Badge>
                    </div>: null}
                    <p className="font-bold text-black text-2xl truncate">{outlet.name}</p>
                </div>
                <Query
                    query={GET_SALES}
                    variables={{
                        outlet_id: outlet.id,
                        from_date: moment(from_date, dateFormat).startOf('day').format(),
                        to_date: moment(to_date, dateFormat).endOf('day').format(),
                    }}
                >
                    {({data, error, loading, refetch}) => {

                        if (loading) {

                            return (
                                <Spinner />
                            )

                        }

                        if (error) {

                            return (


                                <Button onClick={() => refetch()}>Retry</Button>

                            )

                        }

                        // console.log(data);

                        if (!loading && !data) {

                            return;

                        }

                        const totals = data ? map(((data.outlet || {}).hourly_sales || []), 'total'): [0];

                        // console.log(totals);


                        return (
                            <React.Fragment>
                                <div className="my-2">
                                    <Chart
                                        options={{
                                            noData: {
                                                text: 'No Data Available',
                                                align: 'center',
                                                verticalAlign: 'middle',
                                                offsetX: 0,
                                                offsetY: 0,
                                                style: {
                                                  color: 'inherit',
                                                  fontSize: '14px',
                                                  fontFamily: 'inherit'
                                                }
                                            },
                                            chart: {
                                                type: "area",
                                                fontFamily: 'inherit',
                                                height: 40.0,
                                                sparkline: {
                                                    enabled: true
                                                },
                                                animations: {
                                                    enabled: false
                                                },
                                            },
                                            dataLabels: {
                                                enabled: false,
                                            },
                                            fill: {
                                                opacity: .16,
                                                type: 'solid'
                                            },
                                            stroke: {
                                                width: 2,
                                                lineCap: "round",
                                                curve: "smooth",
                                            },
                                            grid: {
                                                strokeDashArray: 4,
                                            },
                                            xaxis: {
                                                labels: {
                                                    padding: 0,
                                                },
                                                tooltip: {
                                                    enabled: false
                                                },
                                                axisBorder: {
                                                    show: false,
                                                },
                                                type: 'datetime',
                                            },
                                            yaxis: {
                                                labels: {
                                                    padding: 4
                                                },
                                            },
                                            labels: map(((data.outlet || {}).hourly_sales || []), 'created_at'),
                                            colors: ["#206bc4"],
                                            legend: {
                                                show: false,
                                            }
                                        }}
                                        series={[{
                                            name: "SALES",
                                            data: totals
                                        }]}
                                        height="200"
                                        type="area"
                                    />
                                    {/* <Trend
                                        smooth
                                        autoDraw
                                        autoDrawDuration={1000}
                                        autoDrawEasing="ease-out"
                                        data={totals.length ? totals.length > 1 ? [...totals]: [totals[0], ...totals]: [0, 0]}
                                        gradient={['#42b3f4']}
                                        radius={25}
                                        strokeWidth={3.2}
                                        strokeLinecap={'butt'}
                                    /> */}
                                </div>
                                <div className="font-bold text-black text-xl px-4">
                                    <CurrencyFormat amount={data.aggregate.aggregate.sum.value} />
                                </div>
                                <div className="pb-4 border-b px-4">
                                    <div className="border-b py-3 text-black flex justify-between items-center">
                                        <p className="">Cash</p>
                                        <p className="font-bold">
                                            <CurrencyFormat amount={data.cash.aggregate.sum.value || 0} />
                                        </p>
                                    </div>
                                    <div className="border-b py-3 text-black flex justify-between items-center">
                                        <p className="">MoMo</p>
                                        <p className="font-bold">
                                            <CurrencyFormat amount={data.momo.aggregate.sum.value || 0} />
                                        </p>
                                    </div>
                                    <div className="py-3 text-black flex justify-between items-center">
                                        <p className="">Card</p>
                                        <p className="font-bold">
                                            <CurrencyFormat amount={data.card.aggregate.sum.value || 0} />
                                        </p>
                                    </div>
                                </div>
                            </React.Fragment>
                        )

                    }}
                </Query>
                <div className="mt-2 px-4">
                    <div className="pt-2">
                        <div className="border-b py-3 text-black flex justify-between items-center">
                            <p className="">Stock Qty</p>
                            <p className="font-bold">{outlet.stock_balance.aggregate.sum.qty || 0}</p>
                        </div>
                        <div className="py-3 text-black flex justify-between items-center">
                            <p className="">Stock Value</p>
                            <p className="font-bold">
                                <CurrencyFormat amount={outlet.stock_balance.aggregate.sum.total || 0} />

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

}

class AllOutlets extends React.Component {

    render() {

        const {companyId, from_date, to_date} = this.props;

        return (
            <div className="bg-white rounded-lg shadow">
                <div className="mt-2 p-4">
                    <p className="font-bold text-black text-2xl">All Outlets</p>
                </div>
                <Query
                    query={GET_SALES_ALL}
                    variables={{
                        company_id: companyId,
                        from_date: moment(from_date, dateFormat).startOf('day').format(),
                        to_date: moment(to_date, dateFormat).endOf('day').format(),
                    }}
                >
                    {({data, error, loading, refetch}) => {

                        if (loading) {

                            return (
                                <Spinner />
                            )

                        }

                        if (error) {

                            return (


                                <Button onClick={() => refetch()}>Retry</Button>

                            )

                        }

                        if (!loading && !data) {

                            return;

                        }

                        const totals = data ? map(((data.company || {}).hourly_sales || []), 'total') : [0];

                        // console.log(totals);


                        return (
                            <React.Fragment>
                                <div className="my-2">
                                    <Chart
                                        options={{
                                            noData: {
                                                text: 'No Data Available',
                                                align: 'center',
                                                verticalAlign: 'middle',
                                                offsetX: 0,
                                                offsetY: 0,
                                                style: {
                                                  color: 'inherit',
                                                  fontSize: '14px',
                                                  fontFamily: 'inherit'
                                                }
                                            },
                                            chart: {
                                                type: "area",
                                                fontFamily: 'inherit',
                                                height: 40.0,
                                                sparkline: {
                                                    enabled: true
                                                },
                                                animations: {
                                                    enabled: false
                                                },
                                            },
                                            dataLabels: {
                                                enabled: false,
                                            },
                                            fill: {
                                                opacity: .16,
                                                type: 'solid'
                                            },
                                            stroke: {
                                                width: 2,
                                                lineCap: "round",
                                                curve: "smooth",
                                            },
                                            grid: {
                                                strokeDashArray: 4,
                                            },
                                            xaxis: {
                                                labels: {
                                                    padding: 0,
                                                },
                                                tooltip: {
                                                    enabled: false
                                                },
                                                axisBorder: {
                                                    show: false,
                                                },
                                                type: 'datetime',
                                            },
                                            yaxis: {
                                                labels: {
                                                    padding: 4
                                                },
                                            },
                                            labels: map(((data.company || {}).hourly_sales || []), 'created_at'),
                                            colors: ["#206bc4"],
                                            legend: {
                                                show: false,
                                            }
                                        }}
                                        series={[{
                                            name: "SALES",
                                            data: totals
                                        }]}
                                        type="area"
                                        height="200"
                                    />
                                    {/* <Trend
                                        // smooth
                                        autoDraw
                                        autoDrawDuration={1000}
                                        autoDrawEasing="ease-out"
                                        data={totals.length ? totals.length > 1 ? [...totals]: [totals[0], ...totals]: [0, 0]}
                                        gradient={['#42b3f4']}
                                        radius={25}
                                        strokeWidth={3.2}
                                        strokeLinecap={'butt'}
                                    /> */}
                                </div>
                                <div className="font-bold text-black text-xl px-4">
                                    <CurrencyFormat amount={data.aggregate.aggregate.sum.value || 0} />
                                </div>
                                <div className="pb-4 px-4">
                                    <div className="border-b py-3 text-black flex justify-between items-center">
                                        <p className="">Cash</p>
                                        <p className="font-bold">
                                            <CurrencyFormat amount={data.cash.aggregate.sum.value || 0} />
                                        </p>
                                    </div>
                                    <div className="border-b py-3 text-black flex justify-between items-center">
                                        <p className="">MoMo</p>
                                        <p className="font-bold">
                                            <CurrencyFormat amount={data.momo.aggregate.sum.value || 0} />
                                        </p>
                                    </div>
                                    <div className="py-3 text-black flex justify-between items-center">
                                        <p className="">Card</p>
                                        <p className="font-bold">
                                            <CurrencyFormat amount={data.card.aggregate.sum.value || 0} />
                                        </p>
                                    </div>
                                </div>
                            </React.Fragment>
                        )

                    }}
                </Query>
            </div>
        )

    }

}

class Home extends React.Component{

    state = {
        from_date: moment().format(dateFormat),
        to_date: moment().format(dateFormat)
    }

    render() {

        const companyId = this.props.match.params.companyId;
        const {from_date, to_date} = this.state;


        return (
            <div className="p-8 overflow-scroll h-full">
                <div className="flex flex-wrap -mx-4 mb-6">
                    <div className="w-1/4 px-4">
                        <p className="font-bold text-black">Total Sales between</p>
                        <DatePicker.RangePicker
                            size="large"
                            format="DD MMM, YYYY"
                            // disabledDate={() => false}
                            onChange={(date) => {

                                // console.log(date, dateString);

                                this.setState({
                                    from_date: date[0].format('YYYY-MM-DD'),
                                    to_date: date[1].format('YYYY-MM-DD')
                                });

                            }}
                            value={[moment(from_date, dateFormat), moment(to_date, dateFormat)]}
                            style={{width: '100%'}}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap -mx-4 mb-8">
                    <div className="w-1/4 px-4">
                        <AllOutlets
                            companyId={companyId}
                            from_date={from_date}
                            to_date={to_date}
                        />
                    </div>
                </div>
                <Query
                    query={GET_OUTLETS}
                    variables={{
                        company_id: companyId
                    }}
                >
                    {({data, error, loading, refetch}) => {

                        if (loading) {

                            return (
                                <Spinner />
                            )

                        }

                        if (error) {

                            return (


                                <Button onClick={() => refetch()}>Retry</Button>

                            )

                        }

                        return (

                            <div className="flex flex-wrap -mx-4">

                                {data.outlets.map((o, i) => {

                                    return (
                                        <div key={i} className="w-1/4 px-4 mt-4">
                                            <Outlet
                                                outlet={o}
                                                from_date={from_date}
                                                to_date={to_date}
                                            />
                                        </div>
                                    )

                                })}

                            </div>

                        )

                    }}
                </Query>
            </div>
        )

    }

}

export default Home;
