import React from 'react';
import { currencyFormat } from 'mout/number';
import { CurrencyWrapper } from '../Currency';
import { sumBy } from 'lodash';
import Barcode from 'react-barcode';
// import Logo64 from '../../images/logo-base64';

// const Logo = Logo64();

class Proforma extends React.Component {

    render() {

        const {
            id,
            local,
            companyName,
            companyLogo,
            companyEmail,
            companyPhone,
            date,
            shop,
            customer,
            salesperson,
            supervisor,
            items,
            total,
            discount,
            discount_amt,
            amt_due,
            tax,
            payments,
            phone,
            is_wholesale,
            reg_no
        } = this.props;

        const change = payments.filter((p) => p.title === 'Change');
        const _payments = payments.filter((p) => p.title !== 'Change');

        return (
            <div className="px-1 text-black">
                <div className="text-center">
                    {companyLogo ? <img className="w-32 mx-auto" src={`${companyLogo}`} alt="" /> :
                        <p className="font-bold text-black text-lg">{companyName}</p>}
                </div>
                <React.Fragment>
                    <div className="text-center mt-4">
                        {/* <p className="">BOX KD 110, Accra-Ghana</p> */}
                        <p className="text-xl text-bold text-black">Pro forma Invoice</p>
                        {phone || companyPhone ? <p className="">Tel: {phone || companyPhone}</p> : null}
                        {companyEmail ? <p className="">{companyEmail}</p> : null}
                        {/* <p className="">www.nallemclothing.com</p> */}
                    </div>
                    <div className="border-b-2">
                        <div className="py-2 mt-3 -mx-2">
                            {local ? null : <div className="py-1 px-2">
                                Invoice No: {id}
                            </div>}
                            <div className="py-1 px-2">
                                Date: {date}
                            </div>
                            {reg_no ? <div className="py-1 px-2">
                                VAT Reg. No: {reg_no}
                            </div> : null}
                            {supervisor ? <div className="py-1 px-2">
                                Supervisor: {supervisor}
                            </div> : null}
                            {salesperson ? <div className="py-1 px-2">
                                Sales Person: {salesperson}
                            </div> : null}
                            <div className="py-1 px-2">
                                Shop: {shop}
                            </div>
                            {customer ? <div className="py-1 px-2">
                                Customer: {customer.name}
                            </div> : null}
                        </div>
                    </div>
                    <table className="mb-3" style={{
                        width: '100%'
                    }}>
                        <thead>
                            <tr className="border-b">
                                <th className="w-32 py-2">
                                    Items {is_wholesale ? `(Wholesale)` : ''}
                                </th>
                                <th className="py-2 px-2 text-right">Price</th>
                                <th className="py-2 px-2 text-right">Qty</th>
                                <CurrencyWrapper
                                    component={({ symbol }) => (
                                        <th className="py-2 px-2 text-right">
                                            Amt ({symbol})
                                        </th>
                                    )}
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((i, index) => {

                                return (
                                    <tr className="" key={index}>
                                        <td className="w-32 align-middle py-2">{i.name}</td>
                                        <td className="align-middle py-2 px-2 text-right">{currencyFormat(i.price)}</td>
                                        <td className="align-middle py-2 px-2 text-right">{i.qty}</td>
                                        <td className="align-middle py-2 px-2 text-right">
                                            {currencyFormat(i.qty * i.price)}
                                        </td>
                                    </tr>
                                )

                            })}
                        </tbody>
                    </table>
                    <CurrencyWrapper
                        component={({ symbol }) => (
                            <div className="text-right border-b border-t py-3">
                                <p className="">TOTAL: {symbol} {currencyFormat(sumBy(items, (i) => i.qty * i.price))}</p>
                                {discount_amt ? <p className="">DISCOUNT {discount && discount.type === "percent" ? `(${discount.value}%)` : ''}: {symbol} {currencyFormat(discount_amt)}</p> : null}
                                <p className="">AMT DUE: {symbol} {currencyFormat(amt_due)}</p>
                                <p className="">VAT + NHIL: {symbol} {currencyFormat(tax)}</p>
                                {/* <p className="">PAID: {symbol} {currencyFormat(sumBy(_payments, 'amount'))}</p>
                                {change.length ? <p className="">CHANGE: {symbol} {currencyFormat(Math.abs(change[0].amount))}</p> : null} */}
                            </div>
                        )}
                    />

                    {/* <div className="my-3">
                        <div className="py-2 border-b border-dashed">
                            <p className="">Payment Method</p>
                        </div>
                        <div className="border-b border-dashed">
                            <div className="flex items-center justify-between py-2">
                                <div className="w-1/3 flex flex-col items-start">
                                    <p className="">Mode</p>
                                </div>
                                <div className="w-1/3 flex flex-col items-center">
                                    <p className="">Currency</p>
                                </div>
                                <div className="w-1/3 flex flex-col items-end">
                                    <p className="">Amount</p>
                                </div>
                            </div>
                            {_payments.map((p, i) => {

                                return (
                                    <div key={i} className="flex items-center justify-between py-2">
                                        <div className="w-1/3 flex flex-col items-start">
                                            <p className="">{p.title}</p>
                                        </div>
                                        <div className="w-1/3 flex flex-col items-center">
                                            <p className="">{p.currency || 'GHS'}</p>
                                        </div>
                                        <div className="w-1/3 flex flex-col items-end">
                                            <p className="">{currencyFormat(Math.abs(p.amount))}</p>
                                        </div>
                                    </div>
                                )

                            })}
                        </div>
                    </div> */}
                    {local ? <div className="my-3 text-center">
                        <Barcode
                            value={id}
                            height={40}
                            width={0.8}
                            displayValue={false}
                        />
                    </div> : null}
                    <div className="my-3 text-center">
                        <p className="">We do NOT refund on purchases, we only offer replacements as per terms and conditions</p>
                    </div>
                    <div className="py-3 mt-6 border-b border-dashed text-center">
                        <p className="">Thank You For Shopping With Us!</p>
                        <p className="pt-6">GomeVend powered by <span className="font-bold">GomeRP</span></p>
                        <p className="">Contact: 0206835553</p>
                    </div>
                </React.Fragment>

            </div>
        );

    }

}

export default Proforma;