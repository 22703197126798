import React from 'react';
import { InputNumber, Input, Table, Button as AntButton, Select } from 'antd';
import { TextInputField, Spinner, Button } from 'evergreen-ui';
import { DeleteOutlined } from '@ant-design/icons';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { currencyFormat } from 'mout/number';
import { values, sum, sumBy, every, keyBy, fromPairs, toPairs } from 'lodash';
import apollo from '../../../services/apollo';
import moment from 'moment';
import {encode} from 'mout/queryString';
import { CurrencyFormat, CurrencyWrapper } from '../../../services/Currency';


const GET_LPO = gql`
	query($id: Int!, $company_id: Int!) {
		lpo: purchase_order(where: { id: { _eq: $id }, company_id: { _eq: $company_id } }) {
			comment
			id
			qty
			total
			comment
			supplier_id
			created_at
			employee {
				id
				display_name
			}
			supplier {
				id
				name
			}
			items {
				id
				cost: price
				qty
				variant {
					id
					name
					price
					product {
						id
						name
					}
				}
			}
		}
	}
`;

const GET_SUPPLIERS = gql`
	query($company_id: Int!) {
		suppliers(where: { company_id: { _eq: $company_id } }) {
			id
			name
			company_id
		}
	}
`;

const SEARCH_PRODUCTS = gql`
	query($q: String!, $company_id: Int!) {
		products: products_variants(
			where: {
				_or: [{ name: { _ilike: $q } }, { product: { name: { _ilike: $q } } }]
				product: { has_variants: { _eq: true }, company_id: { _eq: $company_id } }
			}
		) {
			id
			name
			cost
			price
			product {
				id
				name
			}
		}
	}
`;

const GET_PRODUCT = gql`
	query($id: Int!) {
		product: products_variants_by_pk(id: $id) {
			id
			name
			cost
			price
			product {
				id
				name
			}
		}
	}
`;

const CREATE_MUTATION = gql`
	mutation create(
		$comment: String!
		$qty: Int!
		$supplier_id: Int!
		$total: float8!
		$employee_id: Int!
		$company_id: Int!
		$items: [purchase_order_items_insert_input!]!
	) {
		purchase_order: insert_purchase_order(
			objects: {
				items: { data: $items }
				comment: $comment
				qty: $qty
				supplier_id: $supplier_id
				total: $total
				employee_id: $employee_id
				company_id: $company_id
			}
		) {
			returning {
				id
			}
		}
	}
`;
// const UPDATE_MUTATION = gql``;

class LPO extends React.Component{

    state = {
        items: this.props.lpo ? keyBy(this.props.lpo.items, 'id'): {},
        qty: this.props.lpo ? fromPairs(this.props.lpo.items.map((i) => [i.id, i.qty])) : {},
        supplier_id: this.props.lpo ? this.props.lpo.supplier_id: null,
        supplier: this.props.lpo ? this.props.lpo.supplier: null,
        comment: this.props.lpo ? this.props.lpo.comment: '',
        searchValue: ''
    }

    columns = [{
        title: '#',
        dataIndex: 'code',
        key: 'code',
        render: (sku, v, i) => <span className="font-bold text-black">{i + 1}</span>
    },{
        title: 'Product',
        render: (v) => v.product ? <div className="">
            <p className="font-bold text-black">{v.product.product.name}</p>
            <p className="font-bold">{v.product.name}</p>
        </div>: null
    },{
        title: 'Retail Price',
        render: (v) => v.product ? <CurrencyFormat amount={v.product.price} />: null
    },{
        title: 'Unit Price',
        render: (v) => {

            const {id} = this.props;


            return (
                v.product && !id ? <CurrencyWrapper
                    component={({symbol}) => (
                        <InputNumber
                            min={1}
                            onClick={(e) => { 

                                e.target.select();

                            }}
                            value={v.product.cost}
                            style={{width: '100%'}}
                            onChange={(cost) => {

                                this.state.items[v.code].product.cost = cost;

                                this.setState({
                                    items: this.state.items
                                });

                            }}
                            parser={value => value.replace(/[^0-9.]/g, '')}
                            formatter={value => `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            size="small"
                        />
                    )}
                />: v.product ? <CurrencyFormat amount={v.product.cost} />: null
            )

        }
    },{
        title: 'Quantity',
        render: (v) => {
            
            const {qty} = this.state;
            const {id} = this.props;

            // console.log(v);

            return v.product && !id ? (
                <InputNumber
                    value={qty[v.code]}
                    min={1}
                    onClick={(e) => e.target.select()}
                    // formatter={value => `${value}  ${v.product.unit.toUpperCase()}`}
                    // parser={value => value.replace(`  ${v.product.unit.toUpperCase()}`, '')}
                    onChange={(_qty) => {

                        qty[v.code] = parseInt(_qty);

                        this.setState({qty});

                    }}
                />
            ): qty[v.code]

        }
    },{
        title: 'Total',
        render: (v) => v.product ? <CurrencyFormat amount={this.state.qty[v.code] * v.product.cost} /> : null
    }, {
        title: '',
        render: (v) => v.loading ? <Spinner size="small" /> : this.props.id ? null : <AntButton onClick={() => {

            let {qty, items} = this.state;

            delete items[v.code];
            delete qty[v.code];


            this.setState({
                items: {...items},
                qty: {...qty}
            });

        }} className="flex items-center justify-center" type="danger" size="small" ghost icon={<DeleteOutlined />} shape="circle" />
    }];

    render() {

        const {items, qty, searchValue, supplier, comment, materialId, supplier_id} = this.state;
        const {companyId, id, user} = this.props;
        const _items = values(items);
        const itemsCount = _items.length;

        // console.log(_items);

        const total_qty = sum(values(qty));
        const total = sumBy(_items, (v) => {

            return v.product ? v.product.cost * qty[v.code]: 0;

        });

        // console.log(qty);

        return (
            <div className="overflow-scroll h-full py-8 ">
                <div className="max-w-4xl mx-auto w-full px-6">
                    <div className="flex items-center justify-between my-10">
                        <div className="flex items-center">
                            <h1 className="font-bold text-4xl mr-2">
                                Purchase Order {id ? `#${id}` : ''}
                            </h1>
                        </div>
                    </div>
                    <div className="">
                        <div className="max-w-xs">
                            {id ? <div className="mb-3">
                                <p className="font-bold text-black">Date Created</p>
                                <p className="font-bold text-black text-lg my-1">
                                    {moment(this.props.lpo.created_at).format('DD/MM/YYYY')}
                                </p>
                            </div>: null}
                            {id && this.props.lpo.employee ? <div className="mb-3">
                                <p className="font-bold text-black">Created By</p>
                                <p className="font-bold text-black text-lg my-1">
                                    {this.props.lpo.employee.display_name}
                                </p>
                            </div>: null}
                            <div className="mb-3">
                                <p className="font-bold text-black">Supplier</p>
                                <div className="my-1">
                                    {!id ? <Query
                                        query={GET_SUPPLIERS}
                                        variables={{
                                            company_id: companyId
                                        }}
                                    >
                                        {({loading, data, error, refetch}) => {

                                            return (
                                                loading ? <Select placeholder="Select Supplier" key="loading" value={loading ? "loading":"choose" } style={{width: '100%'}}>
                                                    <Select.Option value="loading" disabled={true}>Loading</Select.Option>
                                                </Select>: <Select disabled={id} value={supplier_id} onChange={(supplier_id) => this.setState({supplier_id})} placeholder="Select Supplier" style={{width: '100%'}}>
                                                    {data.suppliers.map((supplier) => {

                                                        return <Select.Option key={supplier.id} value={supplier.id}>{supplier.name}</Select.Option>

                                                    })}
                                                </Select>
                                            )

                                        }}
                                    </Query>: <p className="font-bold text-lg text-black">{supplier.name}</p>}
                                </div>
                            </div>
                            {id ? <AntButton
                                onClick={() => {
                                    
                                    this.props.history.push(`/${companyId}/pos/barcodes${encode({v: toPairs(qty)})}`)
                                
                                }}
                                type="primary"
                                size="small"
                                ghost
                            >
                                Print labels
                            </AntButton>: null}
                        </div>
                    </div>
                    {!id ? <div className="my-8">
                        <Query
                            query={SEARCH_PRODUCTS}
                            variables={{
                                q: `%${searchValue}%`,
                                company_id: companyId,
                            }}
                            skip={!searchValue}
                        >
                            {({data, error, loading}) => {


                                return (
                                    <Select
                                        showSearch
                                        disabled={!supplier_id}
                                        style={{ width: '100%' }}
                                        placeholder={`Search Products`}
                                        notFoundContent={loading ? <div className="flex items-center justify-center p-4"><Spinner size="small" /></div>: null}
                                        filterOption={false}
                                        onSearch={(searchValue) => this.setState({searchValue})}
                                        value={materialId}
                                        onChange={(code) => {

                                            // console.log();
                                            code = parseInt(code);

                                            qty[code] = (qty[code] || 0) + 1;

                                            items[code] = {
                                                code,
                                                loading: true,
                                                updatedAt: Date.now()
                                            }

                                            setTimeout(async () => {

                                                const {data} = await apollo.query({
                                                    query: GET_PRODUCT,
                                                    variables:{
                                                        id: code
                                                    }
                                                });
            
                                                // console.log(data);
            
                                                items[code].loading = false;
                                                items[code] = {
                                                    ...items[code],
                                                    product: data.product
                                                };
            
                                                this.setState({
                                                    materialId: '',
                                                    items
                                                });
            
                                            }, 1);
                                            
                                            this.setState({
                                                qty,
                                                items,
                                                searchValue: '',
                                                // materialId: id
                                            });

                                        }}
                                    >
                                        {!loading && data && data.products && data.products.length ? data.products.map((p) => {

                                            return (
                                                <Select.Option key={p.id} value={p.id}>
                                                    {p.product.name} - {p.name}
                                                </Select.Option>
                                            )

                                        }) :null}
                                    </Select>
                                )

                            }}
                        </Query>
                    </div>: null}
                    <div className="my-8 shadow rounded">
                        <Table
                            dataSource={_items}
                            // dataSource={orderBy(_items, ['updatedAt'], ['desc'])}
                            rowKey={(i) => i.code}
                            columns={this.columns}
                            pagination={false}
                        />
                    </div>
                    <div className="flex justify-between my-8 -mx-4">
                        <div className="flex-1 px-4">
                            <div className="mb-4">
                                {!id ? <Input.TextArea
                                    disabled={id ? true: false}
                                    defaultValue={comment}
                                    onChange={(e) => this.setState({comment: e.target.value})}
                                    autosize={{ minRows: 6, maxRows: 6 }}
                                    placeholder="Notes / Comments"
                                    style={{width: '100%'}}
                                />: <>
                                    <p className="font-bold text-black">Notes / Comments</p>
                                    <p className="text-black">{comment? comment : "No Comment"}</p>
                                </>}
                            </div>
                            {every(_items, (i) => i.product) && !id ? <Mutation
                                mutation={CREATE_MUTATION}
                                variables={{
                                    supplier_id,
                                    qty: total_qty,
                                    total,
                                    comment,
                                    employee_id: user.id,
                                    company_id: companyId,
                                    items: _items.map((i) => ({
                                        // id: 10,
                                        price: i.product.cost,
                                        qty: qty[i.code],
                                        item_id: i.product.id
                                    }))
                                }}
                                onCompleted={(data) => {

                                    this.props.history.push(`/${companyId}/pos/purchase-order/${data.purchase_order.returning[0].id}`)

                                }}
                            >
                                {(create, {loading}) => {

                                    return (
                                        <Button onClick={() => create()} isLoading={loading} disabled={itemsCount < 1 || !every(_items, (v) => v.product)} intent="success" appearance="primary">Save</Button>
                                    );

                                }}
                            </Mutation>: null}
                        </div>
                        <div className="px-4">
                            <div className="rounded w-64 bg-gray-100 px-6 py-3">
                                <div className="border-b py-2 my-2 flex items-center justify-between">
                                        <p className="text-black font-bold">Total Quantity</p>
                                        <p className="font-bold text-black">{total_qty}</p>
                                    </div>
                                    <div className="py-2 my-2 flex items-center justify-between">
                                        <p className="font-bold text-black">Total</p>
                                        <p className="font-bold text-black">
                                            <CurrencyFormat amount={total} />
                                        </p>
                                    </div>
                                </div>
                            {/* <div className="my-4">
                                <EverButton intent="success" appearance="primary">Save</EverButton>
                            </div> */}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

class LPOContainer extends React.Component {
	state = {
		companyId: this.props.match.params.companyId,
		id: this.props.match.params.id
	};

	render() {
		const { companyId, id } = this.state;

		return (
			<Query
				query={GET_LPO}
				skip={id ? false : true}
				variables={{
					id,
					company_id: companyId
				}}
			>
				{({ loading, data, error, refetch }) => {
					return (
						<div className="overflow-scroll h-full w-full">
							{loading ? (
								<div className="flex items-center justify-center py-10">
									<Spinner />
								</div>
							) : !id || (data && data.lpo && data.lpo.length) ? (
								<LPO
									history={this.props.history}
									companyId={companyId}
									id={id}
									{...this.props}
									lpo={
										!id ? null : (
											{
												...data.lpo[0],
												items: data.lpo[0].items.map((i) => ({
													id: i.variant.id,
													qty: i.qty,
													code: i.variant.id,
													product: {
														id: i.variant.id,
														name: i.variant.name,
														cost: i.cost,
														price: i.variant.price,
														product: {
															id: i.variant.product.id,
															name: i.variant.product.name
														}
													}
												}))
											}
										)
									}
								/>
							) : (
								<p className="font-bold text-lg p-6 text-black">No Purchase Order Found</p>
							)}
						</div>
					);
				}}
			</Query>
		);
	}
}

export default LPOContainer;
