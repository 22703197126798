import React from 'react';
import {RefreshCw, MoreHorizontal} from 'react-feather';
import {Input, Radio, Button, Select} from 'antd';
import {values, map, debounce} from 'lodash';
import {Spinner, Popover, Position, Menu, Badge} from 'evergreen-ui';
import firebase from '../services/firebase';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import {PlusOutlined} from '@ant-design/icons';


const GET_DEPARTMENTS = gql`
    query(
        $company_id: Int!
    ) {

        departments: employee_department(where: {company_id: {_eq: $company_id}}) {
            id
            name
        }

    }
`;

const GET_EMPLOYEES = gql`
        query(
            $company_id: Int!,
            $department_id: Int,
            $name: String
        ) {
            employees(where: {
                company_id: {_eq: $company_id},
                department_id: { _eq: $department_id },
                name: {_ilike: $name}
            }) {
                id
                name
                designation
                date_joined
                department{
                    id
                    name
                }
            }

        }
`;

class Employees extends React.Component{

    state = {
        companyId: this.props.match.params.companyId,
        employees: [],
        department: 'all',
        searchValue: '',
        role: null
    };

    componentDidMount() {

        window.onscroll = debounce(() => {
      
            // Checks that the page has scrolled to the bottom
            if (
              window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight
            ) {
              
                console.log('load more');

            }

        }, 100);


    }

    componentWillUnmount() {

        window.onscroll = null;

    }

    render() {

        const {companyId, department, searchValue} = this.state;

        // console.log(companyId);

        return (
            <div className="overflow-scroll w-full h-full py-12">
                <Query
                    query={GET_EMPLOYEES}
                    variables={{
                        company_id: companyId,
                        name: searchValue ? `%${searchValue}%`: null,
                        department_id: department !== 'all' ? parseInt(department): null
                    }}
                >
                    {({error, data, loading, refetch}) => {
                        
                        return (
                            <div className="w-full max-w-3xl mx-auto px-4">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <h1 className="font-bold text-4xl mr-2">
                                            Employees 
                                        </h1>
                                        {loading ? null :<button className="ml-4 focus:outline-none text-black" onClick={() => refetch()}>
                                            <RefreshCw className={(loading) ? "rotating": null} size={24} />
                                        </button>}
                                    </div>
                                    <div className="">
                                        <Button type="primary" onClick={() => this.props.history.push(`/${companyId}/hr/employee/add`)} shape="round" ghost icon={<PlusOutlined />} className="flex items-center justify-center">Add Employee</Button>
                                    </div>
                                </div>
                                <div className="flex items justify-between mt-8">
                                    <div className="flex-1 pr-4">
                                        <Input.Search
                                            defaultValue={searchValue}
                                            placeholder="Search employees"
                                            onSearch={searchValue => this.setState({searchValue})}
                                            // style={{ width: 200 }}
                                        />
                                    </div>
                                    <div className="">
                                        <Query
                                            query={GET_DEPARTMENTS}
                                            variables={{
                                                company_id: companyId
                                            }}
                                        >
                                            {({data, error, loading}) => {

                                                return (
                                                    !loading && data ? <Select style={{width: 240}} onChange={(department) => this.setState({department})} defaultValue={department || ''} placeholder="">
                                                        <Select.Option value="all">All Departments</Select.Option>
                                                        {data.departments.map((department) => {
                        
                                                            return <Select.Option value={department.id} key={department.id}>{department.name}</Select.Option>
                        
                                                        })}
                                                    </Select> : null
                                                )

                                            }}
                                        </Query>
                                    </div>
                                </div>
                                {loading ? <div className="flex items-center justify-center py-10">
                                    <Spinner />
                                </div> : data && data.employees.length ? <div className="my-4">
                                    {data.employees.map((employee) => {

                                        return (
                                            <div className="border-b my-4 py-4" key={employee.id}>
                                                <div className="flex items-center justify-between">
                                                    <div onClick={(e) => {

                                                        e.stopPropagation();

                                                        this.props.history.push(`/${companyId}/hr/employee/${employee.id}`);

                                                    }} className="cursor-pointer flex-1">
                                                        <div className="font-bold text-xl text-black flex items-center">
                                                            <div className="">
                                                                {employee.name}
                                                            </div>
                                                            <div className="mx-2">
                                                                <Badge color="neutral" marginRight={8}>{employee.department.name}</Badge>
                                                            </div>
                                                        </div>
                                                        <p className="">{employee.designation}</p>
                                                        <p className="text-sm">Joined {employee.date_joined}</p>
                                                    </div>
                                                    <div className="text-black">
                                                        <Popover
                                                            position={Position.BOTTOM_RIGHT}
                                                            content={
                                                                ({close}) => {

                                                                    return <Menu>
                                                                        <Menu.Item
                                                                            onSelect={() => {

                                                                                close();

                                                                                this.props.history.push(`/${companyId}/hr/employee/${employee.id}`);

                                                                            }}
                                                                        >
                                                                            Edit Employee
                                                                        </Menu.Item>
                                                                        <Menu.Divider />
                                                                        <Menu.Item
                                                                            onSelect={() => {}}
                                                                            intent="danger"
                                                                        >
                                                                            Delete Employee
                                                                        </Menu.Item>
                                                                    </Menu>
                                                                }
                                                            }
                                                        >
                                                            {({getRef, toggle, isShown}) => (

                                                                <button ref={getRef} onClick={toggle} className="active:outline-none focus:outline-none">
                                                                    <MoreHorizontal size={28} />
                                                                </button>

                                                            )}
                                                        </Popover>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    })}
                                </div>: <div className="">
                                    <p className="text-center py-10 text-black">
                                        <span className="">No Employees found</span>
                                        {searchValue ? <span className=""> similar to "{searchValue}"</span>: null}
                                        .
                                    </p>
                                </div>}
                            </div>

                        );
                    }}
                </Query>
            </div>
        );

    }

}

export default Employees;