import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/database';
import '@firebase/firestore';
import '@firebase/storage';
import '@firebase/messaging';

const config = {
    apiKey: "AIzaSyAQ1fxkaUmRrkIAngium78CV6cZoffqihk",
    authDomain: "payhive-84e5b.firebaseapp.com",
    databaseURL: "https://payhive-84e5b.firebaseio.com",
    projectId: "payhive-84e5b",
    storageBucket: "payhive-84e5b.appspot.com",
    messagingSenderId: "526238034310",
    appId: "1:526238034310:web:e574a3c08be0d484"
};

firebase.initializeApp(config);

export default firebase;