import React from 'react';
import {Query, Mutation} from 'react-apollo';
import gql from 'graphql-tag';
import {Button, Modal, Select, Input, message} from 'antd';
import {TextInputField, BackButton, Button as EverButton, FormFieldLabel, Spinner} from 'evergreen-ui';
import {withRouter} from 'react-router-dom';
import {values, map, includes, groupBy, toPairs} from 'lodash';
import roles, {groupedRoles, requiresOutlet} from '../services/roles';
import api from '../services/api';

const GET_USER = gql`

    query ($id: Int!, $account_id: Int!) {
        user: users_by_pk(account_id: $account_id, id: $id) {
            id
            email
            display_name
            companies {
                company {
                    id
                    name
                    created_by
                }
                outlet_id
                outlet{
                    name
                }
                role
                user{
                    id
                    employee{
                        id
                    }
                }
            }
        }
    }

`;

const GET_OUTLETS = gql`

    query ($company_id: Int!) {
        outlets(where: {
            company_id:{_eq: $company_id}
        }) {
            id
            name
        }
    }

`;

const GET_EMPLOYEES_WITH_CURRENT = gql`

    query ($company_id: Int!, $id: Int) {
        employees(where: {
            _or: [
                {
                    company_id:{_eq: $company_id},
                    credential_id: {_is_null: true},
                },
                {
                    company_id:{_eq: $company_id},
                    id: {_eq: $id},
                }
            ]
        }) {
            id
            name
            designation
        }
    }

`;

const GET_EMPLOYEES = gql`

    query ($company_id: Int!, $id: Int) {
        employees(where: {
            company_id:{_eq: $company_id},
            credential_id: {_is_null: true}
        }) {
            id
            name
            designation
        }
    }

`;

const UPDATE_ROLE_MUTATION = gql`

    mutation (
        $account_id: Int!,
        $company_id: Int!,
        $role: String!,
        $user_id: Int!,
        $outlet_id: Int,
        $employee_id: Int,
        $old_employee: Int
    ) {
        update_company_users(where: {
            company_id: {_eq: $company_id}, user_id: {_eq: $user_id}, account_id: {_eq: $account_id}
        }, _set: {
            role: $role,
            outlet_id: $outlet_id
        }) {
            affected_rows
        }
    }

`;

const UPDATE_ROLE_MUTATION_WITH_EMPLOYEE = gql`

    mutation (
        $account_id: Int!,
        $company_id: Int!,
        $role: String!,
        $user_id: Int!,
        $outlet_id: Int,
        $employee_id: Int!,
        $old_employee: Int!
    ) {
        update_company_users(where: {
            company_id: {_eq: $company_id}, user_id: {_eq: $user_id}, account_id: {_eq: $account_id}
        }, _set: {
            role: $role,
            outlet_id: $outlet_id
        }) {
            affected_rows
        }
        update_employees(where:{id:{_eq: $employee_id}}, _set:{
            credential_id: $user_id
        }){
            affected_rows
        }
        old_employee: update_employees(where:{id:{_eq: $old_employee}}, _set:{
            credential_id: null
        }){
            affected_rows
        }
    }

`;

const DELETE_ROLE_MUTATION = gql`

    mutation (
        $account_id: Int!,
        $company_id: Int!,
        $user_id: Int!
    ) {
        delete_company_users(where: {
            company_id: {_eq: $company_id}, user_id: {_eq: $user_id}, account_id: {_eq: $account_id}
        }) {
            affected_rows
        }
    }

`;

const CREATE_ROLE_MUTATION = gql`
    mutation (
        $account_id: Int!,
        $company_id: Int!,
        $role: String!,
        $user_id: Int!,
        $outlet_id: Int,
        $employee_id: Int,
        $old_employee: Int
    ) {
        insert_company_users(objects: {
            account_id: $account_id,
            company_id: $company_id,
            role: $role,
            outlet_id: $outlet_id,
            user_id: $user_id
        }) {
            affected_rows
        }
    }
`;

const CREATE_ROLE_MUTATION_WITH_EMPLOYEE = gql`
    mutation (
        $account_id: Int!,
        $company_id: Int!,
        $role: String!,
        $user_id: Int!,
        $outlet_id: Int,
        $employee_id: Int!,
        $old_employee: Int!
    ) {
        insert_company_users(objects: {
            account_id: $account_id,
            company_id: $company_id,
            role: $role,
            outlet_id: $outlet_id,
            user_id: $user_id
        }) {
            affected_rows
        }
        update_employees(where:{id:{_eq: $employee_id}}, _set:{
            credential_id: $user_id
        }){
            affected_rows
        }
        old_employee: update_employees(where:{id:{_eq: $old_employee}}, _set:{
            credential_id: null
        }){
            affected_rows
        }
    }
`;

const SAVE_MUTATION = gql`
    mutation ($display_name: String!, $account_id: Int!, $id: Int!) {
        update_users(where: {account_id: {_eq: $account_id}, id: {_eq: $id}}, _set: {display_name: $display_name}) {
            affected_rows
        }
    }
`;

class User extends React.Component{

    state = {
        userId: this.props.userId,
        name: this.props.user.display_name || '',
        email: this.props.user.email || '',
        photo_url: this.props.user.photo_url || '',
        companies: this.props.user.companies || [],
        password: '',
        repeat_password: '',
        account: this.props.account,
        disable_company: false,
        selected_company: null,
        selected_role: null,
        selected_outlet: null,
        showAddCompany: false
    }

    create = async () => {

        const {
            name,
            email,
            repeat_password,
            password,
            photo_url,
            account
        } = this.state;

        if (password.length < 8) {

            return message.error('Password must be 8 characters or more.');

        }

        if (password !== repeat_password) {

            return message.error('Passwords do no match');

        }

        this.setState({isCreating: true});

        try {

            const res = await api.post(`/auth/create`, {
                display_name: name,
                email,
                password,
                photo_url,
                account_id: account.id
            });

            this.setState({isCreating: false});

            this.props.history.push(`/users/${res.data.id}`);

        } catch(e) {

            if (e.response && e.response.data.message) {

                message.error(e.response.data.message);

            }

            this.setState({isCreating: false});

        }


    }

    changePassword = async () => {

        const {
            userId,
            account,
            repeat_password,
            password
        } = this.state;

        if (password.length < 8) {

            return message.error('Password must be 8 characters or more.');

        }

        if (password !== repeat_password) {

            return message.error('Passwords do no match');

        }

        this.setState({isChangingPassword: true});


        try {

            const res = await api.post(`/auth/password/change`, {
                user_id: userId,
                account_id: account.id,
                password
            });

            this.setState({isChangingPassword: false, repeat_password: '', password: '', changePasswordMode: false});

            // this.props.history.push(`/users/${res.data.id}`);

        } catch(e) {

            if (e.response && e.response.data.message) {

                message.error(e.response.data.message);

            }

            this.setState({isChangingPassword: false});

        }


    }

    componentDidUpdate(prevProps) {

        if (prevProps.user !== this.props.user) {

            this.setState({
                name: this.props.user.display_name || '',
                email: this.props.user.email || '',
                photo_url: this.props.user.photo_url || '',
                companies: this.props.user.companies || [],
            })

        }



    }

    render(){

        const {
            userId,
            name,
            email,
            isCreating,
            photo_url,
            password,
            repeat_password,
            disable_company,
            companies,
            selected_company,
            selected_role,
            selected_outlet,
            selected_employee,
            old_employee,
            account,
            changePasswordMode,
            isChangingPassword,
            showAddCompany
        } = this.state;

        const {user, _user, history} = this.props;

        const selectedCompanies = map(companies, (c) => c.company.id);
        const possibleCompanies = account.companies.filter((c) => !includes(selectedCompanies, c.id));

        return (
            <div className="max-w-2xl mx-auto">

                <div className="mt-10">
                    <BackButton appearance="default" onClick={()=>{

                        this.props.history.push('/users');

                    }} children="Back to users" />
                </div>
                <div className="my-10 flex items-center justify-between">
                    <p className="text-black font-bold text-3xl">{userId ? 'Edit' : 'Create'} User</p>
                    <div className="">
                        {!userId ? <EverButton isLoading={isCreating} onClick={() => this.create()} appearance="primary" intent="success">Save</EverButton>: <Mutation
                            mutation={SAVE_MUTATION}
                            variables={{
                                display_name: name,
                                account_id: account.id,
                                id: userId
                            }}
                        >
                            {(mutate, {loading}) => <EverButton isLoading={loading} onClick={() => mutate()} appearance="primary" intent="success">Save</EverButton>}
                        </Mutation>}
                    </div>
                </div>
                <div className="">
                    <TextInputField label="Full Name" defaultValue={name} onChange={(e) => {

                        this.setState({
                            name: e.target.value
                        });

                    }} placeholder="Example John Doe" />
                    <TextInputField disabled={userId ? true: false} label="Email" defaultValue={email} onChange={(e) => {

                        this.setState({
                            email: e.target.value
                        });

                    }} placeholder="Example johndoe@example.com" />
                    {!userId ? <div className="my-4">
                        <div className="my-3">
                            <FormFieldLabel>Password</FormFieldLabel>
                            <Input.Password onChange={(e) => this.setState({password: e.target.value})} placeholder="Enter Password" />
                        </div>
                        <div className="my-3">
                            <FormFieldLabel>Repeat Password</FormFieldLabel>
                            <Input.Password onChange={(e) => this.setState({repeat_password: e.target.value})} placeholder="Repeat Password" />
                        </div>
                    </div>: changePasswordMode ? <div className="my-4">
                        <div className="my-3">
                            <FormFieldLabel>Password</FormFieldLabel>
                            <Input.Password onChange={(e) => this.setState({password: e.target.value})} placeholder="Enter Password" />
                        </div>
                        <div className="my-3">
                            <FormFieldLabel>Repeat Password</FormFieldLabel>
                            <Input.Password onChange={(e) => this.setState({repeat_password: e.target.value})} placeholder="Repeat Password" />
                        </div>
                        <div className="my-3">
                            <Button onClick={() => this.changePassword()} loading={isChangingPassword} ghost type="primary">Change Password</Button>
                            <Button className="ml-3" onClick={() => this.setState({changePasswordMode: false, repeat_password: '', password: ''})} ghost type="danger">Cancel</Button>
                        </div>
                    </div> :parseInt(userId) !== parseInt(_user.id) ? <div className="my-4">
                        <FormFieldLabel>Password</FormFieldLabel>
                        <div className="my-3">
                            <Button onClick={() => this.setState({changePasswordMode: true})} ghost type="primary">Change Password</Button>
                        </div>
                    </div>: null}
                </div>
                {!userId? null :<div className="border-b mt-8 py-3 my-4 flex items-center">
                    <p className="font-light text-black text-xl">Companies</p>
                    {possibleCompanies.length ? <Button className="mx-2" type="primary" size="small" ghost onClick={() => this.setState({showAddCompany: true})}>add</Button>: null}
                </div>}
                {!userId? null : <div className="my-4">
                    {companies.map((company) => {

                        // console.log(company.user);

                        return (
                            <div key={company.company.id} className="flex items-center justify-between py-4 border-b">
                                <div className="">
                                    <p className="text-black text-xl">{company.company.name}</p>
                                    <p className="font-light text-black">{roles[company.role].name}</p>
                                    {company.outlet ? <p className="font-light text-black">{company.outlet.name}</p>: null}
                                </div>
                                <div className="flex items-center -mx-2">
                                    {company.company.created_by !== user.id ? <Button className="mx-2" type="primary" size="small" ghost onClick={() => this.setState({
                                        showAddCompany: true,
                                        disable_company: true,
                                        selected_company: company.company.id,
                                        selected_outlet: company.outlet_id,
                                        selected_role: company.role,
                                        old_employee: company.user.employee.length ? company.user.employee[0].id: null,
                                        selected_employee: company.user.employee.length ? company.user.employee[0].id: null
                                    })}>edit</Button>: null}
                                    {company.company.created_by !== user.id ? <Mutation
                                        mutation={DELETE_ROLE_MUTATION}
                                        variables={{
                                            company_id: company.company.id,
                                            account_id: account.id,
                                            user_id: userId
                                        }}
                                        onCompleted={() => history.push('/users')}
                                    >
                                        {(mutate, {loading}) => <Button className="mx-2" type="danger" loading={loading} size="small" ghost onClick={() => mutate()}>delete</Button>}    
                                    </Mutation>: null}
                                </div>
                            </div>
                        )

                    })}
                </div>}
                <Modal
                    title={disable_company ? "Edit Role": "Add user"}
                    visible={showAddCompany}
                    okText="Save"
                    footer={false}
                    onCancel={() => this.setState({
                        disable_company: false,
                        showAddCompany: false,
                        selected_company: null,
                        selected_outlet: null,
                        selected_role: null,
                        selected_employee: null,
                        old_employee: null
                    })}
                >

                    <div className="mb-3">
                        <p className="font-bold mb-1">Company</p>
                        <Select disabled={disable_company} value={selected_company} onChange={(selected_company) => this.setState({selected_company})} style={{width: '100%'}}>
                            {(disable_company? account.companies : possibleCompanies).map((company) => {

                                return <Select.Option key={company.id} value={company.id}>{company.name}</Select.Option>

                            })}
                        </Select>
                    </div>

                    <div className="my-3">
                        <p className="font-bold mb-1">Role</p>
                        <Select value={selected_role} onChange={(selected_role) => {
                            
                            this.setState({
                                selected_role,
                                selected_outlet: null
                            });
                            
                        }}  style={{width: '100%'}}>
                            {toPairs(groupBy(values(roles), 'group')).map((group) => {

                                return <Select.OptGroup label={groupedRoles[group[0]]} key={group[0]}>
                                    {group[1].map((role) => {

                                        return (
                                            <Select.Option key={role.value} value={role.value}>{role.name}</Select.Option>
                                        );

                                    })}
                                </Select.OptGroup>

                            })}
                        </Select>
                    </div>

                    {selected_role && includes(requiresOutlet, selected_role) ? <div className="my-3">
                        <p className="font-bold mb-1">Outlet</p>
                        <Query
                            query={GET_OUTLETS}
                            variables={{
                                company_id: `${selected_company}`
                            }}
                        >
                            {({loading, refetch, error, data}) => {

                                return (
                                    <Select value={selected_outlet} onChange={(selected_outlet) => this.setState({selected_outlet})}  style={{width: '100%'}}>
                                        {!loading && data.outlets ? data.outlets.map((o) => {

                                            return (
                                                <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                                            );

                                        }): null}
                                    </Select>
                                )

                            }}
                        </Query>
                    </div>: null}
                    <div className="my-3">
                        <p className="font-bold mb-1">Attach to Employee</p>
                        <Query
                            query={selected_employee ? GET_EMPLOYEES_WITH_CURRENT :GET_EMPLOYEES}
                            variables={{
                                company_id: `${selected_company}`,
                                id: selected_employee
                            }}
                        >
                            {({loading, refetch, error, data}) => {

                                return (
                                    <Select value={selected_employee} onChange={(selected_employee) => this.setState({selected_employee})}  style={{width: '100%'}}>
                                        {!loading && data && data.employees ? data.employees.map((e) => {

                                            return (
                                                <Select.Option key={e.id} value={e.id}>{e.name} - ({e.designation})</Select.Option>
                                            );

                                        }): null}
                                    </Select>
                                )

                            }}
                        </Query>
                    </div>
                    <div className="my-3">
                        <Mutation
                            onCompleted={() => {

                                this.setState({
                                    disable_company: false,
                                    showAddCompany: false,
                                    selected_outlet: null,
                                    selected_company: null,
                                    selected_role: null,
                                    selected_employee: null,
                                    old_employee: null
                                }, () => {

                                    history.push('/users');

                                });

                            }}
                            mutation={disable_company ? selected_employee ? UPDATE_ROLE_MUTATION_WITH_EMPLOYEE : UPDATE_ROLE_MUTATION: selected_employee ? CREATE_ROLE_MUTATION_WITH_EMPLOYEE : CREATE_ROLE_MUTATION}
                            variables={{
                                company_id: selected_company,
                                account_id: account.id,
                                user_id: userId,
                                role: selected_role,
                                outlet_id: selected_outlet,
                                employee_id: selected_employee,
                                old_employee
                            }}
                        >
                            {(mutate, {loading}) => <Button ghost type="primary" loading={loading} onClick={() => mutate()}>Save</Button>}
                        </Mutation>
                    </div>

                </Modal>
            </div>
        )

    }

}

const UserWrapper = withRouter(User);

class UserContainer extends React.Component{

    state = {
        userId: this.props.match.params.userId
    }

    render() {

        const {userId} = this.state;
        const {account, user} = this.props;

        return (
            userId ? <Query
                query={GET_USER}
                variables={{
                    id: userId,
                    account_id: account.id
                }}
                skip={!userId}
            >
                {({error, data, loading}) => {

                    return (
                        !userId || !loading && data ? <UserWrapper {...this.props} user={data ? data.user : {}} _user={user} account={account} userId={userId} /> : <div className="flex h-full items-center justify-center py-10">
                            <Spinner />
                        </div>
                    );

                }}
            </Query>: <UserWrapper user={{}} account={account} userId={userId} />
        );

    }

}

export default UserContainer;