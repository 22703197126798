import React from 'react';
import ReactDOM from 'react-dom';
import Main from './Main';
import {Router} from 'react-router-dom';
import history from './services/history';
import {register} from './serviceWorker';
import {cache} from './services/apollo';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
import DatabaseProvider from '@nozbe/watermelondb/DatabaseProvider';
import {database} from './models/Database';

(async () => {

    await persistCache({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
    });

})();

register();

ReactDOM.render(
    <Router history={history}>
        <DatabaseProvider database={database}>
            <Main />
        </DatabaseProvider>
    </Router>,
    document.getElementById('root')
);
