import React from 'react';
import {RefreshCw} from 'react-feather';
import {Button, Input} from 'antd';
import {Spinner, Badge} from 'evergreen-ui';
import {Link} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';

import gql from "graphql-tag";
import { Query } from "react-apollo";

const GET_CUSTOMERS = gql`
    query($company_id: Int!, $name: String) {

        customers (where: {
            company_id: {_eq: $company_id},
            _or: [
                {name: {_ilike: $name},}
            ]
        }) {
            id
            first_name
            last_name
            phone
            email
            date_of_birth
        }

    }
`;

class Customers extends React.Component{

    state = {
        name: '',
        companyId: this.props.match.params.companyId
    };

    componentDidCatch(error, errorInfo) {
    
        console.log(error, errorInfo);

    }

    render(){

        const {companyId, name} = this.state;

        return (
            <div className="py-12">
                <div className="w-full max-w-3xl mx-auto px-4">
                    <Query
                        variables={{
                            company_id: companyId,
                            name: `%${name}%`
                        }}
                        fetchPolicy="cache-and-network" errorPolicy="ignore"
                        query={GET_CUSTOMERS}
                    >
                        {({loading, data, error, refetch}) => {

                            // console.log(data);

                            return (
                                <React.Fragment>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <h1 className="font-bold text-4xl mr-2">
                                                Customers 
                                            </h1>
                                            {loading ? null :<button className="ml-4 focus:outline-none text-black" onClick={() => refetch()}>
                                                <RefreshCw size={24} />
                                            </button>}
                                        </div>
                                        <div className="">
                                            <Button type="primary" onClick={() => this.props.history.push(`/${companyId}/pos/customers/create`)} shape="round" ghost icon={<PlusOutlined />} className="flex items-center justify-center">Add</Button>
                                        </div>
                                    </div>
                                    <div className="flex items justify-between my-8">
                                        <div className="flex-1">
                                            <Input.Search
                                                defaultValue={name}
                                                placeholder="Search customers"
                                                onSearch={name => this.setState({name})}
                                                // style={{ width: 200 }}
                                            />
                                        </div>
                                    </div>
                                    {loading ? <div className="flex items-center justify-center"><Spinner /></div> : <div className="">
                                        {data && data.customers ? data.customers.map((customer) => {

                                            return (
                                                <div key={customer.id} className="py-4 border-b">
                                                    <div className="flex items-center">
                                                        <p className="font-bold text-lg text-black">
                                                            {customer.first_name} {customer.last_name}
                                                        </p>
                                                    </div>
                                                    <Link to={`/${companyId}/pos/customers/${customer.id}`}>View</Link>
                                                </div>
                                            )

                                        }) :null}
                                    </div>}
                                </React.Fragment>
                            )

                        }}
                    </Query>

                </div>
            </div>
        )

    }

}

export default Customers