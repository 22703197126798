import React from 'react';
import {Popover, Position, Menu} from 'evergreen-ui';
import {MoreHorizontal} from 'react-feather';
import {withRouter, Link} from 'react-router-dom';


class Accounts extends React.Component{

    render() {

        const {currentCompany, company} = this.props;

        return (
            <ul className="list-reset flex items-center -mx-4">
                <li>
                    <Link to={`/${currentCompany}/accounting`} className="px-4 font-medium">Transactions</Link>
                </li>
                <li>
                    <Link to={`/${currentCompany}/accounting/charts`} className="px-4 font-medium">Chart of Accounts</Link>
                </li>
                {/* <li>
                    <Link to={`/${currentCompany}/accounting/reports`} className="px-4 font-medium">Reports</Link>
                </li> */}
            </ul>
        )

    }

}

export default withRouter(Accounts);