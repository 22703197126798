import React from 'react';
import {currencyFormat} from 'mout/number';
import {sumBy} from 'lodash';
import {Table} from 'antd';

class Pensions extends React.Component{

    columns = [{
        title: 'Employee Name',
        dataIndex: 'name',
        key: 'name',
        render: (name, e) => <div className={e.employeeId == 'total' ? 'font-bold': ''}>{name}</div>
    },{
        title: 'SSNIT Number',
        dataIndex: 'ssnit_number',
        key: 'ssnit_number',
        render: (ssnit_number, e) => <div className={e.employeeId == 'total' ? 'font-bold': ''}>{ssnit_number}</div>
    },{
        title: 'Basic Salary',
        dataIndex: 'basic_salary',
        key: 'basic_salary',
        render: (basic_salary, e) => <div className={e.employeeId == 'total' ? 'font-bold': ''}>{currencyFormat(basic_salary)}</div>
    },{
        title: 'SSF (5.5%)',
        dataIndex: 'ssnit_employee',
        key: 'ssnit_employee',
        render: (ssnit_employee, e) => <div className={e.employeeId == 'total' ? 'font-bold': ''}>{currencyFormat(ssnit_employee)}</div>
    },{
        title: 'SSF (13%)',
        dataIndex: 'ssnit_employer',
        key: 'ssnit_employer',
        render: (ssnit_employer, e) => <div className={e.employeeId == 'total' ? 'font-bold': ''}>{currencyFormat(ssnit_employer)}</div>
    },{
        title: 'Total SSF',
        dataIndex: 'total_pension',
        key: 'total_pension',
        render: (total_pension, e) => <div className={e.employeeId == 'total' ? 'font-bold': ''}>{currencyFormat(total_pension)}</div>
    },{
        title: 'SSNIT (1st Tier)',
        dataIndex: 'tier_1',
        key: 'tier_1',
        render: (tier_1, e) => <div className={e.employeeId == 'total' ? 'font-bold': ''}>{currencyFormat(tier_1)}</div>
    },{
        title: '2nd Tier',
        dataIndex: 'tier_2',
        key: 'tier_2',
        render: (tier_2, e) => <div className={e.employeeId == 'total' ? 'font-bold': ''}>{currencyFormat(tier_2)}</div>
    },{
        title: 'Total Distributed',
        dataIndex: 'tier_3',
        key: 'tier_3',
        render: (tier_3, e) => <div className={e.employeeId == 'total' ? 'font-bold': ''}>{currencyFormat(tier_3)}</div>
    }]

    render() {

        const {employees, month, company} = this.props;

        return (
            <div className="print-only text-black">
                <div className="">
                    <div className="mt-4 mb-10 text-center">
                        <h3 className="font-bold text-3xl">{company.name}</h3>
                        <h6 className="font-bold text-xl">SOCIAL SECURITY CONTRIBUTIONS</h6>
                    </div>
                    <div className="flex my-4">
                        <h6 className="font-bold ">FILE NUMBER :</h6>
                        <div className="ml-3 border-b max-w-xs w-full"></div>
                    </div>
                </div>
                <div className="mt-10">
                    <Table rowKey={(e) => e.employeeId} className="text-black" size="small" columns={this.columns} dataSource={[
                        ...employees.map((e) => ({...e, tier_3: e.tier_1 + e.tier_2})),
                        {
                            employeeId: 'total',
                            name: 'Grand Total',
                            basic_salary: sumBy(employees, (e) => (parseFloat(e.basic_salary))),
                            ssnit_employer: sumBy(employees, (e) => (parseFloat(e.ssnit_employer))),
                            total_pension: sumBy(employees, (e) => (parseFloat(e.total_pension))),
                            tier_1: sumBy(employees, (e) => (parseFloat(e.tier_1))),
                            tier_2: sumBy(employees, (e) => (parseFloat(e.tier_2))),
                            tier_3: sumBy(employees, (e) => (parseFloat(e.tier_1) + parseFloat(e.tier_2))),
                            ssnit_employee: sumBy(employees, (e) => (parseFloat(e.ssnit_employee)))
                        }
                    ]} pagination={false} />
                </div>
                <div className="flex my-8 -mx-4">
                    <div className="flex flex-col flex-1 px-4">
                        <div className="flex my-4">
                            <div className="uppercase text-black font-bold">
                                Prepared By:
                            </div>
                            <div className="border-b flex-1"></div>
                        </div>
                        <div className="flex my-4">
                            <div className="uppercase text-black font-bold">
                                Date:
                            </div>
                            <div className="border-b flex-1"></div>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1 px-4">
                        <div className="flex my-4">
                            <div className="uppercase text-black font-bold">
                                Approved By:
                            </div>
                            <div className="border-b flex-1"></div>
                        </div>
                        <div className="flex my-4">
                            <div className="uppercase text-black font-bold">
                                Date:
                            </div>
                            <div className="border-b flex-1"></div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

}

export default Pensions;