import React from 'react';
import {TextInputField, Spinner, BackButton, Button as EverButton} from 'evergreen-ui';
import {Table, Button, Modal, message} from 'antd';

import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import {withRouter} from 'react-router-dom';
import {encode} from 'mout/queryString';
import {CurrencyFormat} from '../../services/Currency';


const GET_PRODUCT = gql`
    query($id: Int!) {

        product: products_by_pk(id: $id) {
            id
            name
            company_id
            supplier{
                name
            }
            category{
                name
            }
            has_variants
            variants{
                id
                name
                cost
                price
                attributes{
                    key
                    value
                }
                inventory: inventory_aggregate {
                    aggregate {
                      sum {
                        qty
                      }
                    }
                }
            }
        }

    }
`;

const GET_VARIANT_STOCK = gql`
    query ($variant_id: Int!) {
        inventory: products_inventory(where: {variant_id: {_eq: $variant_id}}) {
            id
            qty
            outlet{
                name
            }
        }
    }
  
`;



class ProductOverview extends React.Component{

    state = {
        companyId: this.props.companyId,
        productId: this.props.productId,
        name: this.props.product.name || '',
        sku: '',
        cost: 0.00,
        price: 0.00,
        autoSKU: true,
        hasVariants: this.props.product.has_variants,
        variantOptions: this.props.product.variants || [],
        supplier: this.props.product.supplier,
        category: this.props.product.category,
        modalVisible: false
    }

    columns = [
        {
            title: 'In Stock',
            render: (v) => v.inventory.aggregate.sum.qty || 0 
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (price) => <CurrencyFormat amount={price} />
        },{
            title: '',
            render: (v) => {

                const {companyId} = this.state;
                
                return (
                    <div className="">
                        <Button onClick={() => this.props.history.push(`/${companyId}/pos/barcodes${encode({v: [[v.id, 1]]})}`)} type="primary" size="small" ghost>Print labels</Button>
                    </div>
                )

            }
        }
    ]

    render() {

        const {name, companyId, hasVariants, cost, price, sku, autoSKU, productId, supplier, category, variantOptions, modalVisible} = this.state;

        // console.log(productId);

        return (
            <div className="max-w-6xl w-full mx-auto my-6">
                <BackButton appearance="default" marginBottom={15} onClick={()=>{

                    this.props.history.push(`/${companyId}/pos/products`);

                }} children="Back to products" />
                <React.Fragment>
                    <div className="flex items-center my-6">
                        <p className="text-3xl font-bold text-black">{name}</p>
                        <Button type="primary" size="small" className="mx-3" onClick={() => this.props.history.push(`/${companyId}/pos/product/${productId}/edit`)} ghost>edit</Button>
                    </div>
                    <div className="mb-6">
                        
                        <EverButton
                            onClick={() => this.setState({modalVisible: true})}
                            intent="danger"
                            appearance="primary"
                        >
                            Archive Product
                        </EverButton>
                    </div>
                    <Mutation
                        mutation={gql`
                            mutation{
                                update_products(where:{
                                    id: {_eq: ${productId}}
                                }, _set:{
                                    archived: true
                                }){
                                    affected_rows
                                }
                            }
                        `}
                        onError={() => message.error('An error occurred while trying to save products.')}
                        onCompleted={() => {

                            message.success('Product successfully archived.');

                            this.props.history.push(`/${companyId}/pos/products`);

                        }}
                    >
                        {(save, {loading}) => {

                            return (
                                <Modal
                                    okText="Delete"
                                    okType="danger"
                                    confirmLoading={loading}
                                    title={`Delete ${name}`}
                                    visible={this.state.modalVisible}
                                    onOk={() => save()}
                                    onCancel={() => this.setState({modalVisible: false})}
                                >
                                    Are you sure you want to delete {name} ?
                                </Modal>
                            )
                            
                        }}
                    </Mutation>
                    <div className="-mx-4 flex items-center flex-wrap">
                        {supplier ? <div className="px-4">
                            <p className="font-bold text-black">Supplier</p>
                            <p className="font-light mt-2">{supplier.name}</p>
                        </div>: null}
                        <div className="px-4">
                            <p className="font-bold text-black">Category</p>
                            <p className="font-light mt-2">{category.name}</p>
                        </div>
                        <div className="px-4">
                            <p className="font-bold text-black">Labels</p>
                            <p className="font-light mt-2"><Button onClick={() => this.props.history.push(`/${companyId}/pos/barcodes${encode({v: variantOptions.map((v) => [v.id, 1])})}`)} type="primary" size="small" ghost>Print labels</Button></p>
                        </div>
                    </div>
                    <div className="my-8">
                        <Table
                            columns={[
                                {
                                    title: 'Name',
                                    dataIndex: 'name',
                                    key: 'name',
                                },
                                ...this.columns
                            ]}
                            rowKey={(r) => r.id}
                            expandedRowRender={r => (
                                <Query
                                    query={GET_VARIANT_STOCK}
                                    variables={{
                                        variant_id: r.id
                                    }}
                                >
                                    {({error, loading, data}) => {

                                        
                                        return (
                                            !loading && data ? data.inventory.length ? <div className="flex -mx-4">
                                                {data.inventory.map((i) => {

                                                    return (
                                                        <div className="px-4" key={i.id}>
                                                            <p className="font-bold text-black">{i.outlet.name}</p>
                                                            <p className="text-black my-2">{i.qty}</p>
                                                        </div>
                                                    )

                                                })}
                                            </div> : <p style={{ margin: 0 }}>No Stock Available</p>: null
                                        )

                                    }}
                                </Query>
                            )}
                            dataSource={variantOptions}
                            pagination={false}
                        />
                    </div>
                </React.Fragment>
            </div>

        );

    }

}

const ProductWrapper = withRouter(ProductOverview);

class ProductContainer extends React.Component {

    state = {
        companyId: this.props.match.params.companyId,
        productId: this.props.match.params.productId
    }

    render() {

        const {companyId, productId} = this.state;

        return (

            <Query
                query={GET_PRODUCT}
                skip={(!productId) ? true: false}
                variables={{
                    id: productId
                }}
            >
                {({loading, data, error, refetch}) => {

                    return (
                        <div className="py-8 overflow-scroll h-full">
                            {loading ? <div className="flex items-center justify-center py-10">
                                <Spinner />
                            </div> : <ProductWrapper companyId={companyId} productId={productId} product={!productId ? {}: data.product} />}

                        </div>

                    );

                }}
                
            </Query>
            
        )

    }

}

export default ProductContainer;