import React from 'react';
import {Button} from 'evergreen-ui';
import {debounce} from 'lodash';
import firebase from '../services/firebase';
import {Spinner, Badge} from 'evergreen-ui';
import {RefreshCw, MoreHorizontal} from 'react-feather';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';
import {Modal} from 'antd';
import Aggregator from './Aggregator';
import {CurrencyFormat} from '../services/Currency';

const GET_AGGREGATORS = gql`
    query($company_id: Int!) {

        aggregators: employee_aggregators(where: {company_id: {_eq: $company_id}}) {
            id
            name
            unit
            rate
            qty

        }

    }
`;

class Aggregators extends React.Component{

    state = {
        showModal: false,
        companyId: this.props.companyId,
        aggregatorId: null
    }

    render() {

        const {showModal, companyId, aggregatorId} = this.state;


        return (
            <div className="overflow-scroll w-full h-full py-12">

                <Query
                    query={GET_AGGREGATORS}
                    variables={{
                        company_id: companyId
                    }}
                >
                    {({data, loading, refetch, error}) => {

                        return (
                            <React.Fragment>
                                <div className="w-full max-w-3xl mx-auto px-4">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <h1 className="font-bold text-4xl mr-2">
                                                Aggregators 
                                            </h1>
                                            <button disabled={loading} className="ml-4 focus:outline-none text-black" onClick={() => refetch()}>
                                                <RefreshCw className={(loading) ? "rotating": null} size={24} />
                                            </button>
                                        </div>
                                        <div className="">
                                            <Button appearance="primary" intent="success" onClick={() => {

                                                this.setState({
                                                    showModal: true
                                                });

                                            }} className="flex items-center justify-center">Add Aggregator</Button>
                                        </div>
                                    </div>
                                    {loading ? <div className="flex items-center justify-center py-10">
                                        <Spinner />
                                    </div> : data.aggregators.length ? <div className="my-4">
                                        {data.aggregators.map((aggregator) => {

                                            const {qty, name, unit, rate} = aggregator;

                                            return (
                                                <div className="border-b my-4 py-4" key={aggregator.id}>
                                                    <div className="">
                                                        <div onClick={(e) => {

                                                            e.stopPropagation();

                                                            // this.props.history.push(`/${companyId}/hr/aggregators/${aggregator.id}`);

                                                            this.setState({
                                                                showModal: true,
                                                                aggregatorId: aggregator.id
                                                            });

                                                        }} className="cursor-pointer flex-1">
                                                            <div className="font-bold text-lg text-black">
                                                                {name}
                                                            </div>
                                                            <p className="">{qty} {unit.toUpperCase()} / <CurrencyFormat amount={rate} /></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );

                                        })}
                                    </div>: <p className="text-center py-10 text-black">No Aggregators found.</p>}
                                </div>
                                <Modal
                                    visible={showModal}
                                    footer={false}
                                    onCancel={() => this.setState({showModal: false})}
                                >
                                    <Aggregator
                                        companyId={companyId}
                                        aggregatorId={aggregatorId}
                                        close={() => this.setState({showModal: false})}
                                    />
                                </Modal>
                            </React.Fragment>
                        );

                    }}
                </Query>
            </div>
        );

    }

}

export default withRouter(Aggregators);