import React from 'react'
import { Modal, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons'
import {TextInputField, Spinner } from 'evergreen-ui';
import {Query, Mutation} from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import {RefreshCw} from 'react-feather';

const GET_DEPARTMENTS = gql`

    query (
        $companyId: Int!
    ) {
        departments: employee_department(where: {company_id: {_eq: $companyId}}) {
            id
            name
            created_at
            aggregate: employees_aggregate {
                aggregate {
                  count
                }
            }
        }
    }
`;

const SAVE_DEPARTMENT = gql`

    mutation(
        $id: Int,
        $name: String!,
        $companyId: Int!,
        $userId: Int
    ){
        update_employee_department(where: {
            company_id: {_eq: $companyId},
            id: {_eq: $id}
        }, _set: {
            name: $name
        }) {
            affected_rows
        }
    }

`;
const CREATE_DEPARTMENT = gql`

    mutation(
        $id: Int,
        $name: String!,
        $companyId: Int!
        $userId: Int
    ){
        insert_employee_department(objects: {
            company_id: $companyId,
            name: $name,
            user_id: $userId
        }) {
            affected_rows
        }
    }

`;

export default class EmployeeDept extends React.Component {

    state = {
        companyId: this.props.companyId,
        showModal: false,
        department: {
            name: ''
        }
    }

    render() {

        const {companyId, department, showModal} = this.state;
        const {user} = this.props;

        return (
            <div className="p-10">

                <Query
                    query={GET_DEPARTMENTS}
                    variables={{
                        companyId
                    }}
                >
                    {({data, loading, refetch, error}) => {

                        return (
                            <React.Fragment>
                                <div className="w-full max-w-3xl mx-auto px-4">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <h1 className="font-bold text-4xl mr-2">
                                                Departments 
                                            </h1>
                                            <button disabled={loading} className="ml-4 focus:outline-none text-black" onClick={() => refetch()}>
                                                <RefreshCw className={(loading) ? "rotating": null} size={24} />
                                            </button>
                                        </div>
                                        <div className="">
                                            <Button
                                                type="primary"
                                                shape="round"
                                                className="flex items-center justify-center"
                                                icon={<PlusOutlined />}
                                                onClick={() => this.setState({
                                                    showModal: true,
                                                    department:{
                                                        name: ''
                                                    }
                                                })}
                                            >
                                                Employee Departments
                                            </Button>
                                        </div>
                                    </div>
                                    {loading ? <div className="flex items-center justify-center py-10">
                                        <Spinner />
                                    </div> : data.departments.length ? <div className="my-4">
                                        {data.departments.map((department) => {

                                            const {aggregate, name, created_at} = department;

                                            return (
                                                <div className="border-b my-4 py-4" key={department.id}>
                                                    <div className="">
                                                        <div onClick={(e) => {

                                                            e.stopPropagation();

                                                            // this.props.history.push(`/${companyId}/hr/aggregators/${aggregator.id}`);

                                                            this.setState({
                                                                showModal: true,
                                                                department
                                                            });

                                                        }} className="cursor-pointer flex-1">
                                                            <div className="font-bold text-lg text-black">
                                                                {name}
                                                            </div>
                                                            <div className="flex justify-between items-center">
                                                                <p className="">{aggregate.aggregate.count} Employees</p>
                                                                <p className="">{moment(created_at).fromNow()}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );

                                        })}
                                    </div>: <p className="text-center py-10 text-black">No Departments found.</p>}

                                </div>
                            </React.Fragment>
                        );

                    }}
                </Query>

                <Modal
                    visible={showModal}
                    okText="Save"
                    title={`${department.id ? 'Edit' :'Add'} Employee Department`}
                    footer={false}
                    onCancel={() => this.setState({
                        showModal: false,
                    })}
                >
                    <div>
                        <TextInputField
                            label="Name"
                            placeholder="Enter Department Name"
                            onChange={(e) => this.setState({
                                department:{
                                    ...department,
                                    name: e.target.value
                                }
                            })}
                            value={department.name}
                        />
                    </div>
                    <div className="my-3">
                        <Mutation
                            mutation={department.id ? SAVE_DEPARTMENT: CREATE_DEPARTMENT}
                            variables={{
                                id: department.id || null,
                                name: department.name,
                                companyId,
                                userId: user.id
                            }}
                            onCompleted={() => {

                                this.setState({
                                    showModal: false,
                                    department: {
                                        name: ''
                                    }
                                })

                            }}
                        >
                            {(save, {loading}) => {

                                return (
                                    <Button ghost type="primary"
                                        onClick={() => save()}
                                        loading={loading}
                                    >
                                        Save
                                    </Button>
                                )

                            }}
                        </Mutation>
                    </div>
                </Modal>
            </div>

        )
    }
}